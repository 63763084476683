import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  installId: null,
  deviceData: {
    appVersion: null,
    browserName: null,
    browserVersion: null,
    systemName: null,
    systemVersion: null,
  },
  connectOptions: {},
  avPermissions: {
    status: null,                     // permissions status (prompt, denied, granted)
    showPermissionsModal: false,      // state to show modal to start permissions flow when they were never requested before (so not denied)
    showPermissionsHelperModal: false,//state to show modal for instructing user to provide permissions manually becuase they were denied
    showPermissionsWarning: false,    // Show header warning: Boutiq cannot access your camera or microphone 
    showLocalTracksWarning: false,    // Show header warning in case of cam/mic used by other app: Could not start camera and microphone
    showLocalTracksHelperModal: false,// state to show modal to try local tracks again (action of the above header warning)
    localTracksHelperModalAction: false, // action of above modal was clicked
  }
};

const systemsSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setInstallId(state, {payload}) {
      state.installId = payload;
    },
    setDeviceData(state, {payload}) {
      state.deviceData = payload;
    },
    setConnectOptions(state, {payload}) {
      state.connectOptions = payload;
    },
    setShowPermissionsModal(state, {payload = false}) {
      state.avPermissions = {
        ...state.avPermissions,
        showPermissionsModal: payload
      };
    },
    setShowPermissionsHelperModal(state, {payload = false}) {
      state.avPermissions = {
        ...state.avPermissions,
        showPermissionsHelperModal: payload
      };
    },    
    setShowPermissionsWarning(state, {payload = false}) {
      state.avPermissions = {
        ...state.avPermissions,
        showPermissionsWarning: payload
      };
    },
    setAVPermissionsStatus(state, {payload = null}) {
      state.avPermissions = {
        ...state.avPermissions,
        status: payload
      };
    },
    setShowLocalTracksWarning(state, {payload = false}) {
      state.avPermissions = {
        ...state.avPermissions,
        showLocalTracksWarning: payload,
      };
    },
    setShowLocalTracksHelperModal(state, {payload = false}) {
      state.avPermissions = {
        ...state.avPermissions,
        showLocalTracksHelperModal: payload
      };
    },
    setShowLocalTracksHelperModalAction(state, {payload = false}) {
      state.avPermissions = {
        ...state.avPermissions,
        localTracksHelperModalAction: payload
      };
    },    
  },
});

export const {
  setInstallId,
  setConnectOptions,
  setDeviceData,
  setShowPermissionsModal,
  setShowPermissionsHelperModal,
  setShowLocalTracksHelperModal,
  setShowLocalTracksWarning,
  setShowPermissionsWarning,
  setAVPermissionsStatus,
  setShowLocalTracksHelperModalAction,
} = systemsSlice.actions;

export default systemsSlice.reducer;
