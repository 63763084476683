import defaultLogger from 'loglevel';
import { getLogger as getVideoLogger } from '@caazam/boutiq-video-room';
import CaazamError from './utils/errors';
import storageFactory from './utils/storageFactory';
import { tryStringify } from './utils/stringify';


function getLoggingConfig() {
    const localStore = storageFactory(() => localStorage);
    let boutiqLogLevel = localStore.getItem('loglevel:boutiq-host');
    if (!boutiqLogLevel) {
        boutiqLogLevel = process.env.REACT_APP_BOUTIQ_LOGGING_DEFAULT || 'error';
    }
    let videoLogLevel = localStore.getItem('loglevel:boutiq-video');
    if (!videoLogLevel) {
        videoLogLevel = process.env.REACT_APP_VIDEO_LOGGING_DEFAULT || 'silent';
    }

    let remoteLoggingEnabled = localStore.getItem('boutiqRemoteLogging');
    if (remoteLoggingEnabled) {
        remoteLoggingEnabled = remoteLoggingEnabled === 'TRUE';
    } else {
        remoteLoggingEnabled = process.env.REACT_APP_REMOTE_LOGGING_DEFAULT === 'TRUE';
    }

    let consoleLoggingEnabled = localStore.getItem('boutiqConsoleLogging');
    if (consoleLoggingEnabled) {
        consoleLoggingEnabled = consoleLoggingEnabled === 'TRUE';
    } else {
        consoleLoggingEnabled = process.env.REACT_APP_CONSOLE_LOGGING_DEFAULT === 'TRUE';
    }

    return { boutiqLogLevel, videoLogLevel, remoteLoggingEnabled, consoleLoggingEnabled }
}

function stringifyVideoData(data) {
    let jsonData = null;

    try {
        jsonData = tryStringify(data);
    } catch (e) {
        console.error('stringifyVideoData', e);
    }
    return jsonData;
}

class AppLogger {
    constructor(remoteLoggingEnabled, consoleLoggingEnabled) {

        this.remoteLoggingEnabled = remoteLoggingEnabled;
        this.consoleLoggingEnabled = consoleLoggingEnabled;

        this.logsBuffer = [];
        this.interval = 1000;
        this.loggers = [];
        this.isSending = false;

        this.errorCounter = 0;
        this.suspendCountdown = 0;
        this.backoff = {
            multiplier: 2,
            limit: 10,
        };
        this.shopId = null;
        this.hostid = null;
    }

    backoffFunc() {
        let next = this.errorCounter * this.backoff.multiplier;
        if (next > this.backoff.limit) next = this.backoff.limit;
        return next;
    }

    startBoutiqLogger(level) {
        const boutiqLogger = defaultLogger.getLogger('boutiq-host');
        this.loggers.push(boutiqLogger);
        const originalFactory = boutiqLogger.methodFactory;
        boutiqLogger.methodFactory = function (methodName, logLevel, loggerName) {
            const rawMethod = originalFactory(methodName, logLevel, loggerName);
            if (methodName === 'error') {
                return function (message, error) {
                    this.consoleLoggingEnabled && rawMethod(message, error);
                    this.remoteLoggingEnabled && this.addLog({
                        loggerName,
                        message,
                        data: `${error.message}\n${error.stack || ''}`,
                        timestamp: new Date().toISOString(),
                        level: methodName,
                        hostId: this.hostId,
                        shopId: this.shopId,
                        callId: this.callId || null,
                    })
                }.bind(this);
            } else {
                return function (message, data) {
                    if (this.consoleLoggingEnabled) {
                        data ? rawMethod(message, data) : rawMethod(message);
                    }
                    if (this.remoteLoggingEnabled) {
                        let jsonData = null;
                        try {
                            jsonData = tryStringify(data);
                        } catch (e) {
                            //do nothing
                        }

                        this.addLog({
                            loggerName,
                            message,
                            data: jsonData,
                            timestamp: new Date().toISOString(),
                            level: methodName,
                            hostId: this.hostId,
                            shopId: this.shopId,
                            callId: this.callId || null,
                        });
                    }
                }.bind(this);
            }
        }.bind(this);
        boutiqLogger.setLevel(level);
        return boutiqLogger;
    }

    captureVideoLogger(level) {
        let videoLogger = getVideoLogger();
        this.loggers.push(videoLogger);
        
        const originalFactory = videoLogger.methodFactory;
        videoLogger.methodFactory = function (methodName, logLevel, loggerName) {
            const rawMethod = originalFactory(methodName, logLevel, loggerName);
            return function (message, data) {
                this.consoleLoggingEnabled && rawMethod(`[${loggerName}]`, message, data);
                this.addLog({
                    loggerName,
                    message: `[${loggerName}] ` + message,
                    data: stringifyVideoData(data), // not all data will be serialized
                    timestamp: new Date().toISOString(),
                    level: methodName,
                    hostId: this.hostId,
                    callId: this.callId || null,
                });
            }.bind(this);
        }.bind(this);
        videoLogger.setLevel(level);

    }

    addLog(entry) {
        this.logsBuffer.push(entry)
    }

    flushLogs() {
        const logsToSend = this.logsBuffer.length;
        if (logsToSend === 0) return;
        if (this.isSending) return;
        if (this.suspendCountdown > 0) {
            this.suspendCountdown -= 1;
            return;
        }

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: 'Bearer ' + this.token,
            },
            body: JSON.stringify(this.logsBuffer),
        }
        this.isSending = true;

        return fetch(`${process.env.REACT_APP_LOGGING_EP}/${this.hostId}`, options)
            .then(response => {
                if (response.ok) {
                    this.logsBuffer = this.logsBuffer.slice(logsToSend);
                    this.errorCounter = 0;
                    this.suspendCountdown = 0;
                }
                else {
                    return response
                        .json()
                        .catch((nonJsonError) => {
                            throw new CaazamError(
                                response.status,
                                nonJsonError.message || 'Something went wrong'
                            );
                        })
                        .then((jsonError) => {
                            throw new CaazamError(jsonError.error.code, jsonError.error.reason);
                        });
                }
            })
            .catch(error => {
                if (error instanceof CaazamError) {
                    if (error.statusCode === 401) {
                        this.refreshToken()
                            .then(token => this.token = token)
                            .catch(tokenError => console.error('Failed to refresh auth token for logging', tokenError));
                        return;
                    }
                }
                this.errorCounter += 1;
                this.suspendCountdown = this.backoffFunc();
            })
            .finally(() => this.isSending = false);
    }

    setShopId(shopId) {
        this.shopId = shopId;
    }

    setCallContext(callId) {
        this.callId = callId;
    }

    async startRemote(hostId, refreshToken) {
        if (!this.remoteLoggingEnabled) return;
        try {
            this.hostId = hostId;
            this.refreshToken = refreshToken;
            this.token = await this.refreshToken();
            this.logsBuffer = this.logsBuffer.map(l => ({ ...l, hostId: hostId }));

            this.intervalTimer = setInterval(this.flushLogs.bind(this), this.interval);
        } catch (error) {
            console.error('Failed to fetch auth token for logging', error);
        }
    }

    async stopRemote() {
        clearInterval(this.intervalTimer);
        this.hostId = null;
        this.shopId = null;
        this.refreshToken = null;
        this.errorCounter = 0;
        this.suspendCountdown = 0;
    }

    setLevel(level) {
        for (const l of this.loggers) {
            l.setLevel(level);
        }
    }

}

let { boutiqLogLevel, videoLogLevel, remoteLoggingEnabled, consoleLoggingEnabled } = getLoggingConfig();
export const appLogger = new AppLogger(remoteLoggingEnabled, consoleLoggingEnabled);
export const logger = appLogger.startBoutiqLogger(boutiqLogLevel);
appLogger.captureVideoLogger(videoLogLevel);

window.onerror = (msg, url, lineNo, columnNo, error) => {
    logger.error(`Uncaught error: ${msg} in url ${url}\n`, error);
};


