import React from 'react';
import { useSelector } from 'react-redux';
import useHostStatus from '../../hooks/useHostStatus';
import { useSoundFX } from '../SoundFXProvider';
import Confirm from '../Modals/confirm';

import './OnFirstLoadModal.scss';

/* 
  modal for incentivizing the user to make a UX interaction so that we can play sounds.
 */
export default function OnFirstLoadModal({ onClose }) {
  const soundFX = useSoundFX();
  const { user: caazamUser, isUserLoading: caazamUserLoading } = useSelector(
    (state) => state.user
  );
  const setHostStatus = useHostStatus(caazamUser);

  const setAvailability = () => {
    soundFX.userInteraction();
    setHostStatus(!caazamUser.available)
      .then(() => {})
      .catch((err) => {
        console.error('Error changing status', err);
      });
  };

  const onCloseModal = () => {
    soundFX.userInteraction();
    onClose();
  };

  const ModalContent = () => {
    return (
      <p>
        Welcome back. Your availability is set to:
        <span className={caazamUser.available ? 'available' : 'unavailable'}>
          {caazamUser.available ? ' Available' : ' Unavailable'}
        </span>
      </p>
    );
  };

  return (
    <Confirm
      loading={caazamUserLoading || !caazamUser}
      content={<ModalContent />}
      cancelText={`Make me ${
        caazamUserLoading || caazamUser.available ? 'unavailable' : 'available'
      }`}
      okFunc={onCloseModal}
      cancelFunc={setAvailability}
      closeFunc={onCloseModal}
      modalClass='first-load-modal'
    />
  );
}
