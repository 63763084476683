
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';


export default function useShopCheckout(shopId, checkoutId) {

    const ref = checkoutId ? firebase.firestore().collection('shops').doc(shopId).collection('checkouts').doc(checkoutId) : null;
    const [checkoutData, checkoutDataLoading, checkoutDataError] = useDocumentData(ref);

    return { checkoutData, checkoutDataLoading }
}
