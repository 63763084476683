import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import Moment from 'moment-timezone';
import GaugeChart from 'react-gauge-chart';
import { currencyFormatter, percentFormatter } from '../../utils';

export const CompletedCallsChart = ({ data }) => {
  return (
    <LineChart width={255} height={32} data={data}>
      <Tooltip labelFormatter={(label) => Moment(label).format('MMM Do')} />
      <XAxis dataKey='date' hide={true} />
      <Line
        type='monotone'
        dataKey='callsCompleted'
        name='Completed Calls'
        stroke='var(--green-color)'
        strokeWidth={2}
      />
    </LineChart>
  );
};

export const TotalSalesChart = ({ data, currencyCode = 'USD' }) => {
  return (
    <LineChart width={255} height={32} data={data}>
      <Tooltip
        labelFormatter={(label) => Moment(label).format('MMM Do')}
        formatter={(value) => currencyFormatter(value, currencyCode)}
      />
      <XAxis dataKey='date' hide={true} />
      <Line
        type='monotone'
        dataKey='ordersTotal'
        name='Total sales'
        stroke='var(--sherbert-color)'
        strokeWidth={2}
      />
    </LineChart>
  );
};

export const NPSScoreChart = ({ value = -100 }) => {
  return (
    <div style={{ width: '40%' }}>
      <GaugeChart
        nrOfLevels={420}
        arcsLength={[0.6, 0.15, 0.2, .15]}
        colors={['#f06e63', '#f9b63e', '#3dc373', '#20ab58']}
        percent={(Number(value) + 100) * .005}
        cornerRadius={0}
        arcPadding={0}
        needleColor="#345243"
        hideText={true}
        marginInPercent={0}
      />
    </div>
  )
}

export const CSATScoreChart = ({ value = 0 }) => {
  return (
    <div style={{ width: '40%' }}>
      <GaugeChart
        nrOfLevels={420}
        arcsLength={[0.25, 0.25, 0.25, 0.25]}
        colors={['#f06e63', '#f9b63e', '#3dc373', '#20ab58']}
        percent={Number(value) / 100}
        cornerRadius={0}
        arcPadding={0}
        needleColor="#345243"
        hideText={true}
        marginInPercent={0}
      />
    </div>
  )
}
