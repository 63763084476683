import React from 'react';
import { PropTypes } from 'prop-types';
import Modal from '../modal';
import { FilledButton, OutlinedButton } from '../../buttons/buttons';
import './confirm.scss';

const buttonStyles = {
  height: '32px',
  width: 'unset',
  padding: '0 16px',
};

const Confirm = ({
  title,
  content,
  okText = 'OK',
  cancelText = 'Cancel',
  okFunc,
  cancelFunc,
  closeFunc,
  modalClass,
  loading,
}) => {
  return (
    <Modal closeFunc={closeFunc} modalClass={modalClass}>
      {!loading && (
        <>
          {title && <h3>{title}</h3>}
          {typeof content === 'string' ? <p>{content}</p> : <>{content}</>}

          <div className='actions'>
            <OutlinedButton
              className='button'
              onClick={cancelFunc}
              style={{ ...buttonStyles, marginRight: '10px' }}>
              {cancelText}
            </OutlinedButton>
            <FilledButton
            className='button'
              id="ok-btn-modal"
              onClick={okFunc}
              style={{
                ...buttonStyles,
                backgroundColor: `${
                  modalClass === 'warning' ? '#d83a68' : 'var(--main-color)'
                }`,
              }}>
              {okText}
            </FilledButton>
          </div>
        </>
      )}
    </Modal>
  );
};

Confirm.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node.isRequired,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  okFunc: PropTypes.func.isRequired,
  cancelFunc: PropTypes.func.isRequired,
  closeFunc: PropTypes.func.isRequired,
  modalClass: PropTypes.string,
};

export default Confirm;
