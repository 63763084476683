import {useEffect, useState} from "react";
import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import {DRAFT_ORDER_STATUS} from "../constants/const";

const useHistoryCallDraftOrder = (shopId, callId, callWithDraftOrder) => {
  const [draftOrder, setDraftOrder] = useState(null)

  useEffect(() => {
    if (callId && shopId && callWithDraftOrder) {
      const draftOrderRef = firebase
        .firestore()
        .collection('shops')
        .doc(shopId)
        .collection('draftOrders')
        .where('callId', '==', callId)
        .where('status', '!=', DRAFT_ORDER_STATUS.COMPLETED)
        .onSnapshot((snapshot) => {
          if (snapshot.docs) {
            setDraftOrder(snapshot.docs.map((doc) => ({
              ...doc.data(),
              draftOrderId: doc.id
            })).pop())
          } else {
            setDraftOrder(null)
          }
        })

      return () => {
        setDraftOrder(null)
        draftOrderRef()
      }
    }
  }, [callId, shopId, callWithDraftOrder])

  return {
    draftOrder
  }
}

export default useHistoryCallDraftOrder;