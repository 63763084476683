import React from 'react';
import './app-settings.scss';
import {useHostSettingsProvider} from "../../../../components/hostSettingsProvider/host-settings-provider";
import SettingRow from "./setting-row/setting-row";

const AppSettings = () => {
  const {
    hostShops,
    onlyActiveShopEvents,
    toggleActiveShopEvents,
    toggleMuteIncomingCallSound,
    isIncomingCallSoundMuted,
  } = useHostSettingsProvider();

  return (
    <div className='app-settings-container'>
      <div className='app-settings-wrapper'>        
        <SettingRow
          isChecked={isIncomingCallSoundMuted}
          toggleFunc={toggleMuteIncomingCallSound}
          title={'Mute incoming call sound'}
        />
        {hostShops.length > 1 && (
          <SettingRow
            isChecked={!onlyActiveShopEvents}
            toggleFunc={toggleActiveShopEvents}
            title={'Show events across all my stores'}
          />
        )}
      </div>
    </div>
  )
}

export default AppSettings;