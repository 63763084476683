import React from 'react';
import './available-slots-skeleton.scss'
import LoadingCard from '../loading-card/loading-card';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';

const AvailableSlotsSkeleton = () => {
  return (
    <div className='available-slot-skeleton-container'>
      <LoadingCard
        height={40}
        width={130}
      />
      <LoadingCard
        marginTop={20}
        height={26}
        width={260}
      />
      <div className='available-slot-skeleton-date-selector-container'>
        <ArrowBackIos color={'disabled'} />
        <LoadingCard
          height={26}
          width={'40%'}
        />
        <LoadingCard
          height={26}
          width={'40%'}
        />
        <ArrowForwardIos color={'disabled'} />
      </div>
      <div className='available-slot-skeleton-slots-container'>
        {Array(3).fill(0).map((_, key) => (
          <div className='available-slot-skeleton-slot' key={key}>
            <LoadingCard
              margin={0}
              height={50}
              width={'100%'}
              borderRadius={25}
            />
          </div>
        ))}
      </div>
      <LoadingCard
        marginTop={30}
        height={60}
        width={'50%'}
        borderRadius={25}
      />
    </div>
  )
}

export default AvailableSlotsSkeleton;
