import { useCallback } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/analytics';
import { useList } from 'react-firebase-hooks/database';
import { ref } from 'firebase/database';
import {parseIdString} from '../utils';
import { logger } from '../logging';

export const useSessionProducts = (callId) => {
  const productsRef = callId ? firebase.database().ref(`session/${callId}/products`) : null;

  const [
    sessionSnapshots,
    sessionSnapshotLoading,
    sessionSnapshotError,
  ] = useList(callId ? ref(firebase.database(), `session/${callId}/products`) : null)

  /**
 * Product data added to showroom must conform to an object. 
 * Product object and product search result obbject retruned by REST API conform to the required
 * interafce. 
 * All other callers of this function should adapt;
 * @param {string}  product.productId           // can be clean ('123') or full identifier ('gid://shopify/...')
 * @param {string}  product.title
 * @param {string}  [product.description]       // either description or descriptionHtml
 * @param {string}  [product.descriptionHtml]   // either description or descriptionHtml
 * @param {string}  [image]                     // image url string
 * @param {string}  [images[].url]              //array of images
 * @param {number}  maxVariantPrice
 * @param {number}  minVariantPrice
 * @param {number}  price
 * @param {string}  currencyCode
 * @param {number}  [compareAtPrice]
 *  */

  const addToSession = useCallback(async (product) => {
    try {
      const productVal = productDataToShowroom(product);
      const newRef = await productsRef.push(productVal);
      logger.info('addToShowroom', { key: newRef.key, productId: product.productId });

      firebase.analytics().logEvent('select_content', {
        content_type: 'showroom_product',
        content_id: callId,
        items: [{
          id: productVal.productId,
          name: productVal.productTitle,
        }]
      })
    } catch (error) {
      logger.error(`addToShowroom failed ${product.productId}`, error);
    }
  }, [productsRef]);

  const removeSessionProduct = (productKey) => {
    return productsRef.child(productKey).remove()
      .then(() => logger.info('removeSessionProduct', { key: productKey }))
      .catch(error => logger.error(`removeSessionProduct error ${productKey}`, error));
  }

  const clearSession = () => {
    return productsRef.remove()
      .then(() => logger.info('clearShowroom'))
      .catch(error => logger.error('clearShowroom', error));
  }

  return {
    sessionSnapshots,
    addToSession,
    removeSessionProduct,
    clearSession,
    sessionSnapshotLoading,
    sessionSnapshotError,
  };
};

function productDataToShowroom(productData) {
  
  let image = null;
  if (typeof productData.image === 'string') {
    image = productData.image;
  } else if (productData.images && productData.images.length > 0) {
    image = productData.images[0].url;
  }

  return {
    productId: parseIdString(productData.productId),
    productTitle: productData.title,
    productDescription: productData.descriptionHtml || productData.descriptionHtml || '',
    image,
    maxVariantPrice: productData.maxVariantPrice,
    minVariantPrice: productData.minVariantPrice,
    price: productData.price,
    compareAtPrice: productData.compareAtPrice || null,
    currencyCode: productData.currencyCode,
  };
}
