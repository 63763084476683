import React, { useState, useEffect } from 'react'
import RateIcon from '../../assets/icons/RateIcon';
import './styles.scss';
import { FEEDBACK_TYPE } from '../../utils/consts';

export const RatingStars = ({ value, styling }) => {
    
    if (value == null) return null;

    return (
        <div className='rating-stars-container'>
            <RateIcon type={styling.type} />
            <span className='rating-value'>
                {value}
            </span>
        </div>
    )
}
