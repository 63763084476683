import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

import { setUserActive } from './user-reducer'

const initialState = {
  availableShops: [],
  activeShopId: null,
  callShopId: null,
};

const shopSlice = createSlice({
  name: 'shops',
  initialState: initialState,
  reducers: {
    setAvailableShops(state, { payload }) {
      state.availableShops = payload;
    },
    setActiveShopId(state, { payload }) {
      state.activeShopId = payload;
    },
    setCallShopId(state, { payload }) {
      state.callShopId = payload;
    },
    resetCallShopId(state) {
      state.callShopId = null;
    },
    resetShopState(state) {
      state.availableShops = [];
      state.activeShopId = null;
      state.callShopId = null;
    }
  },
});

export const { setCallShopId, resetCallShopId, resetShopState } = shopSlice.actions;

export const setActiveShop = (shopId) => (dispatch) => {
  dispatch(shopSlice.actions.setActiveShopId(shopId));
};

export const initHostShops = (shopPermissions) => async (dispatch) => {
  if (shopPermissions) {
    let shopIds = [];
    Object.keys(shopPermissions).forEach((shopId) => {
      if (shopPermissions[shopId].includes('host')) {
        shopIds.push(shopId);
      }
    });
    let hostShops = {};
    for (const shopId of shopIds) {
      const shopRef = firebase.firestore().collection('shops').doc(shopId);
      const shopDocument = await shopRef.get();
      if (shopDocument.exists) {
        const shop = shopDocument.data();
        shop.updatedAt = shop.updatedAt.toMillis();
        shop.createdAt = shop.createdAt.toMillis();
        hostShops[shopId] = shop;
      } else {
        console.error(`shop doesn't exist`);
      }
    }
    dispatch(shopSlice.actions.setAvailableShops(hostShops));
    const shopCount = Object.keys(hostShops).length;
    dispatch(setUserActive(shopCount > 0));
  } else {
    dispatch(shopSlice.actions.setAvailableShops([]));
    dispatch(setUserActive(false));
  }
};

export const updateActiveShop = (userId, activeShopId) => async (dispatch) => {
  const userRef = firebase.firestore().collection('users').doc(userId);
  try {
    await userRef.update({ activeShop: activeShopId });
  } catch (e) {
    //TODO: error handling in UI
    console.error('there was an error switching stores');
  }
};

export default shopSlice.reducer;
