import React, { createContext, useContext, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import { logger } from '../logging';
import { useAuthState } from 'react-firebase-hooks/auth';
import useMessaging from './useMessaging';
import useInstallId from './useInstallId';
import useCaazamInit from '../hooks/useCaazamInit';

export const signInWithEmailAndPassword = (email, password) =>
  firebase.auth().signInWithEmailAndPassword(email, password);
export const signInWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  return firebase.auth().signInWithPopup(provider);
};

export const AuthStateContext = createContext(null);

export default function AppAuthStateProvider(props) {
  const [user, loading, authError] = useAuthState(firebase.auth());
  const { stopInstallId } = useInstallId(user);
  const { messagingError, stopMessaging } = useMessaging(user);
  const { waitForUserInit } = useCaazamInit(user, loading);

  authError && console.error('AppAuthStateProvider', authError);
  messagingError && console.error('messagingError', messagingError);

  const signOut = async () => {   
    logger.info('signout') ;
    await Promise.all([stopMessaging(), stopInstallId()]);
    await firebase.auth().signOut();
  };

  return (
    <AuthStateContext.Provider value={{ authenticatedUser: user, signOut, waitForUserInit }}>
      {props.children}
    </AuthStateContext.Provider>
  );
}

export function useAppAuthState() {
  const context = useContext(AuthStateContext);
  if (!context) {
    throw new Error(
      'useAppAuthState must be used within the AppAuthStateProvider'
    );
  }
  return context;
}
