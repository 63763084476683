import React from 'react';
import AddIcon from '@material-ui/icons/Add';

import {FilledButton} from "../../buttons/buttons";
import ChatTime from "../chatTime";
import RoomItem from "../roomItem";

import './index.scss';

const PendingChats = ({pendingRooms, pressPlus, isActionInProgress, openDialog, selectedRoom}) => {
  if (!pendingRooms || pendingRooms.length === 0) {
    return null;
  }

  return (
    <>
      {pendingRooms.map((item, index) => {
          const customer = item.participants[item.initiator.uuid];
          const colorStyle = (selectedRoom && selectedRoom.id === item.id) ? 'gray' : 'white';

          return (
            <li key={index} className='menu-item-container'>
              <div className={colorStyle} onClick={() => openDialog(item)}>
                <div className='space'>
                  {(!!customer)
                    ? (!!customer.displayName)
                      ? <h4 className='customer-name customer'>{customer.displayName}</h4>
                      : <h4>{customer.email}</h4>
                    : null
                  }
                  <FilledButton
                    onClick={(event) => {
                      event.stopPropagation();
                      pressPlus(item);
                    }}
                    disabled={isActionInProgress}
                    style={{
                      height: '30px',
                      width: '30px',
                      backgroundColor: 'var(--main-color)',
                      color: 'white',
                      WebkitTextFillColor: 'white',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <AddIcon style={{ fontSize: '1rem' }} />
                  </FilledButton>
                </div>
                <div className='space'>
                  <RoomItem item={item} />
                  <ChatTime item={item} />
                </div>
                <hr />
              </div>
            </li>
          )
        }
      )}
    </>
  )
};

export default PendingChats;
