import React, { useState } from 'react';
import { useShowroomPresetProvider } from '../../../../../providers/preset-provider/preset-provider';
import './preparation-tab-save-action.scss'
import { SAVE_SHOWROOM_ACTION } from '../../../../../constants/const';

const PreparationTabSaveAction = ({ position = 'bottom' }) => {
  const {
    onShowSaveShowroomModal,
    callPreparationShowroom,
    preparationProducts,
    editedPreset
  } = useShowroomPresetProvider()
  const [saveAsModalVisible, setSaveAsModalVisible] = useState(false)

  const onHandleClick = (event) => {
    if (preparationProducts.length) {
      if (callPreparationShowroom?.presetId || editedPreset) {
        setSaveAsModalVisible(prev => !prev)
      } else {
        onShowSaveShowroomModal(SAVE_SHOWROOM_ACTION.SAVE)
      }
      event.stopPropagation()
    }
  }

  return (
    <div
      className='preparation-tab-save-action'
      onClick={(event) => onHandleClick(event)}
      onMouseLeave={() => setSaveAsModalVisible(false)}
    >
      <p className={`preset-button${preparationProducts?.length ? '' : ' disabled'}`}>Save</p>
      <div className={`more-call-action-modal${saveAsModalVisible ? ' visible' : ''} ${position}`}>
        <ul>
          <li onClick={() => onShowSaveShowroomModal(SAVE_SHOWROOM_ACTION.SAVE)}>
            Save
          </li>
          <li onClick={callPreparationShowroom?.presetId || editedPreset ? () => onShowSaveShowroomModal(SAVE_SHOWROOM_ACTION.SAVE_AS) : null}>
            {'Save As...'}
          </li>
        </ul>
      </div>
    </div>
  )
}

export default PreparationTabSaveAction;