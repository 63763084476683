import React from 'react';

const IconNotificationBag = (props) => (
  <svg width={44} height={44} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M23.273 6.118c1.857 0 3.637.76 4.95 2.115a7.34 7.34 0 0 1 2.05 5.107v1.007l7.008-.23c.303 0 .597.095.84.269l.118.093c.264.232.431.552.47.898l2.555 22.656a1.398 1.398 0 0 1-.357 1.1 1.437 1.437 0 0 1-1.071.472l-34.125.513a1.456 1.456 0 0 1-1.071-.472 1.411 1.411 0 0 1-.358-1.1l4.15-22.27c.04-.346.207-.666.47-.899a1.45 1.45 0 0 1 .96-.36l6.41-.21.001-1.467a7.34 7.34 0 0 1 2.05-5.107 6.892 6.892 0 0 1 4.95-2.115Z"
        fill="#A9D6D0"
      />
      <path
        d="M36.048 11.408H6.993c-.597 0-1.174.218-1.621.614a2.417 2.417 0 0 0-.801 1.54L2.015 36.31a2.407 2.407 0 0 0 .61 1.887A2.437 2.437 0 0 0 4.438 39h34.165a2.45 2.45 0 0 0 1.813-.803 2.419 2.419 0 0 0 .61-1.887L38.47 13.56a2.416 2.416 0 0 0-.8-1.54 2.447 2.447 0 0 0-1.622-.613Zm-29.055 2h29.055c.11 0 .215.04.296.111.078.07.127.164.138.265l2.556 22.749a.41.41 0 0 1-.105.322.437.437 0 0 1-.33.145H4.438a.451.451 0 0 1-.33-.145.418.418 0 0 1-.105-.322l2.555-22.748a.417.417 0 0 1 .14-.266.447.447 0 0 1 .295-.11Z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M21.52 4c2.142 0 4.196.855 5.71 2.377a8.127 8.127 0 0 1 2.356 5.425l.006.307v5.687a1 1 0 0 1-1.993.117l-.007-.117v-5.687c0-1.621-.64-3.176-1.78-4.322A6.052 6.052 0 0 0 21.52 6c-1.609 0-3.152.643-4.29 1.787a6.126 6.126 0 0 0-1.774 4.037l-.007.285v5.687a1 1 0 0 1-1.993.117l-.007-.117v-5.687c0-2.15.85-4.211 2.362-5.732A8.052 8.052 0 0 1 21.521 4Z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default IconNotificationBag