import React from 'react';
import { PropTypes } from 'prop-types';
import Modal from '../modal';
import './info.scss';

const InfoModal = ({ title, isClosable }) => {
  return (
    <Modal isClosable={isClosable} modalClass='info-dialog-container'>
      <h3>{title}</h3>
    </Modal>
  );
};

InfoModal.propTypes = {
  title: PropTypes.string.isRequired,
  isClosable: PropTypes.bool,
};

export default InfoModal;
