import React from 'react';

const IconChatActive = (props) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M27.141 24.006c1.298-1.484 1.547-3.375.746-5.672l.516-5.154c.981-1.871.981-3.589 0-5.153-1.473-2.346-2.744-2.68-5.458-3.542-3.148-1-9.868 2.778-11.355 2.778-3.965 0-7.163 5.719-7.277 7.463-.111 1.704-.46 6.17 2.103 8.79 2.562 2.618 5.565 1.715 8.641 2.19 3.076.474 10.136.527 12.084-1.7Z"
        fill={props.fillColor ?? '#D0D5FF'}
      />
      <g fill={'#66676C'} fillRule="nonzero">
        <path d="M25.3 6.263H6.7a1.345 1.345 0 0 0-1.345 1.345v16.784a1.345 1.345 0 0 0 1.522 1.334l.15-.03c.198-.049.382-.142.54-.274l3.401-2.861.224-.081H25.3a1.346 1.346 0 0 0 1.346-1.345V7.608a1.346 1.346 0 0 0-1.346-1.345Zm-18.6 1h18.6a.346.346 0 0 1 .345.345v13.527a.346.346 0 0 1-.346.345H11.104l-.171.03-.39.142-.15.087-3.47 2.917a.346.346 0 0 1-.568-.264V7.608a.345.345 0 0 1 .346-.345Z" />
        <path d="M19.382 12.18a.5.5 0 0 1 .09.992l-.09.008h-6.764a.5.5 0 0 1-.09-.991l.09-.009h6.764ZM19.382 15.562a.5.5 0 0 1 .09.992l-.09.008h-6.764a.5.5 0 0 1-.09-.992l.09-.008h6.764Z" />
      </g>
    </g>
  </svg>
);

export default IconChatActive