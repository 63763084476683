import React, { useMemo } from 'react';
import './call-preparation-tab.scss';
import SideBarProductSearch from '../side-bar-product-search/side-bar-product-search';
import { useCallDetailsProvider } from '../call-details-provider/call-details-provider';
import CallDetailsCartItem
  from '../call-showroom-tab/call-details-cart-component/call-details-cart-item/call-details-cart-item';
import { useShowroomPresetProvider } from '../../../../providers/preset-provider/preset-provider';
import ListProductSkeleton from '../../../../components/skeletons/list-product-skeleton/list-product-skeleton';
import PreparationTabHeader from './preparation-tab-header/preparation-tab-header';
import { useSelector } from 'react-redux';
import { MAX_SHOWROOM_PRODUCTS } from '../../../../utils/consts';

const CallPreparationTab = ({visible}) => {
  const {callDetails} = useCallDetailsProvider();
  const {
    preparationProducts,
    onUpdatePreparationProducts,
    showroomPresetLoading,
    isProductsPresetChanged,
  } = useShowroomPresetProvider()
  const { activeShopId } = useSelector((state) => state.shops);

  return (
    <div className={`call-preparation-tab${visible ? ' visible' : ''}`}>
      <PreparationTabHeader
        hasProducts={preparationProducts?.length}
      />
      <SideBarProductSearch
        disabled={preparationProducts?.length >= MAX_SHOWROOM_PRODUCTS}
        placeholder={'Search for products'}
        products={preparationProducts}
        onUpdateProducts={onUpdatePreparationProducts}
        showVariantSelector={false}
        {...callDetails}
        shopId={callDetails?.shopId ?? activeShopId}
      />
      {preparationProducts?.length > 0 && <div className={'call-preparation-products-counter'}>
        <p className={preparationProducts?.length >= MAX_SHOWROOM_PRODUCTS ? 'limit' : null}>
          {preparationProducts?.length} product{preparationProducts?.length != 1 ? 's' : ''} in showroom
          {preparationProducts?.length >= MAX_SHOWROOM_PRODUCTS
            && ' (max)'
          }
        </p>
      </div>}
      {isProductsPresetChanged && (
        <p className='stale-data'>{'Some products in this showroom are no longer available'}</p>
      )}
      <div className='call-preparation-tab-products-list'>
        {showroomPresetLoading && !preparationProducts.length ? (
          <ListProductSkeleton />
        ) : (preparationProducts.length ? (
            preparationProducts.map((product) => (
              <CallDetailsCartItem
                key={(product.variantId ?? product.productId) + 'selectedProduct'}
                product={product}
                call={callDetails}
                onUpdateProducts={onUpdatePreparationProducts}
                products={preparationProducts}
                withVariant={false}
              />
            ))
          ) : (
            <div className='empty-results'>
              <p>Prepare a showroom for {callDetails?.customerName?.split(' ')[0] ?? 'a client'}</p>
              <p>Search and add products or load a saved showroom</p>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default CallPreparationTab;