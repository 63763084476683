import React, {useEffect, useMemo, useState} from 'react';
import './timezone-selector.scss';
import {OptionListModal} from "../Modals/option-list-modal/option-list-modal";
import useTimezones from "../../hooks/useTimezones";
import {formatTimezone, localTimezone, localTimezoneGMTOffset} from "../../utils";
import TimezoneSelectorSkeleton from '../skeletons/timezone-selector-skeleton/timezone-selector-skeleton';

const TimezoneSelector = ({timezone, onSelectTimezone, activator}) => {
  const [timezoneSettingVisible, setTimezoneSettingVisible] = useState(false);
  const {firebaseTimezones, loadTimezones} = useTimezones()

  const timezones = useMemo(() => (
    (firebaseTimezones?.length ? [{
      id: localTimezone,
      offset: localTimezoneGMTOffset(),
    }, ...firebaseTimezones] : [])
      .sort((a, b) => a.offset - b.offset)
  ), [firebaseTimezones])

  useEffect(() => {
    if (timezoneSettingVisible) {
      const selectedTimezoneIndex = timezones.findIndex(({id}) => id === timezone.id)
      let element = document.getElementById(`timezone ${selectedTimezoneIndex}`)
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    }
  }, [timezoneSettingVisible, loadTimezones])

  const changeListVisibility = () => setTimezoneSettingVisible(current => !current)

  const timezoneListItem = (item, index) => {
    const {id} = item;
    const time = new Intl.DateTimeFormat([], {timeZone: id, timeStyle: 'short'}).format(new Date())
    return (
      <button
        id={`timezone ${index}`}
        key={`timezone ${index}`}
        onClick={() => onSelectTimezone(item)}
        className={`locale-item${id === timezone?.id ? ' selected' : ''}`}
      >
        <span>{id}</span>
        <span className='timezone-time'>{`${time}(${formatTimezone(id)})`}</span>
      </button>
    )
  }

  return (
    <div className='timezone-selector'>
      {activator(changeListVisibility)}
      {timezoneSettingVisible && (
        <OptionListModal positionX={'left'} onClose={changeListVisibility}>
          {loadTimezones ? (
            <TimezoneSelectorSkeleton />
          ) : (
            timezones.map(timezoneListItem)
          )}
        </OptionListModal>
      )}
    </div>
  )
}

export default TimezoneSelector;
