import {
  SearchInput
} from '../../../../pages/video-room/components/sidebar/customer-profile/components/search-input/search-input';
import { FilledButton } from '../../../buttons/buttons';
import React, { useState } from 'react';
import { useShowroomPresetProvider } from '../../../../providers/preset-provider/preset-provider';
import './showroom-preset-editmode-header.scss'

const ShowroomPresetEditModeHeader = () => {
  const {
    showroomPresetsList,
    onShowEditPresetModal,
    filterText,
    onChangeFilter,
    isEditPresetMode
  } = useShowroomPresetProvider();

  return (
    <div className='showroom-preset-edit-mode-wrapper'>
      {showroomPresetsList.length ? (
        <div className={`filter-preset-wrapper ${isEditPresetMode ? 'edit-mode' : ''}`}>
          <SearchInput
            placeholder={'Search showrooms'}
            searchInputValue={filterText}
            onChange={onChangeFilter}
          />
          {isEditPresetMode && (
            <button
              className={'btn-plus'}
              onClick={onShowEditPresetModal}
            />
          )}
        </div>
      ) : (
        <FilledButton
          className='button'
          style={{width: '45%', height: '46px', justifySelf: 'flex-end'}}
          onClick={onShowEditPresetModal}
        >
          {'Create Showroom'}
        </FilledButton>
      )}
    </div>
  )
}

export default ShowroomPresetEditModeHeader;