import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import useCaazamREST from "./useCaazamREST";

export const CartSourceType = {
  session : 'session',
  preloaded: 'preloaded',
}

const useCallCartActions = (callId, shopId) => {
  const {createCart} = useCaazamREST()

  const onCreateCart = () => {
    return createCart(shopId, callId)
  }

  const onUpdateCart = (cartId, products) => {
    return firebase
      .firestore()
      .collection('shops')
      .doc(shopId)
      .collection('carts')
      .doc(cartId)
      .set({
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        products: JSON.parse(JSON.stringify(products))
          .filter((product) => !!product.variantId) //to remove undefined fields and products without variantId
          .map(product => ({
            ...product,
            source: CartSourceType.session
          }))
      }, {merge: true})
  }

  return ({
    onUpdateCart,
    onCreateCart
  })
}

export default useCallCartActions;