import { useState, useEffect, useCallback } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import useCaazamREST from '../hooks/useCaazamREST';

export default function useIncomingCall(userId) {
  const [incomingCallData, setIncomingCallData] = useState(null);
  const [incomingCallError, setIncomingCallError] = useState(null);
  const { acceptCall, rejectCall } = useCaazamREST();

  // Listen to incoming calls on database
  useEffect(() => {
    if (userId) {
      const unsub = firebase
        .firestore()
        .collection('calls')
        .where(`assignedHosts.${userId}`, '==', true)
        .where('status', '==', 'ringing')
        .limit(1)
        .onSnapshot(
          (snapshot) => {
            if (snapshot.size === 0) setIncomingCallData(null);
            else {
              snapshot.docChanges().forEach((change) => {
                if (change.type === 'added') {
                  setIncomingCallData({
                    id: change.doc.id,
                    ...change.doc.data(),
                  });
                }
              });
            }
          },
          (error) => {
            console.error('useIncomingCall ERROR', error);
            setIncomingCallError(error);
          }
        );

      return () => unsub();
    }
  }, [userId]);

  // TBD: also use message events from Firebase messaging

  const acceptInCall = useCallback(() => {
    return incomingCallData
      ? acceptCall(incomingCallData.shopId, incomingCallData.id)
      : null;
  }, [incomingCallData]);

  const rejectInCall = useCallback(() => {
    return incomingCallData
      ? rejectCall(incomingCallData.shopId, incomingCallData.id)
      : null;
  }, [incomingCallData]);

  return {
    incomingCallData,
    incomingCallError,
    acceptInCall,
    rejectInCall,
  };
}
