import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CaazamLoader } from '@caazam/caazam-ui';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import { FilledButton, StyledInput } from '../../components';
import './auth.scss';

function ResetPassword({ actionCode, continueUrl }) {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(null);
  const [hasPasswordError, setPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  let history = useHistory();

  useEffect(() => {
    firebase
      .auth()
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        setEmail(email);
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(
          'The reset password action is not valid. Please resend reset password email'
        );
        setEmail(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [actionCode]);

  useEffect(() => {
    setButtonDisabled(!password);
  }, [password]);

  const handlePassword = (e) => {
      setPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  useEffect(() => {
    if (password) {
      if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/.test(password)) {
        setPasswordError(true);
        setErrorMessage(
          'Minimum eight characters, at least one letter and one number'
        );
      } else if (confirmPassword && password !== confirmPassword) {
        setPasswordError(true);
        setErrorMessage('The two passwords that you entered do not match!');
      } else {
        setPasswordError(false);
        setErrorMessage(null);
      }
    } else {
      setPasswordError(false);
      setErrorMessage(null);
    }
  }, [password, confirmPassword ])

  const onSubmit = () => {
    if (!password) return;
    if (!confirmPassword && !hasPasswordError) {
      setPasswordError(true);
      setErrorMessage('Please confirm your password');
      return;
    }
    if (!hasPasswordError) {
      setButtonLoading(true);
      firebase
        .auth()
        .confirmPasswordReset(actionCode, password)
        .then(() => {
          firebase.analytics().logEvent('host_reset_password');
          if (continueUrl) window.location.replace(continueUrl);
          else history.replace('/login');
        })
        .catch((error) => {
          setButtonLoading(false);
          console.error(error);
          var message = 'There was an error resetting your password';
          switch (error.code) {
            case 'auth/expired-action-code':
              message =
                'The reset password action has expired. Please resend reset password email';
              break;
            case 'auth/invalid-action-code':
              message =
                'The reset password action is not valid. Please resend reset password email';
              break;
            case 'auth/user-disabled':
              message = 'This user is disbaled';
              break;
            case 'auth/user-not-found':
              message = 'This user was deleted';
              break;
            default:
              break;
          }
          setErrorMessage(message);
        });
    }
  };

  return (
    <div className='auth-page'>
      <div className='background-element' />
      <img src='../boutiq.svg' className='brand-logo' />
      <div className='auth-form-container'>
        <div className='auth-spinner'>{loading && <CaazamLoader />}</div>
        {!loading && email && (
          <form>
            <h2>Reset Your Password</h2>
            <div className='input-container'>
              <StyledInput
                placeholder='Password'
                type='password'
                onChange={handlePassword}
              />
              <StyledInput
                placeholder='Confirm Password'
                type='password'
                onChange={handleConfirmPassword}
              />
            </div>
            <div className='form-footer'>
              <FilledButton
                isLoading={buttonLoading}
                onClick={onSubmit}
                disabled={isButtonDisabled}
                style={{ marginBottom: '10px' }}>
                Confirm
              </FilledButton>
              <p className='error-text'>{errorMessage}</p>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

//https://caazam-remote-retail-host-dev.web.app/auth/action?mode=resetPassword&oobCode=-tTw8M3lUsM2fq38urXo4du8GTHn2hTL-Lxcu1Tq07wAAAFzLPgqtw&apiKey=AIzaSyCo7nQlGr3sYGy37uYEa2TZN2tNUdWxh_U&lang=en

export default function Auth() {
  const queryParams = new URLSearchParams(window.location.search);
  // Get the action to complete.
  var mode = queryParams.get('mode');
  // Get the one-time code from the query parameter.
  var actionCode = queryParams.get('oobCode');
  // (Optional) Get the continue URL from the query parameter if available.
  var continueUrl = queryParams.get('continueUrl');
  // (Optional) Get the language code if available.
  var lang = queryParams.get('lang') || 'en';
  // Get the api Key param
  var apiKey = queryParams.get('apiKey');

  if (!actionCode) {
    return <p>Action code is missing</p>;
  }
  switch (mode) {
    case 'resetPassword':
      return (
        <ResetPassword actionCode={actionCode} continueUrl={continueUrl} />
      );
    case 'signIn':
      /* 
      need to work around Firebase limitation that there can be only 1 custom 
      action handler for auth so if we want our own custom password reset, verify email, etc
      we have to manually reconstruct the signin link generated by the host invite and redirect 
      to ourselves
      */
      const signinSearchParams = new URLSearchParams({
        mode,
        oobCode: actionCode,
        apiKey,
      });
      const target = `${continueUrl}&${signinSearchParams.toString()}`;
      window.location.assign(target);
      return;
    case 'recoverEmail':
    case 'verifyEmail':
    default:
      return <p>This action is not supported</p>;
  }
}
