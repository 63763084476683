import React from 'react';
import './conversation-summary-tab.scss'
import TextSkeleton from '../../../../components/skeletons/text-skeleton/text-skeleton';

const ConversationSummaryTab = ({callSummary, loading}) => {

  if (loading) {
    return (
      <div className={'conversation-summary-tab-container'}>
        <TextSkeleton />
      </div>
    )
  }

  return (
    <div className={'conversation-summary-tab-container'}>
      {typeof callSummary == 'string' ? (
        <p>{callSummary}</p>
      ) : (
        <div className={'summary-grid'}>
          {callSummary.customer_background &&
            <p>{callSummary.customer_background}</p>
          }
          {callSummary.call_summary &&
            <p>{callSummary.call_summary}</p>
          }
          {callSummary.conslusion_and_followup &&
            <p>{callSummary.conslusion_and_followup}</p>
          }
          {callSummary.measurements &&
            <div className={'summary-grid'}>
              {callSummary.measurements.map(m => (
                <div>
                  <p>{m.measurement_name}:</p>
                  <p>{m.value}:</p>
                  <p>{m.unit}:</p>
                </div>
              ))}
            </div>
          }
        </div>
      )}
    </div>
  )
}

export default ConversationSummaryTab;
