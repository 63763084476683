import React from 'react';
import './SelectFlowModal.scss'
import {FilledButton} from "../../../../components";
import Modal from "../../../../components/Modals/modal";

export const SelectFlowModal = ({
  onClose,
  showRescheduleFlow,
  showAskClientFlow,
  customerName,
  customerEmail
}) => {

  const description = (label) => (
    <p className='flow-description'>
      {label}
      <span className='customer-name'>{customerName}</span>
      {' at '}
      <span className='customer-name'>{customerEmail}</span>
    </p>
  )

  return (
    <Modal closeFunc={onClose} modalClass='call-details-modal'>
      <div className={'select-flow'}>
        <div style={{marginBottom: '20%'}}>
          <FilledButton
            className='button'
            style={{ width: '100%', marginTop: '10px' }}
            onClick={showRescheduleFlow}>
            Reschedule yourself
          </FilledButton>
          {description('Select a new time for the appointment. An email with updated details will be sent to ')}
        </div>
        <div>
          <FilledButton
            className='button'
            style={{ width: '100%', marginTop: '10px' }}
            onClick={showAskClientFlow}>
            Client request
          </FilledButton>
          {description('A rescheduling request email will be sent to ')}
        </div>
      </div>
    </Modal>
  )
}