import React from 'react';
import { FORM_OBJECT_TYPES } from '../formBuilder/templateFormBuilder';
import DatePicker from 'react-datepicker';
import Moment from 'moment-timezone';
import './OptionElement.scss';
import 'react-datepicker/dist/react-datepicker.css';

export const OptionElement = ({ disableDateTimePicker, disableDatePicker, disableSelect, element, onValueChange, styles = {} }) => {
    return (
        <div className={`option-cotainer-wrapper${element.visibility ? ' visible' : ''}`} style={styles.wrapper}>
            <div className='option-cotainer' style={styles.container}>
                <span className='option-label' style={styles.title}>{`${element.label ? element.label + ':' : ''}`}</span>
                {FORM_OBJECT_TYPES.select === element.type &&
                    <select 
                        disabled={disableSelect} 
                        value={element.value} 
                        className='option-value' 
                        onChange={(e) => onValueChange(e.target.name, e.target.value)} 
                        name={element.name}>
                        {element.values.map((option, index) => (
                            <option key={`${index}-${element.name}`} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                }
                {FORM_OBJECT_TYPES.date === element.type &&
                    <DatePicker
                        disabled={disableDatePicker}
                        name={element.name}
                        selected={element.value}
                        onChange={(date) => onValueChange(element.name, date)}
                        minDate={element.minDate}
                        minTime={element.minTime}
                        maxTime={element.maxTime}
                        dateFormat='d MMM yyyy'
                        timeIntervals={30}
                    />
                }
                {FORM_OBJECT_TYPES.dateTime === element.type &&
                    <>
                        <DatePicker
                            disabled={disableDateTimePicker}
                            name={element.name}
                            selected={element.value}
                            onChange={(date) => onValueChange(element.name, date)}
                            showTimeSelect
                            timeFormat='h:mm aa'
                            minDate={element.minDate}
                            minTime={element.minTime}
                            maxTime={element.maxTime}
                            timeIntervals={30}
                            dateFormat='d MMM yyyy h:mm aa'
                        />
                    </>
                }
            </div>
        </div>
    )
}
