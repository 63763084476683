import React from 'react';
import {useSelector} from "react-redux";
import {CLIENT_SEARCH_RESULT} from "../../constants/const";

const PATH = [
  "M 0,-100 A 100,100 0 0,1 86.6,-50",
  "M 86.6,-50 A 100,100 0 0,1 86.6,50",
  "M 86.6,50 A 100,100 0 0,1 0,100",
  "M 0,100 A 100,100 0 0,1 -86.6,50",
  "M -86.6,50 A 100,100 0 0,1 -86.6,-50",
  "M -86.6,-50 A 100,100 0 0,1 0,-100"
]

const CircleProgress = ({percentage, amount, description, userIsFetched, circleBackground}) => {
  const {clientSearchStatus} = useSelector(state => state.clients);

  const step = PATH.length
  const division = 100/step;
  const divisionCount = percentage === -1
    ? userIsFetched ? 0 : 1
    : Math.floor(percentage/division);

  const divisionPercentage = () => {
    const currentPercentage = Math.round(100 * (percentage%division) / division)
    if (currentPercentage <= step || currentPercentage >= 100 - step) {
      return step
    }
    return currentPercentage
  }

  const strokeOptions = (index) => {
    if (index < divisionCount) {
      return {
        strokeOpacity: 1,
        strokeDasharray: 100
      }
    }
    if (index === divisionCount) {
      return {
        strokeOpacity: 1,
        strokeDasharray: `${divisionPercentage()}, 100`
      }
    }
    return {
      strokeOpacity: 0,
      strokeDasharray: 0
    }
  }

  const circlePath = (d, index) => (
    <path
      key={index}
      d={d}
      stroke={`url(#gradient${index})`}
      strokeDasharray={strokeOptions(index).strokeDasharray}
      strokeOpacity={strokeOptions(index).strokeOpacity}
      strokeLinecap='round'
    />
  )

  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-10 -10 220 220">
      <defs>
        <linearGradient id="gradient0" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="1" y2="1" fill={'black'}>
          <stop offset="0%" stopColor={"#ef9795"}/>
          <stop offset="100%" stopColor={"#f4b592"}/>
        </linearGradient>
        <linearGradient id="gradient1" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0" y2="1" fill={'black'}>
          <stop offset="0%" stopColor={"#f4b592"}/>
          <stop offset="100%" stopColor={"#f9d890"}/>
        </linearGradient>
        <linearGradient id="gradient2" gradientUnits="objectBoundingBox" x1="1" y1="0" x2="0" y2="1" fill={'black'}>
          <stop offset="0%" stopColor={"#f9d890"}/>
          <stop offset="100%" stopColor={"#e6e4a2"}/>
        </linearGradient>
        <linearGradient id="gradient3" gradientUnits="objectBoundingBox" x1="1" y1="1" x2="0" y2="0" fill={'black'}>
          <stop offset="0%" stopColor={"#e6e4a2"}/>
          <stop offset="100%" stopColor={"#b4e0d1"}/>
        </linearGradient>
        <linearGradient id="gradient4" gradientUnits="objectBoundingBox" x1="0" y1="1" x2="0" y2="0" fill={'black'}>
          <stop offset="0%" stopColor={"#b4e0d1"}/>
          <stop offset="100%" stopColor={"#8ee09c"}/>
        </linearGradient>
        <linearGradient id="gradient5" gradientUnits="objectBoundingBox" x1="0" y1="1" x2="1" y2="0" fill={'black'}>
          <stop offset="0%" stopColor={"#8ee09c"}/>
          <stop offset="100%" stopColor={"#86e766"}/>
        </linearGradient>
      </defs>
      <circle  cx={100} cy={100} r={108} fill='#f2f2f2'/>
      <circle  cx={100} cy={100} r={92} fill={circleBackground}/>
      {(percentage !== -1 || clientSearchStatus !== CLIENT_SEARCH_RESULT.LOADING) && <text x="45%" y="40%" dy=".3em" fontSize="26px" strokeWidth="1px" textAnchor="middle" fill="black">{amount ? amount : 'N/A'}</text>}
      {percentage !== -1 && <text x="45%" y="60%" dy=".3em" fontSize="22px" strokeWidth="1px" textAnchor="middle" fill="#66676C">{description}</text>}
      <g fill="none" strokeWidth="16" transform="translate(100,100)">
        {PATH.map(circlePath)}
      </g>
    </svg>
  )
}

export default CircleProgress
