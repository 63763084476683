import { useDocumentData } from 'react-firebase-hooks/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useAbandonedCart(shopId, cartId) {

    const ref = cartId ? firebase.firestore().collection('shops').doc(shopId).collection('carts').doc(cartId) : null;
    const [abandonedCartItems, abandonedCartLoading, abandonedCartError] = useDocumentData(ref);
    abandonedCartError && console.error('useAbandonedCartItems', shopId, cartId, abandonedCartError);

    const deleteDiscount = () => {
        ref.update({ cartDiscount: null });
    }

    const submitDiscount = (discountData) => {
        ref.update({ cartDiscount: discountData });
    }

    return { abandonedCartItems, abandonedCartLoading, deleteDiscount, submitDiscount }
}

export function useAbandonedCartActions(shopId, cartId) {

    const ref = cartId ? firebase.firestore().collection('shops').doc(shopId).collection('carts').doc(cartId) : null;

    const deleteDiscount = () => {
        ref.update({ cartDiscount: null });
    }

    const submitDiscount = (discountData) => {
        ref.update({ cartDiscount: discountData });
    }

    return { deleteDiscount, submitDiscount }
}
