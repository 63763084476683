import React, {createContext, useContext, useEffect, useState} from 'react';
import './InvoiceModal.scss';
import Checkbox from "../../checkbox";
import {FilledButton, OutlinedButton} from "../../buttons/buttons";
import {useSelector} from "react-redux";
import useCaazamREST from "../../../hooks/useCaazamREST";
import {isEmail} from "../../../utils";

export const DraftOrderInvoiceContext = createContext(null);

export default function DraftOrderInvoiceProvider({children}) {
  const [visibility, setVisibility] = useState(false);
  const [customer, setCustomer] = useState();
  const [draftOrderId, setDraftOrderId] = useState();
  const [callData, setCallData] = useState();
  const [sendTo, setSendTo] = useState('');
  const [sendFrom, setSendFrom] = useState('');
  const [hostEmailSelected, setHostEmailSelected] = useState(false)
  const [subject, setSubject] = useState('')
  const [customMessage, setCustomMessage] = useState('');
  const [isInvoicePreviewLoading, setIsInvoicePreviewLoading] = useState(false)
  const [preview, setPreview] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [invalidEmailMessage, setInvalidEmailMessage] = useState('')

  const { availableShops } = useSelector((state) => state.shops);
  const shopData = availableShops && callData && callData.shopId && availableShops[callData.shopId];

  const { getInvoicePreview, sendInvoice } = useCaazamREST();

  useEffect(() => {
    if (shopData && callData && visibility) {
      setSendTo(customer?.email ?? callData.customerEmail)
      setSendFrom(shopData.contactEmail)
      setSubject(`Custom order {{name}} - call with ${shopData.name}`)
    } else {
      clearState()
    }
  }, [customer, shopData, callData, visibility])

  useEffect(() => {
    setInvalidEmailMessage('')
    const timeout = setTimeout(() => {
      if (!isEmail(sendTo)) {
        setInvalidEmailMessage('Please enter a valid email')
      }
    }, 600)
    return () => {
      clearTimeout(timeout)
    }
  }, [sendTo])

  const showInvoiceModal = ({customer, draftOrderId, callData}) => {
    setCustomer(customer)
    setDraftOrderId(draftOrderId)
    setCallData(callData)
    setVisibility(true)
  }

  const hideInvoiceModal = () => {
    clearState()
    setCustomer(null)
    setDraftOrderId(null)
    setCallData(null)
    setVisibility(false)
  }

  const clearState = () => {
    setSendTo('')
    setHostEmailSelected(false)
    setSubject('')
    setCustomMessage('')
    setPreview(null)
    setInvalidEmailMessage('')
    setErrorMessage('')
  }

  const onSendToChanged = (e) => {
    const email = e.target.value
    setSendTo(email);
  }

  const onSendForChanged = (e) => {
    setSendFrom(e.target.value);
  }

  const onSubjectChanged = (e) => {
    setSubject(e.target.value);
  }

  const onCustomMessageChange = (e) => {
    setCustomMessage(e.target.value)
  }

  const onChangeBcc = () => {
    setHostEmailSelected(!hostEmailSelected)
  }

  const onCloseInvoicePress = () => {
    setPreview(null)
    hideInvoiceModal()
  }

  const onBackPress = () => {
    setPreview(null)
  }

  const onInvoicePress = async () => {
    try {
      setIsInvoicePreviewLoading(true)
      const {previewHtml} = await getInvoicePreview(
        callData.shopId,
        {
          to: sendTo,
          from: sendFrom,
          subject,
          customMessage,
          bcc: hostEmailSelected ? callData.host.email : null
        }, draftOrderId)
      setPreview(previewHtml)
    } catch (e) {
      handleError(e)
    } finally {
      setIsInvoicePreviewLoading(false)
    }
  }

  const onSendInvoicePress = async () => {
    try {
      setIsInvoicePreviewLoading(true)
      await sendInvoice(
        callData.shopId,
        {
          to: sendTo,
          from: sendFrom,
          subject,
          customMessage,
          bcc: hostEmailSelected ? callData.host.email : null
        }, draftOrderId)
      onCloseInvoicePress()
    } catch (e) {
      handleError(e)
    } finally {
      setIsInvoicePreviewLoading(false)
    }
  }

  const handleError = (error) => {
    if (error.reason) {
      setErrorMessage(error.reason)
      return;
    }
    if (error.message) {
      setErrorMessage(error.message)
      return;
    }
    setErrorMessage('Sorry, something went wrong')
  }

  const invoiceHeader = () => (
    <div className='invoice-header'>
      <h3>Send invoice</h3>
      <button className='close-button' onClick={onCloseInvoicePress}>
        &#215;
      </button>
    </div>
  )

  const invoiceButtons = () => (
    <div className='invoice-footer'>
      <span>{errorMessage}</span>
      {preview && (
        <OutlinedButton
          className={'button'}
          style={{
            height: '44px',
            width: '100px',
            textTransform: 'capitalize',
            fontSize: '14px',
            justifySelf: 'flex-start',
            gridRow: '2/2',
          }}
          onClick={onBackPress}
        >
          Back
        </OutlinedButton>
      )}
      <FilledButton
        disabled={isInvoicePreviewLoading || invalidEmailMessage}
        isLoading={isInvoicePreviewLoading}
        className='button'
        style={{
          height: '44px',
          backgroundColor: `var(--main-color)`,
          gridColumn: '4/4',
          width: '150px',
          gridRow: '2/2',
        }}
        onClick={preview ? onSendInvoicePress : onInvoicePress}
      >
        {`${preview ? 'Send'  : 'Review' } Invoice`}
      </FilledButton>
    </div>
  )

  return (
   <DraftOrderInvoiceContext.Provider value={{
     showInvoiceModal,
     hideInvoiceModal,
   }}>
     <div className={`invoice-modal-background${visibility ? ' visible' : ''}`}>
       <div className={`invoice-modal${!visibility ? ' no-show' : ''}`}>
         {invoiceHeader()}
         {preview ? (
           <div className='invoice-container'>
             <iframe className='invoice-preview' srcDoc={preview}/>
           </div>
         ) : (
           <div className='invoice-container'>
             <div className='invoice-modal-send-container'>
               <div className='section'>
                 <p>To</p>
                 <input
                   className='invoice-input'
                   value={sendTo}
                   onChange={onSendToChanged}
                 />
                 <span>{invalidEmailMessage}</span>
               </div>
               <div className='section'>
                 <p>From</p>
                 <input
                   disabled={true}
                   className='invoice-input'
                   value={sendFrom}
                   onChange={onSendForChanged}
                 />
               </div>
             </div>
             <div className='invoice-modal-bcc'>
               <h5>Send bcc to:</h5>
               <Checkbox
                 value={hostEmailSelected}
                 label={callData?.host?.email}
                 onChange={onChangeBcc}
               />
             </div>
             <div className='section'>
               <p>Subject</p>
               <input
                 className='invoice-input'
                 value={subject}
                 onChange={onSubjectChanged}
               />
             </div>
             <div className='section'>
               <p>Custom message (optional)</p>
               <textarea
                 rows={5}
                 className='invoice-input invoice-custom-message'
                 value={customMessage}
                 onChange={onCustomMessageChange}
               />
             </div>
           </div>
         )}
         {invoiceButtons()}
       </div>
     </div>
     {children}
   </DraftOrderInvoiceContext.Provider>
  )
}

export function useDraftOrderInvoiceProvider() {
  const context = useContext(DraftOrderInvoiceContext);
  if (!context) {
    throw new Error('useDraftOrderInvoiceProvider must be used within the DraftOrderInvoiceProvider');
  }
  return context;
}