import React, {useState, useEffect, useRef, useMemo} from 'react';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import './toast.scss';
import {useSelector} from "react-redux";

const Toast = ({
    autoDismiss,
    dismissTime,
    toastQueue,
    setToastQueue}) => {

    const [currentToast, setCurrentToast] = useState(null);
    const [isAnimationComplete, setisAnimationComplete] = useState(true);
    const [isAutoDismissCycle, setisAutoDismissCycle] = useState(null);
    const [position, setPosition] = useState('top');
    const toastElement = useRef(null);
    const stepsAppearing = 30;
    const stepsHiding = 25;
    const { availableShops: hostShops } = useSelector((state) => state.shops);

    const notifiedShopName = useMemo(() => {
        if (hostShops && currentToast && (Object.keys(hostShops).length > 0)) {
            return hostShops[currentToast.shopId]?.name
        }
    }, [hostShops, currentToast])

    const isMultipleShops = useMemo(() => (
      hostShops && (Object.keys(hostShops).length > 1)
    ), [hostShops])

    const toastAnimation = {
        positionBySteps(start, end, step, steps) {
            return Math.max(0, Math.min(end, this.easeOutQuad(start, end, step, steps)));
        },
        easeOutQuad(start, end, t, steps) {
            t /= steps;
            t--;
            return Math.round(-end * (t * t * t * t - 1) + start);
        },
    };

    useEffect(() => {
        if (toastQueue.length > 0 && isAnimationComplete) {
            let toastOrder = 0;
            const position = toastQueue[toastOrder]?.type === 'info' ? 'bottom' : 'top'
            setPosition(position)
            const show = () => {
                clearInterval(isAutoDismissCycle);
                setisAnimationComplete(false);
                animateToast(false, position).then(() => {
                    setCurrentToast(toastQueue[toastOrder]);
                    animateToast(true, position).then(() => {
                        if (toastQueue.length - 1 > toastOrder) {
                            toastOrder++;
                            show();
                        } else {
                            setisAnimationComplete(true);
                        }
                    })
                })
            }
            show();
            setToastQueue([]);
            if (autoDismiss) {
                clearToast(position);
            } else {
                if (toastQueue.length === 1 && toastQueue[0].preventAutoDismiss !== true) {
                    clearToast(position);
                }
            }
        }
    }, [toastQueue, isAnimationComplete]);

    const animateToast = (direction, position = 'top') => {
        return new Promise(function (resolve) {
            if (isAnimationComplete) {
                setisAnimationComplete(false);
            }
            let step = 0;
            const start = 0;
            const end = 100;
            const currentSteps = direction ? stepsAppearing : stepsHiding;
            let requestID;

            const animate = () => {
                const contentPosition = toastAnimation.positionBySteps(
                    start,
                    end,
                    step,
                    currentSteps);
                if (step <= currentSteps) {
                    step++;
                    if (position === 'top') {
                        toastElement.current.style.transform = `translateY(${direction ? -100 + contentPosition : -1.1 * contentPosition}%)`;
                    } else {
                        if (toastElement.current?.style) {
                            toastElement.current.style.transform = `translateY(${direction ?  window.innerHeight - contentPosition : (window.innerHeight - end) + contentPosition}px)`;
                        }
                    }
                    requestID = requestAnimationFrame(() => {
                        animate();
                    });
                } else {
                    cancelAnimationFrame(requestID);
                    resolve();
                }
            }
            requestID = requestAnimationFrame(animate);
        });
    }

    const dismissToast = () => {
        if (isAnimationComplete) {
            if (isAutoDismissCycle) {
                clearInterval(isAutoDismissCycle);
            }
            animateToast(false, position).then(() => {
                setCurrentToast(null);
                setisAnimationComplete(true);
                setPosition('top');
            });
        }
    }

    const clearToast = (position) => {
        if (isAnimationComplete) {
            const lifetime = setInterval(() => {
                animateToast(false, position).then(() => {
                    setCurrentToast(null);
                    clearInterval(lifetime);
                    setisAnimationComplete(true);
                    setPosition('top');
                });
            }, dismissTime);
            setisAutoDismissCycle(lifetime);
        }
    }

    return (
        <div className={`notification-container`}>
            <div ref={toastElement} className={`toast-wrapper`}>
                {currentToast &&
                    <div className={`notification ${currentToast.type ? currentToast.type : 'default'}`}>
                        {currentToast.icon && <div className={`icon-container ${currentToast.iconType ? currentToast.iconType : ''}`}>
                            {currentToast.icon}
                        </div>}
                        <div className={`message-wrapper${currentToast.description ? '' : ' title-only'}${currentToast.type === 'info' ? ' center-text' : ''}`}>
                            {currentToast.title && <p className='title'>
                                {currentToast.title}
                            </p>}
                            {currentToast.description && <p className='description'>
                                {currentToast.description}
                            </p>}
                            {currentToast.shopId && isMultipleShops && (
                              <p className='shop-name'>{notifiedShopName}</p>
                            )}
                        </div>
                        <button onClick={dismissToast} className='dismiss-button'><CloseRoundedIcon /></button>
                    </div>
                }
            </div>
        </div>
    );
}

export default Toast;

