import { useCallback } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import useCaazamREST from './useCaazamREST';

const usePermalink = (shopId) => {
  const { createPermalink } = useCaazamREST();

  const deletePermalink = useCallback(
    (shopId, callLinkId) => {
      return firebase
        .firestore()
        .collection('shops')
        .doc(shopId)
        .collection('permalinks')
        .doc(callLinkId)
        .delete();
    },
    [shopId]
  );

  return {
    createPermalink,
    deletePermalink,
  };
};

export default usePermalink;
