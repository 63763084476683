import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { ref } from 'firebase/database'
import { useObjectVal } from 'react-firebase-hooks/database';

export default function useSessionDiscount(callId, activeCartId){
  const [cartDiscount, cartDiscountLoading, cartDiscountError] = useObjectVal(ref(firebase.database(),  `session/${callId}/cart/${activeCartId}/discount`));  
  
  return { cartDiscount, cartDiscountLoading, cartDiscountError };
};

