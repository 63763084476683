import React from 'react';
import './invite-client-button.scss';
import {
  useCallDetailsProvider
} from "../../../pages/home-page/call-details-side-bar/call-details-provider/call-details-provider";

const InviteClientButton = ({onOpenInviteClient, onOpenBlockOffTime, modalVisible, onShow}) => {
  const {sidebarAnimation} = useCallDetailsProvider()
  return (
    <div className={`btn-plus-wrapper${modalVisible ? ' modalVisible' : ''} ${sidebarAnimation}`} onClick={onShow}>
      <div className={'btn-plus'} />
      <ul>
        <li onClick={onOpenInviteClient}>Invite a client</li>
        <li onClick={onOpenBlockOffTime}>Create block off time</li>
      </ul>
    </div>
  )
}

export default InviteClientButton;