import React from "react";
import {currencyFormatter} from "../../utils";

import './styles.scss';

const ProductPrice = ({product, selectedProductVariant = null}) => {
  if (selectedProductVariant) {
    return (
      <div className='price-container'>
        <h4>
          {currencyFormatter(parseFloat(selectedProductVariant.price), selectedProductVariant.currencyCode)}
        </h4>
        <h4>
          {selectedProductVariant.compareAtPrice &&
          currencyFormatter(
            selectedProductVariant.compareAtPrice,
            selectedProductVariant.currencyCode)}
        </h4>
      </div>
    )
  }

  if (product.maxVariantPrice !== product.minVariantPrice) {
    return (
      <div className='price-container'>
        <h4>
          {`${currencyFormatter(product.minVariantPrice, product.currencyCode)} — ${currencyFormatter(product.maxVariantPrice, product.currencyCode)}`}
        </h4>
      </div>
    )
  } else {
    return (
      <div className='price-container'>
        <h4>
          {currencyFormatter(parseFloat(product.price), product.currencyCode)}
        </h4>
        <h4>
          {product.compareAtPrice && product.compareAtPrice !== product.price
          ? currencyFormatter(
            parseFloat(product.compareAtPrice),
            product.currencyCode)
          : null
          }
        </h4>
      </div>
    )
  }
}

export default ProductPrice;
