import {useEffect, useState} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import useHostCalls from "./useHostCalls";

const initialState = () => ({
  callsCompleted: 0,
  callsMissed: 0,
  ordersCount: 0,
  ordersTotal: 0,
});

export default function useHostDailyCalls(hostId, date = new Date()) {
  const startTime = new Date(new Date(date).setHours(0, 0, 0, 0));
  const endTime = new Date(new Date(date).setHours(23, 59, 59, 999));

  const [callsDailySummary, setCallsDailySummary] = useState(initialState());

  const {allCalls, isLoading} = useHostCalls((shopId) => {
    if (hostId && shopId) {
      return (
        firebase
          .firestore()
          .collection('calls')
          .where('host.id', '==', hostId)
          .where('shopId', '==', shopId)
          .where('status', 'in', ['completed', 'error'])
          .where('timestamp', '>=', startTime)
          .where('timestamp', '<=', endTime)
      )
    }
  }, [hostId, date]);

  useEffect(() => {
    const KPIs = initialState();
    if (allCalls && allCalls.length > 0) {
      allCalls.forEach((callData) => {
          KPIs.callsCompleted += callData.status === 'completed' ? 1 : 0;
          KPIs.callsMissed += callData.status === 'error' ? 1 : 0;
          KPIs.ordersCount += callData.totalOrderAmount ? 1 : 0;
          KPIs.ordersTotal += callData.totalOrderAmount
            ? callData.totalOrderAmount
            : 0;
        }
      );
    }
    setCallsDailySummary(KPIs);
  }, [allCalls])

  return {
    callsDailySummary,
    pastCalls: allCalls,
    pastCallsLoading: isLoading,
  };
}
