import React, { useState, useRef } from 'react';
import { StyledInput } from '../inputs/inputs';
import { FilledButton } from '../buttons/buttons';
import './InputWithCopy.scss';
import {useNotificationProvider} from "../notifications/NotificationProvider";

const InputWithCopy = ({ inputText }) => {
  const [isTextCopied, setTextCopied] = useState(false);
  const inputRef = useRef(null);
  const {showToast} = useNotificationProvider();

  const copyLink = () => {
    inputRef.current.select();
    document.execCommand('copy');
    setTextCopied(true);
    showToast({
      description: 'Link copied to clipboard',
      type: 'info',
    });
  };

  return (
    <div className='copy-link-container'>
      <StyledInput value={inputText} readOnly ref={inputRef}></StyledInput>
      <FilledButton
        style={{
          height: '34px',
          width: '80px',
          fontSize: '14px',
          position: 'absolute',
          right: '0',
          border: `1px solid ${isTextCopied ? 'var(--main-color-hover)' : 'var(--main-color)'}`,
          backgroundColor: `${isTextCopied ? 'var(--main-color)' : 'var(--main-color)'}`,
        }}
        className='button'
        id='copy-client-link-btn'
        onClick={copyLink}>
        {isTextCopied ? 'copied' : 'copy'}
      </FilledButton>
    </div>
  );
};

export default InputWithCopy;
