import React from 'react';
import { PropTypes } from 'prop-types';
import './modal.scss';
import {ArrowBackIos} from "@material-ui/icons";

const Modal = ({ closeFunc, children, modalClass = '', isClosable = true, backFunc }) => {
  return (
    <div className='modal-overlay'>
      <div id="host-modal" className={`modal ${modalClass}`}>
        {isClosable && (
          <button className='close-button' onClick={closeFunc}>
            &#215;
          </button>
        )}
        {backFunc && (
          <ArrowBackIos className='back-modal-button' onClick={backFunc} />
        )}
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  closeFunc: PropTypes.func,
  children: PropTypes.node.isRequired,
  modalClass: PropTypes.string,
  isClosable: PropTypes.bool,
};

export default Modal;
