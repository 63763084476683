import * as React from "react"

const ChatRegular = (props) => (
  <svg width={24} height={24} {...props} viewBox="0 0 32 32">
    <g fill={props.fillColor ?? "#66676C"} fillRule="nonzero">
      <path d="M25.3 6.263H6.7a1.345 1.345 0 0 0-1.345 1.345v16.784a1.345 1.345 0 0 0 1.522 1.334l.15-.03c.198-.049.382-.142.54-.274l3.401-2.861.224-.081H25.3a1.346 1.346 0 0 0 1.346-1.345V7.608a1.346 1.346 0 0 0-1.346-1.345Zm-18.6 1h18.6a.346.346 0 0 1 .345.345v13.527a.346.346 0 0 1-.346.345H11.104l-.171.03-.39.142-.15.087-3.47 2.917a.346.346 0 0 1-.568-.264V7.608a.345.345 0 0 1 .346-.345Z" />
      <path d="M19.382 12.18a.5.5 0 0 1 .09.992l-.09.008h-6.764a.5.5 0 0 1-.09-.991l.09-.009h6.764ZM19.382 15.562a.5.5 0 0 1 .09.992l-.09.008h-6.764a.5.5 0 0 1-.09-.992l.09-.008h6.764Z" />
    </g>
  </svg>
);

export default ChatRegular
