import React, { useState, useCallback, useRef, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';
import { useSelector } from 'react-redux';
import ReactCrop from 'react-image-crop';
import { OutlinedButton } from '../../../../../components';
import Modal from '../../../../../components/Modals/modal';
import 'react-image-crop/dist/ReactCrop.css';
import './index.scss';

const LOADING_STATES = {
    none: '',
    error: ' error',
    success: ' success'
};

export function ImageCropper({ onClose, upImg }) {
    const caazamUser = useSelector((state) => state.user.user);
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const reactCropperRef = useRef(null);
    const [imageHeight, setImageHeight] = useState('auto');
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isUploadSuccessful, setIsUploadSuccessful] = useState(LOADING_STATES.none);

    function generateDownload(canvas, crop) {
        if (!crop || !canvas) {
            return;
        }
        setIsLoading(true);
        canvas.toBlob(
            (blob) => {
                const previewUrl = URL.createObjectURL(blob);
                const fileName = `${Date.now()}_${previewUrl.split('/').slice(-1)[0]}.jpeg`;
                uploadBlob(blob, fileName)
            },
            'image/jpeg',
            1
        );
    }

    const updateAvatar = (bucket, path) => {
        var updateUserAvatar = firebase
            .functions()
            .httpsCallable('users-processUserAvatar');
        updateUserAvatar({
            fileBucket: bucket,
            filePath: path,
        })
            .then(() => {  
                firebase.analytics().logEvent("host_user_avatar_updated");
                uploadingDone(
                    () => onClose()); 
                    setIsUploadSuccessful(LOADING_STATES.success); 
                })
            .catch(error => { 
                uploadingDone(() => {
                    setIsLoading(false); 
                    setIsUploadSuccessful(LOADING_STATES.none)
                }); 
                setIsUploadSuccessful(LOADING_STATES.error); 
            })
    };

    const uploadingDone = (callback) => {
        setTimeout(() => {
            callback();
          }, 2000);
    }

    function uploadBlob(file, fileName) {
        const storage = firebase.app().storage(process.env.REACT_APP_FIREBASE_AVATARS_BUCKET);
        const storageRef = storage.ref().child('users').child(caazamUser.id).child(fileName);
        storageRef.put(file).then(() => {
            let fileBucket = storageRef.bucket;
            let filePath = storageRef.fullPath
            updateAvatar(fileBucket, filePath)
        });
    }

    const onLoad = useCallback((img) => {
        if (img.height >= img.width) {
            setImageHeight(getComputedStyle(reactCropperRef.current).height)
        }
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);

    return (
        <Modal closeFunc={onClose} modalClass='call-details-modal'>
            <div className={`image-crop-container${isLoading ? ' loading' : ''}`}>
                <h3 style={{ gridColumn: 1 / 3, gridRow: 1 }}>Update profile picture</h3>
                <div className={`image-cropper-wrapper`}>
                    <div className='upload-progress'>
                        <p
                            className={`loading-state${isUploadSuccessful}`}
                            succes-message='Picture uploaded'
                            error-message='Upload failed - please try again'
                        >
                            <span className='loading-message'>
                                Loading, please wait
                            </span>
                        </p>
                    </div>
                    <div ref={reactCropperRef} className='image-cropper-container'>
                        <ReactCrop
                            className={'image-cropper'}
                            imageStyle={{ height: imageHeight }}
                            src={upImg}
                            onImageLoaded={onLoad}
                            crop={crop}
                            onChange={(c) => setCrop(c)}
                            onComplete={(c) => setCompletedCrop(c)}
                        />
                    </div>
                </div>
                <div style={{ display: 'none' }}>
                    <canvas
                        ref={previewCanvasRef}
                        style={{
                            width: Math.round(completedCrop?.width ?? 0),
                            height: Math.round(completedCrop?.height ?? 0)
                        }}
                    />
                </div>
                <OutlinedButton
                    className='cropper-btn'
                    style={{ gridColumn: 1, gridRow: 3 }}
                    onClick={() =>
                        onClose()}>
                    Cancel
                </OutlinedButton>
                <OutlinedButton
                    isLoading={isLoading}
                    className='cropper-btn submit'
                    style={{ gridColumn: 2, gridRow: 3 }}
                    disabled={!completedCrop?.width || !completedCrop?.height}
                    onClick={() =>
                        generateDownload(previewCanvasRef.current, completedCrop)}>
                    Upload
                </OutlinedButton>
            </div>
        </Modal>
    );
}
