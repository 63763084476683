
import { useCollection } from 'react-firebase-hooks/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useShopHosts(shopId) {

    const ref = shopId ? firebase.firestore().collection('shops').doc(shopId).collection('shopUsers').where('roles', 'array-contains', 'host') : null;
    const [ query, hostsLoading, hostError] = useCollection(ref);

    const hosts = query ? query.docs.map(doc => ({ id: doc.id, ...doc.data()})) : [];

    return { hosts, hostsLoading, hostError }
}
