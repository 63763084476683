import {useEffect, useState} from "react";
import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';

export default function useShopOrder(shopId, orderIds, callId) {
  const [orderData, setOrderData] = useState(null)
  const [orderDataLoading, setOrderDataLoading] = useState(false)

  useEffect(() => {
    return () => {
      setOrderData(null)
    }
  }, [callId])

  useEffect(() => {
    if (shopId && orderIds.length) {
      setOrderDataLoading(true)
      firebase
        .firestore()
        .collection('shops')
        .doc(shopId)
        .collection('orders')
        .where(firebase.firestore.FieldPath.documentId(), 'in', orderIds)
        .get()
        .then((orders) => {
          setOrderData(orders.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id
          })))
        })
        .catch((error) => console.error('orderData', error))
        .finally(() => setOrderDataLoading(false))

    }
  }, [orderIds, shopId])

  return {
    orderData,
    orderDataLoading
  }
}
