import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import {
	useCallDetailsProvider
} from "../pages/home-page/call-details-side-bar/call-details-provider/call-details-provider";

export default function useShopCalls(shopId, statusOptions, rowsCount, hostId) {
	const {callDetails} = useCallDetailsProvider();
	const [calls, setCalls] = useState([]);
	const [getNextCalls, setNextCalls] = useState(null);
	const limit = rowsCount * 2;

	useEffect(() => {
		if (callDetails) {
			const ref = firebase
				.firestore()
				.collection('calls')
				.doc(callDetails.id)
				.onSnapshot((doc) => {
					setCalls(currentCalls => currentCalls.map((call) => {
						if (call.id === doc.id) {
							return doc
						}
						return call
					}))
				})

			return () => {
				//if we change call details until get changes from firestore
				setTimeout(() => {
					ref()
				}, 10000)
			}
		}
	}, [callDetails])

	const getCalls = async (cursor = null) => {
		let q = firebase
			.firestore()
			.collection('calls')
			.where('shopId', '==', shopId)
			.where('host.id', '==', hostId)
			.where('status', 'in', statusOptions)
			.orderBy('timestamp', 'desc')

		if (cursor) {
			q = q.startAt(cursor);
		}

		const querySnap = await q.limit(limit + 1).get();
		let docs = querySnap.docs;
		let next = null;
		if (querySnap.size > limit) {
			const nextCursor = docs.pop();
			next = () => () => getCalls(nextCursor);
		}
		return { docs, next };
	}

	useEffect(() => {
		getCalls()
			.then(({docs, next}) => {
				setCalls(prevCalls => [...prevCalls, ...docs]);
				setNextCalls(next);
			})
		return () => {
			setCalls([])
			setNextCalls(null)
		}
	}, [shopId]);

	const getMoreCalls = () => {
		if (getNextCalls) {
			getNextCalls().then(({ docs, next }) => {
				setCalls([...calls, ...docs]);
				setNextCalls(next);
			});
		}
	}

	return { calls, getMoreCalls };
}
