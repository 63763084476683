import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';

export default function useHostShopUser(shopId, userId) {
  const hostDoc = shopId && userId
    ? firebase
      .firestore()
      .doc(`shops/${shopId}/shopUsers/${userId}`) : null;

  const [value, loading, error] = useDocumentData(hostDoc);
  error && console.error('useHostShopUser', error);

  return {
    hostPermalink: value?.permalink,
    hostTeamId: value?.teamId ?? null,
    hostBio: value?.bio,
    hostDisplayName: value?.displayName,
    hostShopUserLoading: loading,
  };
}
