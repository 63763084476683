import React from 'react';
import { HEADER_NOTIFICATION_TYPES } from '../../utils/consts';
import { FilledButton } from '../buttons/buttons';
import './header-notifications.scss';
import {useDispatch, useSelector} from "react-redux";
import {setShowPermissionsHelperModal, setShowPermissionsModal, setShowLocalTracksHelperModal} from "../../reducers/system-reducer";
import {PERMISSIONS_STATUS} from "../../constants/permissions";

const HeaderNotification = ({ type, incomingCallProps, rejoiningCallProps, permissionsProps }) => {
  const dispatch = useDispatch();
  const { avPermissions: { status, showLocalTracksHelperModal } } = useSelector(state => state.system);


  const showPermissionsModal = () => {
    if (status === PERMISSIONS_STATUS.PROMPT) {
      dispatch(setShowPermissionsModal(true));
    } else {
      dispatch(setShowPermissionsHelperModal(true));
    }
  }

  const showPermissionsLockedModal = () => {
    dispatch(setShowLocalTracksHelperModal(!showLocalTracksHelperModal));
  }

  switch (type) {
    case HEADER_NOTIFICATION_TYPES.INCOMING_CALL:
      {
        const {
          customerName,
          acceptCall,
          rejectCall,
          shopName,
        } = incomingCallProps;
        return (
          <div id="header-notification-success" className='header-notification success'>
            <h4>
              Incoming call from <strong>{customerName}</strong> on{' '}
              <strong>{shopName}</strong>
            </h4>
            <FilledButton
              id="success-answer-btn"
              onClick={acceptCall}
              style={{
                width: '132px',
                height: '32px',
                backgroundColor: 'white',
                color: 'var(--green-color)',
                WebkitTextFillColor: 'var(--green-color)',
                textTransform: 'capitalize',
              }}>
              Answer
            </FilledButton>
            <button id='reject-call-btn' onClick={rejectCall}>Reject</button>
          </div>
        );
      }
    case HEADER_NOTIFICATION_TYPES.REJOINING_CALL:
      {
        const {
          rejoinCall,
          teardownCall,
          customerName,
          shopName,
        } = rejoiningCallProps;
        return (
          <div id="header-notification-warning" className='header-notification warning'>
            <h4>
              Call is in progress with <strong>{customerName}</strong> on <strong>{shopName}</strong>
            </h4>
            <FilledButton
              id="success-answer-btn"
              onClick={rejoinCall}
              style={{
                width: '132px',
                height: '32px',
                backgroundColor: 'white',
                color: 'var(--warning-color)',
                WebkitTextFillColor: 'var(--warning-color)',
                textTransform: 'capitalize',
              }}>
              Rejoin Call
            </FilledButton>
            <button id='reject-call-btn' onClick={teardownCall}>End call</button>
          </div>
        );
      }
    case HEADER_NOTIFICATION_TYPES.SHOP_INACTIVE:
      return (
        <div className='header-notification error'>
          <h4>This shop is inactive</h4>
        </div>
      );
    case HEADER_NOTIFICATION_TYPES.AV_PERMISSIONS_DENIED:
      return (
        <div className='header-notification error'>
          <h4>Boutiq cannot access your camera or microphone</h4>
          {
            !permissionsProps?.isHideHelper &&
              <button className='help-link' onClick={showPermissionsModal}>help</button>
          }
        </div>
      );
    case HEADER_NOTIFICATION_TYPES.AV_PERMISSIONS_LOCKED:
      return (
        <div className='header-notification error'>
          <h4>Could not start camera or microphone</h4>
          <button className='help-link' onClick={showPermissionsLockedModal}>help</button>
        </div>
      );
    default:
      console.error(
        'Unknown notification type provided to header notification'
      );
      return null;
  }
};

export default HeaderNotification;
