const existingVariants = {
  variantMap: {},
};
existingVariants.setVariant = (variant) => {
  existingVariants.variantMap[variant.variantId] = variant;
};
existingVariants.getVariant = (variantId) => {
  return existingVariants.variantMap[variantId];
};

export default existingVariants;
