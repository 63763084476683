import React, { useEffect}  from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/analytics';
import { useList } from 'react-firebase-hooks/database';
import { ref } from 'firebase/database';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveCartId } from '../reducers/active-call-reducer';
import { logger } from '../logging';


const CartSourceType = {
  session : 'session',
  preloaded: 'preloaded',
}

export function useActiveCart(callId) {
  const dispatch = useDispatch();
  useEffect(()=>{
    if (callId) {
      const activeCartRef = firebase.database().ref(`session/${callId}/cart/activeCartId`);
      activeCartRef.on('value', snap => dispatch(setActiveCartId({activeCartId: snap.val()})));
      return () => {
        activeCartRef.off();
        dispatch(setActiveCartId({activeCartId: null }));
      }
    }
  },[callId]);
} 

export const useSessionCart = (callId) => {
  const { activeCartId } = useSelector((state) => state.activeCall);
  const cartRef = callId && activeCartId ? firebase.database().ref(`session/${callId}/cart/${activeCartId}/products`) : null;

  const [cartSnapshots, cartSnapshotLoading, cartSnapshotError] = useList(
    callId && activeCartId ? ref(firebase.database(),  `session/${callId}/cart/${activeCartId}/products`) : null
  );
  cartSnapshotError && console.error('useSessionCart', cartSnapshotError);

  const addToCart = async (product) => {
    const { productId, productTitle, variantId, currentPrice, currencyCode, inventoryPolicy, inventoryQuantity } = product;

    try {
      await cartRef.push({ ...product, source: CartSourceType.session });
      logger.info('addToCart', { key: cartRef.key, productId, variantId, currentPrice, inventoryPolicy, inventoryQuantity });
      firebase.analytics().logEvent('add_to_cart', {
        value: currentPrice,
        currency: currencyCode,
        items: [{
          id: productId,
          name: productTitle,
          price: currentPrice,
          quantity: 1,
        }]
      })
    } catch (error) {
      logger.error(`addToCart failed ${productId} ${variantId}`, error);
    }
  };

  const updateCartProduct = (key, newData) =>
    cartRef.child(key).update({ ...newData, source: CartSourceType.session })
      .then(() => logger.info('updateCartProduct', { key, ...newData }))
      .catch(error => logger.error(`updateCartProduct failed ${key}`, error));

  const removeCartProduct = async (productKey, product) => {
    const { productId, productTitle, variantId, currentPrice, currencyCode } = product;

    try {
      await cartRef.child(productKey).remove();
      logger.info('removeCartProduct', { productKey, productId, variantId });
      firebase.analytics().logEvent('remove_from_cart', {
        value: currentPrice,
        currency: currencyCode,
        items: [{
          id: productId,
          name: productTitle,
          price: currentPrice,
          quantity: 1,
        }]
      })
    } catch (error) {
      logger.error(`removeCartProduct failed ${productKey} ${productId} ${variantId}`, error);
    }
  }

  return {
    cartSnapshots,
    addToCart,
    updateCartProduct,
    removeCartProduct,
    cartSnapshotLoading,
    cartSnapshotError,
  };
};
