import React, {useState, useEffect} from "react";
import useCaazamREST from "../../../../hooks/useCaazamREST";
import './RescheduleFlowModal.scss';
import SelectSlot from "../../../../components/InviteToCallModal/select-slot";
import InviteSuccess from "../../../../components/InviteToCallModal/invite-success";
import InviteError from "../../../../components/InviteToCallModal/invite-error";
import {logger} from "../../../../logging";
import Modal from "../../../../components/Modals/modal";
import {localTimezone} from '../../../../utils';
import useScheduledSlots from '../../../../hooks/useScheduledSlots';
import SummaryData from '../../../../components/InviteToCallModal/summary-data/summary-data';
import {useSelector} from 'react-redux';

const PAGES = {
    SCHEDULE: 'schedule',
    SUMMARY: 'summary',
    SUCCESS: 'success',
    ERROR: 'error',
};

export const RescheduleFlowModal = ({
    onClose,
    shopId,
    scheduleId,
    currentUser,
    timestamp,
    endTimestamp,
    callLinkUrl,
    customerName,
    customerEmail,
    icsDownloadUrl,
    onBack,
    isInviteFlow = true,
    customerTimezone,
    eventType
}) => {
    const { rescheduleEvent } = useCaazamREST();
    const [page, setPage] = useState(PAGES.SCHEDULE);
    const [error, setError] = useState(null);
    const routeToError = (error) => {
        setPage(PAGES.ERROR)
        setError(error)
    };
    const returnToMain = () => onClose();
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [slot, setSlot] = useState({ timestamp, endTimestamp });

    const [isScheduling, setIsScheduling] = useState(null);
    const [timezone, setTimezone] = useState(null);
    const {availableSlots} = useScheduledSlots(scheduleId, eventType?.eventTypeId);

    const selectSlot = (session) => {
        if (session.isAvailable) {
            setSelectedSlot(session);
        }
    };

    useEffect(() => {
        if (customerTimezone) {
            setTimezone({id: customerTimezone})
        }
    }, [customerTimezone])

    const reschedule = () => {
        setIsScheduling(true);
        rescheduleEvent(shopId, scheduleId, {
            slot: {
                start: selectedSlot.timeSlot,
                end: selectedSlot.timeSlotEnd
            },
            customerTimezone: timezone?.id ?? null
        }).then(() => {
            setSlot({
                timestamp: selectedSlot.timeSlot,
                endTimestamp: selectedSlot.timeSlotEnd,
            });
            setPage(PAGES.SUCCESS);
        })
            .catch((err) => {
                logger.error('reschedule', err);

                routeToError(err)
            })
            .finally(() => setIsScheduling(false));
    };

    const navigateToSummary = () => setPage(PAGES.SUMMARY)

    const onPressBack = () => {
        if (page === PAGES.SCHEDULE) {
            onBack()
        }
        if (page === PAGES.SUMMARY) {
            setPage(PAGES.SCHEDULE);
        }
    }

    const content = () => {
        switch (page) {
            case PAGES.SCHEDULE:
                return (
                  <SelectSlot
                    isInviteFlow={isInviteFlow}
                    selectSlot={selectSlot}
                    selectedSlot={selectedSlot}
                    availableSlots={availableSlots}
                    isScheduling={isScheduling}
                    scheduleCall={navigateToSummary}
                    rescheduledSlot={timestamp}
                  />
                )
            case PAGES.SUMMARY:
                return (
                  <SummaryData
                    selectedEvent={eventType}
                    selectedSlot={selectedSlot}
                    scheduleCall={reschedule}
                    timezone={timezone}
                    customerName={customerName}
                    customerEmail={customerEmail}
                    onSelectTimezone={setTimezone}
                    isScheduling={isScheduling}
                  />
                )
            case PAGES.SUCCESS:
                return (
                  <InviteSuccess
                    callLink={callLinkUrl}
                    timeSlot={{
                        timeSlot: slot.timestamp,
                        timeSlotEnd: slot.endTimestamp,
                    }}
                    customerName={customerName}
                    customerEmail={customerEmail}
                    icsDownloadUrl={icsDownloadUrl}
                  />
                )
            case PAGES.ERROR:
                return (
                  <InviteError returnToMain={returnToMain} isBannedClient={error?.code === 403} />
                )
            default:
                return null
        }

    }

    return (
        <Modal
          backFunc={page === PAGES.SCHEDULE || page === PAGES.SUMMARY ? onPressBack : null}
          closeFunc={onClose}
          modalClass='call-details-modal'
        >
            {content()}
        </Modal>
    )
}
