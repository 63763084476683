import React, {useState} from 'react';
import './AskClientFlowModal.scss';
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import useScheduledCalls from "../../../../hooks/useScheduledCalls";
import {FilledButton} from "../../../../components";
import Modal from "../../../../components/Modals/modal";

const PAGES = {
  ASK_CLIENT: 'askClient',
  SUCCESS: 'success',
};

export const AskClientFlowModal = ({
  onClose,
  scheduleId,
  shopId,
  customerName,
  customerEmail,
  onBack
}) => {

  const {
    requestClientToReschedule,
    fetchRequestClientToReschedule,
    requestClientToRescheduleError
  } = useScheduledCalls(shopId);

  const [note, setNote] = useState('')
  const [page, setPage] = useState(PAGES.ASK_CLIENT)

  const sendNotesToClient = () => {
    requestClientToReschedule({
      scheduleId,
      note,
      onSuccess: () => setPage(PAGES.SUCCESS)
    })
  }

  const onChangeNote = (e) => {
    const { value } = e.target;
    setNote(value);
  }

  const askClientPage = () => (
    <>
      <div>
        <p className='description-for-client'>
          {'A rescheduling request email will be sent to '}
          <span className='customer-name'>{customerName}</span>
          {' at '}
          <span className='customer-name'>{customerEmail}</span>
        </p>
        <textarea
          name="note"
          rows='10'
          value={note}
          placeholder="Add a personal note"
          onChange={onChangeNote}
          className='text-input'
        />
        {requestClientToRescheduleError && (
          <p className='error-message'>{requestClientToRescheduleError.message}</p>
        )}
      </div>
      <FilledButton
        className='button'
        style={{ width: '100%' }}
        isLoading={fetchRequestClientToReschedule}
        onClick={sendNotesToClient}>
        Send
      </FilledButton>
    </>
  )

  const successPage = () => (
    <div className='success-container'>
      <div className='description-success-container '>
        <CheckCircleOutlineRoundedIcon
          style={{
            fontSize: '120px',
            marginBottom: '28px',
            color: 'var(--main-color)',
            WebkitTextFillColor: 'var(--main-color)',
          }}
        />
        <p className='success-description'>
          {'Rescheduling request successfully sent to '}
          <span className='customer-name'>{customerName}</span>
          {' at '}
          <span className='customer-name'>{customerEmail}</span>
        </p>
      </div>
      <FilledButton
        className='button'
        style={{ width: '100%' }}
        onClick={onClose}>
        Done
      </FilledButton>
    </div>
  )

  return (
    <Modal backFunc={page === PAGES.ASK_CLIENT ? onBack : null} closeFunc={onClose} modalClass='call-details-modal'>
      {page === PAGES.ASK_CLIENT && askClientPage()}
      {page === PAGES.SUCCESS && successPage()}
    </Modal>
  )
}