import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {useSelector, useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom';
import useCaazamREST from '../../../hooks/useCaazamREST';

import roomsRequests from '../../../modules/chat/api/rooms/requests';
import {setActiveRoomId} from '../../../modules/chat/actions/activeRoom';
import './chat-menu.scss';
import PendingChats from "../../../components/chat/pendingChatsList";
import ActiveChatsList from "../../../components/chat/activeChatsList";

const ChatMenu = ({
                    setIsChatVisible,
                    pendingRooms,
                    activeRooms,
                    setSelectedRoom,
                    selectedRoom,
                    user,
                    setRouteForNotifications,
                  }) => {

  const numberOfPendingMessages = !!pendingRooms && pendingRooms.length;
  const isNumberOfMessagesEmpty = numberOfPendingMessages === 0;
  const {activeShopId} = useSelector((state) => state.shops);
  const { joinAsHost, isActionInProgress } = roomsRequests({ shopId: activeShopId });
  const dispatch = useDispatch();
  const { activateChat }  = useCaazamREST();
  const history  = useHistory();

  const backToDashboard = () => {
    setRouteForNotifications('/');
    setIsChatVisible(false);
    dispatch(setActiveRoomId(null));
  };

  const pressPlus = (item) => {
    joinAsHost({ activateChat, chatId: item.id });
    openDialog(item);
  };

  const openDialog = (item) => {
    setSelectedRoom(item.id);
  };

  const renderStyles = () => {
    if (isNumberOfMessagesEmpty) {
      return ['smallPending', 'bigActive'];
    }
    if (numberOfPendingMessages === 1) {
      return ['onePending', 'oneActive'];
    }
    if (numberOfPendingMessages === 2) {
      return ['twoPending', 'twoActive'];
    }
    if (numberOfPendingMessages === 3) {
      return ['threePending', 'threeActive'];
    }
    if (numberOfPendingMessages === 4) {
      return ['fourPending', 'fourActive'];
    }
    if (numberOfPendingMessages > 4) {
      return ['fourPending', 'fourActive'];
    }

    return ['smallPending', 'bigActive'];
  };

  return (
    <div className='chat-sections'>
      <div className='pending-header'>
        <button className='back-btn' onClick={backToDashboard}>
          <div className='back-btn-label-wrapper'>
            <div className='back-btn-icon'>
              <ArrowBackIcon />
            </div>
            <span className='back-btn-label'>Back</span>
          </div>
        </button>
        <div className='row'>
          <p>PENDING</p>
          {!isNumberOfMessagesEmpty &&
          <div className='indicator'>{numberOfPendingMessages}</div>
          }
        </div>
      </div>
      <section className={`pending-menu ${renderStyles()[0]}`}>
        <ul className='menu-list'>
          <PendingChats
            pendingRooms={pendingRooms}
            isActionInProgress={isActionInProgress}
            selectedRoom={selectedRoom}
            pressPlus={pressPlus}
            openDialog={openDialog}
          />
        </ul>
      </section>
      <section className={`active-menu ${renderStyles()[1]}`}>
        <p>ACTIVE</p>
        <ul className='menu-list'>
          <ActiveChatsList
            activeRooms={activeRooms}
            selectedRoom={selectedRoom}
            user={user}
            openDialog={openDialog}
          />
        </ul>
      </section>
    </div>
  )
}

export default ChatMenu;
