import * as React from "react"

const ActiveUser = (props) => (
  <svg width={24} height={24} {...props} viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <path
        d="M27.23 26.539c.538-.616 1.14-2.177.76-4.279-.524-2.904-1.6-1.967-1.6-4.538 0-.631-.206-1.664 0-3.768.23-2.341-1.018-4.852-1.794-6.088-1.472-2.345-4.329.259-7.043-.603-2.098-.667-4.338.162-6.72 2.487-4.03 2.954-6.388 5.61-7.073 7.972-1.028 3.54-.092 5.265 2.47 7.883 2.563 2.619 5.566 1.716 8.642 2.19 3.076.475 10.41.97 12.358-1.256Z"
        fill="#D0D5FF"
      />
      <g fill="#66676C" fillRule="nonzero">
        <path
          d="M16 5.075a7.752 7.752 0 1 0 0 15.505 7.752 7.752 0 0 0 0-15.505Zm0 1a6.752 6.752 0 1 1 0 13.505 6.752 6.752 0 0 1 0-13.505Z"/>
        <path
          d="M16 19.58a13.195 13.195 0 0 1 11.426 6.594.5.5 0 1 1-.866.5 12.195 12.195 0 0 0-21.12 0 .5.5 0 1 1-.866-.5A13.196 13.196 0 0 1 16 19.58Z"/>
      </g>
    </g>
  </svg>
);

export default ActiveUser
