import { useCollection } from 'react-firebase-hooks/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useScheduleEmailEvents(shopId, scheduleId) {

  const ref = (shopId && scheduleId)
    ? firebase.firestore()
      .collection('shops')
      .doc(shopId)
      .collection('schedule')
      .doc(scheduleId)
      .collection('scheduleNotifications')
      .orderBy('sentAt', 'desc')
    : null;

  const [scheduleEmailEvents, scheduleEmailEventsLoading, scheduleEmailEventsLoadingError] = useCollection(ref);
  scheduleEmailEventsLoadingError && console.error(scheduleEmailEventsLoadingError);
  return { scheduleEmailEvents, scheduleEmailEventsLoading, scheduleEmailEventsLoadingError }
}