import { useRef, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import Moment from 'moment-timezone';

import { useCollection } from 'react-firebase-hooks/firestore';

const getInitialState = (startDate, endDate) => {
    var data = {
        callsCompleted: 0,
        ordersCount: 0,
        ordersTotal: 0,
        conversionRate: null,
        totalCallsRated: 0,
        totalRating: 0,
        totalCallsNPSRated: 0,
        totalNPSPromoters: 0,
        totalNPSPassives: 0,
        totalNPSDetractors: 0,
        totalCallsCSATRated: 0,
        totalCSATPromoters: 0,
        daily:{},
    };
    var dt = Moment(startDate);
    const endCondition = Moment(endDate);
    while (dt.isSameOrBefore(endCondition)) {
        data.daily[dt.format('YYYY-MM-DD')] = {
            callsCompleted: 0,
            ordersCount: 0,
            ordersTotal: 0,
            conversionRate: 0,
        };
        dt = dt.add(1, 'day');
    }

    return data;
}


export default function useHostCallsHistory(shopId, hostId, date = new Date()) {
    const startTime = Moment(date).subtract(30, 'days').startOf('day').toDate();
    const endTime = Moment(date).endOf('day').toDate();

    const [callsHistorySummary, setCallsHistorySummary] = useState(getInitialState(startTime, endTime));

    const dbRef = hostId && shopId
        ? firebase
            .firestore()
            .collection('calls')
            .where('host.id', '==', hostId)
            .where('shopId', '==', shopId)
            .where('status', 'in', ['completed', 'error'])
            .where('timestamp', '>=', startTime)
            .where('timestamp', '<=', endTime)
        : null;

    const [callsHistoryQuery, callsHistoryQueryLoading, callsHistoryQueryError] = useCollection(dbRef);
    callsHistoryQueryError && console.error('useHostCallsHistory', callsHistoryQueryError);

    useEffect(() => {

        let KPIs = getInitialState(startTime, endTime);

        if (callsHistoryQuery && !callsHistoryQueryLoading) {
            callsHistoryQuery.docs.forEach(doc => {
                const callData = doc.data();
                const { status, totalOrderAmount, callFeedback, timestamp } = callData;
                const formattedDate = Moment(timestamp.toDate()).format('YYYY-MM-DD');
                const callCount = status === 'completed' ? 1 : 0;
                const ordersCount = totalOrderAmount ? 1 : 0;
                const ordersTotal = totalOrderAmount || 0;
                KPIs.callsCompleted += callCount;
                KPIs.ordersCount += ordersCount;
                KPIs.ordersTotal += ordersTotal;
                if (KPIs.daily[formattedDate]) {
                    KPIs.daily[formattedDate].callsCompleted += callCount;
                    KPIs.daily[formattedDate].ordersCount += ordersCount;
                    KPIs.daily[formattedDate].ordersTotal += ordersTotal;
                }
                if (callFeedback?.[0]) {
                    const { rating, npsRating, csatRating } = callFeedback[0];
                    if (rating != null) {
                        KPIs.totalCallsRated += 1;
                        KPIs.totalRating += rating;
                    } else if (npsRating != null) {
                        KPIs.totalCallsNPSRated += 1;
                        KPIs.totalNPSPromoters += npsRating >= 9 ? 1 : 0;
                        KPIs.totalNPSPassives += npsRating >= 7 && npsRating <= 8 ? 1 : 0;
                        KPIs.totalNPSDetractors += npsRating <= 6 ? 1 : 0;
                    } else if (csatRating != null) {
                        KPIs.totalCallsCSATRated += 1;
                        KPIs.totalCSATPromoters += csatRating >= 4 ? 1 : 0;
                    }
                }
            });

            KPIs.conversionRate = KPIs.callsCompleted > 0 ? KPIs.ordersCount / KPIs.callsCompleted : null;
            Object.keys(KPIs.daily).forEach(day => {
                KPIs.daily[day].conversionRate = KPIs.daily[day].callsCompleted > 0 ? KPIs.daily[day].ordersCount / KPIs.daily[day].callsCompleted : null;
            });
        }
        setCallsHistorySummary(KPIs);

    }, [callsHistoryQuery, callsHistoryQueryLoading])

    return {
        callsHistorySummary,
        callsHistoryLoading: callsHistoryQueryLoading,
    };
}
