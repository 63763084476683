import { APP_DIAGNOSTICS_ACTION_TYPES } from "../pages/home-page/host-setting/constants/appDiagnostics";

const initPreflight = {
  report: null,
  error: null,
  stopped: false,
  signalingGatewayReachable: false,
  turnServersReachable: false,
};

const initialState = {
  duration: null,
  preflightTest: Object.assign({}, initPreflight),
  preflightTestInProgress: false,
  preflightTestFinished: false,
  reportSent: false,
  finalResult: null,
};

const appDiagnostics = (state = initialState, action) => {
  switch (action.type) {
    case APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_STARTED: {
      return {
        ...state,
        duration: action.duration,
        preflightTestInProgress: true,
        preflightTestFinished: false,
        preflightTest: {
          ...Object.assign({}, initPreflight),
        }
      }
    }

    case APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_COMPLETED: {
      return {
        ...state,
        preflightTest: {
          ...state.preflightTest,
          report: action.report,
          signalingGatewayReachable: action.report?.websocketConnectivity?.result !== 'failed',
          turnServersReachable: action.report?.networkConnectivity?.result !== 'failed',
        },
        preflightTestFinished: true,
        preflightTestInProgress: false,
      }
    }

    case APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_FAILED: {
      return {
        ...state,
        preflightTest: {
          ...state.preflightTest,
          error: action.error,
        },
        preflightTestFinished: true,
        preflightTestInProgress: false,
      }
    }

    case APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_STOPPED:
      return {
        ...state,
        preflightTest: {
          ...state.preflightTest,
          stopped: true,
          report: action.report,
        },
        preflightTestInProgress: false,
        preflightTestFinished: false,
}



    case APP_DIAGNOSTICS_ACTION_TYPES.SET_TWILIO_STATUS:
      return {
        ...state,
        twilioStatus: action.statusObj,
        twilioStatusError: null,
        twilioStatusFinished: true,
      }

    case APP_DIAGNOSTICS_ACTION_TYPES.SET_TWILIO_STATUS_ERROR:
      return {
        ...state,
        twilioStatusError: action.error,
        twilioStatusFinished: true,
      }

    case APP_DIAGNOSTICS_ACTION_TYPES.DIAGNOSTIC_RESULT: {
      return {
        ...state,
        finalResult: action.result,
      }
    }

    case APP_DIAGNOSTICS_ACTION_TYPES.REPORT_SENT:
      return {
        ...state,
        reportSent: true,
      }

    case APP_DIAGNOSTICS_ACTION_TYPES.CLEAR_DIAGNOSTIC:
      return {
        ...initialState
      }

    default:
      return state;
  }
};

export default appDiagnostics;
