import {errorBadgeType} from "../../../../../utils";
import React from "react";
import './call-badges.scss'

export const CallError = ({ error }) => {
  return (
    <span className='call-outcome error'>
      {errorBadgeType(error)}
    </span>
  );
};

export default CallError;