import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { parseIdString, toPresetProduct } from '../utils';
import useCaazamREST from './useCaazamREST';
import { useActiveCallProvider } from '../components/ActiveCallProvider';
import {
  useCallDetailsProvider
} from '../pages/home-page/call-details-side-bar/call-details-provider/call-details-provider';
import { useSessionProducts } from './useSessionProducts';

const useShowroomPresets = () => {
  const {callShopId} = useSelector((state) => state.shops);
  const {callDetails} = useCallDetailsProvider()
  const {activeCallId} = useActiveCallProvider();
  const [loading, setLoading] = useState(false)
  const {user} = useSelector((state) => state.user);
  const [callPreparationShowroom, setCallPreparationShowroom] = useState(null)
  const [showroomPreset, setShowroomPreset] = useState(null)
  const [showroomPresetsList, setShowroomPresetsList] = useState([])
  const [isProductsPresetChanged, setProductsPresetChanged] = useState(false)
  const {refreshShowroomPresetProductDetails} = useCaazamREST()
  const {sessionSnapshots, addToSession} = useSessionProducts(activeCallId);
  const { activeShopId } = useSelector((state) => state.shops);

  const shopId = useMemo(() => {
    if (callShopId) {
      return callShopId
    }
    if (callDetails?.shopId) {
      return callDetails.shopId
    }
    return activeShopId
  }, [callShopId, callDetails, activeShopId])

  const callId = useMemo(() => (
    activeCallId ?? callDetails?.id
  ), [activeCallId, callDetails])

  const showroomsRef = useMemo(() => (
    shopId
      ? firebase
        .firestore()
        .collection('shops')
        .doc(shopId)
        .collection('showrooms')
      : null
  ), [shopId])

  const presetRef = useMemo(() => (
    shopId
      ? firebase
        .firestore()
        .collection('shops')
        .doc(shopId)
        .collection('showroomPresets')
      : null
  ), [shopId])

  useEffect(() => {
    if (callId && callDetails?.showroomId) {
      const unsub = showroomsRef
        .doc(callDetails?.showroomId)
        .onSnapshot((snapshot) => {
          setCallPreparationShowroom(snapshot.data())
        })
      return () => {
        unsub()
        setCallPreparationShowroom(null)
      }
    }
    return () => {
      setCallPreparationShowroom(null)
    }
  }, [callId, callDetails?.showroomId])

  useEffect(() => {
    if (shopId && user) {
      const unsub = presetRef
        .where('owner', 'in', ['public', user.id])
        .onSnapshot((snapshot) => {
          const presetList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id
          }))
          setShowroomPresetsList(presetList)
        })
      return () => {
        unsub()
        setShowroomPresetsList([])
      }
    }
  }, [user, shopId])

  useEffect(() => {
    return () => {
      setProductsPresetChanged(false)
      setShowroomPreset(null)
    }
  }, [callId])

  useEffect(() => {
    if (callPreparationShowroom && callPreparationShowroom.presetId) {
      const preset = showroomPresetsList.find(({id}) => callPreparationShowroom.presetId === id)
      setShowroomPreset(preset)
    } else {
      setShowroomPreset(null)
    }
  }, [callPreparationShowroom?.presetId, showroomPresetsList])

  const addShowroomToCall = async (showroomId) => (
    firebase
      .firestore()
      .collection('shops')
      .doc(shopId)
      .collection('schedule')
      .doc(callId)
      .update({
        showroomId,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })
  )

  const getShowroomProducts = async () => {
    if (callDetails?.showroomId) {
      try {
        setLoading(true)
        const snapshot = await showroomsRef.doc(callDetails.showroomId).get()
        return snapshot.data().products
      } catch (e) {
        console.error('getShowroomProducts error', e)
        return []
      } finally {
        setLoading(false)
      }
    }
    return []
  }

  const onUpdateShowroomProducts = async (products, preset) => {
    try {
      setLoading(true)
      const presetId = () => {
        if (preset) {
          return preset.id
        }
        if (products.length && callPreparationShowroom?.presetId) {
          return callPreparationShowroom?.presetId
        }
        return null
      }

      if (callDetails?.showroomId) {
        await showroomsRef
          .doc(callDetails.showroomId)
          .update({
            products: JSON.parse(JSON.stringify(products)).map(toPresetProduct),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            presetId: presetId()
          })
      } else {
        const doc = showroomsRef.doc();
        await addShowroomToCall(doc.id)
        await doc.set(({
          products: JSON.parse(JSON.stringify(products)).map(toPresetProduct),
          owner: user.id,
          createdBy: user.id,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          presetId: presetId()
        }), {merge: true})
      }
    } catch (e) {
      console.error('onUpdateShowroomProducts error', e)
    } finally {
      setLoading(false)
    }
  }

  const onUpdatePreset = async (presetId, presetData) => {
    const {products, name, owner} = presetData
    try {
      setLoading(true)
      await presetRef.doc(presetId).update({
        name,
        owner,
        products: JSON.parse(JSON.stringify(products)).map(toPresetProduct),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      setProductsPresetChanged(false)
    } catch (e) {
      console.error('onUpdatePreset error', e)
    } finally {
      setLoading(false)
    }
  }

  const onSaveNewPreset = async (products, name, isPublic) => {
    try {
      setLoading(true)
      const doc = presetRef.doc();
      await doc.set(({
        products: JSON.parse(JSON.stringify(products)).map(toPresetProduct),
        name,
        owner: isPublic ? 'public' : user.id,
        createdBy: user.id,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      }), {merge: true})
      if (callDetails) {
        await showroomsRef
          .doc(callDetails.showroomId)
          .update({
            presetId: doc.id,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          })
      }
      setProductsPresetChanged(false)
    } catch (e) {
      console.error('onSaveNewPreset error', e)
    } finally {
      setLoading(false)
    }
  }

  const refreshProductDetails = async (productIds, presetId) => {
    try {
      setLoading(true)
      const doc = callDetails.showroomId ? showroomsRef.doc(callDetails.showroomId) : showroomsRef.doc()
      await doc[callDetails.showroomId ? 'update' : 'set']({
        presetId,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        ...(callDetails.showroomId
            ? {}
            : {
              owner: user.id,
              createdBy: user.id,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            }
        )
      }, {merge: true})
      const presetProducts = await refreshShowroomPresetProductDetails(shopId, productIds.map(parseIdString));
      setProductsPresetChanged(presetProducts.some(product => !product))
      return presetProducts
        .filter((product) => !!product)
        .map((product) => ({...product, productId: parseIdString(product.productId)}));
    } catch (e) {
      console.error('refreshProductDetails error', e)
      return []
    } finally {
      setLoading(false)
    }
  }

  const onUpdateVideoRoomShowroom = async (products) => {
    try {
      setLoading(true)
      const currentShowroomProducts = sessionSnapshots.map(snap => snap.val())
      const presetProducts = await refreshShowroomPresetProductDetails(
        shopId,
        products.map(({productId}) => productId).map(parseIdString)
      );
      await Promise.allSettled(
        presetProducts
          .filter((product) => !!product)
          .map((product) => ({...product, productId: parseIdString(product.productId)}))
          .filter((product) =>
            !currentShowroomProducts.some(({productId}) => product.productId === productId)
          )
          .map((product) => addToSession(product))
      )
    } catch (e) {
      console.error('onUpdateVideoRoomShowroom error', e)
    } finally {
      setLoading(false)
    }
  }

  const onRemovePreset = async (presetId) => {
    try {
      setLoading(true)
      await presetRef.doc(presetId).delete();
    } catch (e) {
      console.error(`onRemovePreset ${presetId} error`, e)
    } finally {
      setLoading(false)
    }
  }

  const onRefreshEditedPresetProducts = async (products) => {
    try {
      setLoading(true)
      const presetProducts = await refreshShowroomPresetProductDetails(
        shopId,
        products.map(({productId}) => productId).map(parseIdString)
      );
      setProductsPresetChanged(presetProducts.some(product => !product))
      return presetProducts
        .filter((product) => !!product)
        .map((product) => ({...product, productId: parseIdString(product.productId)}));
    } catch (e) {
      console.error(`onRefreshEditedPresetProducts error`, e)

    } finally {
      setLoading(false)
    }
  }

  return ({
    callPreparationShowroom,
    onUpdateShowroomProducts,
    showroomPresetsList,
    getShowroomProducts,
    onUpdatePreset,
    onSaveNewPreset,
    loading,
    refreshProductDetails,
    isProductsPresetChanged,
    setProductsPresetChanged,
    showroomPreset,
    onUpdateVideoRoomShowroom,
    onRemovePreset,
    onRefreshEditedPresetProducts
  })
}

export default useShowroomPresets;