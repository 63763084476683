import {CLIENT_SEARCH_RESULT} from "../../../../constants/const";
import {ArrowBackIos} from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import CustomerProfileSidebar from "../../../video-room/components/sidebar/customer-profile/customer-profile";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import './client-details-tab.scss';
import {useCallDetailsProvider} from "../call-details-provider/call-details-provider";

const ClientDetailsTab = ({visible, hideHeader}) => {
  const {clientSearchStatus} = useSelector(state => state.clients);
  const [clientName, setClientName] = useState(null);

  const {
    client,
    callDetails,
    onChangeClient
  } = useCallDetailsProvider();

  const customerProfileSidebarRef = useRef();

  useEffect(() => {
    if (clientSearchStatus !== CLIENT_SEARCH_RESULT.LOADING) {
      if (client) {
        setClientName({name: client.displayName, email: client.email})
      }  else {
        setClientName({name: callDetails.customerName, email: callDetails.customerEmail, anonymous: true})
      }
    }
  }, [client, clientSearchStatus, callDetails])

  const commonHeader = () => (
    <div className='customer-profile-side-bar-header'>
      <p>Client Profile</p>
      <button className='customer-search-button'>
        <SearchIcon
          onClick={customerProfileSidebarRef.current?.onSearchPress}
          style={{color: '#66676c'}}
        />
      </button>
    </div>
  )

  const differentCustomerHeader = () => (
    <div>
      <div className='customer-profile-found-client-header'>
        <div className='customer-profile-found-name'>
          <button className='customer-search-panel-back-button'>
            <ArrowBackIos
              onClick={customerProfileSidebarRef.current?.onSearchPress}
              style={{color: '#66676c'}}
            />
          </button>
          <h3>{client.displayName}</h3>
        </div>
        <p>{`${client.email} ${client.phone ? <p>&#x2022; `${client.phone}</p> : ''}`}</p>
      </div>
      <div className='customer-profile-side-bar-header'>
        <p>Client Profile</p>
      </div>
    </div>
  )

  return (
    <div className={`client-details-tab${visible ? ' visible' : ''}`}>
      <CustomerProfileSidebar
        ref={customerProfileSidebarRef}
        user={client}
        clientName={clientName}
        setCustomer={onChangeClient}
        isUserLoading={false}
        initClientEmail={callDetails.customerEmail}
        initClientName={callDetails.customerName}
        showCustomerSearch={() => { }}
        shopId={callDetails.shopId}
        isCustomerLoading={clientSearchStatus === CLIENT_SEARCH_RESULT.LOADING}
        header={() => {
          if (hideHeader) {
            return <div />
          }
          if (client && client.email !== callDetails.customerEmail) {
            return differentCustomerHeader()
          }
          return commonHeader()
        }}
      />
    </div>
  )
}

export default ClientDetailsTab;
