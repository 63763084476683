import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import Moment from 'moment-timezone';
import {useSelector} from 'react-redux';
import {currencyFormatter} from '../../../../../utils';
import ImageWithOverlay from '../../../../../components/image-with-overlay/image-with-overlay';
import {ProductsSuggectionSection} from '../components/products-suggection-section/products-suggection-section';
import SearchIcon from "@material-ui/icons/Search";
import ClientProfileChart from "../../../../../components/clientProfileChart/ClientProfileChart";
import useClientSummary, {CLIENT_VIEW_TYPE} from '../../../../../hooks/useClientSummary';
import {SearchClientsPanel} from './components/search-clients-panel/search-clients-panel';
import {CLIENT_SEARCH_RESULT} from '../../../../../constants/const';
import './customer-profile.scss';
import {
  useCallDetailsProvider
} from '../../../../home-page/call-details-side-bar/call-details-provider/call-details-provider';
import CustomerProfileSkeleton
  from '../../../../../components/skeletons/customer-profile-skeleton/customer-profile-skeleton';

const CustomerProfileSidebar = ({
  user,
  isCustomerLoading,
  setCustomerLoading = () => {},
  currentContextId,
  viewItem,
  viewPreviouslyPurchased,
  setCustomer = () => {},
  userRecentlyViewed,
  initClientEmail,
  initClientName,
  backFunc,
  header,
  setViewType = () => {},
  clientName,
  shopId
}, ref) => {
  const {
    searchClient,
    isUserLoading,
    client,
    onSearchPress,
    searchForItems,
    viewType,
    isSearching,
    selectNewCustomer,
    showProfile,
    setSearchClient
  } = useClientSummary(initClientEmail ? initClientEmail : initClientName, shopId, CLIENT_SEARCH_RESULT.NOT_EXIST);

  const [searchTerm, setSearchTerm] = useState(null);
  const {callDetails} = useCallDetailsProvider();

  useImperativeHandle(ref, () => ({
    onSearchPress,
  }), [])

  useEffect(() => {
    setSearchTerm(null)
  }, [callDetails?.id, initClientName, initClientEmail])

  useEffect(() => {
    setCustomer(client)
  }, [client])

  useEffect(() => {
    setCustomerLoading(isUserLoading)
  }, [isUserLoading])

  useEffect(() => {
    setViewType(viewType)
  }, [viewType])

  const onBack = () => {
    showProfile(initClientEmail ?? initClientName);
    setSearchTerm(null)
    setSearchClient([])
  }

  const onSubmitSearch = () => {
    searchForItems(searchTerm);
  }

  const onDestroy = () => {
    backFunc()
    setCustomer(null);
  }

  const {customerProfileConfig} = useSelector(state => state.user);

  const sortRecentlyViewedProducts = (array) => {
    return array.sort((a, b) => (a.viewed_at < b.viewed_at) ? 1 : ((b.viewed_at < a.viewed_at) ? -1 : 0))
  }
  const formatCustomerFieldDate = (dateString) => Moment(dateString).format('ll');

  const customerDataItem = ({isVisible, title, value, key}) => {
    return isVisible && (
      <div key={key} className='data-item'>
        <div className='field-label'>
          <p className='title-container'>
            <span className='title'>{title}</span>:
          </p>
        </div>
        <p className='value'>
          {value}
        </p>
      </div>
    )
  }

  const customerProfileContainer = () => (
    <>
      {header ? header() : (
        <div className='customer-panel-header'>
          <button disabled={isUserLoading} className='customer-search-button'>
            <SearchIcon onClick={() => onSearchPress()}/>
          </button>
          <div className='customer-details'>
            {backFunc && (
              <button className='back-button' onClick={onDestroy}>
                &#215;
              </button>
            )}
            <h2 className='customer-name'>{clientName.name}</h2>
            <h3>{clientName.email}</h3>
          </div>
        </div>
      )}
      <div className='customer-profile'>
        {user?.anonymous || clientName.anonymous ? anonymousCustomerProfile() : customerProfileDetails() }
        {customerProfileConfig.showRecentPurchases &&
          user &&
          user?.recentlyPurchased && (
            <div className='product-display'>
              {user.recentlyPurchased.length === 0 && (
                <h4>No recent purchases</h4>
              )}
              {user.recentlyPurchased.length > 0 && (
                <>
                  <h4>Recent purchases</h4>
                  <div className='product-container'>
                    <div className='product-view'>
                      {user.recentlyPurchased.map((productStub, i) => (
                        <ImageWithOverlay
                          isAdaptive={true}
                          imageUrl={productStub.image}
                          alt={productStub.productTitle}
                          viewFunction={viewPreviouslyPurchased ? () => viewPreviouslyPurchased(productStub) : null}
                          key={i}
                        />
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          )
        }
        {(customerProfileConfig.showRecentlyViewedProducts || customerProfileConfig.showProductRecommendations) &&
          <ProductsSuggectionSection
            recentlyViewed={customerProfileConfig.showRecentlyViewedProducts ?
              userRecentlyViewed && userRecentlyViewed.productsViewed.length > 0 ?
                sortRecentlyViewedProducts(userRecentlyViewed.productsViewed) : [] : []}
            recommendations={customerProfileConfig.showProductRecommendations ?
              user && user.productRecommendations ? user && user.productRecommendations : [] : []}
            viewItem={viewItem}
          />
        }
      </div>
    </>
  )

  function renderCustomFieldValue(field) {
    const formatValue = (v) => {
      // Shai 2024-01-24: we are using Endear custom fields types and should normalize across other data providers
      if (!v) return v;
      if (field.type === 'ABSOLUTE_DATE') return formatCustomerFieldDate(v);
      else return v;
    }

    return field.allowMultiple ? field.value.map(v=> formatValue(v)).join(', ') : formatValue(field.value);
  }

  const customerProfileDetails = () => (
    user && (
      <>
        <ClientProfileChart
          containerClass='purchase-data'
          user={user}
          config={customerProfileConfig}
        />
        <div className='customer-data'>
          {customerDataItem({
            isVisible: customerProfileConfig.showTotalCredit,
            title: 'Credit',
            value: currencyFormatter(user.creditAmount, user.currencyCode)
          })}
          {customerDataItem({
            isVisible: customerProfileConfig.showTotalRefunds,
            title: 'Total refunds',
            value: currencyFormatter(user.totalRefunds, user.currencyCode)
          })}
          {customerDataItem({
            isVisible: customerProfileConfig.showLastPurchaseDate,
            title: 'Last purchase',
            value: user.lastOrder && formatCustomerFieldDate(user.lastOrder.updatedAt)
          })}
          {customerDataItem({
            isVisible: customerProfileConfig.showClientNote,
            title: 'Note',
            value: user.note
          })}
          {customerProfileConfig.customFields && (
            customerProfileConfig.customFields.filter(fieldId => !!user?.[fieldId]).map((fieldId, index) => (
              customerDataItem({
                key: index,
                isVisible: true,
                title: user[fieldId].label,
                value: renderCustomFieldValue(user[fieldId]),
              })
            ))
          )}
        </div>
      </>
    )
  )

  const anonymousCustomerProfile = () => (
    <div className='customer-profile-anonymous-profile'>
      <p>
        {`We couldn’t find a client profile for ${clientName.name}. You can try searching for a different profile.`}
      </p>
    </div>
  )

  return (
    <div className='video-room-sidebar-wrapper'>
      <div className='video-room-sidebar'>
        {viewType === CLIENT_VIEW_TYPE.SEARCH ?
          <SearchClientsPanel
            searchForItems={searchForItems}
            isSearching={isSearching}
            searchClient={searchClient}
            selectNewCustomer={(customer) => { selectNewCustomer(customer, currentContextId) }}
            onBack={onBack}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            onSubmitSearch={onSubmitSearch}
          /> : (
            !clientName || isUserLoading || isCustomerLoading ? (
              <CustomerProfileSkeleton />
            ) : (
              customerProfileContainer()
            )
          )
        }
      </div>
    </div>
  );
};

export default forwardRef(CustomerProfileSidebar);
