import React from 'react';
import Modal from '../Modals/modal/index';
import { FilledButton } from '../buttons/buttons';
import { useAppAuthState } from '../../authState';

const InactiveUserModal = ({ onClose }) => {
  const { signOut } = useAppAuthState();

  const signOutAndClose = () => {
    signOut().then(() => {
      onClose();
    });
  };

  return (
    <Modal isClosable={false}>
      <h3>Your Boutiq account isn't associated with any shops as a host</h3>
      <div className='actions'>
        <FilledButton
          onClick={signOutAndClose}
          style={{
            height: '32px',
            width: 'unset',
            padding: '0 16px',
            backgroundColor: 'var(--main-color)',
          }}>
          Log Out
        </FilledButton>
      </div>
    </Modal>
  );
};

export default InactiveUserModal;
