export const CLIENT_SEARCH_RESULT = {
  LOADING: 'LOADING',
  EXIST: 'EXIST',
  NOT_EXIST: 'NOT_EXIST',
  ERROR: 'ERROR'
}

export const DRAFT_ORDER_STATUS = {
  OPEN: 'OPEN',
  COMPLETED: 'COMPLETED',
  INVOICE_SENT: 'INVOICE_SENT'
}

export const SETTINGS_TAB = {
  MANAGE_AVAILABILITY: 'Manage Availability',
  PROFILE_DETAILS: 'Profile Details',
  AUDIO_VIDEO: 'Audio & Video',
  APP_SETTINGS: 'App settings',
  CALL_HISTORY: 'Call History'
}

export const CHANGE_EVENT = {
  NO_SHOW: 'noShow',
  DISABLE_CLIENT_EMAIL: 'disableClientEmail',
  MESSAGE: 'message'
}

export const CALL_ROUTING_POLICY = {
  CUSTOM: 'custom',
}

export const HOST_AVAILABILITY = {
  EVENTS: 'hostAvailabilityEvents',
  CALLS: 'hostAvailabilityCalls'
}

export const DRAFT_ORDER_SOURCE_TYPE = 'DraftOrder'

export const ANIMATION = {
  SHOW: 'show',
  CLOSE: 'close'
}

export const SAVE_SHOWROOM_ACTION = {
  SAVE: 'save',
  SAVE_AS: 'save_ass'
}

export const EMAIL_EVENTS = {
  scheduleConfirmEmail: {
    name: 'Confirm appointment',
  },
  scheduleInviteEmail: {
    name: 'Invited to appointment',
  },
  scheduleStartChangedEmail: {
    name: 'Appointment rescheduled',
  },
  scheduleAlertEmail: {
    name: 'Appointment reminder',
  },
  scheduleRequestChange: {
    name: 'Request reschedule',
  },
  scheduleCancelledEmail: {
    name: 'Appointment cancelled',
  }
}