import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import useCaazamREST from '../../hooks/useCaazamREST';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import './inviteToCallModal.scss';
import InviteError from './invite-error';
import InviteSuccess from './invite-success';
import SelectSlot from './select-slot';
import CustomerSearch from './customer-search';
import Modal from '../Modals/modal';
import { logger } from '../../logging';
import SelectEventType from "./select-event-type/select-event-type";
import {localTimezone} from '../../utils';
import useScheduledSlots from '../../hooks/useScheduledSlots';
import SummaryData from './summary-data/summary-data';

const PAGES = {
  CUSTOMER_SEARCH: 'customerSearch',
  SCHEDULE: 'schedule',
  SUCCESS: 'success',
  SUMMARY: 'summary',
  ERROR: 'error',
  SELECT_EVENT_TYPE: 'select_event_type'
};

export default function InviteToCallModal({ onClose }) {
  const [customer, setCustomer] = useState(null);
  const customerName = useRef(null);
  const [email, setEmail] = useState('');
  const [page, setPage] = useState(PAGES.CUSTOMER_SEARCH);
  const [linkUrl, setLinkUrl] = useState(null);
  const [icsDownloadUrl, setIcsDowdnloadUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [isScheduling, setIsScheduling] = useState(null);
  const { createCustomerInvite, createEvent, getEventTypes } = useCaazamREST();
  const [error, setError] = useState(null)
  const returnToCustomerSearch = () => setPage(PAGES.CUSTOMER_SEARCH);
  const routeToError = (error) => {
    setPage(PAGES.ERROR)
    setError(error)
  };
  const routeToSchedule = () => setPage(PAGES.SCHEDULE);
  const routeToEventType = () => setPage(PAGES.SELECT_EVENT_TYPE);
  const routeToSummary = () => setPage(PAGES.SUMMARY);
  const shopId = useSelector((state) => state.shops.activeShopId);
  const [eventTypesLoading, setEventTypesLoading] = useState(false);
  const [eventTypes, setEventTypes] = useState(null)
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [timezone, setTimezone] = useState(null)
  const {availableSlots, formWarningMessage, formErrorMessage} = useScheduledSlots(null, selectedEvent?.id);

  useEffect(() => {
    const fetchEventTypes = async () => {
      try {
        setEventTypesLoading(true)
        const {eventTypes} = await getEventTypes(shopId)
        setEventTypes(eventTypes)
      } catch (e) {
        setEventTypes([])
      } finally {
        setEventTypesLoading(false)
      }
    }
    if (shopId) {
      fetchEventTypes()
    }
  }, [shopId]);

  const onSelectCustomer = () => {
    if (!eventTypesLoading && eventTypes?.length > 0) {
      routeToEventType();
    } else {
      routeToSchedule()
    }
  };


  const onInviteFinish = () => {
    setLinkUrl(null);
    setIcsDowdnloadUrl(null);
    setLoading(true);
    firebase.analytics().logEvent('host_invite_link_created');
    createCustomerInvite(
      shopId,
      customerName.current,
      email,
      customer.id ? customer.id.split('/').pop() : null
    )
      .then((inviteRes) => {
        setLinkUrl(inviteRes.clientCallUrl);
        setPage(PAGES.SUCCESS);
      })
      .catch((error) => {
        logger.error('onInviteFinish',error);
        routeToError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onCustomerChanged = (value) => {
    setCustomer(value ? value.value : null);
    customerName.current = value ? value.value : null;
    if (value && value.value.id) {
      // value is a search result object
      setEmail(value.value.email);
      customerName.current = value.value.displayName;
    }
  };

  const selectSlot = (session) => {
    if (session.isAvailable) {
      setSelectedSlot(session);
    }
  };

  const requestScheduleCall = async () => {
    setIsScheduling(true);
    const bodyData = {
      slot: {
        start: selectedSlot.timeSlot,
        end: selectedSlot.timeSlotEnd,
      },
      customerName: customerName.current,
      customerEmail: email,
      customerTimezone: timezone?.id ?? null
    };
    if (selectedEvent) {
      bodyData.eventTypeId = selectedEvent.id
    }
    firebase.analytics().logEvent('host_scheduled_call_created');
    try {
      const res = await createEvent(shopId, bodyData);
      setLinkUrl(res.callLink);
      setIcsDowdnloadUrl(res.icsDownloadUrl);
      setPage(PAGES.SUCCESS);
    } catch (error) {
      logger.error(`Scheduling new event failed ${JSON.stringify(bodyData)}`, error);
      routeToError(error);
    } finally {
      setIsScheduling(false);
    }
  };

  const isBackButtonVisible = page === PAGES.SCHEDULE && eventTypes?.length > 0 || page === PAGES.SUMMARY

  const onBack = () => {
    if (page === PAGES.SCHEDULE && eventTypes?.length > 0) {
      routeToEventType()
    }
    if (page === PAGES.SUMMARY) {
      routeToSchedule()
    }
  }

  const content = () => {
    switch (page) {
      case PAGES.CUSTOMER_SEARCH:
        return (
          <CustomerSearch
            customer={customer}
            email={email}
            setEmail={setEmail}
            onCustomerNameChanged={onCustomerChanged}
            shopId={shopId}
            availableSlots={availableSlots}
            routeToSchedule={onSelectCustomer}
            onInviteFinish={onInviteFinish}
            loading={loading}
            formErrorMessage={formErrorMessage}
            formWarningMessage={formWarningMessage}
          />
        )
      case PAGES.SELECT_EVENT_TYPE:
        return (
          <SelectEventType
            onNextPress={routeToSchedule}
            events={eventTypes}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
          />
        )
      case PAGES.SCHEDULE:
        return (
          <SelectSlot
            selectSlot={selectSlot}
            scheduleCall={routeToSummary}
            isScheduling={isScheduling}
            selectedSlot={selectedSlot}
            availableSlots={availableSlots}
          />
        )
      case PAGES.SUMMARY:
        return (
          <SummaryData
            selectedEvent={selectedEvent}
            selectedSlot={selectedSlot}
            scheduleCall={requestScheduleCall}
            timezone={timezone}
            customerName={customerName.current}
            customerEmail={email}
            onSelectTimezone={setTimezone}
            isScheduling={isScheduling}
            isInviteFlow={true}
          />
        )
      case PAGES.SUCCESS:
        return (
          <InviteSuccess
            timezone={timezone}
            callLink={linkUrl}
            timeSlot={selectedSlot}
            customerName={customerName.current}
            customerEmail={email}
            icsDownloadUrl={icsDownloadUrl}
          />
        )
      default:
        return <InviteError returnToMain={returnToCustomerSearch} isBannedClient={error?.statusCode === 403} />
    }
  }

  return (
    <Modal
      closeFunc={onClose}
      backFunc={isBackButtonVisible ? onBack : null}
      modalClass='create-invite-modal'
    >
      {content()}
    </Modal>
  );
}
