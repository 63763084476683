import { useCallback } from 'react';
import { handleResponse } from '../utils/http';

export const useProductSearch = (shopId, pricingContext = null) => {
  return {
    searchProducts: useCallback(
      (searchTerm, limit, cursor) => {

        const params = new URLSearchParams({
          search: searchTerm,
          limit,
        });
        if (cursor) params.append('cursor', cursor);
        if (pricingContext) params.append('pricingContext', pricingContext);

        return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopId}/productsv2/search?${params.toString()}`)
          .then(result => handleResponse(result));
      },
      [shopId, pricingContext]
    ),
  };
};
