import { ArrowBackIos } from '@material-ui/icons';
import { SAVE_SHOWROOM_ACTION } from '../../../../../constants/const';
import PreparationTabSaveAction from '../preparation-tab-save-action/preparation-tab-save-action';
import React from 'react';
import { useShowroomPresetProvider } from '../../../../../providers/preset-provider/preset-provider';
import './preparation-tab-header.scss'

const PreparationTabHeader = ({hasProducts}) => {
  const {
    onShowPresetModal,
    onClearPreparationProducts,
    isEditPresetMode,
    onShowSaveShowroomModal,
    onHideEditPresetModal,
    editedPreset,
    showroomPresetsList
  } = useShowroomPresetProvider()

  return (isEditPresetMode ? (
        <div className='call-preparation-tab-header edit-mode'>
          <ArrowBackIos
            className='back-icon'
            onClick={onHideEditPresetModal}
          />
          {editedPreset ? (
            <PreparationTabSaveAction position={'bottom-left'}/>
          ) : (
            <p
              className={`preset-button${hasProducts ? '' : ' disabled'}`}
              onClick={hasProducts ? () => onShowSaveShowroomModal(SAVE_SHOWROOM_ACTION.SAVE) : null}
            >
              Save
            </p>
          )}
        </div>
      ) : (
        <div className='call-preparation-tab-header'>
          <p
            className={`preset-button ${showroomPresetsList.length ? '' : ' disabled'}`}
            onClick={showroomPresetsList.length ? () => onShowPresetModal(false) : null}
          >
            Load
          </p>
          <PreparationTabSaveAction/>
          <p
            className={`preset-button${hasProducts ? '' : ' disabled'}`}
            onClick={hasProducts ? onClearPreparationProducts : null}
          >
            {'Clear'}
          </p>
        </div>
      )
  )
}

export default PreparationTabHeader;