import React from 'react';
import Modal from '../modal';
import './email-events-modal.scss';
import {
  useCallDetailsProvider
} from '../../../pages/home-page/call-details-side-bar/call-details-provider/call-details-provider';
import { EMAIL_EVENTS } from '../../../constants/const';
import EmailEventSkeleton from '../../skeletons/email-event-skeleton/email-event-skeleton';

const EmailEventsModal = () => {
  const {onHideEmailEvents, emailEvents} = useCallDetailsProvider()

  const formatEmailEvent = (data) => {
    const lastEvent = () => {
      if (!data.events) return null;
      const events = Object.values(data.events)
        .sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate())
        .filter(e => e.event !== 'processed')
      let event = events[0];
      switch (event.event) {
        case 'dropped':
          return `${event.event} - ${event.reason}`;
        case 'deferred':
          return `${event.event} - attempts:${event.attempt}`;
        case 'bounce':
        {
          if (event.type === 'bounce')
            return `bounce - ${event.reason}`;
          if (event.type === 'blocked')
            return `blocked - ${event.reason}`;
        }
        default:
          return `${event.event}`;
      }
    }

    const engagement = () => {
      if (!data.engagement) return null;
      let stats = '';
      if (data.engagement.open != null) {
        stats +=`open:${data.engagement.open}`
      }
      if (data.engagement.click != null) {
        stats +=` click:${data.engagement.click}`
      }
      return `(${stats})`
    }
    return (
      <span className='data'>
        <span className='event-name'>{EMAIL_EVENTS[data.templateId].name}</span> <span>{lastEvent()} {engagement()}</span>
      </span>
    )
  }

  const formatShortEventDate = (date) => (
    new Intl.DateTimeFormat(navigator.language, {
      dateStyle: 'medium',
      timeStyle: 'short',
    }).format(date)
  )

  return (
    <Modal modalClass='email-events-modal-container' closeFunc={onHideEmailEvents}>
      <div className='email-events-modal-wrapper'>
        <h3 className='email-events-modal-header'>Email events</h3>
        <div className='email-events-modal-data-wrapper'>
          {!emailEvents && (
            <EmailEventSkeleton />
          )}
          {emailEvents?.map((event, index) => (
            <div className='row' key={`event_${index}`}>
              {formatEmailEvent(event)}
              <span>{formatShortEventDate(new Date(event.sentAt.toDate()))}</span>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default EmailEventsModal;