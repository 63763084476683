import React from "react";
import './call-summary-header.scss';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const CallSummaryHeader = ({title, badge, isContentVisible}) => {
  return (
    <div className='call-summary-header'>
      <div className='call-summary-header-wrapper'>
        <div className='call-summary-detail'>
          <p className='call-summary-tab-label'>{title}</p>
        </div>
        <div>
          {badge}
        </div>
      </div>
      <KeyboardArrowDownIcon
        className={`down-caret ${isContentVisible ? 'rotate' : ''}`}
      />
    </div>
  )
}

export default CallSummaryHeader;