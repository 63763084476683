import {DISCOUNT_TYPE} from "../components/Modals/draft-order-modal/components/order-discount/OrderDiscount";
import {parseIdString} from "./index";

const NO_VARIANT_TITLE = 'Default Title';

export const generateVariantTitle = (title) => title === NO_VARIANT_TITLE ? '' : title

export const normalizedProduct = (product) => ({
  productId: product.productId,
  image: product.image ?? null,
  currencyCode: product.currencyCode,
  currentPrice: product.price,
  productTitle: product.productTitle,
  productDescription: product.descriptionHtml ?? product.productDescription,
  variantId: product.variantId,
  variantTitle: product.variantTitle,
  inventoryPolicy: product.inventoryPolicy,
  inventoryQuantity: product.inventoryQuantity,
  quantity: product.quantity ?? 1,
});

export const normalizedShopifyProduct = (product, currencyCode) => {
  let orderDiscount = {}
  if (product.appliedDiscount) {
    const {valueType, value, description} = product.appliedDiscount;
    orderDiscount = {
      discount: {
        discountType: valueType,
        discountValue: value
      },
      discountReason: description
    }
  }
  return ({
    productId: parseIdString(product.id),
    variantId: parseIdString( product.custom ? product.id : product.variantId),
    image: product.image,
    quantity: product.quantity,
    productTitle: product.title,
    variantTitle: product.variantTitle,
    price: product.discountedTotal/product.quantity,
    compareAtPrice: product.appliedDiscount ? product.originalTotal/product.quantity : null,
    currencyCode,
    ...orderDiscount,
    isCustom: product.custom,
    isTaxable: product.isTaxable,
    isRequiresShipping: product.isRequiresShipping
  })
}

export const calculateProductPrice = (orderDiscount, product) => {
  if (!orderDiscount.discount) {
    return product.price
  }
  const {discountType, discountValue} = orderDiscount.discount;
  const price = product.compareAtPrice ? product.compareAtPrice : product.price
  if (discountType === DISCOUNT_TYPE.AMOUNT) {
    if (discountValue >= price) {
      return 0;
    } else {
      return price - discountValue;
    }
  } else {
    if (discountValue >= 100) {
      return 0;
    } else {
      return (1 - discountValue / 100) * price;
    }
  }
}

export const draftOrderProducts = (products) => (
  products.map((product) => {
    let draftOrderProduct;
    if (product.isCustom) {
      draftOrderProduct = {
        title: product.productTitle,
        quantity: product.quantity,
        price: product.compareAtPrice ?? product.price,
        taxable: product.isTaxable,
        requiresShipping: product.isRequiresShipping
      }
    } else {
      draftOrderProduct = {
        variantId: product.variantId,
        quantity: product.quantity,
      }
    }
    if (product.compareAtPrice) {
      const discount = product.discount;
      draftOrderProduct.discount = {
        valueType: discount?.discountType,
        value: Number(discount?.discountValue),
        description: product.discountReason || ''
      }
    }
    return draftOrderProduct
  })
)
export const normalizeDraftOrderProducts = (draftOrder) => {
  return draftOrder?.lineItems?.map((product) => {
    const {
      image,
      discountedTotal,
      quantity,
      variantTitle,
      title,
      custom,
      id,
      originalTotal,
      appliedDiscount,
    } = product;

    return ({
      currencyCode: draftOrder.currencyCode,
      variantId: custom ? parseIdString(id) : product.variantId,
      image,
      price: discountedTotal / quantity,
      variantTitle,
      productTitle: title,
      quantity,
      compareAtPrice: appliedDiscount ? originalTotal / quantity : null,
      isCustom: custom,
      discount: appliedDiscount ? {
        discountType: appliedDiscount.valueType,
        discountValue: appliedDiscount.value,
        description: appliedDiscount.description,
      } : null,
      discountReason: appliedDiscount ? appliedDiscount.description : null
    })

  })

}

export const normalizeCartProduct = (product) => ({
  ...product,
  compareAtPrice: null
})


