import Toggle from "../../../../../components/toggle/toggle";
import React from "react";
import './setting-row.scss';

const SettingRow = ({isChecked, toggleFunc, title}) => {
  return (
    <div className='app-setting-row'>
      <p className='setting-title'>{title}</p>
      <Toggle isChecked={isChecked} toggleFunc={toggleFunc} />
    </div>
  )
}

export default SettingRow;