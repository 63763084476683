import {getContrastYIQ} from "../../../../../utils";
import React from "react";

const CallEventType = ({ eventType, classStyle }) => {
  if (eventType.eventTypeId === 'default')
    return null;

  return (
    <span
      style={{'--badge-color': `${eventType.color}`}}
      className={`call-outcome event-type ${classStyle}`}
    >
      <span style={{
        '--contrastColor': getContrastYIQ(eventType.color),
      }} className='event-type-text'>{eventType.name}</span>
    </span>
  )
}

export default CallEventType;