import React from 'react';
import './product-options-skeleton.scss'
import LoadingCard from '../loading-card/loading-card';

const ProductOptionsSkeleton = () => {
  return (
    <div>
      <LoadingCard
        marginLeft={0}
        marginBottom={0}
        marginRight={0}
        width={'100%'}
        height={36}
      />
      <LoadingCard
        marginLeft={0}
        marginBottom={0}
        marginRight={0}
        width={'100%'}
        height={36}
      />
      <LoadingCard
        marginLeft={'calc(100% - 80px)'}
        marginRight={0}
        width={80}
        height={40}
        borderRadius={20}
      />
    </div>
  )
}

export default ProductOptionsSkeleton;
