import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useHostPushNotifications from '../../hooks/useHostPushNotifications';

import HeaderNotification from './header-notification';
import Toast from './toast';
import AnimatedConfetti from '../confetti/AnimatedConfetti';

export const NotificationContext = createContext(null);

export default function NotificationProvider({children}) {
	const currentLocation = useLocation();
	const autoDismiss= currentLocation.pathname !== '/video-room';
	const dismissTime = 8000;

	const currentRouteRef = useRef('/');
	const [toastQueue, setToastQueue] = useState([]);
	const [headerNotificationType, setHeaderNotificationType] = useState(null);
	const [isHeaderNotificationVisible, setHeaderNotificationVisible] = useState(false);

	const [incomingCallProps, setIncomingCallProps] = useState(null);
	const [rejoiningCallProps, setRejoiningCallProps] = useState(null);

	const [isConfettiVisible, setConfettiVisible] = useState(false);

	const updateCurrentRoute = (route) => {
		if (currentRouteRef.current !== route) {
			currentRouteRef.current = route;
		}
	}

	const showToast = (message) => {
		if (message && (!message.excludes || !message.excludes.includes(currentRouteRef.current))) {
			setToastQueue([...toastQueue, message])
		}
	}

	const showHeaderNotification = ({type, incomingCallProps, rejoiningCallProps}) => {
		setHeaderNotificationVisible(true);
		setHeaderNotificationType(type);
		setIncomingCallProps(incomingCallProps);
		setRejoiningCallProps(rejoiningCallProps);
	}

	const removeHeaderNotification = () => {
		setHeaderNotificationVisible(false);
	}

	// listen to push notifications from FCM
	useHostPushNotifications(showToast, setConfettiVisible);

	useEffect(() => {
		if (isConfettiVisible) {
		  const timer = setTimeout(() => {
			setConfettiVisible(false);
		  }, 5000);
		  return () => clearTimeout(timer);
		}
	  }, [isConfettiVisible]);

	return <NotificationContext.Provider value={{
		showHeaderNotification,
		removeHeaderNotification,
		showToast,
		setRouteForNotifications: updateCurrentRoute,
	}}>
		<>
			{isHeaderNotificationVisible ? (
				<HeaderNotification
					type={headerNotificationType}
					incomingCallProps={incomingCallProps}
					rejoiningCallProps={rejoiningCallProps}
				/>
			) : null}
			<Toast
				autoDismiss={autoDismiss}
				dismissTime={dismissTime}
				toastQueue={toastQueue}
				setToastQueue={setToastQueue}/>
			<AnimatedConfetti isConfettiVisible={isConfettiVisible}/>
		</>
		{children}
	</NotificationContext.Provider>
}

export function useNotificationProvider() {
	const context = useContext(NotificationContext);
	if (!context) {
		throw new Error('useNotificationProvider must be used within the NotificationProvider');
	}
	return context;
}
