import * as React from "react"

const IconOrderComplete = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M10 18.248a8.248 8.248 0 1 0 0-16.496 8.248 8.248 0 0 0 0 16.496z"
        stroke="#6E76F2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.187 11.93c0 .433-.109.847-.326 1.24-.217.393-.535.72-.955.983-.42.262-.924.41-1.512.447v1.098h-.732V14.6c-.822-.072-1.491-.337-2.006-.793-.515-.456-.777-1.037-.786-1.742h1.315c.036.38.178.71.427.99.248.28.598.456 1.05.528v-3.389c-.605-.153-1.093-.311-1.464-.474a2.395 2.395 0 0 1-.949-.76c-.262-.343-.393-.804-.393-1.382 0-.732.255-1.338.766-1.816.51-.48 1.19-.746 2.04-.8V3.837h.732v1.125c.768.063 1.387.31 1.857.739s.741.987.814 1.674H11.75a1.594 1.594 0 0 0-.42-.861c-.235-.258-.547-.427-.936-.508v3.307c.597.154 1.082.31 1.457.468.376.158.692.409.95.752.257.344.386.809.386 1.396zm-5.07-4.42c0 .443.13.782.393 1.017.262.235.646.43 1.152.583V5.978c-.47.046-.845.197-1.125.455-.28.257-.42.616-.42 1.077zm2.277 6.087c.488-.054.87-.23 1.146-.529.275-.298.413-.655.413-1.07 0-.444-.135-.785-.407-1.024-.27-.24-.655-.432-1.152-.576v3.199z"
        fill="#6E76F2"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default IconOrderComplete
