import {useCallback, useEffect, useState} from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const useBlockOffEvents = (shopId, scheduleId) => {
  const [blockOffEvent, setBlockOffEvent] = useState(null)

  useEffect(() => {
    if (shopId && scheduleId) {
      const unsub = firebase
        .firestore()
        .collection('shops')
        .doc(shopId)
        .collection('schedule')
        .doc(scheduleId)
        .onSnapshot((snapshot) => {
          if (snapshot.data()) {
            setBlockOffEvent(snapshot.data())
          }
        })
      return () => unsub();
    }
  }, [shopId, scheduleId])

  const updateEvent = useCallback((title, description) => {
    return firebase
      .firestore()
      .collection('shops')
      .doc(shopId)
      .collection('schedule')
      .doc(scheduleId)
      .update({
        description,
        title,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
  }, [shopId, scheduleId])

  return {
    updateEvent,
    blockOffEvent
  }
}

export default useBlockOffEvents;