import React, {useState} from 'react';
import './CustomProduct.scss'
import {FilledButton, OutlinedButton} from "../../../../../buttons/buttons";
import Checkbox from "../../../../../checkbox";
import {useDigitalInput} from "../../../../../../hooks/useDigitalInput";

const CustomProduct = ({onHideModal, onAddCustomProduct}) => {
  const [productName, setProductName] = useState('');
  const [isTaxable, setTaxable] = useState(true)
  const [isRequiresShipping, setRequiresShipping] = useState(true)

  const {ref: quantityRef, value: productQuantity} = useDigitalInput({
    min: 1,
    initValue: '1'
  })
  const {ref: priceRef, value: productPrice, setValue: setProductPrice} = useDigitalInput()

  const onChangeProductName = (e) => {
    setProductName(e.target.value)
  }

  const onAddProduct = () => {
    onAddCustomProduct({
      title: productName,
      discountedTotal: productPrice*productQuantity,
      quantity: productQuantity,
      id: Date.now().toString(),
      custom: true,
      isTaxable,
      isRequiresShipping
    })
    onHideModal()
  }

  const onChangeShipping = () => {
    setRequiresShipping(!isRequiresShipping)
  }

  const onChangeTaxable = () => {
    setTaxable(!isTaxable)
  }

  return (
    <div className='draft-order-custom-product'>
      <div className='section'>
        <p>Item name</p>
        <input
          className='custom-product-input'
          value={productName}
          onChange={onChangeProductName}
        />
      </div>
      <div className='custom-product-description-container'>
        <div className='section'>
          <p>Price</p>
          <span
            symbol={productPrice ? '$' : ''}
            className='input-symbol'
          >
            <input
              ref={priceRef}
              className='custom-product-input'
              placeholder='$0.00'
            />
          </span>
        </div>
        <div className='section'>
          <p>Quantity</p>
          <input
            ref={quantityRef}
            className='custom-product-input'
          />
        </div>
      </div>
      <Checkbox
        value={isTaxable}
        label={'Item is taxable'}
        onChange={onChangeTaxable}
      />
      <Checkbox
        value={isRequiresShipping}
        label={'Item requires shipping'}
        onChange={onChangeShipping}
      />
      <div className='custom-product-buttons'>
        <OutlinedButton
          className='button'
          style={{
            height: '34px',
            textTransform: 'capitalize',
            width: '100px'
          }}
          onClick={onHideModal}
          bordercolor={'var(--main-color)'}
        >
          Close
        </OutlinedButton>
        <FilledButton
          disabled={!(productName && productPrice)}
          className='button'
          style={{
            height: '34px',
            backgroundColor: `var(--main-color)`,
            textTransform: 'capitalize',
            width: '100px'
          }}
          onClick={onAddProduct}
        >
          Add
        </FilledButton>
      </div>
    </div>
  )
}

export default CustomProduct;