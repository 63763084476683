import {MESSAGES} from "../constants/chatActionTypes";
import {MSG_STATUS, SYSTEM} from "../constants/messaging";

const initialState = {
  messages: [],
  isMessagesLoading: false,
  tempImageMessageId: null,
  isShowStartVideoCallMessage: false,
  initRoomListenerId: null,
  seenActivated: false,
};

const chatMessages = (state = initialState, action) => {
  switch (action.type) {
    case MESSAGES.CLEAR_MESSAGES:
      return {
        ...state,
        messages: [],
      }

    case MESSAGES.GET_MESSAGES.REQUEST:
      return {
        ...state,
        isMessagesLoading: true,
      }

    case MESSAGES.GET_MESSAGES.FAILURE:
      return {
        ...state,
        isMessagesLoading: false,
      }

    case MESSAGES.GET_MESSAGES.SUCCESS:
      return {
        ...state,
        messages: [...action.messages],
        isMessagesLoading: false
      }

    case MESSAGES.APPEND_MESSAGES:
      return {
        ...state,
        isMessagesLoading: false,
        messages: [...state.messages, ...action.messages]
      }

    case MESSAGES.SAVE_NEW_MESSAGE:
      return {
        ...state,
        messages: [...action.messages, ...state.messages]
      }

    case MESSAGES.SET_TEMP_IMAGE_MESSAGE:
      return {
        ...state,
        tempImageMessageId: action.id,
      }

    case MESSAGES.SHOW_VIDEO_CALL_MESSAGE:
      return {
        ...state,
        isShowStartVideoCallMessage: action.state,
      }

    case MESSAGES.SEEN_MESSAGE.SUCCESS:
      return {
        ...state,
        messages: state.messages.map((item) => {
          return {
              ...item,
              status: {
                ...item.status,
                [action.userId]: MSG_STATUS.SEEN,
              }
          }
        })
      }

    case MESSAGES.INIT_ROOM_LISTENER_ID:
      return {
        ...state,
        initRoomListenerId: action.roomId,
        messages: [],
      }

    case MESSAGES.SEEN_ACTIVATED:
      return {
        ...state,
        seenActivated: action.status,
      }

    case SYSTEM.SET_CHAT_STATUS_IN_VIDEO:
      return {
        ...state,
        seenActivated: action.status,
      }

    default:
      return state;
  }
};

export default chatMessages;
