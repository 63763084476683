import React from 'react';
import './sidebar-tab-bar.scss';

const SidebarTabBar = ({tabs, onPressTab, selectedTab, barStyle, tabStyle}) => {
  const tabCounter = (tab) => {
    if (tab.products) {
      return `(${tab.products})`
    }
    return ''
  }

  return (
    <div className="sidebar-tab-bar" style={barStyle}>
      {tabs.map((tab, index) => (
        <div
          key={`${index} ${tab.key}`}
          style={tabStyle}
          className={`sidebar-tab${selectedTab === tab.key ? ' selected' : ''}`}
          onClick={() => onPressTab(tab.key)}
        >
          {!!tab.icon && (
            <div className='sidebar-tab-icon'>
              {selectedTab === tab.key ? tab.activeIcon() : tab.icon()}
            </div>
          )}
          <p
            className={`sidebar-tab-label${selectedTab === tab.key ? ' selected' : ''}`}
          >
            {`${tab.label}${tabCounter(tab)}`}
          </p>
          {parseInt(tab.counter) > 0 && (
            <p className={'sidebar-tab-counter'}>{tab.counter}</p>
          )}
        </div>
      ))}
    </div>
  )
}

export default SidebarTabBar;