import React, {Fragment} from 'react';
import './list-calls-skeleton.scss'
import LoadingCard from '../loading-card/loading-card';

const ListCallsSkeleton = ({callsNumber = 2}) => {
  return (
    <div className='list-calls-skeleton-container'>
      <div className='skeleton-content-header'>
        <LoadingCard
          height={40}
          width={240}
          borderRadius={10}
        />
        <LoadingCard
          height={40}
          width={180}
          borderRadius={10}
        />
      </div>
      <hr />
      {Array(callsNumber).fill(0).map((_, key) => (
        <Fragment key={key}>
          <LoadingCard
            paddingHorizontal={48}
            height={85}
            maxWidth={1024}
            width={'calc(100% - 100px)'}
            borderRadius={10}
            marginTop={10}
            marginBottom={10}
          />
        </Fragment>
      ))}
    </div>
  )
}

export default ListCallsSkeleton
