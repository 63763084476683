import React, { createContext, useContext, useState, useEffect } from "react";

import { useSelector } from "react-redux";
import useVideoAudioSettingsListener from "../hooks/useVideoAudioSettingsListener";
import { browserSupport } from "@caazam/boutiq-video-room";
import useVideoSettingsActions from "../hooks/useVideoSettingsActions";
import useStorageBucket from "../hooks/useStorageBucket";

export const VideoConfigurationContext = createContext(null);

export default function VideoConfigurationProvider({ children }) {

  const { user } = useSelector((state) => state.user);
  const { activeShopId } = useSelector((state) => state.shops);

  const hostId = user ? user.id : null;
  const mediaPath = `userMedia/${hostId}/video_processor/${activeShopId}`;

  const { videoProcessorConfig, isVideoConfigLoaded, hostAppMuteVideoOnCallStart } = useVideoAudioSettingsListener({ shopId: activeShopId, hostId });
  const { uploadFile } = useStorageBucket({ bucket: process.env.REACT_APP_FIREBASE_MEDIA_BUCKET });
  const { onToggleMuteVideoOnCallStart, saveVideoSetting, clearVideoProcessorSettings } = useVideoSettingsActions({ shopId: activeShopId, hostId });

  const [activeVideoProcessor, setActiveProcessor] = useState(null);

  useEffect(() => {
    if (browserSupport.supportsVideoProcessing && isVideoConfigLoaded) {
        if (videoProcessorConfig) {
          setActiveProcessor(videoProcessorConfig);
        } else {
        setActiveProcessor(null);
      }
    }
  }, [videoProcessorConfig, isVideoConfigLoaded]);

  const uploadVirtualBackground = async (file) => {
    let { filePublicUrl, fileStorageRef } = await uploadFile(mediaPath, file);
    return { imagePublicUrl: filePublicUrl, imageStorageRef: fileStorageRef };
  }

  return <VideoConfigurationContext.Provider value={{
    activeVideoProcessor,
    videoProcessorConfig,
    uploadVirtualBackground,
    saveVideoSetting,
    clearVideoProcessorSettings,
    hostAppMuteVideoOnCallStart,
    onToggleMuteVideoOnCallStart
  }}>
    {children}
  </VideoConfigurationContext.Provider>;

}

export function useVideoConfiguration() {
  const context = useContext(VideoConfigurationContext);
  if (!context) {
    throw new Error('useVideoConfiguration must be used within the VideoConfigurationProvider');
  }
  return context;
}


