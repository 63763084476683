import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import 'firebase/compat/analytics';
import Moment from 'moment-timezone';

import InsertLink from '@material-ui/icons/InsertLink';
import ChatIcon from '@material-ui/icons/Chat';
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useAppAuthState } from '../../authState';
import { FilledButton, PlainButton } from '../index';
import useHostStatus from '../../hooks/useHostStatus';
import useHostShopUser from '../../hooks/useHostShopUser';
import usePermalink from '../../hooks/usePermalink';
import usePendingCalls from '../../hooks/usePendingCalls';
import useShopLocales from '../../hooks/useShopLocales';

import useCaazamREST from '../../hooks/useCaazamREST';
import { useSoundFX } from '../../components/SoundFXProvider';
import InfoModal from '../Modals/info';
import Confirm from '../Modals/confirm';
import InputWithCopy from '../../components/input-with-copy/InputWithCopy';
import { updateActiveShop } from '../../reducers/shops-reducer';
import { useNotificationProvider } from '../notifications/NotificationProvider';
import './page-header.scss';
import PendingRequestListItem from './PendingRequestListItem/PendingRequestListItem';
import { useMediaQuery } from '../../utils';
import { HostAvatar } from './components/Avatar/Avatar';
import { calcChatNotifications } from "../../modules/chat/utils/rooms";
import useShopChatConfig from '../../hooks/useShopChatConfig';
import useShopTeams from '../../hooks/useShopTeams';
import { useHostSettingsProvider } from "../hostSettingsProvider/host-settings-provider";
import { logger } from '../../logging';
import IconNotificationClock from "../../assets/icons/notificationClock";
import { useShowroomPresetProvider } from '../../providers/preset-provider/preset-provider';
import {
	useCallDetailsProvider
} from '../../pages/home-page/call-details-side-bar/call-details-provider/call-details-provider';
const LOGOUT_MESSAGE = 'Logging out. please wait...';

const PageHeader = ({ createMeeting, isShopInactive, setIsChatVisible, onHideAll = () => {}, isShowInviteModal }) => {
	const [isInviteLinkVisible, setInviteLinkVisible] = useState(false);
	const [isUserMenuVisible, setUserMenuVisible] = useState(false);
	const [isNotificationsVisible, setNotificationsVisible] = useState(false);
	const [isRequestingLink, setRequestingLink] = useState(false);
	const [isDeletingLink, setDeletingLink] = useState(false);
	const [currentlyAssigning, setCurrentlyAssigning] = useState(null);
	const [isDeletLinkModalVisible, setDeleteLinkModalVisible] = useState(false);
	const [isShopListVisible, setShopListVisible] = useState(false);
	const { availableShops: hostShops, activeShopId } = useSelector((state) => state.shops);
	const activeShopData = hostShops && activeShopId && hostShops[activeShopId];
	const [isChatEnabled, setIsChatEnabled] = useState(false);
	const { shopUserTeams } = useShopTeams(activeShopId);
	const caazamUser = useSelector((state) => state.user.user);
	const [hostTeam, setHostTeam] = useState(null);
	const rooms = useSelector(state => state.rooms);
	const avatar = caazamUser ? caazamUser.avatar ? caazamUser.avatar.thumbnailUrl : false : null;
	const {setIsClientHistory} = useCallDetailsProvider()
	const { signOut } = useAppAuthState();
	const setHostStatus = useHostStatus(caazamUser);
	const { createPermalink, deletePermalink } = usePermalink();
	const { hostPermalink, hostTeamId } = useHostShopUser(activeShopId, caazamUser && caazamUser.id);
	const { pendingCalls } = usePendingCalls();
	const { assignEvent } = useCaazamREST();
	const { toggleSettingsDialog } = useHostSettingsProvider();
	const { showToast, setRouteForNotifications } = useNotificationProvider();
	const { primaryLocale } = useShopLocales(activeShopId);
	const windowWidth = useMediaQuery()[0];
	const isSmallWarningNotification = windowWidth <= 1024 ? 'small' : '';
	const { chatConfig } = useShopChatConfig(activeShopId);
	const {onShowPresetModal, onHidePresetModal} = useShowroomPresetProvider()

	const soundFX = useSoundFX();
	const dispatch = useDispatch();

	const isShopDisabled = activeShopData && !activeShopData.active;
	const [
		isNotificationWarningVisible,
		setNotificationWarningVisible,
	] = useState(false);
	const [openLogoutModal, setOpenLogoutModal] = useState(false);

	const chatNotifications = caazamUser ? calcChatNotifications(rooms, caazamUser.id) : 0;

	useEffect(() => {
		if (!firebase.messaging.isSupported()) {
			setNotificationWarningVisible(true);
		}
	}, []);

	useEffect(() => {
		if (hostTeamId) {
			const team = shopUserTeams.find(team => team.id === hostTeamId);
			if (team) {
				setHostTeam(team.name);
			} else {
				setHostTeam(null);
			}
			return () => setHostTeam(null);
		}
	}, [hostTeamId, shopUserTeams])

	useEffect(() => {
		if (!!chatConfig && chatConfig.enabled) {
			setIsChatEnabled(true);
		} else {
			setIsChatEnabled(false);
		}
	}, [chatConfig]);

	const hideAll = () => {
		onHideAll()
		setInviteLinkVisible(false);
		setUserMenuVisible(false);
		setNotificationsVisible(false);
		setShopListVisible(false);
	};
	const toggleHostInviteLinkModal = () => {
		soundFX.userInteraction();
		hideAll();
		setInviteLinkVisible(!isInviteLinkVisible);
	};
	const toggleUserMenuVisible = () => {
		soundFX.userInteraction();
		hideAll();
		setUserMenuVisible(!isUserMenuVisible);
	};

	const toggleNotificationVisible = () => {
		soundFX.userInteraction();
		hideAll();
		setNotificationsVisible(!isNotificationsVisible);
	};
	const toggleShopListVisible = () => {
		soundFX.userInteraction();
		hideAll();
		setShopListVisible(!isShopListVisible);
	};

	const showDeleteLinkConfirm = () => {
		setDeleteLinkModalVisible(true);
	};
	const hideDeleteLinkConfirm = () => {
		setDeleteLinkModalVisible(false);
	};

	const isPopoverVisible =
		isInviteLinkVisible ||
		isNotificationsVisible ||
		isUserMenuVisible ||
		isShopListVisible ||
		isShowInviteModal;

	const onInviteAClient = () => {
		createMeeting()
		toggleHostInviteLinkModal();
	}

	const onSignOut = async () => {
		setOpenLogoutModal(true);
		onHidePresetModal()
		await setHostStatus(false); // need to set host to unavailable if they are signing out
		await signOut();
	};

	const openPresets = () => {
		onShowPresetModal(true)
		setUserMenuVisible(false);
		toggleSettingsDialog(false);
		setIsChatVisible(false);
	}

	const openSettings = () => {
		setRouteForNotifications('/settings');
		setUserMenuVisible(false);
		toggleSettingsDialog(true);
		setIsChatVisible(false);
		setIsClientHistory(false);
		onHidePresetModal()
	};

	const openChat = () => {
		setRouteForNotifications('/chat');
		setUserMenuVisible(false);
		setIsChatVisible(true);
		setIsClientHistory(false);
		onHidePresetModal()
	};

	const getPermalink = () => {
		setRequestingLink(true);
		createPermalink(activeShopId)
			.then(() => {
				setRequestingLink(false);
			})
			.catch((error) => {
				console.error('Failed to create permalink', error);
			});
	};

	const handleAssign = (callId, callData, shopId) => {
		setCurrentlyAssigning(callId);
		assignEvent(shopId, callId)
			.then(() => {
				showToast({
					title: `Call with ${callData.customer.name} has been assigned to you`,
					description: `${Moment(callData.startTimestamp.toDate()).format('MMM Do, LT')}`,
					icon: <IconNotificationClock fillColor={'#A9D6D0'}/>,
				});
				firebase.analytics().logEvent('host_scheduled_call_assigned');
			})
			.catch(assignError => {
				logger.error(`Failed to assign to scheduled call ${callId}`, assignError);
				if (assignError.statusCode === 409) {
					showToast({
						title: `Call with ${callData.customer.name} has already been assigned to another host`,
						icon: <IconNotificationClock fillColor={'#FFBE88'} />,
						type: 'warning',
					});
				} else {
					showToast({
						title: `Could not assign you to call with ${callData.customer.name}`,
						description: 'please try again',
						icon: <IconNotificationClock fillColor={'#E94848'} />,
						type: 'error',
					});
				}
			})
			.finally(() => {
				setCurrentlyAssigning(null);
			})
	};

	const resetPermalink = () => {
		hideDeleteLinkConfirm();
		setDeletingLink(true);
		// TODO: [CRRC-316] need to show confim dialog before deleting the permalink with warning message
		deletePermalink(activeShopId, hostPermalink.callLinkId)
			.catch((error) => console.error('Failed to delete permalink', error))
			.finally(() => {
				toggleHostInviteLinkModal();
				setDeletingLink(false);
			});
	};

	const changeShops = (newShopId) => {
		dispatch(updateActiveShop(caazamUser.id, newShopId));
		setShopListVisible(false);
	};

	return (
		<>
			<nav className='page-header'>
				<div
					className={`click-outside ${isPopoverVisible ? 'visible' : ''}`}
					onClick={hideAll}></div>
				<div className='start'>
					<img src='../boutiq_icon.svg' className='header-logo' />
					<p className='shop-name'>{activeShopData && activeShopData.name}</p>
					{Object.keys(hostShops).length > 1 ? (
						<div className='action-container'>
							<PlainButton
								style={{ color: 'white', height: '27px' }}
								onClick={toggleShopListVisible}>
								<KeyboardArrowDownIcon
									className={`down-caret ${isShopListVisible ? 'rotate' : ''}`}
								/>
							</PlainButton>
							<div
								className={`nav-popover shop-list ${isShopListVisible ? 'visible' : ''
									}`}>
								{hostShops && (
									<ul>
										{Object.keys(hostShops).map((activeShopId) => (
											<li
												id={hostShops[activeShopId].myshopifyDomain}
												key={activeShopId}
												onClick={() => changeShops(activeShopId)}>
												<h4>{hostShops[activeShopId].name}</h4>
												<p>{hostShops[activeShopId].myshopifyDomain}</p>
											</li>
										))}
									</ul>
								)}
							</div>
						</div>
					) : null}
					{activeShopData && hostTeam && <div className='team-badge'><p>{hostTeam}</p></div>}
				</div>
				{isNotificationWarningVisible && (
					<div className={`notification-warning ${isSmallWarningNotification}`}>
						<ErrorRoundedIcon
							style={{ color: '#ff9900', marginRight: '8px' }}
						/>
						<p>
							This browser doesn't support push notifications - you might miss
							client calls or other important events
						</p>
					</div>
				)}
				<div className='end'>
					<div className='action-container'>
						<span className='clickable' onClick={toggleNotificationVisible}>
							{pendingCalls?.length ? (
								<div className='indicator'>{pendingCalls.length}</div>
							) : null}
							<NotificationsRoundedIcon style={{ fontSize: 26 }} />
						</span>
						<div
							className={`nav-popover notifications ${isNotificationsVisible ? 'visible' : ''
								}`}>
							<h5>Pending Appointment Requests</h5>
							{pendingCalls?.length ? (
								<ul>
									{pendingCalls.map((callData) =>
										<PendingRequestListItem
											key={callData.id}
											id={callData.id}
											primaryLocale={primaryLocale}
											callData={callData}
											handleAssign={handleAssign}
											currentlyAssigning={currentlyAssigning}
										/>
									)}
								</ul>
							) : (
								<p>No pending client appointment requests</p>
							)}
						</div>
					</div>
					<div className='action-container'>
						<InsertLink
							onClick={toggleHostInviteLinkModal}
							className={`${isShopDisabled ? '' : 'clickable'}`}
							style={{ fontSize: 32 }}
						/>
						{!isShopDisabled && isInviteLinkVisible && (
							<div className='nav-popover copy-link'>
								<h4>Personal Host Link</h4>
								{hostPermalink ? (
									<>
										<p>Send this link to clients for calling you directly.</p>
										<div>
											<InputWithCopy inputText={hostPermalink.callLinkUrl} />
											<PlainButton
												className='clickable'
												onClick={showDeleteLinkConfirm}
												isLoading={isDeletingLink}
												style={{ paddingLeft: '100px', marginBottom: '15px' }}
												loaderSize={'32px'}
											>
												<p>Delete link</p>
											</PlainButton>
										</div>
									</>
								) : (
									<>
										<p>
											Create a personal call link so clients can call you directly. </p>
										<FilledButton
											className='button'
											id="create-personal-link-btn"
											onClick={getPermalink}
											style={{
												height: '32px',
												margin: '25px 0 15px 105px',
												width: '200px',
											}}
											isLoading={isRequestingLink}
											loaderSize={'28px'}>
											Create Link
										</FilledButton>
									</>
								)}
							</div>
						)}
					</div>
					{isChatEnabled &&
						<div className='action-container'>
							<span className='clickable' onClick={openChat}>
								{chatNotifications > 0 ? (
									<div className='indicator'>{chatNotifications}</div>
								) : null}
								<ChatIcon style={{ fontSize: 24 }} />
							</span>
						</div>
					}
					{caazamUser && <div className='action-container'>
						<button onClick={toggleUserMenuVisible}>
							<HostAvatar hostInitials={`${caazamUser.firstName.charAt(0)}${caazamUser.lastName.charAt(0)}`} src={avatar} isAvailable={caazamUser.available} />
						</button>
						<div
							className={`nav-popover user-menu ${isUserMenuVisible ? 'visible' : ''
								}`}>
							<ul>
								<li>
									<ul className='user-navigation'>
										<li className='clickable' onClick={openPresets}>
											Showrooms
										</li>
									</ul>
								</li>
								<li>
									<ul className='user-navigation'>
										<li className='clickable' onClick={() => openSettings()}>
											Settings
										</li>
									</ul>
								</li>
								<li className='clickable' onClick={onSignOut}>
									Logout
								</li>
							</ul>
						</div>
					</div>}
				</div>
			</nav>
			{openLogoutModal && (
				<InfoModal title={LOGOUT_MESSAGE} isClosable={false} />
			)}
			{isDeletLinkModalVisible && (
				<Confirm
					content='deleting your current personal host link will prevent clients who have access to the link from using it'
					okFunc={resetPermalink}
					cancelFunc={hideDeleteLinkConfirm}
					closeFunc={hideDeleteLinkConfirm}
				/>
			)}
		</>
	);
};

export default PageHeader;
