import React, { useState } from 'react';

import { Redirect, useLocation, useHistory } from 'react-router-dom';

import { useAppAuthState } from '../../authState';

import LoginForm from './login-form';
import ResetPassword from './reset-password';
import ResetPasswordSuccess from './reset-password-success';
import './login.scss';

const VIEWS = {
  LOGIN: 'login',
  RESET_PASSWORD: 'resetPassword',
  RESET_PASSWORD_SUCCESS: 'resetPasswordSuccess',
};

const Login = ({ signInWithEmailAndPassword, signInWithGoogle, resetPassword = false }) => {
  const [view, setView] = useState(resetPassword ? VIEWS.RESET_PASSWORD : VIEWS.LOGIN);

  let history = useHistory();
  const { authenticatedUser } = useAppAuthState();

  const queryParams = new URLSearchParams(useLocation().search);
  const redirectUrl = queryParams.get('redirectUrl');

  const navigateOnSucess = () => {
      if (redirectUrl)
        window.location.replace(redirectUrl);
      else
        setView(VIEWS.LOGIN);
  }

  const navigateToResetPassword = () => setView(VIEWS.RESET_PASSWORD);
  const navigateToResetPasswordSuccess = () =>
    setView(VIEWS.RESET_PASSWORD_SUCCESS);

  const navigateToHomePage = () => {
    history.push('/');
  };

  const onGoogleLogin = () => {
    signInWithGoogle()
      .then((res) => history.push('/'))
      .catch((err) =>
        console.error('There was an error signing in. Please try again.')
      );
  };

  const renderView = () => {
    if (authenticatedUser && !resetPassword) {
      return <Redirect to='/' />;
    }
    switch (view) {
      case VIEWS.LOGIN:
        return (
          <LoginForm
            signInWithEmailAndPassword={signInWithEmailAndPassword}
            navigateToResetPassword={navigateToResetPassword}
            navigateToHomePage={navigateToHomePage}
          />
        );
      case VIEWS.RESET_PASSWORD:
        return <ResetPassword onSuccess={navigateToResetPasswordSuccess} redirectUrl={redirectUrl} />;
      case VIEWS.RESET_PASSWORD_SUCCESS:
        return <ResetPasswordSuccess navigateToLogin={navigateOnSucess} />;
    }
  };

  return (
    <div className='login-page-container'>
      <div className='background-element' />
      <img src="../boutiq.svg" className='brand-logo' />
      {renderView()}
    </div>
  );
};

export default Login;
