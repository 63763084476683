import React, {useMemo, useState} from "react";
import Moment from "moment-timezone";
import CallFeedbackRating from "../../../components/CallFeedbackRating";
import './calendar-row.scss'
import {CALL_TYPES, FEEDBACK_TYPE } from "../../../utils/consts";
import {useSelector} from "react-redux";
import Confirm from "../../../components/Modals/confirm";

import ScheduledActions from "./components/scheduled-actions/scheduled-actions";
import CallOrder from "./components/badges/call-order";
import CallError from "./components/badges/call-error";
import CallCheckout from "./components/badges/call-checkout";
import DraftOrderAbandoned from "./components/badges/draft-order-abandoned";
import CartAbandoned from "./components/badges/cart-abandoned";
import ScheduledNextCall from "./components/badges/scheduled-next-call";
import {RescheduleFlowModal} from "../call-details-side-bar/reschedule-flow-modal/RescheduleFlowModal";
import {AskClientFlowModal} from "../call-details-side-bar/ask-client-flow-modal/AskClientFlowModal";
import {SelectFlowModal} from "../call-details-side-bar/select-flow-modal/SelectFlowModal";
import {useHostSettingsProvider} from "../../../components/hostSettingsProvider/host-settings-provider";
import CallEventType from "./components/badges/call-event-type";
import {useNotificationProvider} from "../../../components/notifications/NotificationProvider";
import useCaazamREST from "../../../hooks/useCaazamREST";
import DeleteConfirmContent from "./components/delete-confirm-content/delete-confirm-content";
import {CHANGE_EVENT} from "../../../constants/const";
import {useCallDetailsProvider} from "../call-details-side-bar/call-details-provider/call-details-provider";

export const CalendarRow = ({
   call,
   feedbackType,
   isSelected = false,
   showDivider = false,
   isFirstChild,
   isDetailsOpen,
   onOpenBlockOffTime
}) => {
  const {
    type,
    timestamp,
    endTimestamp,
    duration,
    status,
    totalOrderAmount,
    orders,
    totalOrderAmountCurrency,
    customerName,
    customerEmail,
    customerPhone,
    customerMessage,
    customerTimezone,
    error,
    abandonedCart,
    checkouts = {},
    callFeedback,
    draftOrders,
    id,
    callLinkUrl,
    icsDownloadUrl,
    eventType,
    shopId,
    title,
    carts
  } = call;
  const {toggleSidebarModal, callDetails} = useCallDetailsProvider();
  const { shopNameIsVisible } = useHostSettingsProvider();
  const { availableShops } = useSelector((state) => state.shops);
  const {user} = useSelector((state) => state.user);
  const [showMoreActionsModal, setShowMoreActionsModal] = useState(false);
  const [showCalendarModalModal, setShowCalendarModalModal] = useState(false);
  const [isConfirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [openSelectFlowModal, setOpenSelectFlowModal] = useState(false)
  const [openRescheduleFlowModal, setOpenRescheduleFlowModal] = useState(false);
  const [openAskClientFlow, setOpenAskClientFlow] = useState(false)
  const [deleteConfirmInfo, setDeleteConfirmInfo] = useState({
    noShow: false,
    disableClientEmail: false,
    message: ''
  })
  const {showToast} = useNotificationProvider();
  const { deleteEvent, unassignEvent, cancelEvent } = useCaazamREST();

  const isAbandonded = useMemo(() => (
    Object.values(carts ?? {}).pop()?.isAbandonded
  ), [carts, call])

  const isModalOpen = useMemo(() => (
    openRescheduleFlowModal || openAskClientFlow || openSelectFlowModal || isConfirmDeleteVisible
  ), [openRescheduleFlowModal, openAskClientFlow, openSelectFlowModal, isConfirmDeleteVisible])

  const showCallInfo = () => {
    setShowCalendarModalModal(false)
    setShowMoreActionsModal(false)
    toggleSidebarModal(isSelected ? null : call)
  };

  const onHideActionModals = () => {
    setShowCalendarModalModal(false)
    setShowMoreActionsModal(false)
  }

  const onCopyCallingLink = async () => {
    setShowCalendarModalModal(false)
    setShowMoreActionsModal(false)
    showToast({
      description: 'Link copied to clipboard',
      type: 'info',
    });
    await navigator.clipboard.writeText(call.callLinkUrl)
  }

  const onHandleClick = (event, action = () => {}) => {
    if (!isModalOpen) {
      action()
      event.stopPropagation()
    }
  }

  const showMoreActions = () => {
    setShowCalendarModalModal(false)
    setShowMoreActionsModal(!showMoreActionsModal)
  }

  const showCalendar = () => {
    setShowMoreActionsModal(false)
    setShowCalendarModalModal(!showCalendarModalModal)
  }

  const unassign = async () => {
    await unassignEvent(shopId, id)
  };

  const showConfirm = () => {
    setConfirmDeleteVisible(true);
  };

  const hideConfirm = () => {
    setConfirmDeleteVisible(false);
    setDeleteConfirmInfo({
      noShow: false,
      disableClientEmail: false,
      message: ''
    })
  };

  const deleteMeeting = async () => {
    setConfirmDeleteVisible(false);
    if (call.type === CALL_TYPES.SCHEDULED) {
      await cancelEvent(shopId, id, {
        ...deleteConfirmInfo,
        message: deleteConfirmInfo.disableClientEmail ? '' : deleteConfirmInfo.message
      })
    } else {
      await deleteEvent(shopId, id);
    }
  };

  const onRescheduled = () => {
    setOpenSelectFlowModal(true)
  }

  const onCloseModal = () => {
    setOpenRescheduleFlowModal(false)
    setOpenSelectFlowModal(false)
    setOpenAskClientFlow(false)
  }

  const onBack = () => {
    setOpenSelectFlowModal(true)
    setOpenRescheduleFlowModal(false)
    setOpenAskClientFlow(false)
  }

  const showAskClientFlow = () => {
    setOpenAskClientFlow(true)
    setOpenSelectFlowModal(false)
  }

  const showRescheduleFlow = () => {
    setOpenRescheduleFlowModal(true)
    setOpenSelectFlowModal(false)
  }

  const onChangeConfirmInfo = (event, type) => {
    switch (type) {
      case CHANGE_EVENT.NO_SHOW:
        setDeleteConfirmInfo(prev => ({
          ...prev,
          noShow: !prev.noShow
        }))
        break;
      case CHANGE_EVENT.DISABLE_CLIENT_EMAIL:
        setDeleteConfirmInfo(prev => ({
          ...prev,
          disableClientEmail: !prev.disableClientEmail
        }))
        break;
      case CHANGE_EVENT.MESSAGE:
        const message = event.target?.value;
        setDeleteConfirmInfo(prev => ({
          ...prev,
          message
        }))
        break;
    }
  }

  return (
    <div
      className={`calendar-row ${type}${isSelected ? ' selected' : ''}${error ? ' short-row': ''}`}
      onClick={(event) => onHandleClick(event, showCallInfo)}
    >
      {shopNameIsVisible && (
        <span className={'shop-name'}>
          {availableShops[shopId].name}
        </span>
      )}
      <span className={`call-time${shopNameIsVisible ? ' with-shop-name' : ''}${error ? ' short-row': ''}`}>
        <p>{Moment(timestamp).format('LT')}</p>
        <p>{duration && `${Math.ceil(duration / 60)} min`}</p>
      </span>
      <span
        className={`call-info${shopNameIsVisible ? ' with-shop-name' : ''}${error ? ' short-row': ''}`}>
        <span className='caller-details'>
          <div className='client-name-wrapper'>
            <h4 className='customer-name overflow'>{customerName ?? title}</h4>
            {eventType &&
              <CallEventType eventType={eventType} classStyle={'overflow'} />
            }
          </div>
          {!error && (
            <div className='customer-contacts-container'>
              <p>{customerEmail}</p> {customerPhone && <p><span className='circle-divider'></span>{customerPhone}</p>}
            </div>
          )}
          {callFeedback?.[0] && feedbackType !== FEEDBACK_TYPE.NONE &&
            <div className='feedback-section'>
              <span>
                <CallFeedbackRating ratingData={callFeedback[0]} feedbackType={feedbackType}/>
              </span>
              {callFeedback[0].message && [FEEDBACK_TYPE.NPS, FEEDBACK_TYPE.CSAT].includes(feedbackType) &&
                <div className='feedback-message-container'>
                  <span className='feedback-message'>{callFeedback[0].message}</span>
                  <p className='feedback-hover-tooltip-text'>{callFeedback[0].message}</p>
                </div>}
            </div>
          }
          {customerMessage && <p className='customers-message'>{customerMessage}</p>}
        </span>
        <div className='badge-wrapper'>
          {(type === CALL_TYPES.SCHEDULED || type === CALL_TYPES.BLOCKED_EVENT) && (
            <ScheduledActions
              isDetailsOpen={isDetailsOpen}
              call={call}
              showCalendar={showCalendar}
              onCopyCallingLink={onCopyCallingLink}
              showMoreActions={showMoreActions}
              onRescheduled={onRescheduled}
              unassign={unassign}
              showConfirm={showConfirm}
              onEditEvent={() => onOpenBlockOffTime(id)}
              showCalendarModalModal={showCalendarModalModal}
              showMoreActionsModal={showMoreActionsModal}
              onHideActionModals={onHideActionModals}
            />
          )}
          {orders && Object.keys(orders).length ? (
            <CallOrder
              totalOrderAmount={totalOrderAmount}
              currency={totalOrderAmountCurrency}
            />
          ) : error ? (
            <CallError error={error}/>
          ) : Object.keys(checkouts).length ? (
            <CallCheckout/>
          ) : draftOrders && Object.keys(draftOrders).length ? (
            <DraftOrderAbandoned/>
          ) : !!abandonedCart || isAbandonded ? (
            <CartAbandoned/>
          ) : type === CALL_TYPES.SCHEDULED && isFirstChild && Moment(timestamp).isSame(Moment(), "day") ? (
            <ScheduledNextCall/>
          ) : null}
        </div>
      </span>
      {!isSelected && type === CALL_TYPES.SCHEDULED && showDivider && < span className='scheduled-call-divider'/>}
      {isConfirmDeleteVisible && (
        <Confirm
          okFunc={deleteMeeting}
          okText={type === CALL_TYPES.SCHEDULED ? 'Cancel Appointment' : 'Delete'}
          cancelFunc={hideConfirm}
          closeFunc={hideConfirm}
          content={type === CALL_TYPES.SCHEDULED ? (
            <DeleteConfirmContent
              deleteConfirmInfo={deleteConfirmInfo}
              onChange={onChangeConfirmInfo}
            />
          ) : 'Are you sure you want to delete this block off?'}
          modalClass='warning'
        />
      )}
      {openSelectFlowModal && <SelectFlowModal
        customerName={customerName}
        customerEmail={customerEmail}
        onClose={onCloseModal}
        showRescheduleFlow={showRescheduleFlow}
        showAskClientFlow={showAskClientFlow}
      />}
      {openAskClientFlow && <AskClientFlowModal
        customerName={customerName}
        customerEmail={customerEmail}
        scheduleId={id}
        shopId={shopId}
        onClose={onCloseModal}
        onBack={onBack}
      />}
      {openRescheduleFlowModal && <RescheduleFlowModal
        isInviteFlow={false}
        onClose={onCloseModal}
        onBack={onBack}
        scheduleId={id}
        shopId={shopId}
        currentUser={user}
        timestamp={timestamp}
        endTimestamp={endTimestamp}
        customerName={customerName}
        customerEmail={customerEmail}
        customerTimezone={customerTimezone}
        callLinkUrl={callLinkUrl}
        icsDownloadUrl={icsDownloadUrl}
        eventType={eventType}
      />}
    </div>
  );
};
