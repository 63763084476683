import React from 'react';
import Modal from '../modal';

import './styles.scss';

import {FilledButton} from "../../buttons/buttons";
import {useSoundFX} from "../../SoundFXProvider";

/* 
  modal to start permissions flow when they were never requested before (so not denied)
 */
const PermissionsModal = ({callPermissions}) => {
  const soundFX = useSoundFX();

  const callAVPermissions = () => {
    soundFX.userInteraction();
    callPermissions();
  }

  return (
    <Modal modalClass='permissions-container' isClosable={false}>
      <img src='../logo_icon.png' className='permissions-logo' />
      <p className='permissions-title'>Welcome to Boutiq!</p>
      <p className='permissions-info'>To make video calls on Boutiq allow notifications, access to the video camera and microphone</p>
      <FilledButton
        className='permissions-button'
        onClick={callAVPermissions}>
        Continue
      </FilledButton>
    </Modal>
  );
};

export default PermissionsModal;
