import React from 'react';

import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import InputWithCopy from '../input-with-copy/InputWithCopy';
import './invite-success.scss'
import { AddToCalendar } from '../add-to-calendar/AddToCalendar';
import { AddToCalendarButton } from '../add-to-calendar-button/AddToCalendarButton';
import {displayDateFormatter} from '../../utils';

const InviteSuccess = ({ timeSlot, callLink, customerName, customerEmail, icsDownloadUrl }) => {

  const header = timeSlot
    ? <span className='confirm-header'>
        <h2>{`Call is scheduled ${displayDateFormatter(timeSlot.timeSlot)} `}</h2>
    </span>
    : <h2>{'Call link created '}</h2>;
  const description = timeSlot
    ? 'At the scheduled time, when the client clicks the link a new Boutiq video call will start and you will be able to answer it'
    : 'When the client clicks the link a new Boutiq video call will start and you will be able to answer it';

  const instructions = timeSlot
    ? <p>Confirmation email with call link sent to the client at {customerEmail}</p>
    : <p>Share the call link with your client</p>


  return (
    <div className='content'>
      <section className='confirm-schedule'>
        <CheckCircleOutlineRoundedIcon
          style={{
            fontSize: '120px',
            marginBottom: '28px',
            color: 'var(--main-color)',
            WebkitTextFillColor: 'var(--main-color)',
          }}
        />
        {header}
        {instructions}
        <p>{description}</p>
        <InputWithCopy inputText={callLink} />
      </section>

      {timeSlot && (
        <footer className='confirm-actions'>
          <div className='add-to-calendar-modal-container'>
            <AddToCalendar
              modalPosition='top'
              button={<AddToCalendarButton />}
              hostId={null}
              icsDownloadUrl={icsDownloadUrl}
            />
          </div>
        </footer>
      )}
    </div>
  );
};

export default InviteSuccess;
