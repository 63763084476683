import React, {useMemo} from 'react';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import {FilledButton} from '../buttons/buttons';

const InviteError = ({returnToMain, isBannedClient}) => {
  const title = useMemo(() => (
    isBannedClient
      ? 'This client is banned'
      : 'Sorry, something went wrong'
  ), [isBannedClient])

  const subtitle = useMemo(() => (
    isBannedClient
      ? 'Cannot schedule a call for this client'
      : 'Cannot schedule your call for some reason'
  ), [isBannedClient])

  const description = useMemo(() => (
    isBannedClient
      ? 'Contact the Boutiq administrator for more details'
      : 'Please try again'
  ), [isBannedClient])

  return (
    <div className='content'>
      <section className='confirm-schedule'>
        <HighlightOffRoundedIcon
          style={{
            fontSize: '120px',
            marginBottom: '40px',
            color: '#d83a68',
            WebkitTextFillColor: '#d83a68',
          }}
        />
        <h3>{title}</h3>
        <div>
          <p>{subtitle}</p>
          <p>{description}</p>
        </div>
      </section>
      <footer className='confirm-actions'>
        <FilledButton
          onClick={returnToMain}
          style={{backgroundColor: 'black'}}>
          {isBannedClient ? 'Ok' : 'Try again'}
        </FilledButton>
      </footer>
    </div>
  );
};

export default InviteError;
