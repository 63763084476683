import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import { FilledButton, StyledInput } from '../../components';
import { useSoundFX } from '../../components/SoundFXProvider';
import { isEmailValid } from '../../utils/index';
import { logger } from '../../logging' ;

const LoginForm = ({
  signInWithEmailAndPassword,
  navigateToResetPassword,
  navigateToHomePage,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState(null);

  const soundFX = useSoundFX();

  const handleEmailInput = (e) => {
    setEmail(e.target.value);
    setFormErrorMessage(null);
  };

  const handlePasswordInput = (e) => {
    setPassword(e.target.value);
    setFormErrorMessage(null);
  };

  const onLogin = () => {
    soundFX.userInteraction();
    setFormErrorMessage(null);
    if (!isEmailValid(email)) {
      setFormErrorMessage('Please enter a valid email');
      return;
    }
    setLoading(true);
    signInWithEmailAndPassword(email, password)
      .then((res) => {
        firebase.analytics().logEvent('login', { method: 'password' });
        logger.info(`login by user ${res.user.uid}`);
        navigateToHomePage();
      })
      .catch((err) => {
        setLoading(false);
        setFormErrorMessage('There was an error signing in. Please try again.');
      });
  };

  return (
    <div className='login-page'>
      <form className='login-container'>
        <h2>Boutiq Host Login</h2>
        <div className='input-container'>
          <StyledInput
            placeholder='Email'
            name='email'
            type='email'
            value={email}
            onChange={handleEmailInput}
          />
          <StyledInput
            placeholder='Password'
            name='password'
            type='password'
            value={password}
            onChange={handlePasswordInput}
          />
        </div>

        <div className='actions'>
          <FilledButton
            className='button'
            onClick={onLogin}
            style={{ width: '200px' }}
            isLoading={isLoading}
            disabled={!email || !password}>
            Login
          </FilledButton>
          <span onClick={navigateToResetPassword} className='clickable'>
            Forgot?
          </span>
          <p className='error-text'>{formErrorMessage}</p>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
