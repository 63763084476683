import React, {useCallback} from 'react';
import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import { PROCESSOR_TYPES } from '../utils/consts';

export default function useVideoSettingsActions({shopId = null, hostId = null}) {

  const saveVideoSetting = useCallback(
    (options) => {
      const processorParams = {};
      if (options.type === PROCESSOR_TYPES.BLUR) {
        processorParams.type = PROCESSOR_TYPES.BLUR;
        processorParams.strength = 0.5;
      }

      if (options.type === PROCESSOR_TYPES.VIRTUAL) {
        processorParams.type = PROCESSOR_TYPES.VIRTUAL;
        processorParams.fitType = 'Fill';

        if (!options.backgroundImage) {
          console.error(`'backgroundImage' parameter is required for virtual background processor`);
          return;
        }
        processorParams.backgroundImage = options.backgroundImage; //TODO: add image path here
        processorParams.backgroundImageRef = options.backgroundImageRef;
      }
      firebase.analytics().logEvent("host_video_filter_set", { type: processorParams.type });

      return firebase
        .firestore()
        .collection('shops')
        .doc(shopId)
        .collection('shopUsers')
        .doc(hostId)
        .collection('settings')
        .doc('audioVideoSettings')
        .set({processorParams}, {merge: true});
    },
    [shopId, hostId]
  );

  const clearVideoProcessorSettings = useCallback(() => {

      firebase
        .firestore()
        .collection('shops')
        .doc(shopId)
        .collection('shopUsers')
        .doc(hostId)
        .collection('settings')
        .doc('audioVideoSettings')
        .update({
          processorParams: firebase.firestore.FieldValue.delete()
        })
        .catch((e) => console.error(`Deleting error of video processor`, e));
    },
    [shopId, hostId]
  );

  const onToggleMuteVideoOnCallStart = useCallback((state) => {
    firebase
      .firestore()
      .collection('shops')
      .doc(shopId)
      .collection('shopUsers')
      .doc(hostId)
      .collection('settings')
      .doc('audioVideoSettings')
      .set({
        hostAppMuteVideoOnCallStart: state
      }, {merge: true})
      .then(() => {
        firebase.analytics().logEvent(`onToggleMuteVideoOnCallStart: ${state}`);
      });
  }, [shopId, hostId])

  return {
    saveVideoSetting,
    clearVideoProcessorSettings,
    onToggleMuteVideoOnCallStart
  };
}
