import React, {useMemo} from 'react';
import './summary-data.scss';
import {formatEventDate} from '../../../utils';
import TimezoneSelector from '../../timezone-selector/timezone-selector';
import {FilledButton} from '../../buttons/buttons';
import {useSelector} from 'react-redux';

const SummaryData = ({
  selectedSlot,
  timezone,
  customerName,
  customerEmail,
  onSelectTimezone,
  selectedEvent,
  scheduleCall,
  isScheduling,
  isInviteFlow
}) => {
  const { availableShops: hostShops, activeShopId } = useSelector((state) => state.shops);
  const activeShopData = hostShops && activeShopId && hostShops[activeShopId];

  const selectedTimezone = useMemo(() => (
    timezone ?? {id: activeShopData.ianaTimezone}
  ), [timezone, activeShopData])

  return (
    <div className='summary-data-container'>
      <header>
        <h3>{`${isInviteFlow ? 'Schedule' : 'Reschedule'} summary`}</h3>
      </header>
      <div className='summary-date-container'>
        <h3 className='date-title'>DATE AND TIME</h3>
        {selectedEvent && (
          <div className='event-type-container'>
            <div className='event-type-color' style={{'--event-type-color': selectedEvent.color}} />
            <span>{selectedEvent.name}</span>
          </div>
        )}
        <p className='date'>{formatEventDate(selectedSlot.timeSlot)}</p>
        <span className='duration'>{selectedSlot.timeSlotEnd.diff(selectedSlot.timeSlot, 'minutes')} minutes</span>
      </div>
      <div className='summary-customer-container'>
        <h3 className='customer-details-title'>CLIENT DETAILS</h3>
        <p className='client-name'>{customerEmail}</p>
        <p className='client-email'>{customerName}</p>
        <span className='timezone'>
          <span>{selectedTimezone.id}</span>
          <TimezoneSelector
            timezone={selectedTimezone}
            onSelectTimezone={onSelectTimezone}
            activator={(onChangeTimezone) => (
              <span
                className='change-button'
                onClick={onChangeTimezone}
              >
                change
              </span>
            )}
          />
        </span>
        <span>({formatEventDate(selectedSlot.timeSlot, selectedTimezone.id)})</span>
      </div>
      <div className='summary-call-details-container'>
        <FilledButton
          onClick={scheduleCall}
          disabled={!selectedSlot}
          className={`${!selectedSlot ? 'disabled' : 'button'}`}
          isLoading={isScheduling}>
          {isInviteFlow ? 'Schedule' : 'Reschedule'}
        </FilledButton>
      </div>
    </div>
  )
}

export default SummaryData;