import React, { createContext, useContext, useEffect, useState } from 'react';
import useShowroomPresets from '../../hooks/useShowroomPresets';
import {
  useCallDetailsProvider
} from '../../pages/home-page/call-details-side-bar/call-details-provider/call-details-provider';

const ShowroomPresetContext = createContext(null);

export const ShowroomPresetProvider = ({children}) => {
  const {callDetails, toggleSidebarModal} = useCallDetailsProvider()
  const [preparationProducts, setPreparationProducts] = useState([])
  const [presetModalVisibility, setPresetModalVisibility] = useState(false)
  const [saveShowroomModalVisibility, setSaveShowroomModalVisibility] = useState(false)
  const [saveAction, setSaveAction] = useState(null)
  const [isEditPresetMode, setIsEditPresetMode] = useState(false)
  const [editPresetModalVisibility, setEditPresetModalVisibility] = useState(false)
  const [editedPreset, setEditedPreset] = useState(null)
  const [filterText, setFilterText] = useState('')
  const [removePresetModalVisibility, setRemovePresetModalVisibility] = useState(false)

  const {
    callPreparationShowroom,
    loading: showroomPresetLoading,
    onUpdateShowroomProducts,
    showroomPresetsList,
    getShowroomProducts,
    onUpdatePreset,
    onSaveNewPreset,
    refreshProductDetails,
    isProductsPresetChanged,
    setProductsPresetChanged,
    showroomPreset,
    onUpdateVideoRoomShowroom,
    onRemovePreset,
    onRefreshEditedPresetProducts
  } = useShowroomPresets()

  const clearState = () => {
    setPreparationProducts([])
    setPresetModalVisibility(false)
    setSaveShowroomModalVisibility(false)
    setSaveAction(null)
    setIsEditPresetMode(false)
    setEditPresetModalVisibility(false)
    setEditedPreset(null)
    setFilterText('')
    setRemovePresetModalVisibility(false)
  }

  useEffect(() => {
    getShowroomProducts().then(products => setPreparationProducts(products))
    return () => {
      clearState()
    }
  }, [callDetails?.id])

  useEffect(() => {
    if (editedPreset && !removePresetModalVisibility) {
      onRefreshEditedPresetProducts(editedPreset.products).then(products => setPreparationProducts(products))
    }
  }, [editedPreset, removePresetModalVisibility])

  const onShowRemovePresetModal = (preset) => {
    setEditedPreset(preset)
    setRemovePresetModalVisibility(true)
  }

  const onHideRemovePresetModal = () => {
    setEditedPreset(null)
    setRemovePresetModalVisibility(false)
  }

  const onChangeFilter = (text) => {
    setFilterText(text)
  }

  const onShowPresetModal = (isEditMode = false) => {
    if (isEditMode && callDetails) {
      toggleSidebarModal(null)
      setTimeout(() => {
        setPresetModalVisibility(true)
        setIsEditPresetMode(isEditMode)
      }, 600)
    } else {
      setPresetModalVisibility(true)
      setIsEditPresetMode(isEditMode)
    }
  }

  const onShowEditPresetModal = (preset) => {
    if (preset.products?.length) {
      setEditedPreset(preset)
    }
    setEditPresetModalVisibility(true)
  }

  const onHideEditPresetModal = () => {
    onUpdatePreparationProducts([])
    setProductsPresetChanged(false)
    setEditPresetModalVisibility(false)
    setEditedPreset(null)
  }

  const onHidePresetModal = () => {
    setPresetModalVisibility(false)
    setIsEditPresetMode(false)
  }

  const onShowSaveShowroomModal = (action) => {
    setSaveAction(action)
    setSaveShowroomModalVisibility(true)
  }

  const onHideSaveShowroomModal = () => {
    setSaveAction(null)
    setSaveShowroomModalVisibility(false)
  }

  const onClearPreparationProducts = async () => {
    await onUpdateShowroomProducts([])
    clearState()
  }

  const onUpdatePreparationProducts = async (updatedProducts, preset) => {
    if (Array.isArray(updatedProducts)) {
      setPreparationProducts(updatedProducts)
      if (!isEditPresetMode) {
        await onUpdateShowroomProducts(updatedProducts, preset)
      }
    }
    if (updatedProducts instanceof Function) {
      setPreparationProducts((currentProducts) => {
        const products = updatedProducts(currentProducts)
        onUpdateShowroomProducts(products)
        return products
      })
    }
  }

  return (
    <ShowroomPresetContext.Provider
      value={{
        callPreparationShowroom,
        preparationProducts,
        showroomPresetsList,
        onShowPresetModal,
        onHidePresetModal,
        presetModalVisibility,
        onShowSaveShowroomModal,
        onHideSaveShowroomModal,
        saveShowroomModalVisibility,
        onClearPreparationProducts,
        onUpdatePreparationProducts,
        showroomPresetLoading,
        onUpdatePreset,
        onSaveNewPreset,
        refreshProductDetails,
        isProductsPresetChanged,
        showroomPreset,
        saveAction,
        onUpdateVideoRoomShowroom,
        isEditPresetMode,
        onRemovePreset,
        editPresetModalVisibility,
        onShowEditPresetModal,
        onHideEditPresetModal,
        editedPreset,
        filterText,
        onChangeFilter,
        onShowRemovePresetModal,
        onHideRemovePresetModal,
        removePresetModalVisibility,
        clearShowroomPresetState: clearState
      }}
    >
      {children}
    </ShowroomPresetContext.Provider>
  )
}

export const useShowroomPresetProvider = () => {
  const context = useContext(ShowroomPresetContext);
  if (!context) {
    throw new Error('useShowroomPresetProvider must be used within the ShowroomPresetContext');
  }
  return context;
}