import {useEffect, useState} from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const useCallsPolicy = (activeShopId) => {
  const [callsPolicy, setCallsPolicy] = useState(null)

  useEffect(() => {
    if (activeShopId) {
      const unsub = firebase
        .firestore()
        .collection('shops')
        .doc(activeShopId)
        .collection('installData')
        .doc('callsPolicy')
        .onSnapshot(
          (snapshot) => {
            if (snapshot && snapshot.data()) {
              setCallsPolicy(snapshot.data())
            }
          },
          (error) => {
            console.error('Error customer profile config', error);
          }
        )
      return () => unsub();
    }

  }, [activeShopId]);

  return ({
    callsPolicy
  })
}

export default useCallsPolicy;