import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import './PaymentSection.scss';
import PaymentInfoItem from "../payment-info-item/PaymentInfoItem";
import {currencyFormatter} from "../../../../../utils";
import OrderDiscount, {DISCOUNT_TYPE} from "../order-discount/OrderDiscount";
import OrderShipping from "../order-shipping/OrderShipping";
import {useSelector} from "react-redux";

const PaymentSection = ({
  products,
  onDiscountChanged,
  onShippingChanged,
  isDraftOrderCompleted,
  setIsChangedFromShopify,
  onDiscountModalChangeVisibility,
  shopId
}, ref) => {
  const { availableShops } = useSelector((state) => state.shops);
  const shopData = availableShops && shopId && availableShops[shopId];
  const currencyCode = shopData?.currencyCode ?? 'USD'
  const [showDiscount, setShowDiscount] = useState(false);
  const [showShipping, setShowShipping] = useState(false);
  const [discount, setDiscount] = useState(null)
  const [shipping, setShipping] = useState(null)
  const [discountSum, setDiscountSum] = useState(0)
  const [shippingSum, setShippingSum] = useState(0)
  const [total, setTotal] = useState(0)
  const [subtotal, setSubtotal] = useState(0)
  const [discountReason, setDiscountReason] = useState('')
  const [totalTax, setTotalTax] = useState('0.00')

  const orderDiscountRef = useRef()
  const orderShippingRef = useRef()

  useImperativeHandle(ref, () => ({
    clearState: () => {
      setDiscountReason('')
      setTotal(0)
      setShippingSum(0)
      setDiscountSum(0)
      setSubtotal(0)
      setShipping(null)
      setDiscount(null)
    },
    onUpdateDiscount: (discount, discountReason) => {
      setDiscount(discount)
      setDiscountReason(discountReason)
      setIsChangedFromShopify(true)
    },
    onUpdateShipping: (shipping) => {
      setShipping(shipping)
      setIsChangedFromShopify(true)
    },
    onRemoveDiscount: onRemoveDiscount,
    onRemoveShipping: onRemoveShipping,
    onUpdateTotalTax: setTotalTax,
    onHideDiscountModals: () => {
      setShowDiscount(false)
      setShowShipping(false)
    }
  }))

  useEffect(() => {
    if (products.length === 0 && (shipping || discount?.discountValue)) {
      setShipping(null)
      setDiscount(null)
      setShippingSum(0)
      setDiscountSum(0)
      setDiscountReason('')
    }
  }, [products.length])

  useEffect(() => {
    onDiscountChanged(discount, discountReason)
  }, [discount, discountReason])

  useEffect(() => {
    onShippingChanged(shipping)
  }, [shipping])

  useEffect(() => {
    if (showDiscount) {
      orderDiscountRef.current.scrollIntoView()
    }
  }, [showDiscount])

  useEffect(() => {
    if (showShipping) {
      orderShippingRef.current.scrollIntoView()
    }
  }, [showShipping])

  useEffect(() => {
    setSubtotal(products.reduce((totalPrice, product) => totalPrice + product.price*product.quantity, 0))
  }, [JSON.stringify(products)])

  useEffect(() => {
    setTotal(subtotal - discountSum + shippingSum + Number(totalTax))
  }, [subtotal, discountSum, shippingSum, totalTax])

  useEffect(() => {
    setShippingSum(Number(shipping?.price ?? 0))
  }, [shipping])

  useEffect(() => {
    if (!discount) {
      setDiscountSum(0)
    } else {
      if (discount.discountType === DISCOUNT_TYPE.AMOUNT) {
        if (discount.discountValue >= subtotal) {
          setDiscountSum(subtotal)
        } else {
          setDiscountSum(discount.discountValue)
        }
      } else {
        if (discount.discountValue >= 100) {
          setDiscountSum(subtotal)
        } else {
          setDiscountSum((discount.discountValue/100) * subtotal)
        }
      }
    }
  }, [subtotal, JSON.stringify(discount)])

  const onAddDiscount = () => {
    setShowDiscount(true)
    setShowShipping(false)
    onDiscountModalChangeVisibility()
  }

  const onUpdateDiscount = (discountOrder) => {
    setShowDiscount(false)
    setIsChangedFromShopify(false)
    setDiscount(discountOrder.discount)
    setDiscountReason(discountOrder.discountReason)
  }

  const onRemoveDiscount = () => {
    setShowDiscount(false)
    setDiscount(null)
    setDiscountReason('')
    setIsChangedFromShopify(false)
  }

  const onUpdateShipping = (shipping) => {
    setShowShipping(false)
    setIsChangedFromShopify(false)
    setShipping(shipping)
  }

  const onRemoveShipping = () => {
    setIsChangedFromShopify(false)
    setShowShipping(false)
    setShipping(null)
  }

  const onAddShipping = () => {
    setShowShipping(true)
    setShowDiscount(false)
    onDiscountModalChangeVisibility()
  }

  return (
    <div className='draft-order-payment'>
      <h3>Payment</h3>
      <div className='draft-order-payment-info'>
        <PaymentInfoItem
          title='Subtotal'
          description=' '
          value={currencyFormatter(subtotal, currencyCode)}
        />
        <PaymentInfoItem
          disabled={isDraftOrderCompleted || products.length === 0}
          title={`${discount?.discountValue ? 'Edit' : 'Add'} discount`}
          value={`${discountSum > 0 ? '-' : ''}${currencyFormatter(discountSum, currencyCode)}`}
          onPress={products.length !== 0 ? onAddDiscount : null}
          description={discount?.discountValue ? discountReason : ''}
        />
        {showDiscount && (
          <OrderDiscount
            ref={orderDiscountRef}
            hideDiscount={() => setShowDiscount(false)}
            updateDiscount={onUpdateDiscount}
            orderDiscount={{discount, discountReason}}
            onRemoveDiscount={onRemoveDiscount}
          />
        )}
        <PaymentInfoItem
          disabled={isDraftOrderCompleted || products.length === 0}
          title={`${shipping ? 'Edit' : 'Add'} shipping`}
          value={currencyFormatter(shippingSum, currencyCode)}
          onPress={products.length !== 0 ? onAddShipping : null}
          description={shipping ? shipping.rateName : ''}
        />
        {showShipping && (
          <OrderShipping
            ref={orderShippingRef}
            hideShipping={() => setShowShipping(false)}
            orderShipping={shipping}
            onUpdateShipping={onUpdateShipping}
            onRemoveShipping={onRemoveShipping}
          />
        )}
        <PaymentInfoItem
          disabled={true}
          title='Tax'
          description={totalTax === '0.00' ? 'not calculated' : ' '}
          value={currencyFormatter(totalTax, currencyCode)}
        />
        <PaymentInfoItem
          title='Total'
          description=' '
          value={currencyFormatter(total, currencyCode)}
          isTotal={true}
        />
      </div>
    </div>
  )
}

export default forwardRef(PaymentSection);