import {useEffect, useRef, useState} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import {logger} from "../logging";

export default function useGoogleSync(shopId) {

  const [isGoogleCalendarSyncEnabled, setGoogleCalendarSyncStatus] = useState(false);

  useEffect(() => {
    if (shopId) {
      const unsub = firebase
        .firestore()
        .collection('shops')
        .doc(shopId)
        .collection('installData')
        .doc('googleSync')
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            const googleSyncData = snapshot.data();
            setGoogleCalendarSyncStatus(!!googleSyncData?.googleCalendarSyncEnabled);
          } else if (isGoogleCalendarSyncEnabled) {
            setGoogleCalendarSyncStatus(false);
          }
        },
        (error) => {
          logger.error('useGoogleSync error', error);
        }
      );

      return () => unsub();
    }
  }, [shopId])

  return {
    isGoogleCalendarSyncEnabled,
  }

}
