import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { FEEDBACK_TYPE } from '../../../../utils/consts';
import useShopCalls from '../../../../hooks/useShopCalls';
import useShopFeedbackConfig from '../../../../hooks/useShopFeedbackConfig';
import {InfiniteScroll} from "../../../../components/infinity-scroll/Infinity-scroll";
import {Loader} from "../../../../components";
import './host-calls.scss';
import CallDetailsSideBar from "../../call-details-side-bar/call-details-side-bar";
import {useCallDetailsProvider} from "../../call-details-side-bar/call-details-provider/call-details-provider";
import { parsePastCompletedCalls } from '../../../../utils/parse';
import HistoryCallItem from '../../history-call-item/history-call-item';

const ROWS_PER_PAGE = 20;

const ActivityLog = () => {
  const activeShopId = useSelector((state) => state.shops.activeShopId);
  const [pastCallsList, setPastCallsList] = useState([]);
  const { feedbackConfig } = useShopFeedbackConfig(activeShopId);
  const feedbackType = feedbackConfig?.type ?? FEEDBACK_TYPE.CSAT;
  const statusOptions = ['completed', 'error'];
  const caazamUser = useSelector((state) => state.user.user);
  const hostId = caazamUser && caazamUser.id;
  const { calls, getMoreCalls } = useShopCalls(
    activeShopId,
    statusOptions,
    ROWS_PER_PAGE,
    hostId
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setLoading] = useState(false)
  const {
    callDetails,
    sidebarAnimation,
    onUpdateCallDetails
  } = useCallDetailsProvider()

  useEffect(() => {
    return () => {
      setCurrentPage(0)
    }
  }, [activeShopId])

  useEffect(() => {
    let pastCompletedCalls = [];

    if (calls && calls.length > 0) {
      pastCompletedCalls = calls
        .map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
        .map(parsePastCompletedCalls);
    }
    setPastCallsList(pastCompletedCalls);
    setLoading(false)
  }, [calls, feedbackType]);

  useEffect(() => {
    onUpdateCallDetails(currentCall => pastCallsList.find(call => call.id === currentCall?.id))
  }, [JSON.stringify(pastCallsList)])

  const hasNext = calls.length >= (currentPage + 1) * (ROWS_PER_PAGE * 2);

  const loadMoreCalls = () => {
    if ((currentPage + 1) * (ROWS_PER_PAGE * 2) >= calls.length) {
      setLoading(true)
      getMoreCalls();
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className='history-calls-wrapper'>
      <div className={`infinite-scroll-calls-items-list ${sidebarAnimation}`}>
        <InfiniteScroll
          isLoading={isLoading}
          hasMore={hasNext}
          loadMore={loadMoreCalls}
          results={pastCallsList}
          loader={<Loader height='26px' width='26px'/>}
          className={'infinite-scroll-item'}
          renderItem={(call) => (
            <HistoryCallItem
              callDetails={callDetails}
              calls={pastCallsList}
              feedbackType={feedbackType}
              item={call}
            />
          )}
        />
      </div>
      {callDetails && (
        <CallDetailsSideBar
        feedbackType={feedbackType}
        />
      )}
    </div>
  );
};

export default ActivityLog;
