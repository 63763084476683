import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import React, { useState, useRef, useEffect } from 'react';
import { kpiCallsModel, kpiRatingModel, kpiSalesModel, } from './kpiSectionItems';
import './kpi-sections.scss';
import { FEEDBACK_TYPE } from '../../../utils/consts';

export const KpiSections = ({
    feedbackType,
    dailySummary,
    shopData,
    averageCallTimeStr,
    scheduledCallsCount,
    callsHistorySummary
}) => {
    const kpiContainerRef = useRef(null);
    const kpiContentRef = useRef(null);
    const [hideSectionContent, setHideSectionContent] = useState(false);
    const updateDimensions = (content) => {
        if (kpiContainerRef.current) {
            const container = kpiContainerRef.current.clientHeight;
            if (content > container) {
                setHideSectionContent(true);
            } else {
                setHideSectionContent(false);
            }
        }
    }

    useEffect(() => {
        if (kpiContainerRef.current &&
            kpiContentRef.current) {
            const content = kpiContentRef.current.scrollHeight;
            const container = kpiContainerRef.current.clientHeight;
            if (content > container) {
                setHideSectionContent(true);
            }

            const resizeEvent = () => {
                updateDimensions(content)
            }

            window.addEventListener('resize', resizeEvent);

            return () => {
                window.removeEventListener('resize', resizeEvent);
            }
        }
    }, [kpiContainerRef.current, kpiContentRef.current]);

    const dataForChart = Object.entries(callsHistorySummary.daily).map(([key,value]) => ({date: key, ...value}));
  
    return (
        <section ref={kpiContainerRef} className='host-kpis'>
            <div className='kpi-section-container'>
                <div ref={kpiContentRef} className='kpi-wrapper'>
                    <Section
                        dataModel={kpiCallsModel(dailySummary, dataForChart, averageCallTimeStr, scheduledCallsCount)}
                        collapseContent={hideSectionContent} />
                    <Section
                        dataModel={kpiSalesModel(shopData, dailySummary, dataForChart)}
                        collapseContent={hideSectionContent} />
                    {feedbackType !== FEEDBACK_TYPE.NONE && <Section
                        dataModel={kpiRatingModel(feedbackType, callsHistorySummary)}
                        collapseContent={hideSectionContent} />
                    }
                </div>
            </div>
        </section>
    )
}

export const Section = ({ collapseContent, dataModel }) => {
    const [isContentVisible, setIsContentVisible] = useState(false);

    useEffect(() => {
        if (collapseContent) {
            setIsContentVisible(false)
        }
    }, [collapseContent])

    const toggleArrowSales = () => {
        setIsContentVisible(!isContentVisible);
    }

    return (
        <article>
            <div className='kpi-header'>
                <div>
                    <h3
                        className={collapseContent && dataModel.items.length > 0 ? 'clickable' : ''}
                        onClick={() => (collapseContent && dataModel.items.length > 0) ? toggleArrowSales() : () => { }}>
                        {dataModel.header.label}
                        {dataModel.items.length > 0 && collapseContent &&
                            <KeyboardArrowDown className={`icon-align${isContentVisible ? ' up' : ''}`} />}
                    </h3>

                </div>
                <h3>
                    {dataModel.header.value}
                </h3>
            </div>
            <ul className={`kpi-items-container${collapseContent ? ' closable' : ''}${isContentVisible ? ' open' : ''}`}>
                {dataModel.items.map((item, index) =>
                    <li key={index}>{`${item.label}: `}{item.value}</li>)}
            </ul>
            <div className='chart'>
                {dataModel.chart}
            </div>
        </article>
    )
}
