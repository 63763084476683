import React from 'react';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import { FilledButton } from '../../components';

const ResetPasswordSuccess = ({ navigateToLogin }) => {
  return (
    <div className='login-page'>
      <div className='login-container reset-success'>
        <CheckCircleOutlineRoundedIcon
          style={{
            fontSize: 130,
            color: 'black',
            WebkitTextFillColor: 'black',
          }}
        />
        <h2>Password Reset Email Sent</h2>
        <p>
          An email has been sent to your rescue email address. Follow the
          directions in the email to reset your password.
        </p>
        <div className='actions'>
          <FilledButton onClick={navigateToLogin}>Back to Login</FilledButton>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
