import {InfiniteScroll} from "../../infinity-scroll/Infinity-scroll";
import {Loader} from "../../loader/loader";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import './search-input-dropdown-field.scss'
import ListProductSkeleton from '../../skeletons/list-product-skeleton/list-product-skeleton';

const SearchInputDropdownField = ({
  onChange,
  placeholder,
  onKeyUp = () => {},
  onSearchForItems,
  data,
  renderItem = () => null,
  isSearching,
  showDropdown = true,
  value,
  disabled,
  loadMore,
  isLoading
}) => {

  const renderSearchResult = () => {
    if (!data || data?.length === 0) {
      return (
        <div className='empty-results'>
          <p>No products found</p>
          <p>Try changing the search term</p>
        </div>
      )
    }
    return (
      <InfiniteScroll
        isLoading={isLoading}
        hasMore={!!loadMore}
        loadMore={loadMore}
        results={data}
        loader={<Loader height='26px' width='26px'/>}
        renderItem={renderItem}
      />
    )
  }

  return (
    <div className={`search-input-dropdown-field-overlay${showDropdown ? ' visible-dropdown' : ''}`}>
      <div className='search-input-dropdown-field-input'>
        <input
          disabled={disabled}
          type='text'
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onKeyUp={onKeyUp}
        />
        {onSearchForItems && <SearchIcon onClick={onSearchForItems}/>}
      </div>
      {(isSearching || !!data) && showDropdown && (
        <div className={`search-input-dropdown-field-result${isSearching ? ' searching' : ''}`} id={'search-dropdown'}>
          {isSearching ? (
            <ListProductSkeleton />
          ) : renderSearchResult()}
        </div>
      )}
    </div>
  )
}

export default SearchInputDropdownField;
