import React from 'react';
import './list-product-skeleton.scss';
import LoadingCard from '../loading-card/loading-card';

const ListProductSkeleton = ({productNumber = 2}) => {
  return (
    Array(productNumber).fill(0).map((_, key) => (
      <div className='list-product-skeleton' key={key}>
        <LoadingCard
          height={80}
          width={60}
          borderRadius={10}
          marginTop={10}
          marginBottom={10}
        />
        <div className='list-product-skeleton-description'>
          <LoadingCard
            height={20}
            width={'100%'}
            borderRadius={4}
            marginBottom={10}
          />
          <LoadingCard
            height={50}
            width={'100%'}
            borderRadius={4}
          />
        </div>
      </div>
    ))
  )
}

export default ListProductSkeleton;