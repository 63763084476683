import { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useDispatch } from 'react-redux';
import { logger } from '../logging';
import {
  setUser,
  setUserLoading,
  restartUserState,
  resetUserState,
} from '../reducers/user-reducer';
import { initHostShops, setActiveShop, resetShopState } from '../reducers/shops-reducer';

export default function useCaazamInit(user, authLoading) {
  const dispatch = useDispatch();

  const updateUserStore = (userDoc) => {
    const userData = userDoc.data();
    if (userData) {
      dispatch(setUser({
        ...userData,
        id: userDoc.id,
        updatedAt: userData.updatedAt ? userData.updatedAt.toMillis() : null,
        createdAt: userData.createdAt ? userData.createdAt.toMillis() : null,
      }));
      dispatch(setActiveShop(userData.activeShop));
    } else {
      dispatch(setUser(userDoc.id));
      dispatch(setActiveShop(null));
    }
  };

  const updateShopsStore = (permissionsDoc) => {
    dispatch(initHostShops(permissionsDoc.data()));
  };

  const initUser = (userDBRef, permissionsDBRef) => {
    dispatch(restartUserState());
    dispatch(resetShopState());
    return Promise.all([userDBRef.get(), permissionsDBRef.get()])
      .then(([userDoc, permissionsDoc]) => {
        updateUserStore(userDoc);
        updateShopsStore(permissionsDoc);
      })
      .catch(error => {
        logger.error(`failed to load user doc or permissions`, error);
      })
      .finally(() => dispatch(setUserLoading(false)))
  };

  const waitForUserInit = async (userUuid, shopId) => {

    dispatch(restartUserState());
    dispatch(resetShopState());

    const waitForUserDoc = () => new Promise((resolve) => {
      const unsubU = firebase.firestore().collection('users').doc(userUuid).onSnapshot((userDoc) => {
        if (userDoc.exists && userDoc.data().activeShop === shopId) {
          unsubU();
          resolve(userDoc);
        }
      })
    })

    const waitForPermissionsDoc = () => new Promise((resolve) => {
      const unsubP = firebase.firestore().collection('users').doc(`${userUuid}/private/permissions`).onSnapshot((permissionsDoc) => {
        if (permissionsDoc.exists && permissionsDoc.data()[shopId] && permissionsDoc.data()[shopId].includes('host')) {
          unsubP();
          resolve(permissionsDoc);
        }
      })
    })

    const [userDoc, permissionsDoc] = await Promise.all([waitForUserDoc(), waitForPermissionsDoc()]);
    updateUserStore(userDoc);
    updateShopsStore(permissionsDoc);
    dispatch(setUserLoading(false));
  }

  useEffect(() => {
    if (!authLoading) {
      if (user) {
        const userDBRef = firebase.firestore().collection('users').doc(user.uid);
        const permissionsDBRef = firebase
          .firestore()
          .collection('users')
          .doc(`${user.uid}/private/permissions`);

        initUser(userDBRef, permissionsDBRef);

        let unsubHost, unsubPermissions;

        const setListeners = async () => {
          unsubHost = userDBRef.onSnapshot(
            (userDoc) => updateUserStore(userDoc),
            (error) => console.error('failed to set listener on user data', error));

          unsubPermissions = permissionsDBRef.onSnapshot(
            (permissionsDoc) => updateShopsStore(permissionsDoc),
            (error) => console.error('failed to set listner on user permissions', error));
        };

        setListeners();        
        return () => {
          unsubHost();
          unsubPermissions();
          dispatch(resetUserState());
          dispatch(resetShopState());
        };
      } else {
        dispatch(resetUserState());
        dispatch(resetShopState());
      }
    }
  }, [user, authLoading]);

  return { waitForUserInit };
}
