import { InfiniteScroll } from '../../../../../components/infinity-scroll/Infinity-scroll';
import { Loader } from '../../../../../components';
import React, { useEffect, useState } from 'react';
import useClientHistory from '../../../../../hooks/useClientHistory';
import { useCallDetailsProvider } from '../../../call-details-side-bar/call-details-provider/call-details-provider';
import './client-calls.scss'
import CallsHistorySkeleton from '../../../../../components/skeletons/calls-history-skeleton/calls-history-skeleton';
import HistoryCallItem from '../../../history-call-item/history-call-item';
import { useSelector } from 'react-redux';
import SyncIcon from '@material-ui/icons/Sync';

const ROWS_PER_PAGE = 40;

const ClientCalls = ({feedbackType}) => {
  const {callDetails, sidebarAnimation, onUpdateCallDetails, client} = useCallDetailsProvider();
  const {activeShopId} = useSelector((state) => state.shops);

  const {calls, callsLoading, onRefreshCalls} = useClientHistory(client?.email)

  const [currentPage, setCurrentPage] = useState(0);
  const [currentCalls, setCurrentCalls] = useState([])

  const hasNext = calls.length >= (currentPage + 1) * (ROWS_PER_PAGE);

  useEffect(() => {
    setCurrentPage(0)
    setCurrentCalls([])
  }, [client?.email, activeShopId])

  useEffect(() => {
    if (calls.length > 0) {
      if (currentPage === 0) {
        setCurrentCalls(calls.slice(0, ROWS_PER_PAGE))
      } else {
        setCurrentCalls(prev => [
          ...prev,
          ...calls.slice(currentPage * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE + ROWS_PER_PAGE)
        ])
      }
    } else {
      setCurrentCalls([])
    }
  }, [currentPage, calls.length])

  useEffect(() => {
    if (calls.some(call => call.id === callDetails?.id)) {
      onUpdateCallDetails(currentCall => calls.find(call => call.id === currentCall?.id))
    }
  }, [JSON.stringify(calls)])

  const loadMoreCalls = () => {
    if ((currentPage + 1) * ROWS_PER_PAGE <= calls.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const onRefresh = () => {
    onUpdateCallDetails(null)
    setCurrentPage(0)
    setCurrentCalls([])
    onRefreshCalls()
  }

  if (client && calls.length === 0 && !callsLoading) {
    return (
      <div className='client-calls-items-list'>
        <p>No calls found for this client</p>
      </div>
    )
  }
  return (
    <div className={`client-calls-items-list ${sidebarAnimation}`}>
      {(currentCalls.length === 0 && callsLoading) || !client ? (
        <CallsHistorySkeleton callsNumber={4} />
      ) : (
        <>
          <div className={'client-calls-header-container'}>
            <p className={'list-header'}>Client call log</p>
            <div className={'refresh-button'} onClick={onRefresh}>
              <SyncIcon />
            </div>
          </div>
          <InfiniteScroll
            hasMore={hasNext}
            loadMore={loadMoreCalls}
            results={currentCalls}
            loader={<Loader height='26px' width='26px'/>}
            className={'infinite-scroll-item'}
            renderItem={(call) => (
              <HistoryCallItem
                calls={calls}
                feedbackType={feedbackType}
                item={call}
              />
            )}
          />
        </>
      )}
    </div>
  )
}

export default ClientCalls;
