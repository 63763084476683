import React, {useMemo} from 'react';
import useRoomsListener from "../api/rooms/listeners";
import {ROLES} from "../constants/participantRoles";
import {useSelector} from "react-redux";

export const useHostChatData = (user, shopId) => {
  const userEntity = useMemo(() => ({...user, role: ROLES.HOST}),[user]);
  useRoomsListener(userEntity, shopId);
  const { activeRooms, pendingRooms } = useSelector(state => state.rooms);

  return {
    activeRooms,
    pendingRooms
  };
};
