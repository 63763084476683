import React, {useEffect, useRef, useState} from "react";
import './event-type-item.scss';

const EventTypeItem = ({event, setSelectedEvent, isSelected}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [loadImageError, setLoadImageError] = useState(false);
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [isToggleButtonAvailable, setToggleButtonAvailable] = useState(false)
  const [descriptionMaxHeight, setDescriptionMaxHeight] = useState(null);
  const [defaultDescriptionHeight, setDefaultDescriptionHeight] = useState(0);
  const eventDescriptionRef = useRef();

  useEffect(() => {
    if (eventDescriptionRef.current) {
      if (eventDescriptionRef.current.clientHeight < eventDescriptionRef.current.scrollHeight) {
        setToggleButtonAvailable(true)
      }
      if (!defaultDescriptionHeight) {
        setDefaultDescriptionHeight(eventDescriptionRef.current.clientHeight)
      }
      setDescriptionMaxHeight(descriptionOpen ? eventDescriptionRef.current.scrollHeight : 45);
    }
  }, [eventDescriptionRef.current, descriptionOpen])

  const animateImageOnLoad = () => {
    setIsImageLoaded(true);
  }
  const onErrorLoad = () => {
    setLoadImageError(true);
  }

  const toggleEventDescription = () => {
    setDescriptionOpen(!descriptionOpen)
  }

  const onSelectEvent = (e, event) => {
    if (!e.target.classList.contains('event-description-toggle-button-label')) {
      setSelectedEvent(event)
    }
  }

  return (
    <div onClick={(e) => onSelectEvent(e, event)}
         className={`event-type-item${isSelected ? ' checked' : ''}`}>
      {event.image && !loadImageError && (
        <div className='event-image-container'>
          <img
            onError={onErrorLoad}
            onLoad={animateImageOnLoad}
            className={`event-image${isImageLoaded ? ' ' : ''}`}
            src={event.image}
          />
        </div>
      )}
      <div className='event-description-wrapper'>
        <div className='event-name-wrapper'>
          <span className='event-name'>{event.name}</span>
        </div>
        <span className='event-duration'>{event.duration} minutes</span>
        <>
          <p
            ref={eventDescriptionRef}
            style={{maxHeight: `${descriptionMaxHeight}px`, '--default-max-height': `${descriptionMaxHeight}px`}}
            className={`event-description${descriptionOpen ? ' open' : ''}`}
          >
            {event.description}
          </p>
          <button onClick={toggleEventDescription}
                  className={`event-description-toggle-button ${isToggleButtonAvailable ? ' available' : ''}`}>
            <div
              className={`event-description-toggle-button-animated-labels-container${descriptionOpen ? ' open' : ''}`}>
              <div className='event-description-toggle-button-label'>
                <p>Show more</p>
              </div>
              <div className='event-description-toggle-button-label active'>
                <p>Show less</p>
              </div>
            </div>
          </button>
        </>
      </div>
    </div>
  )
}

export default EventTypeItem;