
import { useEffect, useCallback, useRef,useState } from 'react';
import { parseIdString } from '../utils/';
import { handleResponse } from '../utils/http';
import { logger } from '../logging';


export const useProducts = (shopId, callId, pricingContext = null) => {
  const [isProductLoading, setProductLoading] = useState(false);
  const [productLoadingError, setProductLoadingError] = useState(null);
  const productCache = useRef({});

  useEffect(() => {
    return () => {
      productCache.current = {};
    }
  }, [shopId, pricingContext, callId]);

  const fetchProduct = async (shopId, productId, cacheKey) => {
    setProductLoadingError(null);
    let params = new URLSearchParams({ cacheKey });
    if (pricingContext) {
      params.append('pricingContext', pricingContext);
    }
    try {
      const product = await fetch(
        `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopId}/productsv2/${productId}?${params.toString()}`
      ).then(handleResponse);
      logger.info(`fetchProduct for ${productId} returned from API`);
      productCache.current[productId] = product;
      return product;
    } catch (error) {
      logger.error(`fetchProduct for ${productId} from API failed`, error);
      setProductLoadingError(error);
      return null;
    }
  }

  const getCachedProduct = useCallback(async (productId) => {
    const cleanProductId = parseIdString(productId);
    if (productCache.current[cleanProductId]) {
      logger.info(`fetchProduct for ${cleanProductId} returned from cache`);
      return productCache.current[cleanProductId];
    } else {
      setProductLoading(true);
      const product = await fetchProduct(shopId, cleanProductId, callId);
      setProductLoading(false);
      return product;
    }
  }, [shopId, pricingContext, callId]);


  return {
    getCachedProduct,
    isProductLoading,
    productLoadingError,
  };
};
