import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useSessionDataClientView(contextId) {

  const [clientViewData, setClientViewData] = useState({});

  useEffect(() => {
    if (contextId) {
      const unsub = firebase.firestore().collection('calls').doc(contextId).collection('sessionData').doc('clientView').onSnapshot(snap=>{
        setClientViewData(snap && snap.data() || {});
      })
      return () => unsub();
    }
  }, [contextId])
  
  return { clientViewData };

};
