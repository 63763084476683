import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useEffect, useState } from 'react';
import { useCallDetailsProvider } from '../../../call-details-side-bar/call-details-provider/call-details-provider';
import './client-history-header.scss'
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const ClientHistoryHeader = ({onPressBack, onSelectClient, selectedClient}) => {
  const { toggleSidebarModal, onChangeClient } = useCallDetailsProvider();

  const [searchIsVisible, setSearchIsVisible] = useState(false)
  const [search, setSearch] = useState('')

  useEffect(() => {
    setSearchIsVisible(!selectedClient)
  }, [])

  const onChange = (e) => {
    setSearch(e.target.value)
  }

  const handleKeyPress = (event) => {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed && search.length > 0 && selectedClient?.email !== search) {
      setSearchIsVisible(prev => !prev)
      onChangeClient(null)
      onSelectClient({email: search})
      toggleSidebarModal()
    }
  }

  const onClickButton = () => {
    if (!searchIsVisible) {
      setSearchIsVisible(prev => !prev)
    } else {
      setSearch('')
    }
  }

  return (
    <div className={`client-history-header`}>
      <div className='back-button-container'>
        <button className='back-btn' onClick={onPressBack}>
          <div className='back-btn-icon'>
            <ArrowBackIcon/>
          </div>
          <span className='header-title'>Client View</span>
        </button>
        <div className='header-search-wrapper' onKeyDown={handleKeyPress} >
          <input
            className={`search-panel-form-container${!searchIsVisible ? ' hide' : ' show'}`}
            type='text'
            placeholder='Search client email'
            autoComplete='off'
            value={search}
            onChange={onChange}
          />
          <div className={`customer-search-button${!searchIsVisible ? ' show' : ''}`} onClick={onClickButton}>
            {searchIsVisible ? <CloseIcon /> : <SearchIcon />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientHistoryHeader;
