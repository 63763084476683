import React, { useEffect, useState } from 'react';
import './client-history.scss';
import { useNotificationProvider } from '../../../components/notifications/NotificationProvider';
import { FEEDBACK_TYPE } from '../../../utils/consts';
import { useCallDetailsProvider } from '../call-details-side-bar/call-details-provider/call-details-provider';
import CallDetailsSideBar from '../call-details-side-bar/call-details-side-bar';
import ClientCalls from './conponents/client-calls/client-calls';
import ClientHistoryHeader from './conponents/client-history-header/client-history-header';
import useShopFeedbackConfig from '../../../hooks/useShopFeedbackConfig';
import { useDispatch, useSelector } from 'react-redux';
import CustomerProfileSidebar from '../../video-room/components/sidebar/customer-profile/customer-profile';
import ShowroomPresetsModal from '../../../components/Modals/showroom-presets-modal/showroom-presets-modal';
import { useShowroomPresetProvider } from '../../../providers/preset-provider/preset-provider';
import EmailEventsModal from '../../../components/Modals/email-events-modal/email-events-modal';
import { updateClientInfo } from '../../../reducers/client-reducer';
import { CLIENT_SEARCH_RESULT } from '../../../constants/const';

const ClientHistory = ({onBack}) => {
  const dispatch = useDispatch();
  const activeShopId = useSelector((state) => state.shops.activeShopId);
  const { setRouteForNotifications } = useNotificationProvider();
  const { callDetails, client, onChangeClient, toggleSidebarModal, emailEventsModalVisibility } = useCallDetailsProvider();
  const {
    onHidePresetModal,
    presetModalVisibility,
  } = useShowroomPresetProvider();
  const { feedbackConfig } = useShopFeedbackConfig(activeShopId);
  const feedbackType = feedbackConfig?.type ?? FEEDBACK_TYPE.CSAT;
  const [clientName, setClientName] = useState(null)
  const [selectedClient, setSelectedClient] = useState(null)
  const {clientSearchStatus} = useSelector(state => state.clients);

  useEffect(() => {
    if (clientSearchStatus === CLIENT_SEARCH_RESULT.ERROR && !callDetails && selectedClient) {
      dispatch(updateClientInfo({
        email: selectedClient.email,
        anonymous: true,
        shopId: activeShopId
      }))
      onChangeClient({
        email: selectedClient.email,
        anonymous: true,
      })
    }
  }, [clientSearchStatus])

  useEffect(() => {
    if (callDetails?.shopId !== activeShopId) {
      toggleSidebarModal()
    }
    onChangeClient(null)
  }, [activeShopId])

  useEffect(() => {
    if (selectedClient) {
      setClientName({name: selectedClient.displayName ?? selectedClient.email, email: selectedClient.email})
    } else if (callDetails) {
      if (callDetails.customerEmail) {
        setSelectedClient({name: callDetails.customerName ?? callDetails.customerEmail, email: callDetails.customerEmail})
        setClientName({name: callDetails.customerName ?? callDetails.customerEmail, email: callDetails.customerEmail})
      } else {
        toggleSidebarModal()
      }
    }
  }, [selectedClient, callDetails])

  useEffect(() => {
    setRouteForNotifications('/client-history')
  }, [])

  const onPressBack = () => {
    setRouteForNotifications('/')
    toggleSidebarModal(null)
    onBack()
  }

  const profileHeader = () => (
    !client?.anonymous && (
      <div>
        <p className='customer-name'>{client?.displayName}</p>
        <p className='customer-email'>{client?.email}</p>
      </div>
    )
  )

  return (
    <div className='client-history-container'>
      <ClientHistoryHeader onPressBack={onPressBack} onSelectClient={setSelectedClient} selectedClient={selectedClient} />
      {clientName ? (
        <div className='client-history-wrapper'>
          <div className='client-history-client-details'>
            <CustomerProfileSidebar
              user={client}
              clientName={clientName}
              setCustomer={onChangeClient}
              initClientEmail={selectedClient?.email}
              initClientName={clientName.name}
              showCustomerSearch={() => { }}
              shopId={activeShopId}
              isCustomerLoading={false}
              header={profileHeader}
            />
          </div>
          <ClientCalls feedbackType={feedbackType} />
        </div>
      ) : (
        <div className='empty-container'>
          Search for a client to display their call history
        </div>
      )}
      {callDetails && (
        <CallDetailsSideBar
          feedbackType={feedbackType}
        />
      )}
      <ShowroomPresetsModal
        visibility={presetModalVisibility}
        onClosePress={onHidePresetModal}
      />
      {emailEventsModalVisibility && (
        <EmailEventsModal />
      )}
    </div>
  )
}

export default ClientHistory;
