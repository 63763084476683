import CaazamError from './errors';

export const setContentTypeHeader = () => {
  return {
    'Content-Type': 'application/json',
  };
};

export const createPostRequest = (url, bodyData) => {
  const options = {
    method: 'POST',
    headers: setContentTypeHeader(),
  };
  if (bodyData) {
    options.body = JSON.stringify(bodyData);
  }
  return fetch(url, options);
};

export const handleResponse = (response) => {
  if (response.ok) return response.json();
  else {
    return response
      .json()
      .catch((nonJsonError) => {
        throw new CaazamError(
          response.status,
          nonJsonError.message || 'Something went wrong'
        );
      })
      .then((jsonError) => {
        throw new CaazamError(jsonError.error.code, jsonError.error.reason);
      });
  }
};
