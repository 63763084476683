import React from 'react';
import './cart-details-tab.scss';
import IconCopy from "../../../../assets/icons/Copy";
import IconDraftOrder from "../../../../assets/icons/DraftOrder";
import EmptyImage from "../../../../components/emptyImage";
import {generateVariantTitle} from "../../../../utils/draftOrder";
import {currencyFormatter, productPrice} from "../../../../utils";
import {CALL_DETAILS_TYPE} from "../../../../utils/consts";
import IconOrderComplete from "../../../../assets/icons/OrderComplite";
import CallOrderHeader from "../call-order-header/call-order-header";
import {useCallDetailsProvider} from "../call-details-provider/call-details-provider";

const CartDetailsTab = ({
  visible,
  products,
  total,
  type,
  openOrderDetails,
  copyToClipboard,
  orderData,
  draftOrder,
  isMultipleOrder
}) => {
  const {
    onOpenDraftOrder
  } = useCallDetailsProvider()

  const variantTitle = (product) => {
    if (product.title || product.variantTitle) {
      return ` | ${generateVariantTitle(product.title || product.variantTitle)}`
    }
    return ''
  }

  const renderOrderDetails = () => {
    const {currencyCode, price} = orderData;
    const row = (title, price, isLast) => (
      <div className={`description-row${isLast ? ' lastItem' : ''}`}>
        <p>{title}</p>
        <p>{currencyFormatter(price, currencyCode)}</p>
      </div>
    )
    return (
      <>
        {row('Subtotal', price.lineItems)}
        {row('Discount', price.discounts)}
        {row('Shipping', price.shipping)}
        {row('Tax', price.tax, true)}
      </>
    )
  }

  const actionText = () => {
    if (type === CALL_DETAILS_TYPE.ORDER) {
      return 'View order details'
    }
    if (type === CALL_DETAILS_TYPE.CHECKOUTS) {
      return 'Copy checkout link'
    }
    return 'Copy invoice link'
  }

  return (
    <div className={`cart-details-tab${visible ? ' visible' : ''} ${type}`}>
      {type === CALL_DETAILS_TYPE.ORDER && orderData && !isMultipleOrder && (
        <CallOrderHeader order={orderData}/>
      )}
      <div className='cart-details-header'>
        <div className='cart-details-action-row' onClick={type === CALL_DETAILS_TYPE.ORDER
          ? () => openOrderDetails(orderData.id)
          : copyToClipboard}>
          {type === CALL_DETAILS_TYPE.ORDER
            ? <IconOrderComplete/>
            : <IconCopy/>}
          <p>{actionText()}</p>
        </div>
        {draftOrder && (
          <div className='cart-details-action-row' onClick={onOpenDraftOrder}>
            <IconDraftOrder/>
            <p>{'Edit draft order'}</p>
          </div>
        )}
      </div>
      <div className='cart-details-products'>
        {products && products.map((product, index) => (
          <div className='cart-details-product-container' key={index}>
            {product.image ? (
              <img
                className='main-image'
                src={product.image}
              />
            ) : (
              <EmptyImage product={product} size={'xs'}/>
            )}
            <div className='product-description'>
              <span className='product-title'>{product.productTitle}</span>
              <span className='variant-title'>{productPrice(product) + variantTitle(product)}</span>
            </div>
          </div>
        ))}
      </div>
      <div className='cart-details-footer'>
        {orderData && renderOrderDetails()}
        <div className='cart-details-footer-total'>
          <p>Total</p>
          <p>{total}</p>
        </div>
      </div>
    </div>
  )
}

export default CartDetailsTab;