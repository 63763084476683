export const SIDEBAR_VIEWS = {
  CUSTOMER: 'customer',
  PRODUCT_SEARCH: 'product-search',
  PRODUCT: 'product',
  CHAT: 'chat'
};

  export const SIDEBAR_FEATURES = {
    CUSTOMER: 'customer',
    PRODUCT: 'product',
  };

  export const DEFAULT_PRODUCT_RESULTS_LIMIT = 20;
