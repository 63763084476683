import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const useCallTranscripts = (callId, shopId, insightsFlag) => {
  const ref = callId && shopId && insightsFlag
    ? firebase.firestore().collection('callTranscripts')
      .where('shopId', '==', shopId)
      .where('callId', '==', callId)
      .where('recordingRule.source', '==', 'user')
    : null;
  const [transcripts, transcriptsLoading, transcriptsError] = useCollectionData(ref);
  return { transcripts, transcriptsLoading, transcriptsError }
}

export default useCallTranscripts;
