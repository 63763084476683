import React, { useCallback, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import useCaazamREST from './useCaazamREST';

const useScheduledCalls = (shopId) => {
  const [fetchRequestClientToReschedule, setFetchRequestClientToReschedule] = useState(false);
  const [requestClientToRescheduleError, setRequestClientToRescheduleError] = useState(null);

  const { deleteEvent, unassignEvent, rescheduleEvent } = useCaazamREST()

  const deleteScheduledCall = useCallback(
    (callId) => deleteEvent(shopId, callId),
    [shopId]);

  const rescheduleCall = useCallback(
    (callId, startTimestamp, endTimestamp) => rescheduleEvent(shopId, callId, { start: startTimestamp, end: endTimestamp }),
    [shopId]
  );

  const unassignCall = useCallback(
    (callId) => unassignEvent(shopId, callId),
    [shopId],
  );

  const requestClientToReschedule = ({ scheduleId, note, onSuccess }) => {
    setFetchRequestClientToReschedule(true)
    setRequestClientToRescheduleError(null)
    const request = firebase.functions().httpsCallable('schedule-sendClientRequestChange');
    request({ shopId, scheduleId, note })
      .then(() => {
        onSuccess()
      })
      .catch((error) => {
        setRequestClientToRescheduleError(error)
      })
      .finally(() => {
        setFetchRequestClientToReschedule(false)
      })
  }

  return {
    requestClientToReschedule,
    fetchRequestClientToReschedule,
    requestClientToRescheduleError
  };
};

export default useScheduledCalls;
