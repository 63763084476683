import { useEffect, useMemo, useState } from 'react';
import firebase from 'firebase/compat/app';
import {
  useCallDetailsProvider
} from '../pages/home-page/call-details-side-bar/call-details-provider/call-details-provider';
import moment from 'moment';
import { parsePastCompletedCalls, parseScheduleCalls } from '../utils/parse';
import { useSelector } from 'react-redux';
import useUserAppSettings from './useUserAppSettings';
import { CALL_TYPES } from '../utils/consts';

const useClientHistory = (customerEmail) => {
  const caazamUser = useSelector((state) => state.user.user);
  const {callDetails} = useCallDetailsProvider();
  const [calls, setCalls] = useState([])
  const [loading, setLoading] = useState(false);
  const {availableShops, activeShopId} = useSelector((state) => state.shops);
  const {
    hostAppEventsShowOnlyActiveShop,
  } = useUserAppSettings()
  const hostShops = useMemo(() => (
    availableShops ? Object.keys(availableShops) : []
  ), [availableShops])


  const shops = useMemo(() => {
    if (activeShopId)
      return [activeShopId];
    else
      return [];

      /* Shai 2023-08-7: don't support multi shops in client hitstory view for now
    if (hostAppEventsShowOnlyActiveShop) {
      return [activeShopId]
    }
    return hostShops
    */
  }, [hostAppEventsShowOnlyActiveShop, hostShops, activeShopId])

  useEffect(() => {
    return () => {
      setCalls([])
      setLoading(false)
    }
  }, [activeShopId])

  useEffect(() => {
    if (callDetails && callDetails.type === CALL_TYPES.HISTORY) {
      const ref = firebase
        .firestore()
        .collection('calls')
        .doc(callDetails.id)
        .onSnapshot((doc) => {
          setCalls(currentCalls => currentCalls.map((call) => {
            if (call.id === doc.id) {
              return parsePastCompletedCalls({...doc.data(), id: doc.id})
            }
            return call
          }))
        })
      return () => {
        ref()
      }
    }
  }, [callDetails?.id])

  const getCalls = async () => {
    const isAdminCalls = true; //TODO: change this field by shop permission
    let historyCallsQuery = firebase
      .firestore()
      .collection('calls')
      .where('timestamp', '>=', moment().subtract(2, 'y').toDate())
      .where('shopId', 'in', shops)
      .where('customerEmail', '==', customerEmail)
      .where('status', 'in', ['completed', 'error'])

    let scheduledCallsQuery = firebase
      .firestore()
      .collectionGroup('schedule')
      .where('shopId', 'in', shops)
      .where('startTimestamp', '>=', moment().subtract(2, 'y').toDate())
      .where('customer.email', '==', customerEmail)
      .where('status', 'in', ['assigned', 'pending'])

    if (!isAdminCalls) {
      historyCallsQuery = historyCallsQuery.where('host.id', '==', caazamUser.id)
      scheduledCallsQuery = scheduledCallsQuery.where('hostId', '==', caazamUser.id)
    }

    const historyCalls = historyCallsQuery
      .orderBy('timestamp', 'desc')
      .limit(300)

    const scheduledCalls = scheduledCallsQuery
      .orderBy('startTimestamp', 'desc')
      .limit(300)

    const [historyCallsDoc, scheduledCallsDoc] = await Promise.all([historyCalls.get(), scheduledCalls.get()])
    return [
      ...historyCallsDoc.docs
        .map((doc) => ({
            id: doc.id,
            ...doc.data()
          })
        )
        .map(parsePastCompletedCalls),
      ...scheduledCallsDoc.docs
        .map((doc) => ({
            id: doc.id,
            ...doc.data()
          })
        )
        .map(parseScheduleCalls)
    ];
  }

  useEffect(() => {
    //we do not need to track changes to the selected shop,
    //because when we change the shop, we reset the current client (they change his email to null)
    //and get the client for this store
    if (customerEmail) {
      onRefreshCalls()
      return () => {
        setLoading(false)
        setCalls([])
      }
    }
  }, [customerEmail]);

  const onRefreshCalls = () => {
    setLoading(true)
    setCalls([])
    getCalls()
      .then((calls) => {
        setCalls(calls.sort((callA, callB) => (callA.timestamp > callB.timestamp ? -1 : 1)));
      })
      .finally(() => {
        setLoading(false)
      })
  }


  return ({
    calls,
    callsLoading: loading,
    onRefreshCalls
  })
}

export default useClientHistory;
