import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import { StyledInput, FilledButton } from '../../components';
import { isEmailValid } from '../../utils/index';

const ResetPassword = ({ onSuccess, redirectUrl = null }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState(null);

  const handleEmailInput = (e) => {
    setEmail(e.target.value);
    setFormErrorMessage(null);
  };

  const onFinish = (e) => {
    e.preventDefault();
    if (!isEmailValid(email)) {
      setFormErrorMessage('Please enter a valid email');
      return;
    }
    setLoading(true);
    firebase.analytics().logEvent('reset_password_req');
    firebase
      .auth()
      .sendPasswordResetEmail(email, {
        url: redirectUrl || window.location.origin + '/login',
      })
      .then(() => {
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        setFormErrorMessage('There was an error requesting a password reset');
        console.error(err);
      });
  };
  return (
    <div className='login-page'>
      <form className='login-container' onSubmit={onFinish}>
        <h2>Reset Password</h2>
        <p>
          Please enter your email address to receive a link to reset your
          password.
        </p>
        <div className='input-container'>
          <StyledInput
            placeholder='Email'
            name='email'
            type='email'
            value={email}
            onChange={handleEmailInput}
          />
        </div>

        <div className='actions'>
          <FilledButton
            type='submit'
            formNoValidate
            style={{ width: '310px' }}
            isLoading={isLoading}
            disabled={!email}>
            Request password reset
          </FilledButton>
          <p className='error-text'>{formErrorMessage}</p>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
