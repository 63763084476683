import React from 'react';
import './loader.scss';

export const Loader = ({ name, hasText, height = '4.67rem', width = '4.67rem', borderWidth, stop }) => {
  return (
    <div className='loader-wrapper'>
      <div className={`loader${stop ? ' stop' : ''}`} style={{height, width, borderWidth}}></div>
      {hasText ? (
        <>
          <h3>Connecting...</h3>
          <p>
            Please wait a moment<b>{name ? ` ${name}` : ''}</b>.
          </p>
        </>
      ) : null}
    </div>
  );
};
