import {useEffect, useState} from "react";
import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';

const useCallCarts = (cartId, shopId) => {
  const [cart, setCart] = useState([])

  useEffect(() => {
    if (cartId && shopId) {
      const unsub = firebase
        .firestore()
        .collection('shops')
        .doc(shopId)
        .collection('carts')
        .doc(cartId)
        .onSnapshot((snapshot) => {
          setCart({
            cartId: snapshot.id,
            ...snapshot.data()
          })
        })
      return () => {
        unsub()
        setCart([])
      };
    }
    setCart([])
  }, [cartId, shopId])

  return ({
    cart
  })
}

export default useCallCarts;