import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './image-with-overlay.scss';
import EmptyImage from "../emptyImage";
import { useMediaQuery } from '../../utils';

const ImageWithOverlay = ({
  imageUrl,
  viewFunction,
  removeFunction,
  dragFunction,
  alt,
  isCart = false,
  highlighted = false,
  isAdaptive = false,
  animated = false,
}) => {
  const handleDrag = (e) => {
    e.preventDefault();
    e.persist();
    dragFunction(e);
  };

  const isClickable = !!viewFunction;
  const windowHeight = useMediaQuery()[1];

  const getSizeOfEmptyImage = () => {
    if (isCart || !isClickable) {
      if (windowHeight < 813) {
        return 's';
      } else {
        return 'm';
      }
    } else {
      if (windowHeight < 900) {
        if (windowHeight < 813) {
          return 's';
        } else {
          return 'm';
        }
      } else {
        return 'l';
      }
    }
  };


  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [showEmptyImage, setShowEmptyImage] = useState(false);

  const fallbackToEmptyImage = () => {
    setShowEmptyImage(true);
  }

  const animateImageOnLoad = () => {
      setIsImageLoaded(true);
  }

  

  return (
    <div
      className={`image-with-overlay${isClickable ? '' : '-no-click'} ${dragFunction ? 'draggable' : ''}${highlighted ? 'highlighted' : ''}`}
      draggable={!!dragFunction}
      onDragStart={handleDrag}>

      {isClickable &&
        <div onClick={viewFunction} className='overlay'>
        </div>
      }
      {removeFunction && isClickable &&
        <div className='close-icon-container'>
          <CloseIcon className={'delete-icon'} fontSize='small' onClick={removeFunction} />
        </div>
      }

      {
        (imageUrl && !showEmptyImage)
          ? <img onError={fallbackToEmptyImage} onLoad={animateImageOnLoad} className={`${animated ? 'animated' : ''}${isImageLoaded ? ' loaded' : ''}`} src={imageUrl} alt={alt} />
          : <EmptyImage
            viewItem={() => isClickable && viewFunction()}
            size={!isAdaptive ? getSizeOfEmptyImage() : 'adaptive'}
            label={alt}
            highlighted={highlighted}
          />
      }
    </div>
  );
};

export default ImageWithOverlay;
