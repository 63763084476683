import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNetworkTests, useLocalTracks } from '@caazam/boutiq-video-room';

import { APP_DIAGNOSTICS_ACTION_TYPES } from "../../constants/appDiagnostics";
import { getPreflightStopped } from '../../ulits/diagnostics';
import { logger } from '../../../../../logging';

export default function usePreflightTest(duration) {
  const { runTests, abortTests } = useNetworkTests();
  const { startCamera, localVideo, stopCamera } = useLocalTracks();
  const [createdTrackForTest, setCreatedTrackForTest] = useState(false);
  const [shouldRun, setShouldRun] = useState(false);
  const dispatch = useDispatch();
  const {
    preflightTestInProgress,
  } = useSelector((state) => state.appDiagnostics);

  const start = useCallback(async () => {
    try {
      logger.info('starting preflight tests', { duration });
      const report = await runTests(duration /* default =30 */);
      if (getPreflightStopped(report, duration)) {
        logger.info('preflight aborted', report);
        dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_STOPPED, report });
      } else {
        logger.info('preflight completed', report);
        dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_COMPLETED, report });
      }
    } catch (error) {
      logger.error('preflight error', error);
      let errorMessage = typeof error === 'string' ? error : error.message;
      dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_FAILED, error: errorMessage });
    }
  }, [runTests]);

  const startPreflightTest = useCallback(async () => {
    // Don't start a new preflight test if one is already running
    if (preflightTestInProgress) {
      logger.warn('startPreflightTest already in progress');
      return;
    }
    dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_STARTED, duration });
    if (!localVideo) {
      logger.info('starting local video for tests');
      // DAILY: for this case where tests are running not inside the AV settings UX we should allocate a getUserMedia track and not use Daily.
      await startCamera({ audioDeviceId: false })
        .catch(cameraError => {
          logger.error('start camera failed', cameraError);
          dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_FAILED, error: cameraError.message });
        })
      setCreatedTrackForTest(true);
    } 
    setShouldRun(true);

  }, [preflightTestInProgress, localVideo, startCamera]);

  const stopPreflightTest = useCallback(async () => {
    try {
      abortTests();
    } catch (error) {
      logger.error('stopPreflightTest failed to abort', error);
    }
  }, [abortTests]);

  useEffect(() => {
    return () => stopPreflightTest();
  }, []);

  useEffect(() => {
    if (localVideo && shouldRun) {
      start();
      setShouldRun(false);
    }
  }, [localVideo, shouldRun, start]);

  useEffect(() => {
    if (!preflightTestInProgress && localVideo && createdTrackForTest) {
      stopCamera();
    }
  }, [preflightTestInProgress, localVideo, createdTrackForTest])


  return {
    startPreflightTest,
    stopPreflightTest,
  };
}
