import React, {useEffect, useState, useCallback, useMemo} from 'react';
import { useSelector } from 'react-redux';
import Moment from 'moment-timezone';

import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import useHostSchedulingConfig from '../../../hooks/useHostSchedulingConfig';
import useShopBusinessHours from '../../../hooks/useShopBusinessHours';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './host-settings.scss';
import CustomPopover from './components/CustomPopover';
import Toggle from '../../../components/toggle/toggle';
import Modal from '../../../components/Modals/modal';
import { FilledButton, OutlinedButton } from '../../../components';
import useCallsPolicy from "../../../hooks/useCallsPolicy";
import {CALL_ROUTING_POLICY, HOST_AVAILABILITY} from "../../../constants/const";
import AvailabilityLegend from "./components/availability-legend/availability-legend";
import useHostAvailabilityActions from "../../../hooks/useHostAvailabilityActions";
import useHostAvailability from "../../../hooks/useHostAvailability";

const buttonStyles = {
	height: '32px',
	width: 'unset',
	padding: '0 16px',
};
const localizer = momentLocalizer(Moment);

export default function HostAvailability() {
	const [confirmationDialogVisibility, setConfirmationDialogVisibility] = useState(false);
	const [customHours, setCustomHours] = useState(false);
	const [noHours, setNoHours] = useState(false);
	const [eventModalOpen, setEventModalOpen] = useState(false);
	const [selectingStart, setSelectingStart] = useState(true);
	const caazamUser = useSelector((state) => state.user.user);
	const hostId = caazamUser && caazamUser.id;
	const activeShopId = useSelector((state) => state.shops.activeShopId);
	const [hostAvailabilityType, setHostAvailabilityType] = useState(HOST_AVAILABILITY.EVENTS)
	const [hostAvailabilityWindows, setHostAvailabilityWindows] = useState([])

	const {callsPolicy} = useCallsPolicy(activeShopId);
	const isCustomCallPolicy = useMemo(() => (
		callsPolicy && callsPolicy.callRoutingPolicy.type === CALL_ROUTING_POLICY.CUSTOM
	), [callsPolicy, customHours])

	const [currentRange, setCurrentRange] = useState({
		start: Moment().startOf('week'),
		end: Moment().endOf('week'),
	});
	const [events, setEvents] = useState([]);
	const [selected, setSelected] = useState(null);

	const {availabilityWindows: hostEventsWindows} = useHostAvailability(
		currentRange, HOST_AVAILABILITY.EVENTS
	);

	const {availabilityWindows: hostCallsWindows} = useHostAvailability(
		currentRange, HOST_AVAILABILITY.CALLS, isCustomCallPolicy && customHours
	);

	const {
		addHostAvailability,
		deleteHostAvailability,
		updateHostAvailability,
		updateRecurrenceRule,
		actionLoading
	} = useHostAvailabilityActions(
		currentRange.start.toDate(), hostAvailabilityType, hostAvailabilityWindows.map(({databaseEvent}) => databaseEvent)
	)

	const {
		hostSchedulingConfig,
		hostSchedulingConfigLoading,
		toggleDefaultBusinessHours,
	} = useHostSchedulingConfig(activeShopId, hostId);

	const {
		businessHoursConfig, 
		businessHoursLoading,
	} = useShopBusinessHours(activeShopId);

	useEffect(() => {
		if (selected) {
			setHostAvailabilityType(selected.type)
		} else {
			setHostAvailabilityType(HOST_AVAILABILITY.EVENTS)
		}
	}, [selected])

	useEffect(() => {
		setHostAvailabilityWindows(
			[...hostEventsWindows, ...hostCallsWindows]
		)
	}, [JSON.stringify(hostEventsWindows), JSON.stringify(hostCallsWindows)])

	const onRangeChange = (range) => {
		if (Array.isArray(range)) {
			const datesCount = range.length;
			if (datesCount === 1)
				setCurrentRange({ start: Moment(range[0]), end: Moment(range[0]).endOf('day') });
			else
				setCurrentRange({ start: Moment(range[0]), end: Moment(range[datesCount - 1]) });
		} else {
			setCurrentRange({ start: Moment(range.start), end: Moment(range.end) });
		}
	}

	const mapEvent = (window) => {
		const titleCustomTitle = window.type === HOST_AVAILABILITY.EVENTS ? 'Scheduled Calls' : 'Instant Calls'
		return {
			id: window.eventId,
			start: window.windowStart,
			end: window.windowEnd,
			resource: window.hostId,
			allDay: false,
			isRecurring: window.isRecurring,
			recurrenceEnd: window.recurrenceEnd,
			title: isCustomCallPolicy ? titleCustomTitle : 'Available',
			type: window.type
		}
	}

	useEffect(() => {
		if (hostAvailabilityWindows) {
			setEvents(hostAvailabilityWindows.map(mapEvent))
		}
	}, [JSON.stringify(hostAvailabilityWindows)])

	useEffect(() => {
		if (!hostSchedulingConfigLoading) {
			setCustomHours(hostSchedulingConfig && !hostSchedulingConfig.defaultBusinessHours);
			setNoHours(!hostSchedulingConfig);
		}
	}, [hostSchedulingConfigLoading, hostSchedulingConfig])

	const durationCalculation = (testSlot) => {
		let start = testSlot.start;
		let end = testSlot.end;

		if (testSlot.startTime) {
			start = Moment(testSlot.startTime).toDate();
		}
		if (testSlot.endTime) {
			end = Moment(testSlot.endTime).toDate();
		}
		const duration = Moment(end).diff(Moment(start), 'minutes');
		return {start, duration}
	}

	const addWindow = async (testSlot) => {
		if (testSlot) {
			const { start, duration } = durationCalculation(testSlot);
			try {
				let recurrence = null;
				if (testSlot.repeat_mode) {
					recurrence = { ...recurrence, isRecurrence: true }
					if (testSlot.repeat_end) {
						recurrence = { ...recurrence, endOn: Moment(testSlot.repeat_end_date).toDate() }
					}
				}
				await addHostAvailability(start, duration, recurrence);
				setEventModalOpen(false);
			} catch (error) {
				console.error(error);
			}
		}
	}

	const deleteWindow = async (testSlot, deleteAllFuture) => {
		if (testSlot) {
			try {
				await deleteHostAvailability(testSlot.id, testSlot.start, deleteAllFuture);
				setEventModalOpen(false);
			} catch (error) {
				console.error(error);
			}
		}
	}

	useEffect(() => {
		if (!customHours && eventModalOpen) {
			setEventModalOpen(false);
		}
	}, [customHours])

	const handleDelete = async (deleteAllFuture = true) => {
		await deleteWindow(selected, deleteAllFuture);
	}
	
	const updateWindow = async (testSlot, allFuture) => {
		if (testSlot) {
			try {
				const { start, duration } = durationCalculation(testSlot);
				await updateHostAvailability(testSlot.id, testSlot.start, start, duration, allFuture);
			} catch (error) {
				console.error(error);
			}
		}
	}

	const updateWindowRecurrenceRule = async (testSlot) => {
		if (testSlot) {
			try {
				let recurrence = null;
				if (testSlot.repeat_mode) {
					recurrence = { ...recurrence, isRecurrence: true }
					if (testSlot.repeat_end) {
						recurrence = { ...recurrence, endOn: Moment(testSlot.repeat_end_date).toDate() }
					}
				}
				await updateRecurrenceRule(testSlot.id, recurrence);
			} catch (error) {
				console.error(error);
			}
		}
	}

	const handleSelect = (e) => {
		if (customHours) {
			setSelectingStart(true);
			setEventModalOpen(true);
			clearEvents();
			setSelected(hostAvailabilityWindows.map(mapEvent).find(({id}) => id === e.id));
		}
	}

	const handleNewEvent = (e) => {
		if (customHours) {
			clearEvents();
			setSelectingStart(true);

			if (e.action === "click" && eventModalOpen) {
				setEventModalOpen(false);
				setSelected(null);
			}

			if (e.action !== "click") {
				const newEvent = {
					start: e.start,
					end: e.end,
					title:  'Availability',
					id: e.id,
					resource: e.resource,
					forcePopup: false,
					type: HOST_AVAILABILITY.EVENTS
				}
				if (e.action === 'select') {
					newEvent.forcePopup = true;
				}
				setSelected(newEvent);
				setEventModalOpen(true);
				setEvents([...events, newEvent])
			}
		}
	}

	const onCancel = () => {
		setEventModalOpen(prev => !prev);
		clearEvents();
	}

	const clearEvents = () => {
		setEvents(existed => existed.filter(({id}) => id));
	}

	const onSelecting = () => {
		if (selectingStart) {
			setSelectingStart(false);
			setEventModalOpen(false);
			clearEvents();
		}
	}

	const onCloseConfirmationDialog = () => {
		setConfirmationDialogVisibility(!confirmationDialogVisibility)
	}

	const onToggleClick = () => {
		setConfirmationDialogVisibility(true);
	}

	function isInBusinessHours(config, testDate) {

		if (!config) return false;
	
		const testMoment = Moment(testDate).tz(config.timezone);
		const testDay = testMoment.format('ddd');
	
		if (!config.activeDays[testDay]) return false;
		if (config.isCustomConfig) {
			for (const range of config.customHours[testDay]) {
				const openTime = range.openTime.split(':');
				const closeTime = range.closeTime.split(':');
	
				const begin = Moment(testMoment).hours(openTime[0]).minutes(openTime[1]);
				const end = Moment(testMoment).hours(closeTime[0]).minutes(closeTime[1]);
				if (testMoment >= begin && testMoment < end) return true;
			}
			return false;
		} else {
			const openTime = config.openTime.split(':');
			const closeTime = config.closeTime.split(':');
			
			const begin = Moment(testMoment).hours(openTime[0]).minutes(openTime[1]);
			const end = Moment(testMoment).hours(closeTime[0]).minutes(closeTime[1]);
			return testMoment >= begin && testMoment < end;
		}
	}

	const slotPropGetter = useCallback((slot) => {
		const open = isInBusinessHours(businessHoursConfig, slot);

		if (!open) return { className: 'slot-shop-closed' }
	}, [businessHoursConfig]);


	const instructions = () => {
		if (noHours) {
			return isCustomCallPolicy
				? 'The store admin has disabled your availability for calls'
				: 'The store admin has disabled your availability for scheduled calls';
		} else if (!customHours) {
			return `View your availability for scheduled client calls. 
			Your availability is set by default to the shop's business hours.`
		} else {
			return isCustomCallPolicy
				? `Set your availability for client calls.\nEdit the calendar to add/remove your availability hours.`
				: `Set your availability for scheduled client calls.\nEdit the calendar to add/remove your availability hours.`
		}		
	}

	const eventStyle = (event, start, end, isSelected) => {
		const selectedEventColor = () => {
			if (event.id) {
				return event.type === HOST_AVAILABILITY.EVENTS ? 'var(--sherbert-color)' : 'var(--main-color)'
			} else {
				return hostAvailabilityType === HOST_AVAILABILITY.EVENTS ? 'var(--sherbert-color)' : 'var(--main-color)'
			}
		}
		const eventColor = event.type === HOST_AVAILABILITY.EVENTS ? 'var(--sherbert-color30)' : 'var(--main-color30)'
		return ({
			style: {
				backgroundColor: isSelected ? selectedEventColor() : eventColor,
				border: 'none',
				borderRadius: 0,
				color: isSelected ? 'white' : 'black',
				fontWeight: '500',
				paddingTop: 4,
				fontSize: 14,
				borderLeft: `2px solid ${selectedEventColor()}`
			}
		})
	}

	let formats = {
		  eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
			let s = localizer.format(start, 'h:mm a', culture);
			let e = localizer.format(new Date(end.getTime() + 1), 'h:mm a', culture);
			return `${s} — ${e}`;
		  },
	  }

	return (
		<>
			{
				confirmationDialogVisibility &&
				<Modal modalClass={'confirmation-dialog'} closeFunc={() => { onCloseConfirmationDialog() }}>
					{customHours
						? <h3>Changing this setting will set your availability to the store's business hours.<br/>
							You will lose your custom availability settings </h3>
						: <h3>Changing this settings means your availability will be set to a custom schedule</h3>
					}
					<div className='actions'>

						<OutlinedButton
							className='button'
							onClick={() => setConfirmationDialogVisibility(false)}
							style={{
								...buttonStyles,
								marginRight: '10px'
							}}>
							Cancel
						</OutlinedButton>

						<FilledButton
							className='button'
							onClick={() => {toggleDefaultBusinessHours(); setConfirmationDialogVisibility(false);}}
							style={{ 
								...buttonStyles,
								backgroundColor: 'var(--main-color)',
							 }}>
							Ok
						</FilledButton>
					</div>
				</Modal>
			}

			<div className={`availability-calendar-container${isCustomCallPolicy && customHours ? ' show-legend' : ''}`}>
				<Calendar
					eventPropGetter={eventStyle}
					localizer={localizer}
					selectable={customHours}
					selected={selected}
					events={events}
					showMultiDayTimes={true}
					formats={formats}
					//step={15}
					views={[Views.WEEK]}
					defaultView={Views.WEEK}
					endAccessor={({ end }) => new Date(end.getTime() - 1)}
					//min={new Date(2000, 1, 1, 7)}
					//max={new Date(2000, 1, 1, 22)}
					scrollToTime={new Date(2000, 1, 1, 8)}
					onRangeChange={onRangeChange}
					allDayAccessor={() => false}
					//eventPropGetter={eventPropGetter}
					slotPropGetter={slotPropGetter}
					//dayPropGetter={dayPropGetter}
					components={{
						toolbar: (e) => (
							<div className='custom-toolbar-container'>
								<p className='instructions-content'>{instructions()}</p>
								<h2 className='title'>{e.label}</h2>

								{!noHours && <div className='edit-mode-container'>
									<label>
										<span className='toggle-message'>Custom availability</span>
									</label>
									<Toggle isChecked={customHours} toggleFunc={onToggleClick} />
								</div>}
								<div className='navigation-group'>
									<button type="button" className='button' onClick={() => { e.onNavigate('PREV'); setEventModalOpen(false); }}>back</button>
									<button type="button" className='button' onClick={() => { e.onNavigate('TODAY'); setEventModalOpen(false); }}>today</button>
									<button type="button" className='button' onClick={() => { e.onNavigate('NEXT'); setEventModalOpen(false); }}>next</button>
								</div>
								{e.children}
							</div>)
					}}
					onSelecting={(() => { onSelecting() })}
					onSelectEvent={handleSelect}
					onSelectSlot={handleNewEvent}
				/>
				{isCustomCallPolicy && customHours && (
					<div className='calendar-legend'>
						<AvailabilityLegend
							title={'Scheduled calls'}
							color={'var(--sherbert-color)'}
						/>
						<AvailabilityLegend
							title={'Instant calls'}
							color={'var(--main-color)'}
						/>
					</div>
				)}
			</div>
			{eventModalOpen &&
				<CustomPopover 
					update={updateWindow} 
					updateWindowRecurrenceRule={updateWindowRecurrenceRule}
					add={addWindow} 
					handleDelete={handleDelete} 
					open={eventModalOpen} 
					onClose={onCancel}
					data={selected}
					isCustomCallPolicy={isCustomCallPolicy && customHours}
					onChangeHostAvailabilityType={setHostAvailabilityType}
					selectedHostAvailabilityType={hostAvailabilityType}
					actionLoading={actionLoading}
				/>
			}
		</>
	);
};
