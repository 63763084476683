import React from 'react';
import LoadingCard from '../loading-card/loading-card';
import './email-event-skeleton.scss';

const EmailEventSkeleton = ({eventNumber = 2}) => {
  return (
    Array(eventNumber).fill(0).map((_, key) => (
      <div className='email-events-skeleton' key={key}>
        <LoadingCard
          height={20}
          width={'100%'}
          borderRadius={4}
        />
      </div>
    ))
  )
}

export default EmailEventSkeleton;