import React, { useState } from 'react';
import { StyledInput, FilledButton } from '../../components';
import { useSoundFX } from '../../components/SoundFXProvider';
import { isEmailValid } from '../../utils/index';

const SignupForm = ({
  onEmailSubmit,
  isLoading,
  shopName,
  setErrorMessage,
}) => {
  const [email, setEmail] = useState('');
  const soundFX = useSoundFX();

  const handleEmailInput = (e) => {
    setErrorMessage(null);
    setEmail(e.target.value);
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    soundFX.userInteraction();
    if (!isEmailValid(email)) {
      setErrorMessage('Please enter a valid email');
      return;
    }
    onEmailSubmit(email);
  };

  return (
    <form onSubmit={handleEmailSubmit}>
      <h2>You've been invited to {shopName} on Boutiq</h2>
      <StyledInput placeholder='Email'  type='email' value={email} onChange={handleEmailInput} />
      <div className='form-footer'>
        <FilledButton
          type='submit'
          formNoValidate
          disabled={!email}
          disabled={!email}
          isLoading={isLoading}>
          Next
        </FilledButton>
      </div>
    </form>
  );
};

export default SignupForm;
