import React from 'react';
import { FilledButton } from '../../../../../../components/buttons/buttons';
import EmptyImage from '../../../../../../components/emptyImage';
import { InventoryQuantity } from '../../../../../../components/inventoryQuantity/InventoryQuantity';
import './product-result-card.scss';

export const ProductResultCard = ({product, viewItem, addToShowroom, renderCurrentPrice,  ...props }) => {
    return ( <div className='search-result-card' {...props}>
        <div className='image-container'>
          <div onClick={() => viewItem(product.productId)} className='product-overlay'>
          </div>
          {product.image ? (
            <img
              src={product.image}
              alt={product.title}
            />
          ) : (
              <EmptyImage product={product} viewItem={viewItem} />
            )}
        </div>
        <div className='product-card-details'>
          <h5>{product.title}</h5>
          <div className='price-container'>
            {renderCurrentPrice(product)}
          </div>
          {product.totalInventory <= 0 && 
            <InventoryQuantity 
              inventory={product.totalInventory} 
              noInventoryLabel={'0 in stock'} />}
        </div>
           <FilledButton
           style={{
             backgroundColor: product.isInFTR ? 'transparent' : 'var(--main-color)',
             height: '28px',
             fontSize: '14px',
             padding: '0 10px',
             width: 'auto',
             border: product.isInFTR ? '1px solid var(--main-color)' : 'none',
             color: product.isInFTR ? 'var(--main-color)' : 'white',
             WebkitTextFillColor: product.isInFTR ? 'var(--main-color)' : 'white',
             cursor: product.isInFTR ? 'default' : 'pointer',
           }}
           onClick={() => {
            !product.isInFTR && addToShowroom(product);
          }}>
           {product.isInFTR ? 'Added' : 'Add'}
         </FilledButton>
      </div>
    );
	};
