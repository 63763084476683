import moment from 'moment-timezone';
import { CalendarRow } from '../calendar-row/calendar-row';
import React from 'react';
import { useCallDetailsProvider } from '../call-details-side-bar/call-details-provider/call-details-provider';
import './history-call-item.scss'

const HistoryCallItem = (props) => {
  const {item: call, calls, feedbackType} = props;
  const { callDetails } = useCallDetailsProvider();

  const divider = () => {
    const currentIndex = calls.findIndex((item) => item.id === call.id);
    const currentContent = calls[currentIndex];
    const prevContent = calls[currentIndex - 1];

    let divider = null;
    if (!currentContent) return null;
    if (prevContent) {
      if (moment(prevContent.timestamp).format('YYYY-MM-DD') !== moment(currentContent.timestamp).format('YYYY-MM-DD')) {
        divider = moment(currentContent.timestamp).format('dddd, MMMM Do YYYY');
      }
    } else {
      divider = moment(currentContent.timestamp).format('dddd, MMMM Do YYYY');
    }
    return divider;
  }

  return (
    <div className={`history-call-item ${call.type.toLowerCase()}`}>
      {divider() && (
        <div className='history-call-divider'>
          <span>{divider().toString()}</span>
          <div />
        </div>
      )}
      <CalendarRow
        call={call}
        isDetailsOpen={!!callDetails}
        feedbackType={feedbackType}
        isSelected={callDetails?.id === call.id}
      />
    </div>
  )
}

export default HistoryCallItem;
