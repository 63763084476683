import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from '../modal';
import './showroom-preset-save-modal.scss'
import Checkbox from '../../checkbox';
import {StyledInput} from '../../inputs/inputs';
import {FilledButton} from '../../buttons/buttons';
import { useShowroomPresetProvider } from '../../../providers/preset-provider/preset-provider';
import { SAVE_SHOWROOM_ACTION } from '../../../constants/const';
import { useSelector } from 'react-redux';

const ShowroomPresetSaveModal = () => {
  const {
    onHideSaveShowroomModal,
    onSaveNewPreset,
    showroomPresetLoading,
    preparationProducts,
    showroomPreset,
    saveAction,
    onUpdatePreset,
    onHideEditPresetModal,
    isEditPresetMode,
    editedPreset
  } = useShowroomPresetProvider()
  const [isPublic, setIsPublic] = useState(false)
  const [presetName, setPresetName] = useState('')
  const {user} = useSelector((state) => state.user);

  const preset = useMemo(() => (
    showroomPreset ?? editedPreset
  ), [showroomPreset, editedPreset])

  useEffect(() => {
    if (preset && saveAction === SAVE_SHOWROOM_ACTION.SAVE) {
      setPresetName(preset.name)
      setIsPublic(preset.owner === 'public')
    }
  }, [preset, saveAction])

  const onChangePublic = useCallback(() => setIsPublic(prev => !prev), [])

  const onChangePresetName = ({currentTarget}) => setPresetName(currentTarget.value)

  const onSave = async () => {
    if (saveAction === SAVE_SHOWROOM_ACTION.SAVE_AS || !preset?.id) {
      await onSaveNewPreset(preparationProducts, presetName, isPublic);
    } else {
      await onUpdatePreset(
        preset.id,
        {
          products: preparationProducts,
          name: presetName,
          owner: isPublic ? 'public' : user.id
        }
      )
    }
    onHideSaveShowroomModal()
    if (isEditPresetMode) {
      onHideEditPresetModal()
    }
  }

  return (
    <Modal modalClass='showroom-preset-save-modal' closeFunc={onHideSaveShowroomModal}>
      <div className='showroom-preset-wrapper'>
        <h3 className='showroom-preset-header'>Save Showroom</h3>
        <div>Save this showroom as a template that can be loaded to scheduled or live calls</div>
        <div className='showroom-preset-data-wrapper'>
          <StyledInput
            type='text'
            value={presetName}
            placeholder={'Showroom name'}
            onChange={onChangePresetName}
          />
          <div className='checkbox-wrapper'>
            <Checkbox
              value={isPublic}
              label={'Showroom is available for all hosts'}
              onChange={onChangePublic}
            />
          </div>
        </div>
        <FilledButton
          disabled={!presetName.trim()}
          isLoading={showroomPresetLoading}
          className='button'
          style={{width: '45%', height: '46px', alignSelf: 'center'}}
          onClick={onSave}
        >
          {'Save'}
        </FilledButton>
      </div>
    </Modal>
    )
}

export default ShowroomPresetSaveModal;