import React, { useState, useEffect, useRef } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/messaging';

import { BoutiqVideoRoomProvider, useBoutiqVideoRoomContext } from '@caazam/boutiq-video-room';
import useScreenViews from './hooks/useScreenViews';
import useHostAppAnalytics from './hooks/useHostAppAnalytics';
import useShopVideoConfig from './hooks/useShopVideoConfig';

import AppAuthStateProvider, {
  signInWithEmailAndPassword,
  signInWithGoogle,
} from './authState';
import SoundFXProvider from './components/SoundFXProvider';

import { logger } from './logging';
import './App.scss';
import useShopLogging from './hooks/useShopLogging';
import Login from './pages/login/login';
import Signup from './pages/signup/signup';
import AuthAction from './pages/authActions/auth';
import HomePage from './pages/home-page/home-page';
import VideoRoom from './pages/video-room/video-room';
import AdminLogin from './components/AdminLogin';

import { useAuthState } from 'react-firebase-hooks/auth';
import PrivateRoute from './components/private-route/private-route';

import useCustomerProfileConfig from './hooks/useCustomerProfileConfig';
import AppDiagnosticScreen from "./pages/appDiagnostic";
import { AppContextProvider } from './providers/app-context-provider';
import LoadingLogo from './components/loading-logo/loading-logo';

function App() {
  const [videoToken, setVideoToken] = useState(null);
  const [user, authLoading, error] = useAuthState(firebase.auth());

  useHostAppAnalytics();
  useScreenViews();
  useShopVideoConfig();
  useCustomerProfileConfig();
  useShopLogging();
  const currentLocation = useLocation();
  const previousLocation = useRef(null);
  const [isFirstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    logger.info(`host app loaded ${process.env.REACT_APP_VERSION}`);
  }, [])

  useEffect(() => {
    // its firstload until there was a navigation (prev location is not null)
    if (previousLocation.current && isFirstLoad) {
      setFirstLoad(false);
    }
    previousLocation.current = currentLocation;
  }, [currentLocation]);

  if (authLoading) {
    return <LoadingLogo />;
  }

  if (error) {
    //TODO: error handling flow here
    return <h3>Error</h3>;
  }

  return (
    <div className='App'>
      <Switch>
        <Route path='/login'>
          <Login
            signInWithEmailAndPassword={signInWithEmailAndPassword}
            signInWithGoogle={signInWithGoogle}
          />
        </Route>
        <Route path='/signup/:URLInviteId'>
          <Signup />
        </Route>
        <Route path='/adminLogin'>
          <AdminLogin />
        </Route>
        <Route path='/auth/action'>
          <AuthAction />
        </Route>
        <Route path='/reset_password'>
          <Login
            resetPassword
          />
        </Route>
        <Route path='/'>
          <BoutiqVideoRoomProvider            
            options={{ enableVideoProcessors: true, enableRecording: true, preemptLoad: true  }}
          >
            <AppContextProvider>
              <PrivateRoutes
                videoToken={videoToken}
                setVideoToken={setVideoToken}
                isFirstLoad={isFirstLoad} />
            </AppContextProvider>
          </BoutiqVideoRoomProvider>
        </Route>
      </Switch>
    </div>
  );
}

function PrivateRoutes({ setVideoToken, isFirstLoad, videoToken }) {

  const { onVideoEvent, offVideoEvent } = useBoutiqVideoRoomContext();
  
  useEffect(()=>{
    const errorHandler = ({error})=> logger.error('BoutiqVideoProvider error event', error );
    onVideoEvent('error', errorHandler);
    return () => {
        offVideoEvent('error', errorHandler);
    }
},[]);

  return (
    <Switch>
      <PrivateRoute exact path='/'>
        <HomePage
          setVideoToken={setVideoToken}
          isFirstLoad={isFirstLoad}
        />
      </PrivateRoute>
      <PrivateRoute path='/video-room'>
        <VideoRoom
          videoToken={videoToken}
          setVideoToken={setVideoToken}
        />
      </PrivateRoute>
      <PrivateRoute exact path='/diagnostics'>
        <AppDiagnosticScreen />
      </PrivateRoute>
      <Redirect to='/' />
    </Switch>
  );
}

function WrappedApp() {
  return (
    <AppAuthStateProvider>
      <SoundFXProvider>
        <App />
      </SoundFXProvider>
    </AppAuthStateProvider>
  );
}

export default WrappedApp;
