import { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import useHostCalls from "./useHostCalls";

export default function useHostCallsSchedule(hostId, date = new Date()) {
  const startTime = new Date(new Date(date).setHours(0, 0, 0, 0));
  const endTime = new Date(new Date(date).setHours(23, 59, 59, 999));

  const {allCalls, isLoading} = useHostCalls((shopId) => {
    if (hostId && shopId) {
      return (
        firebase
          .firestore()
          .collection('shops')
          .doc(shopId)
          .collection('schedule')
          .where('startTimestamp', '>=', startTime)
          .where('startTimestamp', '<=', endTime)
          .where('hostId', '==', hostId)
          .where('status', '==', 'assigned')
          .orderBy('startTimestamp', 'asc')
      )
    }
  }, [hostId, date]);

  return {
    hostScheduleCalls: allCalls,
    hostScheduleCallsLoading: isLoading,
  };
}
