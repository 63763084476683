import { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {firestore} from '../../firebaseInitialization';
import {parseRoomObject} from "../../utils/parsers";
import {FIRESTORE_COLLECTIONS} from "../../constants/firestore";
import {setActiveRoom} from "../../actions/activeRoom";
import usePrevious from "../../hooks/usePreviousState";

export default function useActiveRoomListener() {
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.activeRoom);
  const prevId = usePrevious(id);

  const listenActiveRoom = () => {
    return firestore()
      .collection(FIRESTORE_COLLECTIONS.CHAT_ROOMS)
      .doc(id)
      .onSnapshot(
        (doc) => {
          if (doc && doc.data()) {
            dispatch(setActiveRoom(parseRoomObject(doc.data())))
          }
        }, listenError => console.error('listenActiveRoom',listenError));
  }

  useEffect(() => {
    if (id !== prevId && id) {
      const activeRoomListener = listenActiveRoom();
      return () => {
        activeRoomListener();
      }
    }
  },[id]);
}
