import LoadingCard from '../loading-card/loading-card';
import React from 'react';
import './text-skeleton.scss'

const TextSkeleton = ({lines = 3}) => {
  return (
    Array(lines).fill(0).map((_, key) => (
      <div key={key} className='text-skeleton-wrapper '>
        <LoadingCard
          margin={0}
          height={26}
          width={'100%'}
        />
      </div>
    ))
  )
}

export default TextSkeleton
