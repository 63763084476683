import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { setConnectOptions } from '../reducers/system-reducer';

export default function useShopVideoConfig() {

	const { activeShopId } = useSelector((state) => state.shops);
	const dispatch = useDispatch();

	useEffect(() => {
		if (activeShopId) {
			const shopId = activeShopId;
			const unsub = firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('video').onSnapshot(snap => {
				const videoConfig = snap.data();

				if (videoConfig?.connectOptions) {
					dispatch(setConnectOptions(videoConfig.connectOptions));
				} else {
					dispatch(setConnectOptions({}));
				}
			})
			return () => unsub();
		}

	}, [activeShopId])
}
