import React from 'react';
import {formatMsgTime} from "../../../modules/chat/utils/time";

const ChatTime = ({item}) => {
  const dateItem = (!!item && !!item.metadata && !!item.metadata.lastMessage)
    ?  item.metadata.lastMessage.timestamp
    : item.createdAt;

  const formattedTime = formatMsgTime(dateItem);

  return (
    <h6>{formattedTime}</h6>
  )

};

export default ChatTime;
