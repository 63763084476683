let CIRCULAR_ERROR_MESSAGE;

export function tryStringify(data) {
    const replacer = (key,value) => {   
        if (key && key.startsWith('_')) return undefined;
        return value;
    }

    try {
        return JSON.stringify(data, replacer);
    } catch (error) {

        if (!CIRCULAR_ERROR_MESSAGE) {      // Populate the circular error message lazily
            try {
                const a = {};
                a.a = a;
                JSON.stringify(a);
            } catch (circular) {
                CIRCULAR_ERROR_MESSAGE = circular.message;
            }
        }
        if (error.message === CIRCULAR_ERROR_MESSAGE) {
            return '[Circular]';
        }
        throw error;
    }
}
