import React, {useState} from 'react';

import './styles.scss';
import {useAppAuthState} from "../../authState";
import AppDiagnostic from "../home-page/host-setting/components/AppDiagnostic";
import {FilledButton} from "../../components";
import AnimatedDots from "../../components/animatedDots";

const AppDiagnosticScreen = () => {
  const { signOut } = useAppAuthState();
  const [isClosing, closePage] = useState(false);

  const backHandler = async () => {
    closePage(true);
    signOut()
      .catch(error => console.error('error signing out', error))
      .finally(() => {
        closePage(false);
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage('closeWebview')
        }
      });
  }

  return (
    <div className='diagnostic-container'>
      <AppDiagnostic />
      <FilledButton
        className='button back-button'
        onClick={backHandler}
      >
        {
          isClosing
            ? <AnimatedDots />
            : 'done'
        }
      </FilledButton>
    </div>
  );
};

export default AppDiagnosticScreen;
