import React, {useState, useEffect, useMemo} from 'react';
import Moment from 'moment-timezone';
import {FORM_ELEMENTS_PROPS, FORM_OBJECT_TYPES} from '../formBuilder/templateFormBuilder';
import { OptionElement } from '../OptionElement';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { FormMessages } from '../FormMessages';
import './CustomPopover.scss';
import {HOST_AVAILABILITY} from "../../../../../constants/const";
import {ButtonLoader} from "../../../../../components";

const CustomPopover = ({ data, add, update, updateWindowRecurrenceRule, handleDelete, onClose, isCustomCallPolicy, selectedHostAvailabilityType, onChangeHostAvailabilityType, actionLoading }) => {
    const [isValidForm, setIsValidForm] = useState(true);

    const hostAvailability = useMemo(() => (
      [
          {name: HOST_AVAILABILITY.EVENTS, value: HOST_AVAILABILITY.EVENTS, label: 'Scheduled calls'},
          {name: HOST_AVAILABILITY.CALLS, value: HOST_AVAILABILITY.CALLS, label: 'Instant calls'}
      ]
    ), [])

    const defaultFormValues = (data) => {
        return {
            'id': data.id ? data.id : null,
            'start': data.start,
            'startTime': data.start,
            'end': data.end,
            'endTime': data.end,
            'repeat_mode': data.isRecurring,
            'repeat_end': !!data.recurrenceEnd,
            'repeat_end_date': data.recurrenceEnd ? data.recurrenceEnd : Moment(data.end).add(1, 'days').toDate(),
        };
    }

    const [formValues, setFormValues] = useState(defaultFormValues(data));
    const [formMessage, setFormMessage] = useState(null);
    const [formFields, setFormFields] = useState([]);
    const [isUpdateSlot, setIsUpdateSlot] = useState(false);
    const [isDeleteSlot, setIsDeleteSlot] = useState(false);
    const [isTimeUpdate, setIsTimeUpdate] = useState(false);
    const [isRecurreceUpdate, setIsRecurreceUpdate] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false)

    useEffect(() => {
        setIsLoadingData(Object.values(actionLoading).reduce((acc, current) => acc || current, false))
    }, [actionLoading])

    const ActionsContainer = ({ isRecurring, isUpdateSlot, isDeleteSlot }) => {
        return (
            <>
                <div className='actions-group'>
                    {actions(!data.id, isRecurring, isUpdateSlot, isDeleteSlot, isRecurreceUpdate).map((action, index) => (
                        <button disabled={!action.isActive} key={`${index}-${action.label}`} className="btn" onClick={() => action.func()}>
                            {action.loading ? (
                              <ButtonLoader loaderSize={'20px'}/>
                            ) : (
                              action.label
                            )}
                        </button>
                    ))}
                </div>
                {(isUpdateSlot || isDeleteSlot) && isRecurring && !isRecurreceUpdate &&
                    <div className={`delete-actions-group`}>
                        {enhancedActions(isUpdateSlot).map((action, index) => (
                            <button disabled={!action.isActive} key={`${index}-${action.label}`} className="btn" onClick={() => action.func()}>
                                {action.loading ? (
                                  <ButtonLoader loaderSize={'20px'}/>
                                ) : (
                                  action.label
                                )}
                            </button>
                        ))}
                    </div>}
            </>
        )
    }

    const actions = (isNewSlot, isRecurring, isUpdateSlot, isDeleteSlot, isRecurreceUpdate) => {
        if (isNewSlot) {
            return [
                {
                    label: 'Cancel',
                    isActive: !isLoadingData,
                    func: () => onClose()
                },
                {
                    label: 'Save',
                    isActive: isValidForm && !isLoadingData,
                    func: async () => await addSlot(formValues),
                    loading: actionLoading.addHostLoading
                }
            ]
        } else {
            if (isUpdateSlot) {
                if (isRecurring) {
                    if (isRecurreceUpdate) {
                        return [
                            {
                                label: 'Cancel',
                                isActive: !isLoadingData,
                                func: () => onResetChanges()
                            },
                            {
                                label: 'Update',
                                isActive: !isLoadingData,
                                func: () => updateSlot(),
                                loading: actionLoading.updateHostLoadingRecurrence
                            },
                        ]
                    } else {
                        return [
                            {
                                label: 'Cancel',
                                isActive: !isLoadingData,
                                func: () => onResetChanges()
                            },
                        ]
                    }
                   
                } else {
                    return [
                        {
                            label: 'Cancel',
                            isActive: !isLoadingData,
                            func: () => onResetChanges()
                        },
                        {
                            label: 'Update',
                            isActive: !isLoadingData,
                            func: () => updateSlot(),
                            loading: actionLoading.updateHostLoadingRecurrence
                        },

                    ]
                }
            } else if (isDeleteSlot) {
                if (isRecurring) {
                    return [
                        {
                            label: 'Cancel',
                            isActive: !isLoadingData,
                            func: () => onResetChanges()
                        }
                    ]
                } else {
                    return [
                        {
                            label: 'Cancel',
                            isActive: !isLoadingData,
                            func: () => onResetChanges()
                        },
                        {
                            label: 'Delete',
                            isActive: !isLoadingData,
                            func: async () => await handleDelete(false),
                            loading: actionLoading.deleteHostLoading
                        }
                    ]
                }
            } else {
                return [
                    {
                        label: 'Delete',
                        isActive: !isLoadingData,
                        func: () => setIsDeleteSlot(true),
                    },
                ]
            }
        }
    }

    const enhancedActions = (isUpdate) => {
        if (isUpdate) {
            return [
                {
                    label: 'Update this instance',
                    isActive: isValidForm && !isLoadingData,
                    func: () => updateSlot(false),
                    loading: actionLoading.updateHostLoading
                },
                {
                    label: 'Update all future instances',
                    isActive: isValidForm && !isLoadingData,
                    func: () => updateSlot(),
                    loading: actionLoading.updateHostLoadingRecurrence
                }
            ]
        } else {
            return [
                {
                    label: 'Delete this instance',
                    isActive: isValidForm && !isLoadingData,
                    func: async () => await handleDelete(false),
                    loading: actionLoading.deleteHostLoading
                },
                {
                    label: 'Delete all future instances',
                    isActive: isValidForm && !isLoadingData,
                    func: async () => await handleDelete(),
                    loading: actionLoading.deleteHostRecurrenceLoading
                }
            ]
        }
    }

    useEffect(() => {
        if (data) {
            setIsUpdateSlot(false);
            setIsDeleteSlot(false);
            setIsTimeUpdate(false);
            setIsRecurreceUpdate(false);
            setFormMessage(null);
            if (data.forcePopup) {
                data.forcePopup = false;
            }
            setFormValues(defaultFormValues(data));
            setFormFields(FORM_ELEMENTS_PROPS.map(item => item(formValues, data.end)).filter(item => item !== null));
        }
    }, [data])

    useEffect(() => {
        if (formValues) {
            const formElements = FORM_ELEMENTS_PROPS.map(item => item(formValues, data.end)).filter(item => item !== null);
            const isFieldsValid = formElements.every(element => element.isValid);
            setIsValidForm(isFieldsValid)
            setFormFields(formElements);
        }
    }, [formValues])

    useEffect(() => {
        if (!isValidForm) {
            setFormMessage({
                icon: <ErrorOutlineIcon style={{ fontSize: '14px', marginRight: '1ch' }} />,
                type: 'error',
                text: 'Close time must be after open time'
            })

        } else {
            setFormMessage(null)
        }
    }, [isValidForm, isDeleteSlot])

    useEffect(() => {
        if (data && data.id) {
            if (Moment(formValues.startTime).valueOf() !== Moment(data.start).valueOf() ||
                Moment(formValues.endTime).valueOf() !== Moment(data.end).valueOf()) {
                setIsUpdateSlot(true);
                setIsTimeUpdate(true);                
            } else if (formValues.repeat_end && Moment(formValues.repeat_end_date).valueOf() !== Moment(data.recurrenceEnd).valueOf() || 
                       formValues.repeat_mode !== data.isRecurring || formValues.repeat_end !== !!data.recurrenceEnd) {
                setIsUpdateSlot(true);
                setIsRecurreceUpdate(true);
            } else {
                setFormMessage(null);
                setIsUpdateSlot(false);
                setIsTimeUpdate(false);
                setIsRecurreceUpdate(false);
            }
        }
    }, [formValues])

    const onResetChanges = () => {
        setIsUpdateSlot(false);
        setIsDeleteSlot(false);
        setIsTimeUpdate(false);
        setIsRecurreceUpdate(false);
        setFormMessage(null);
        setFormValues({ ...formValues, ...defaultFormValues(data) });
    }

    const onValueChange = (name, value) => {
        if (value === 'true' || value === 'false') {
            value = JSON.parse(value);
        }
        setFormValues({ ...formValues, [name]: value })
    }

    const addSlot = async () => {
        await add(formValues);
    }

    const updateSlot = async (allFuture = true) => {
        if (isTimeUpdate) {
            await onUpdateStartEndTime(allFuture);
        } else {
            await updateRecurrence();
        }
        onClose();
    }

    const onUpdateStartEndTime = async (allFuture = true) => {
        await update(formValues, allFuture);
    }

    const updateRecurrence = async () => {
        await updateWindowRecurrenceRule(formValues);
    }

    const onTypeChange = (_, value) => {
        onChangeHostAvailabilityType(value)
    }
    return (
        <>
            <div className={`tooltip-container active`}>
                <div className={`tooltip`}>
                    <ul className='content'>
                        <button className='btn-close-icon' onClick={() => onClose()}>
                            <CloseIcon style={{ fontSize: '20px' }} />
                        </button>
                        <li className='row'>
                            <span className='title'>{data.title}</span>
                        </li>
                        {isCustomCallPolicy && (
                          <li className='properties row'>
                              <OptionElement
                                disableSelect={data.id}
                                styles={{
                                    container: {
                                        gridTemplateColumns: '120px auto'
                                    }
                                }}
                                element={{
                                    value: selectedHostAvailabilityType,
                                    label: 'host availability',
                                    visibility: 'visible',
                                    type: FORM_OBJECT_TYPES.select,
                                    values: hostAvailability,
                                    name: 'host availability',
                                }}
                                onValueChange={onTypeChange}
                              />
                          </li>
                        )}
                        <li className='properties row'>
                            {formFields.map((element, index) => (
                                <OptionElement
                                    disableDateTimePicker={isRecurreceUpdate || isDeleteSlot}
                                    disableDatePicker={isTimeUpdate || isDeleteSlot}
                                    disableSelect={isTimeUpdate || isDeleteSlot}
                                    slotData={data}
                                    key={`${index}-${element.name}`}
                                    element={element}
                                    onValueChange={onValueChange}
                                />))}
                        </li>
                        <li className='actions'>
                            <div className='actions-message-container'>
                                <FormMessages message={formMessage} />
                            </div>

                            <div className='actions-buttons-container'>
                                <ActionsContainer
                                    isRecurring={data.isRecurring}
                                    isUpdateSlot={isUpdateSlot}
                                    isDeleteSlot={isDeleteSlot}
                                />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default CustomPopover;
