import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  isUserLoading: true,
  isUserActive: true,
  customerProfileConfig: {
    showAvgBasket: true,
    showMaxBasket: true,
    showTotalSpend: true,
    showLastPurchaseDate: true,
    showTotalCredit: true,
    showClientNote: true,
    showTotalRefunds: true,
    showRecentPurchases: true,
    showProductRecommendations: true,
    showRecentlyViewedProducts: true,
  },
  googleAuth: {
    refreshToken: null,
    isLoggedIn: false,
    isGoogleAuthLoading: false,
  }
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, { payload }) {
      state.user = payload;
    },
    setUserLoading(state, { payload }) {
      state.isUserLoading = payload;
    },
    setUserActive(state, { payload }) {
      state.isUserActive = payload;
    },
    restartUserState(state) {
      state.user = null;
      state.isUserLoading = true;
      state.isUserActive = true;
    },
    resetUserState(state) {
      state.user = null;
      state.isUserLoading = false;
      state.isUserActive = false;
    },
    setCustomerProfileConfig(state, { payload }) {
      state.customerProfileConfig = payload;
    },
    updateGoogleAuth(state, { payload }) {
      state.googleAuth = {
        ...state.googleAuth,
        ...payload,
        isLoggedIn: !!payload?.refresh_token,
        isGoogleAuthLoading: false,
      };
    },
    setGoogleAuthLoading(state, { payload }) {
      if (payload !== state.googleAuth.isGoogleAuthLoading) {
        state.googleAuth = {
          ...state.googleAuth,
          isGoogleAuthLoading: payload
        };
      }
    },
    deleteGoogleAuth(state) {
      state.googleAuth = initialState.googleAuth;
    },
  },
});

export const { setUser, setUserLoading, setUserActive, restartUserState, resetUserState, setCustomerProfileConfig, updateGoogleAuth, setGoogleAuthLoading, deleteGoogleAuth } = userSlice.actions;

export default userSlice.reducer;
