import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/messaging';
import { logger } from '../logging'

export default function useMessaging(user) {
  const [messagingToken, setMessagingToken] = useState(null);
  const [messagingError, setMessagingError] = useState(null);
  const { installId } = useSelector(state => state.system);

  // get the current messaging token
  useEffect(() => {

    const getCurrentToken = async () => {
      try {
        const currentToken = await firebase.messaging().getToken({ vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_PUBLIC_KEY });
        if (currentToken) {
          logger.info('Setting messaging token',  currentToken.substring(0,10));
          setMessagingToken(currentToken);
        } else {
          logger.info('No Instance ID token available. Request permission to generate one.');
          setMessagingToken(null);
        }
      } catch (error) {
        logger.error('An error occurred while retrieving token. ', error);
        setMessagingToken(null);
        setMessagingError(error);
      }
    };

    if (firebase.messaging.isSupported() && user) {
      getCurrentToken();
    }

  }, [user]);

  // set the current messaging token to server
  useEffect(() => {
    const setToServer = async () => {
      if (user && installId) {
        return firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .collection('private')
          .doc('messaging')
          .set({
            messagingToken: firebase.firestore.FieldValue.delete(), // to delete old way of strong tokens
            [installId]: {
              fcmToken: messagingToken,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              hostname: window.location.hostname,
            }
          }, { merge: true })
          .then(() => {
            setMessagingError(null);
          })
          .catch((error) => {
            setMessagingError(error);
          });
      }
    };
    setToServer();
  }, [messagingToken, user, installId]);

  const stopMessaging = useCallback(() => {
    if (user && installId) {
      return firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .collection('private')
        .doc('messaging')
        .set({
          [installId]: firebase.firestore.FieldValue.delete(),
        }, { merge: true })
        .then(() => {
          if (firebase.messaging.isSupported()) {
            firebase.messaging().deleteToken().catch(err => logger.error('deleteToken', err))
          }
        });
    }
  }, [user, installId]);

  return { messagingError, stopMessaging };
}
