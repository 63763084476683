import React, { useState, useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import './search-sidebar.scss';
import { currencyFormatter, parseIdString } from '../../../../../utils';
import { useSessionProducts } from '../../../../../hooks';
import { Loader } from '../../../../../components/loader/loader';
import { useActiveCallProvider } from '../../../../../components/ActiveCallProvider';
import { ProductResultCard } from './components/product-result-card';
import { DEFAULT_PRODUCT_RESULTS_LIMIT } from '../consts';
import { InfiniteScroll } from '../../../../../components/infinity-scroll/Infinity-scroll';
import {SearchInput} from "../customer-profile/components/search-input/search-input";
import { useShowroomPresetProvider } from '../../../../../providers/preset-provider/preset-provider';
import ListProductSkeleton from '../../../../../components/skeletons/list-product-skeleton/list-product-skeleton';

const SearchSidebar = ({
  isSearching,
  setSearching,
  searchProducts,
  searchResults,
  viewItem,
  searchTerm,
  setSearchTerm,
  isNewSearch,
  setIsNewSearch,
  onSubmitSearch,
}) => {
  const [productIdsInShowroom, setProductIdsInShowroom] = useState(null);
  const [formattedResults, setFormattedResults] = useState(null);
  const [noResults, setNoResults] = useState(false);
  const [cursor, setCursor] = useState(null);
  const { activeCallId: contextId } = useActiveCallProvider();
  const { sessionSnapshots, addToSession } = useSessionProducts(contextId);
  const [currentPage, setCurrentPage] = useState(0);
  const {onShowPresetModal, showroomPresetsList} = useShowroomPresetProvider()

  useEffect(() => {
    if (isNewSearch) {
      setCursor(null);
      setFormattedResults(null);
      setCurrentPage(0);
    }
  }, [isNewSearch]);

  const findProductsInSR = (productArray, existingProductIds) => {
    const newProductArray = productArray.map((product) => {
      return {
        ...product,
        isInFTR: existingProductIds.includes(parseIdString(product.productId)),
      };
    });
    setFormattedResults(newProductArray);
  };

  const addToShowroom = (product) => {
    addToSession(product);
  };

  useEffect(() => {
    if (!sessionSnapshots) return;

    const existingProductIds = sessionSnapshots.map(
      (snapshot) => snapshot.val().productId
    );
    setProductIdsInShowroom(existingProductIds);
    if (existingProductIds && formattedResults) {
      findProductsInSR(formattedResults, existingProductIds);
    }
  }, [sessionSnapshots]);

  useEffect(() => {
    if (!searchResults) return;
    if (!searchResults.products.length) {
      setNoResults(true);
      return;
    }
    setNoResults(false);
    if (!productIdsInShowroom) {
      setFormattedResults(searchResults.products);
    } else {
      findProductsInSR(searchResults.products, productIdsInShowroom);
    }
    setCursor(searchResults.cursor);
  }, [searchResults]);

  const fetchNextPage = () => {
    if (!isSearching) {
      setSearching(true);
      setIsNewSearch(false);
      setTimeout(() => {
        searchProducts(searchTerm, DEFAULT_PRODUCT_RESULTS_LIMIT, cursor)
          .then((searchResults) => {
            setCurrentPage(currentPage + 1);
            if (!productIdsInShowroom) {
              setFormattedResults([...formattedResults, ...searchResults.products]);
            } else {
              findProductsInSR(
                [...formattedResults, ...searchResults.products],
                productIdsInShowroom
              );
            }
            setCursor(searchResults.cursor);
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setSearching(false);
          });
      }, 1000);
    }
  };


  const loadMoreRows = isSearching ? () => { } : fetchNextPage;

  const renderCurrentPrice = (product) => {
    if (product.maxVariantPrice !== product.minVariantPrice) {
      return (
        <>
          <h4>
            {`${currencyFormatter(product.minVariantPrice, product.currencyCode)} — ${currencyFormatter(product.maxVariantPrice, product.currencyCode)}`}
          </h4>
        </>
      )
    } else {
      return (
        <>
          <h4>
            {product.price && currencyFormatter(product.price, product.currencyCode)}
          </h4>
          {product.compareAtPrice && product.price !== product.compareAtPrice ? (
            <h4 className='old-price'>
              {currencyFormatter(product.compareAtPrice, product.currencyCode)}
            </h4>
          ) : null}
        </>
      )
    }
  }

  const createResultCard = (result) => {
    return (
      <ProductResultCard
        product={result}
        addToShowroom={addToShowroom}
        renderCurrentPrice={renderCurrentPrice}
        viewItem={viewItem}
      />
    );
  };

  return (
    <div className='search-container'>
      <div className='search-container-wrapper'>
        <SearchInput
          placeholder={'Search for products'}
          searchInputValue={searchTerm}
          onChange={setSearchTerm}
          onSubmit={onSubmitSearch}
        />
        {!!showroomPresetsList.length && <p onClick={() => onShowPresetModal(false)} className='load-showroom-button'>Load showroom</p>}
      </div>
      {isNewSearch && isSearching ? (
        <ListProductSkeleton />
      ) : noResults ? (
        <div className='empty-results'>
          <p>No products found</p>
          <p>Try changing the search term</p>
        </div>
      ) : formattedResults ? (
        <InfiniteScroll
          isLoading={isSearching}
          hasMore={!!cursor}
          loadMore={loadMoreRows}
          results={formattedResults}
          loader={<Loader height='26px' width='26px' borderWidth='2px' />}
          renderItem={createResultCard}
        />
      ) : (
        <div className='empty'>
          <SearchIcon
            style={{
              color: '#CFC8BB',
              fontSize: '80px',
              WebkitTextFillColor: '#CFC8BB',
            }}
          />
          <p>Search for products</p>
        </div>
      )}
    </div>
  );
};

export default SearchSidebar;
