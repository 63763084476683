import React from 'react';
import ContentHeader from '../header-content';
import CustomerChat from './customer-chat';
import './chat-content.scss';
import { useCallDetailsProvider } from '../call-details-side-bar/call-details-provider/call-details-provider';

const ChatContent = ({
  setAvailability,
  messageValue,
  setMessageValue,
  setPendingDialog,
  pendingDialog,
  setSelectedRoom,
  selectedRoom,
  setIsChatVisible
}) => {

  const {
    setIsClientHistory
  } = useCallDetailsProvider()

  const onShowClientPage = () => {
    setIsChatVisible(false)
    setIsClientHistory(true)
  }

  return (
    <div className='container'>
      <ContentHeader
        setAvailability={setAvailability}
        visibleDate={new Date()}
        onShowClientHistory={onShowClientPage}
        isChatScreen={true}
      />
      {selectedRoom
        ? <CustomerChat
            messageValue={messageValue}
            setMessageValue={setMessageValue}
            pendingDialog={pendingDialog}
            setPendingDialog={setPendingDialog}
            selectedRoom={selectedRoom}
            setSelectedRoom={setSelectedRoom}
          />
        : <div className='empty-content'>
            <h1>Client chats</h1>
            <h1>You can select a pending or active chat from the chat list on the left</h1>
          </div>
      }
    </div>
  )
}

export default ChatContent;
