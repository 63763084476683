import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

import { appLogger, logger } from '../logging';

export default function useShopLogging() {
    const { activeShopId, callShopId, availableShops } = useSelector((state) => state.shops);
    const [user] = useAuthState(firebase.auth());


    useEffect(() => {
        if (user) {
            appLogger.startRemote(user.uid, () => user.getIdToken(/* force refresh */ true));
        }
        return () => appLogger.stopRemote();
    }, [user]);

    useEffect(() => {
        if (callShopId)
            appLogger.setShopId(callShopId);
        else if (activeShopId)
            appLogger.setShopId(activeShopId);
        else
            appLogger.setShopId(null);

    }, [activeShopId, callShopId]);

    useEffect(() => {
        logger.info(`user ${user && user.uid} set to active shop ${activeShopId}`);
    }, [activeShopId])

    useEffect(() => {
        logger.info(`user ${user && user.uid} set to available shops`, { availableShops: Object.keys(availableShops) });
    }, [availableShops.length])
}
