import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

import { putFileToStorage } from '../modules/chat/firebaseInitialization';

export default function useStorageBucket({ bucket }) {

  const uploadFile = async (path, file) => {
    if (!file || !file.name) {
      console.error('File is incorrect', file);
      return;
    }

    const storageRef = firebase.app()
      .storage(bucket)
      .ref()
      .child(path)
      .child(file.name);

    await putFileToStorage(storageRef, file);
    let fileStorageRef = storageRef.toString();
    let filePublicUrl = await storageRef.getDownloadURL();
    return { fileStorageRef, filePublicUrl};
  }

  const deleteFile = async (ref) => {
    if (!ref) {
      return;
    }

    const storageRef = firebase.app()
    .storage(bucket)
    .refFromURL(ref);

    storageRef.delete()
      .catch((error) => console.error('File deletion error', error));
  }

  return {
    uploadFile,
    deleteFile,
  };
}
