import React from 'react';

import './buttons.scss';

export const ButtonLoader = ({ color, backgroundColor, loaderSize = '40px' }) => (
  <div
    className='button-loader'
    style={{
      border: `2px solid ${color ? color : 'var(--main-color)'}`,
      borderTop: `2px solid ${backgroundColor ? backgroundColor : 'white'}`,
      borderRadius: '50%',
      width: loaderSize,
      height: loaderSize,
      margin: 'auto',
    }}
  />
);

const CaazamButton = (props) => {
  const { children, isLoading, loaderSize, ...buttonProps } = props;
  return (
    <button
      type={props.type || 'button'}
      {...buttonProps}
      style={{
        textTransform: 'uppercase',
        fontWeight: '600',
        borderRadius: '28px',
        height: '56px',
        outline: 'none',
        margin: 0,
        width: '184px',
        boxSizing: 'border-box',
        ...buttonProps.style,
        backgroundColor: buttonProps.disabled
          ? 'var(--main-color-hover)'
          : buttonProps.style.backgroundColor
          ? buttonProps.style.backgroundColor
          : 'var(--main-color)',
      }}>
      {isLoading ? (
        <ButtonLoader
          backgroundColor={props.style.backgroundColor}
          color={props.style.color}
          WebkitTextFillColor={props.style.color}
          loaderSize={loaderSize}
        />
      ) : (
        children
      )}
    </button>
  );
};

export const FilledButton = (props) => {
  return (
    <CaazamButton
      {...props}
      style={{
        backgroundColor: 'var(--main-color)',
        border: 'none',
        color: '#ffffff',
        WebkitTextFillColor: '#ffffff',
        ...props.style,
      }}
      className={props.className ? `${props.className} button` : ''}
    />
  );
};

export const OutlinedButton = (props) => {
  return (
    <CaazamButton
      {...props}
      style={{
        border: `1px solid ${props.bordercolor || 'var(--main-color)'}`,
        color: `${props.bordercolor || 'var(--main-color)'}`,
        backgroundColor: '#ffffff',
        WebkitTextFillColor: `${props.bordercolor || 'var(--main-color)'}`,
        ...props.style,
      }}
      className={props.className ? `${props.className} button` : ''}
    />
  );
};

export const PlainButton = (props) => {
  const { children, isLoading, ...buttonProps } = props;
  return (
    <button
      type={props.type || 'button'}
      {...buttonProps}
      style={{
        outline: 'none',
        margin: 0,
        color: `${(props.style && props.style.color) || 'var(--main-color)'}`,
        WebkitTextFillColor: `${(props.style && props.style.color) || 'var(--main-color)'}`,
        ...buttonProps.style,
      }}>
      {isLoading ? (
        <ButtonLoader color={props.style && props.style.color} loaderSize={props.loaderSize} />
      ) : (
        children
      )}
    </button>
  );
};
