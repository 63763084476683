import React, {useState, Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useActiveCallProvider} from '../../../../components/ActiveCallProvider';
import {SIDEBAR_VIEWS, SIDEBAR_FEATURES} from './consts/index';
import DraftOrderModal from "../../../../components/Modals/draft-order-modal/DraftOrderModal";
import {hideDraftOrderModal} from "../../../../reducers/active-call-reducer";
import './sidebar.scss';
import SidebarTabBar from "../../../../components/sidebar-tab-bar/sidebar-tab-bar";
import ActiveUser from "../../../../assets/icons/user/ActiveUser";
import RegularUser from "../../../../assets/icons/user/RegularUser";
import ProductActive from "../../../../assets/icons/products/ProductActive";
import ProductRegular from "../../../../assets/icons/products/ProductRegular";
import ChatActive from "../../../../assets/icons/chat/ChatActive";
import ChatRegular from "../../../../assets/icons/chat/ChatRegular";
import SidebarClientTabs from "./components/sidebar-client-tabs/sidebar-client-tabs";
import SidebarProductTab from "./components/sidebar-product-tab/sidebar-product-tab";
import SidebarChatTab from "./components/sidebar-chat-tab/sidebar-chat-tab";
import {calcVideoRoomChatNotifications} from "../../../../modules/chat/utils/rooms";
import ShowroomPresetsModal from '../../../../components/Modals/showroom-presets-modal/showroom-presets-modal';
import { useShowroomPresetProvider } from '../../../../providers/preset-provider/preset-provider';

export {SIDEBAR_FEATURES};

export default function Sidebar({
  customer,
  setCustomer,
  shopId,
  isUserLoading,
  setUserLoading,
  clearSelectedProductIds,
  selectedProductVariantId,
  updateProduct,
  selectedSnapshotKey,
  selectedProductId,
  selectProduct,
  clientViewData,
  allowProductDetails = true,
  setFullSizeChatImage = null,
  clientParticipant
}) {
  const dispatch = useDispatch()
  const [previousPurchaseData, setPreviousPurchaseData] = useState(null);
  const {
    activeCallData,
    activeCallId,
    openShowRoom
  } = useActiveCallProvider();
  const {
    presetModalVisibility,
    onHidePresetModal,
    onUpdateVideoRoomShowroom
  } = useShowroomPresetProvider();

  const standaloneMode = !activeCallId;
  const activeRoom = useSelector((state) => state.activeRoom);
  const {id: chatRoomId} = activeRoom;
  const chatEnabled = !!chatRoomId; // chat always enabled in video call
  const {showDraftOrder, draftOrderId} = useSelector(state => state.activeCall);
  const [selectedTab, setSelectedTab] = useState(SIDEBAR_VIEWS.CUSTOMER);
  const { user } = useSelector((state) => state.user);
  const [prevSelectedTab, setPrevSelectedTab] = useState(null);

  useEffect(() => {
    if (!!selectedProductId) {
      setPrevSelectedTab(selectedTab)
    } else {
      if (prevSelectedTab === SIDEBAR_VIEWS.CUSTOMER) {
        setSelectedTab(SIDEBAR_VIEWS.CUSTOMER)
      }
    }
  }, [selectedProductId])

  const tabs = () => {
    const tabs = [
      {
        key: SIDEBAR_VIEWS.CUSTOMER,
        label: 'Clients',
        icon: () => <RegularUser/>,
        activeIcon: () => <ActiveUser/>,
        component: () => (
          <SidebarClientTabs
            visible={selectedTab === SIDEBAR_VIEWS.CUSTOMER}
            customer={customer}
            setCustomer={setCustomer}
            clientParticipant={clientParticipant}
            allowProductDetails={allowProductDetails}
            selectProduct={selectProduct}
            viewPreviouslyPurchased={viewPreviouslyPurchased}
            isUserLoading={isUserLoading}
            setUserLoading={setUserLoading}
            shopId={shopId}
          />
        )
      },
      {
        key: SIDEBAR_VIEWS.PRODUCT,
        label: 'Products',
        icon: () => <ProductRegular/>,
        activeIcon: () => <ProductActive/>,
        component: () => (
          <SidebarProductTab
            visible={selectedTab === SIDEBAR_VIEWS.PRODUCT}
            shopId={shopId}
            selectedProductVariantId={selectedProductVariantId}
            updateProduct={updateProduct}
            selectedSnapshotKey={selectedSnapshotKey}
            previousPurchaseData={previousPurchaseData}
            setPreviousPurchaseData={setPreviousPurchaseData}
            clearSelectedProductIds={clearSelectedProductIds}
            clientViewData={clientViewData}
            selectedProductId={selectedProductId}
            updateCurrentTab={setSelectedTab}
            selectProduct={selectProduct}
          />
        )
      },
    ]
    if (chatEnabled && activeCallId) {
      tabs.push({
        key: SIDEBAR_VIEWS.CHAT,
        label: 'Chat',
        icon: () => <ChatRegular/>,
        activeIcon: () => <ChatActive/>,
        counter: calcVideoRoomChatNotifications(user?.id, activeRoom),
        component: () => (
          <SidebarChatTab
            visible={selectedTab === SIDEBAR_VIEWS.CHAT}
            setFullSizeChatImage={setFullSizeChatImage}
          />
        )
      })
    }
    return tabs;
  }

  const hideDraftOrder = () => {
    dispatch(hideDraftOrderModal())
  }

  const viewPreviouslyPurchased = (productStub) => {
    const {
      productId,
      amount,
      currencyCode,
      variantTitle,
      processedAt,
    } = productStub;
    setPreviousPurchaseData({
      amount,
      currencyCode,
      variantTitle,
      productId,
      processedAt: new Date(processedAt).toLocaleDateString(),
    });
    selectProduct(productId);
  };

  const onUpdateShowroomProducts = async (products) => {
    await onUpdateVideoRoomShowroom(products)
    openShowRoom()
  }

  const activeCallTabs = () => (
    <>
      <SidebarTabBar
        barStyle={{
          justifyContent: 'space-between',
          margin: 0,
          marginTop: 16,
          width: '100%',
        }}
        tabStyle={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        tabs={tabs()}
        onPressTab={setSelectedTab}
        selectedTab={selectedTab}
      />
      <div className='tabs-wrapper'>
        {tabs().map(({component, key}) => (
          <Fragment key={key}>
            {component()}
          </Fragment>
        ))}
      </div>
    </>
  )

  return (
    <div className={`sidebar ${standaloneMode ? 'standalone-height' : 'video-height'}`}>
      {!!activeCallId
        ? activeCallTabs()
        : (
          <div className='tabs-wrapper'>
            <SidebarClientTabs
              visible={selectedTab === SIDEBAR_VIEWS.CUSTOMER}
              customer={customer}
              setCustomer={setCustomer}
              clientParticipant={clientParticipant}
              allowProductDetails={allowProductDetails}
              selectProduct={selectProduct}
              viewPreviouslyPurchased={viewPreviouslyPurchased}
              isUserLoading={isUserLoading}
              setUserLoading={setUserLoading}
              shopId={shopId}
            />
          </div>
        )}
      <DraftOrderModal
        visibility={showDraftOrder}
        onClosePress={hideDraftOrder}
        customer={customer}
        initialDraftOrderId={draftOrderId}
        callData={{
          ...activeCallData,
          id: activeCallId
        }}
      />
      <ShowroomPresetsModal
        onUpdateShowroomProducts={onUpdateShowroomProducts}
        visibility={presetModalVisibility}
        onClosePress={onHidePresetModal}
      />
    </div>
  );
};