import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import './OrderDiscount.scss';
import {FilledButton, OutlinedButton} from "../../../../buttons/buttons";
import Select from "react-select";
import {useIMask} from "react-imask";
import {useDigitalInput} from "../../../../../hooks/useDigitalInput";

export const DISCOUNT_TYPE = {
  AMOUNT: 'FIXED_AMOUNT',
  PERCENTAGE: 'PERCENTAGE'
}

const options = [
  {value: DISCOUNT_TYPE.AMOUNT, label: 'Amount'},
  {value: DISCOUNT_TYPE.PERCENTAGE, label: 'Percentage'}
]

const OrderDiscount = ({hideDiscount, updateDiscount, orderDiscount, onRemoveDiscount}, ref) => {
  const [discountType, setDiscountType] = useState({})
  const [reason, setReason] = useState('')
  const [discountPlaceholder, setDiscountPlaceholder] = useState('$0.00')
  const {ref: discountRef, value, setValue} = useDigitalInput()

  const [discount, setDiscount] = useState('')
  const containerRef = useRef()

  useEffect(() => {
    setDiscount(value);
  }, [value])

  useImperativeHandle(ref, () => ({
    scrollIntoView: () => {
      const container = document.getElementById('draft-order-container')
      container.scroll({
        top: containerRef.current.offsetTop,
        behavior: 'smooth'
      })
    }
  }), [])

  useEffect(() => {
    setReason(orderDiscount?.discountReason)
    if (orderDiscount?.discount) {
      const {discountValue, discountType} = orderDiscount.discount;
      setDiscountType({
        value: discountType,
        label: discountLabelByType(discountType)
      })
      setValue(discountValue.toString())
      setDiscountPlaceholder(discountPlaceholderByType(discountType))
    } else {
      setDiscountType({
        value: DISCOUNT_TYPE.AMOUNT,
        label: discountLabelByType(DISCOUNT_TYPE.AMOUNT)
      })
    }
  }, [])

  useEffect(() => {
    setDiscountPlaceholder(discountPlaceholderByType(discountType?.value))
  }, [discountType])

  const discountLabelByType = (type) => {
    return type === DISCOUNT_TYPE.AMOUNT ? 'Amount' : 'Percentage'
  }

  const discountPlaceholderByType = (type) => {
    return type === DISCOUNT_TYPE.AMOUNT ? '$0.00' : '%'
  }

  const onApply = () => {
    updateDiscount({
      discount: {
        discountType: discountType.value,
        discountValue: discount,
      },
      discountReason: reason
    })
  }

  const onChangeReason = (e) => {
    setReason(e.target.value)
  }

  const discountFooter = () => (
    <div className={`draft-order-discount${orderDiscount?.discount ? '-edit' : ''}-buttons`}>
      <OutlinedButton
        className='button'
        style={{
          height: '34px',
          textTransform: 'capitalize',
          width: orderDiscount?.discount ? '200px' : '100px'
        }}
        onClick={orderDiscount?.discount ? onRemoveDiscount : hideDiscount}
        bordercolor={orderDiscount?.discount ? 'var(--error-color)' : 'var(--main-color)'}
      >
        {orderDiscount?.discount ? 'Remove discount' : 'Close'}
      </OutlinedButton>
      <FilledButton
        disabled={!discount}
        className='button'
        style={{
          height: '34px',
          backgroundColor: `var(--main-color)`,
          textTransform: 'capitalize',
          width: '100px'
        }}
        onClick={onApply}
      >
        {orderDiscount?.discount ? 'Done' : 'Apply'}
      </FilledButton>
    </div>
  )

  return (
    <div className='draft-order-discount' ref={containerRef}>
      <div className='draft-order-discount-container'>
        <div className='draft-order-discount-amount'>
          <div className='section'>
            <p>Discount type</p>
            <Select
              isSearchable={false}
              onChange={setDiscountType}
              placeholder={'Amount'}
              menuPlacement='auto'
              value={discountType}
              styles={{
                control: (styles) => ({
                  ...styles,
                  boxSizing: 'border-box',
                  border: '1px solid #CCC3BD',
                  borderRadius: '8px',
                  marginTop: '10px'
                }),
                option: (styles, { isFocused, isSelected }) => {
                  return {
                    ...styles,
                    backgroundColor: isSelected
                      ? '#DCDCDC'
                      : isFocused
                        ? 'rgba(249,139,0,0.04)'
                        : null,
                  };
                },
              }}
              options={options}
            />
          </div>
          <div className='section'>
            <p>Discount value</p>
            <span
              symbol={discount ? discountType.value === DISCOUNT_TYPE.AMOUNT ? '$' : '%' : ''}
              className={`input-symbol` }
            >
              <input
                ref={discountRef}
                className='draft-order-discount-value'
                placeholder={discountPlaceholder}
              />
            </span>
          </div>
        </div>
        <div className='section'>
          <p>Reason</p>
          <input
            value={reason}
            className='draft-order-discount-reason'
            type='text'
            placeholder={'Reason'}
            onChange={onChangeReason}
          />
        </div>
        <p>Your clients can see this reason</p>
      </div>
      {discountFooter()}
    </div>
  )
}

export default forwardRef(OrderDiscount);
