import React, {useEffect, useState} from 'react';
import './AddToCalendar.scss';
import { logger } from '../../logging';

export const AddToCalendar = ({ button, hostId, icsDownloadUrl, showCloseButton = false, modalPosition = 'bottom', modalVisibility = false, containerStyle }) => {
    const [showAddToCalendarModal, setShowAddToCalendarModal] = useState(false);

    useEffect(() => {
        setShowAddToCalendarModal(modalVisibility)
    }, [modalVisibility])
    
    const toggleAddToCalendarModal = () => setShowAddToCalendarModal(!showAddToCalendarModal);
    
    const downloadGoogleEvent = async () => {
        try {
            let newWondow = window.open();
            if (icsDownloadUrl) {
                let url = icsDownloadUrl + `&google=true&hostId=${hostId}`;
                let res = await fetch(url);
                if (!res.ok) {
                    throw new Error('Failed to download Google Calendar Event');
                }
                let { googleCalendarUrl } = await res.json();
                newWondow.location = googleCalendarUrl;
            }
        } catch (error) {
            logger.error('downloadGoogleEvent', error);
        } finally {
            toggleAddToCalendarModal();
        }
    }

    const downloadIcs = async () => {
        try {
            if (icsDownloadUrl) {
                let url = icsDownloadUrl + `&hostId=${hostId}`;
                let icsData = await fetch(url);
                if (!icsData.ok) {
                    throw new Error('Failed to download Event ICS file');
                }
                let blobUrl = URL.createObjectURL(await icsData.blob());
                const a = document.createElement('a')
                a.href = blobUrl;
                a.download = 'invite.ics';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

            }
        } catch (error) {
            logger.error('downloadIcs', error);
        } finally {
            toggleAddToCalendarModal();
        }
    }

    return (
        <>
            {React.cloneElement(button, {
                onClick: toggleAddToCalendarModal
            })}
            <div className={`add-to-calendar-modal${showAddToCalendarModal ? ' visible' : ''} ${modalPosition}`} style={containerStyle}>
                <ul>
                    <li onClick={downloadGoogleEvent}>Google</li>
                    <li onClick={downloadIcs}>iCal</li>
                    <li onClick={downloadIcs}>Outlook</li>
                </ul>
                {showCloseButton && <button onClick={toggleAddToCalendarModal} className='add-to-calendar-modal-close-button'>
                    close
                </button>}
            </div>
        </>
    )
}
