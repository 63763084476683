import { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import useHostCalls from "./useHostCalls";

const usePendingCalls = () => {

  const {allCalls, isLoading} = useHostCalls((shopId) => {
    if (shopId) {
      return (
        firebase
          .firestore()
          .collection('shops')
          .doc(shopId)
          .collection('schedule')
          .where('status', '==', 'pending')
          .orderBy('startTimestamp', 'asc')
      )
    }
  }, [])

  return {
    pendingCalls: allCalls,
    pendingCallsLoading: isLoading,
  };
};

export default usePendingCalls;
