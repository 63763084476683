import React from 'react';
import IconImage from '@material-ui/icons/Image';
import {useSelector} from 'react-redux';

import AnimatedDots from "../../animatedDots";
import {useRoomIsTyping} from '../../../modules/chat/api/features/listeners';

import './styles.scss';

const RoomItem = ({item}) => {
  const { user: caazamUser } = useSelector((state) => state.user);
  const { roomTyping } = useRoomIsTyping(item.id);
  const remoteTyping = roomTyping && caazamUser && Object.keys(roomTyping).filter(id => id !== caazamUser.id).find(id => !!roomTyping[id]);

  if (remoteTyping) {
    return (
      <div className='row'>
        <h5>typing</h5>
        <AnimatedDots />
      </div>
    )
  }

  if (!!item && !!item.metadata && !!item.metadata.lastMessage && item.metadata.lastMessage.type === 'text') {
    const textLenght = item.metadata.lastMessage.text.length;
    const finalText = textLenght > 27
      ? `${(item.metadata.lastMessage.text).substring(0, 27)} ...`
      : item.metadata.lastMessage.text;

    return (
      <h5>{finalText}</h5>
    )
  }

  if (!!item && !!item.metadata && !!item.metadata.lastMessage && item.metadata.lastMessage.type === 'image') {
    return (
      <div className='image'>
        <h5>image</h5>
        <IconImage className='icon' />
      </div>
    )
  }

  return (
    <>
      {
        (!!item && !!item.metadata && !!item.metadata.lastMessage && !!item.metadata.lastMessage.text)
          ? <h5>{item.metadata.lastMessage.text}</h5>
          : null
      }
    </>
  )
};

export default RoomItem;
