import React, { useEffect } from "react";
import './call-details-side-bar.scss';
import Moment from "moment-timezone";
import {Close} from '@material-ui/icons';
import CallDetailsTabs from "./call-details-tabs/call-details-tabs";
import DraftOrderModal from "../../../components/Modals/draft-order-modal/DraftOrderModal";
import {CALL_DETAILS_TYPE, CALL_STATUS, CALL_TYPES} from "../../../utils/consts";
import {errorBadgeType} from "../../../utils";
import CallEventType from "../calendar-row/components/badges/call-event-type";
import IconDraftOrder from "../../../assets/icons/DraftOrder";
import {FilledButton} from "../../../components";
import ReactTooltip from "react-tooltip";
import {useCallDetailsProvider} from "./call-details-provider/call-details-provider";
import {normalizeCartProduct} from "../../../utils/draftOrder";
import useShopHosts from '../../../hooks/useShopHosts';

const CallDetailsSideBar = ({feedbackType, onOpenBlockOffTime}) => {
  const {
    draftOrder,
    selectedTab,
    abandonedCartItems,
    onOpenDraftOrder,
    onHideDraftOrder,
    postCallProducts,
    cart,
    isSupportDraftOrder,
    callDraftOrderId,
    openDraftOrder,
    client,
    callDetails,
    sidebarAnimation,
    toggleSidebarModal,
    onAnimationEnd,
    products,
    emailEvents,
    onShowEmailEvents
  } = useCallDetailsProvider()

  const {
    customerName,
    title,
    customerEmail,
    timestamp,
    duration,
    customerPhone,
    error,
    description,
    status,
    eventType,
    type,
    orders,
    checkouts,
    draftOrders,
    host,
    shopId,
    hostId
  } = callDetails;

  const { hosts } = useShopHosts(shopId);

  useEffect(() => {
    return () => {
      onHideDraftOrder()
    }
  }, [callDetails?.id])

  useEffect(() => {
    if (Object.keys(draftOrders ?? {}).length === 0) {
      onHideDraftOrder()
    }
  }, [draftOrders])

  const hostFullName = () => {
    let currentHost = host
    if (type === CALL_TYPES.SCHEDULED) {
      currentHost = hosts.find(({id}) => id === hostId)
    }
    if (currentHost) {
      let fullName = currentHost.firstName;
      if (currentHost.lastName) {
        fullName += ` ${currentHost.lastName}`
      }
      return fullName
    }
    return ''
  }

  const header = () => (
    <div className='call-details-sidebar-header'>
      <div className='call-duration-container'>
        <p className='customer-name'>{customerName ?? title}</p>
        <button onClick={() => toggleSidebarModal(null)}>
          <Close/>
        </button>
      </div>
      <div className='call-duration-container'>
        <p
          className={`customer-info email ${emailEvents?.length ? 'clickable' : ''}`}
          onClick={emailEvents?.length ? onShowEmailEvents : null}
        >
          {customerEmail}
        </p>
        <p className='customer-info'>
          {Moment(timestamp).format('DD MMM YYYY, LT')}{duration && ` | ${Math.ceil(duration / 60)} min`}
        </p>
      </div>
      <p className='customer-info right'>{`${hostFullName()}`}</p>
      <p className='customer-info'>{customerPhone}</p>
    </div>
  )

  const errorMessageComponent = () => {
    return (
      <div className='call-details-error-component'>
        <span className='error-call-outcome'>
          {errorBadgeType(error)}
        </span>
      </div>
    )
  }

  const blockEventContainer = () => (
    <div className='block-event-wrapper'>
      <div className='description-wrapper'>
        <p>{description}</p>
      </div>
      <FilledButton
        onClick={onOpenBlockOffTime}
        isLoading={false}
        disabled={false}
        className={'button'}
        style={{
          width: '100%',
          marginHeight: 12
        }}
      >
        {'Edit'}
      </FilledButton>
    </div>
  )

  const onPressDraftOrderButton = () => {
    if (cart && cart.products?.some(({variantId}) => !variantId)) {
      return () => null
    }
    onOpenDraftOrder((abandonedCartItems?.products ?? cart?.products)?.map(normalizeCartProduct))
  }

  const draftOrderButton = () => {
    const isDisabledButton = cart && cart.products?.some(({variantId}) => !variantId)
      || products.some(({variantId}) => !variantId)
    return isSupportDraftOrder
      && Object.keys(orders ?? {}).length === 0
      && Object.keys(draftOrders ?? {}).length === 0
      && !draftOrder && (
        <>
          <ReactTooltip
            id="draftOrderTooltip"
            className='processor-tooltip'
            effect="solid"
            type='warning'
            place='left'
            multiline={true}
            backgroundColor='#6e6e6e'
            disable={!isDisabledButton}
          >
            <span>
              Choose product variants in a cart <br/>to create draft order
            </span>
          </ReactTooltip>
          <div
            data-tip
            data-for="draftOrderTooltip"
            className={`cart-details-action-row${isDisabledButton ? ' disabled' : ''}`}
            onClick={onPressDraftOrderButton}
          >
            <IconDraftOrder color={isDisabledButton ? 'rgba(0, 0, 0, 0.32)' : '#6E76F2'} />
            <p>{'Create draft order'}</p>
          </div>
        </>
      )
  }

  const callDetailContainer = () => (
    <>
      <div className='badge-row'>
        {status === CALL_STATUS.ERROR && errorMessageComponent()}
        {eventType && <CallEventType eventType={eventType}/>}
        {draftOrderButton()}
      </div>
      <div className={`call-details-sidebar-wrapper${selectedTab === CALL_DETAILS_TYPE.INFO ? ' scroll' : ''}`}>
        <CallDetailsTabs feedbackType={feedbackType} />
      </div>
      <DraftOrderModal
        visibility={openDraftOrder}
        customer={client}
        onClosePress={onHideDraftOrder}
        initialProducts={postCallProducts}
        initialDraftOrderId={callDraftOrderId}
        callData={callDetails}
      />
    </>
  )

  return (
    <div className={`call-details-sidebar-container ${sidebarAnimation}`} onAnimationEnd={onAnimationEnd}>
      {header()}
      {type !== CALL_TYPES.BLOCKED_EVENT ? callDetailContainer() : blockEventContainer()}
    </div>
  )
}

export default CallDetailsSideBar;
