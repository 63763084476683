import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { useDocumentData } from 'react-firebase-hooks/firestore'

export default function useShopLocales(shopId) {
	
	const ref = shopId ? firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('shopLocales') : null;
	const [ shopLocales, shopLocalesLoading, shopLocalesError ] = useDocumentData(ref);
	shopLocalesError && console.error('useShopLocales', shopLocalesError);

	// NOTE: the primary locale or any of the other locales might not be supported by our apps
	const primaryLocale = shopLocales && shopLocales.primary ? shopLocales.primary.locale : 'en';

	return {primaryLocale, shopLocales: shopLocales ? shopLocales.shopLocales : null,  shopLocalesLoading }		
}
