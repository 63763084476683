// rrule will always return dates derevied from rules in local browser timestamp although it will use Z offset.
import RRule from "rrule";

export const convertRRuleInstance = (instanceDate) => {
  if (!instanceDate) return null;

  return new Date(
    instanceDate.getUTCFullYear(),
    instanceDate.getUTCMonth(),
    instanceDate.getUTCDate(),
    instanceDate.getUTCHours(),
    instanceDate.getUTCMinutes(),
    instanceDate.getUTCSeconds(),
  )
}

export const convertDateInstance = (instanceDate) => {
  if (!instanceDate) return null;

  return new Date(Date.UTC(
    instanceDate.getFullYear(),
    instanceDate.getMonth(),
    instanceDate.getDate(),
    instanceDate.getHours(),
    instanceDate.getMinutes(),
    instanceDate.getSeconds(),
  ))
}

// rrule always wants dates in UTC format (Z offset) even though the dates/times will be in local timezone.
export const convertToRRuleDate = (momentDate) => {
  if (!momentDate) return null;
  return new Date(Date.UTC(momentDate.year(), momentDate.month(), momentDate.date(), momentDate.hours(), momentDate.minutes(), momentDate.seconds()));
}

export const convertDayToRRule = (day) => {
  // in rrule monday is 0 while in moment js sunday is 0
  if (day === 0) return RRule.SU;
  return day - 1;
}