import React, {useState, useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import ChatMenu from './chat-menu';
import ChatContent from './chat-content';
import {useHostChatData} from '../../../modules/chat/hooks/useHostChatData';
import {setActiveRoomId} from '../../../modules/chat/actions/activeRoom';
import {buildUserEntity} from '../../../modules/chat/utils/auth';
import usePrevious from "../../../modules/chat/hooks/usePreviousState";
import {useNotificationProvider} from "../../../components/notifications/NotificationProvider";

const Chat = ({ isChatVisible, setAvailability, setIsChatVisible }) => {
	const dispatch = useDispatch();
  const { user: caazamUser } = useSelector((state) => state.user);

  const { activeShopId } = useSelector((state) => state.shops);
  const { id: roomId } = useSelector((state) => state.activeRoom);
  const userEntity = useMemo(() => buildUserEntity(caazamUser), [caazamUser])
  const {pendingRooms, activeRooms} = useHostChatData(userEntity, activeShopId);
  const [selectedRoomId, setSelectedRoomId] = useState(null);

  const [messageValue, setMessageValue] = useState('');

  const prevShopId = usePrevious(activeShopId);
  const { setRouteForNotifications } = useNotificationProvider();

  const currentActiveRoom =  activeRooms && selectedRoomId ? activeRooms.find(item => item.id === selectedRoomId) : null ;
  const currentPendingRoom =  pendingRooms && selectedRoomId ? pendingRooms.find(item => item.id === selectedRoomId) : null;
  const currentSelectedRoom = currentActiveRoom || currentPendingRoom || null;

  useEffect(() => {
    if (isChatVisible) {
      const firstActiveRoom = activeRooms && activeRooms.length > 0 ? activeRooms[0] : null;
      //const firstPendingRoom = pendingRoom && pendingRoom.length > 0 ? pendingRoom[0] : null;

      // if no room is selected than try to show first active room
      if (!selectedRoomId) {
        if (firstActiveRoom) {
          setMessageValue('');
          setSelectedRoomId(firstActiveRoom.id);
        }
      } else if (currentSelectedRoom && (currentSelectedRoom.id !== roomId || !roomId)) {
        setMessageValue('');
        dispatch(setActiveRoomId(currentSelectedRoom.id));
      }
    }
  }, [selectedRoomId, currentSelectedRoom, isChatVisible]);


  useEffect(() => {
    if (prevShopId !== activeShopId) {
      dispatch(setActiveRoomId(null));
      setSelectedRoomId(null);
    }
  }, [activeShopId]);

	return (
		<>
			{isChatVisible && (
        <>
					<ChatMenu
            setRouteForNotifications={setRouteForNotifications}
					  user={userEntity}
					  setIsChatVisible={setIsChatVisible}
						pendingRooms={pendingRooms}
						activeRooms={activeRooms}
						setSelectedRoom={setSelectedRoomId}
						selectedRoom={currentSelectedRoom}
					/>
          <ChatContent
            setIsChatVisible={setIsChatVisible}
            setAvailability={setAvailability}
						messageValue={messageValue}
						setMessageValue={setMessageValue}
            selectedRoom={currentSelectedRoom}
            setSelectedRoom={setSelectedRoomId}
					/>
        </>
			)}
		</>
	);
};

export default Chat;
