import * as React from "react"

const ArrowForwardIosRounded = (props) => (
  <svg
    viewBox="0 0 24 24"
    aria-hidden="true"
    fill={props?.fill}
    style={props?.style}
    className={props?.className}
    {...props}
  >
    <path d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31a.996.996 0 0 0 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z" />
  </svg>
)

export default ArrowForwardIosRounded
