import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  callId: null,
  callDuration: 0,
  shopId: null,
  dailyDisconnect: false,
};

const prevCallSlice = createSlice({
  name: 'prevCall',
  initialState,
  reducers: {
    setPrevCall(state, { payload }) {
      state.callId = payload.callId;
      state.callDuration = payload.callDuration;
      state.shopId = payload.shopId;
    },
    resetPrevCall(state) {
      state.callId = null;
      state.callDuration = 0;
      state.shopId = null;
    },
    setPrevCallDailyDisconnect(state, { payload }) {
      state.dailyDisconnect = payload;
    }
  },
});

export const { setPrevCall, resetPrevCall, setPrevCallDailyDisconnect} = prevCallSlice.actions;

export default prevCallSlice.reducer;
