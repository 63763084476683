import {useEffect, useState} from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import {useDispatch} from "react-redux";
import {updateDraftOrderId} from "../reducers/active-call-reducer";

const useDraftOrder = (shopId, draftOrderId, isRemoveEvent) => {
  const dispatch = useDispatch()
  const [draftOrder, setDraftOrder] = useState(null)

  useEffect(() => {
    if (draftOrderId) {
      const draftOrderRef = firebase
        .firestore()
        .collection('shops')
        .doc(shopId)
        .collection('draftOrders')
        .doc(draftOrderId)
        .onSnapshot((snapshot) => {
          if (snapshot) {
            const draftOrder = snapshot.data();
            if (draftOrder) {
              dispatch(updateDraftOrderId({draftOrderId}))
              setDraftOrder(draftOrder)
            }
          }
        })
      return () => {
        draftOrderRef()
        setDraftOrder(null)
      }
    }
  }, [draftOrderId])

  useEffect(() => {
    if (isRemoveEvent) {
      dispatch(updateDraftOrderId({draftOrderId: null}))
      setDraftOrder(null)
    }
  }, [isRemoveEvent])

  return {
    draftOrder,
  }
}

export default useDraftOrder;