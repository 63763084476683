import moment from 'moment';
import { CALL_TYPES } from './consts';

export const parsePastScheduledCalls = (callData) => {
  const {
    shopId,
    customer,
    clientForm,
    startTimestamp,
    endTimestamp,
    callLinkUrl,
    createdBy,
    createdFor,
    icsDownloadUrl,
    status,
    message,
    eventType,
    scheduleId,
    title,
    description,
    showroomId,
    hostId
  } = callData;

  return {
    shopId,
    id: callData.id,
    timestamp: startTimestamp.toDate(),
    duration: moment(endTimestamp.toDate()).diff(
      moment(startTimestamp.toDate()),
      'seconds'
    ),
    endTimestamp: endTimestamp.toDate(),
    customerName: customer?.name,
    customerEmail: customer?.email,
    customerPhone: customer?.phone,
    customerTimezone: customer?.timezone,
    customerMessage: message,
    clientForm,
    callLinkUrl,
    createdBy,
    createdFor,
    status,
    type: customer ? CALL_TYPES.SCHEDULED : CALL_TYPES.BLOCKED_EVENT,
    eventType,
    scheduleId,
    title,
    description,
    icsDownloadUrl,
    showroomId,
    hostId
  };
}

export const parsePastCompletedCalls = (callData) => {
  const {
    shopId,
    timestamp,
    videoDuration,
    status,
    customerName,
    customerEmail,
    totalOrderAmount,
    totalOrderAmountCurrency,
    error,
    checkouts = {},
    orders,
    callFeedback,
    abandonedCart,
    draftOrders,
    host,
    eventType,
    scheduleId,
    countryCode,
    carts,
    callShowroomId,
    insightsFlag
  } = callData;
  return {
    shopId,
    id: callData.id,
    timestamp: timestamp?.toDate(),
    duration: videoDuration,
    status,
    customerName,
    customerEmail,
    totalOrderAmount,
    totalOrderAmountCurrency,
    error,
    checkouts,
    orders,
    type: CALL_TYPES.HISTORY,
    callFeedback,
    abandonedCart,
    draftOrders,
    host,
    eventType,
    scheduleId,
    countryCode,
    carts,
    callShowroomId,
    insightsFlag
  };
}

export const parseScheduleCalls = (callData) => {
  const {
    shopId,
    customer,
    message,
    clientForm,
    startTimestamp,
    endTimestamp,
    callLinkUrl,
    createdBy,
    createdFor,
    icsDownloadUrl,
    eventType,
    scheduleId,
    title,
    description,
    showroomId,
    status,
    hostId,
  } = callData;

  return {
    shopId,
    id: callData.id,
    timestamp: startTimestamp.toDate(),
    duration: moment(endTimestamp.toDate()).diff(
      moment(startTimestamp.toDate()),
      'seconds'
    ),
    endTimestamp: endTimestamp.toDate(),
    customerName: customer?.name,
    customerEmail: customer?.email,
    customerPhone: customer?.phone,
    customerTimezone: customer?.timezone,
    customerMessage: message,
    clientForm,
    callLinkUrl,
    createdBy,
    createdFor,
    status,
    type: customer ? CALL_TYPES.SCHEDULED : CALL_TYPES.BLOCKED_EVENT,
    eventType,
    scheduleId,
    title,
    description,
    icsDownloadUrl,
    showroomId,
    hostId
  };
}
