const loadScript =  (id = null, jsSrc = null, cb,) => {
  if (jsSrc === null) {
    console.error('Script loading error. jsSrc=', jsSrc)
    return;
  }

  if (id === null) {
    id = new Date().valueOf();
  }

  const tag = 'script';

  const handleError = (error) => console.error(error)

  const element = document.getElementsByTagName(tag)[0]
  const fjs = element
  let js = element
  js = document.createElement(tag)
  js.id = id
  js.src = jsSrc
  js.setAttribute('defer','');
  js.setAttribute('async','');
  if (fjs && fjs.parentNode) {
    fjs.parentNode.insertBefore(js, fjs)
  } else {
    document.head.appendChild(js)
  }
  js.onerror = handleError
  js.onload = cb
}

const removeScript = (id) => {
  const element = document.getElementById(id)

  if (element) {
    element.parentNode.removeChild(element)
  }
}

export {
  loadScript,
  removeScript,
}
