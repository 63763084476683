import React from 'react';
import {ShowroomPresetProvider} from './preset-provider/preset-provider';
import {
  CallDetailsProvider
} from '../pages/home-page/call-details-side-bar/call-details-provider/call-details-provider';
import ActiveCallProvider from '../components/ActiveCallProvider';
import DraftOrderInvoiceProvider from '../components/Modals/invoice-modal/InvoiceModal';
import NotificationProvider from '../components/notifications/NotificationProvider';
import VideoConfigurationProvider from '../components/VideoConfigurationProvider';
import AppDiagnosticsProvider from '../pages/home-page/host-setting/AppDiagnosticsProvider';
import HostSettingsProvider from '../components/hostSettingsProvider/host-settings-provider';

const combineComponents = (...components) => components.reduce(
  (AccumulatedComponents, CurrentComponent) => ({ children }) => (
    <AccumulatedComponents>
      <CurrentComponent>{children}</CurrentComponent>
    </AccumulatedComponents>
  ),
  ({ children }) => <>{children}</>,
);

export const AppContextProvider = combineComponents(
  CallDetailsProvider,
  ActiveCallProvider,
  ShowroomPresetProvider,
  DraftOrderInvoiceProvider,
  NotificationProvider,
  VideoConfigurationProvider,
  AppDiagnosticsProvider,
  HostSettingsProvider
);