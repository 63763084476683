import React from 'react';
import { currencyFormatter, percentFormatter } from "../../../utils";
import { CompletedCallsChart, NPSScoreChart, CSATScoreChart ,TotalSalesChart } from "../charts";
import { FEEDBACK_TYPE } from '../../../utils/consts';

export const kpiSalesModel = (shopData, dailySummary, dataForChart) => {
  return {
    header: {
      label: 'Total Sales',
      value: shopData &&
        currencyFormatter(
          dailySummary.ordersTotal,
          shopData.currencyCode
        )
    },
    items: kpiSalesSectionItems(dailySummary, shopData),
    chart: <TotalSalesChart
      data={dataForChart}
      currencyCode={shopData && shopData.currencyCode}
    />
  }
}

export const kpiCallsModel = (dailySummary, dataForChart, averageCallTimeStr, scheduledCallsCount) => {
  return {
    header: {
      label: 'Completed Calls',
      value: dailySummary.callsCompleted
    },
    items: kpiCallsSectionItems(dailySummary, averageCallTimeStr, scheduledCallsCount),
    chart: <CompletedCallsChart
      data={dataForChart}
    />
  }
}

export const kpiRatingModel = (feedbackType, callsHistorySummary) => {
  
  const getCallsRating = () => {
    let result = 'N/A';
    if (callsHistorySummary && callsHistorySummary.totalCallsRated !== null && callsHistorySummary.totalCallsRated !== 0) {
      result = (callsHistorySummary.totalRating / callsHistorySummary.totalCallsRated).toFixed(2);
    }
    return result;
  }

  const getNPSScore = () => {
    let result = 'N/A';
    const promoters = callsHistorySummary.totalNPSPromoters;
    const count = callsHistorySummary.totalCallsNPSRated;
    const detractors = callsHistorySummary.totalNPSDetractors;

    if (count !== 0) {
      result = Math.round((promoters - detractors) / count * 100);
    }
    return result;
  }

  const getCSATScore = () => {
    let result = 'N/A';
    const promoters = callsHistorySummary.totalCSATPromoters;
    const count = callsHistorySummary.totalCallsCSATRated;

    if (count !== 0) {
      result = Math.round((promoters / count) * 100);
    }
    return result;
  }

  const getFeedbackScore = () => {
    if (!callsHistorySummary) return;
    switch (feedbackType) {
      case FEEDBACK_TYPE.NPS:
        return getNPSScore();
      case FEEDBACK_TYPE.CSAT:
        return getCSATScore();
      case FEEDBACK_TYPE.SIMPLE:
        return getCallsRating();
      case FEEDBACK_TYPE.NONE:
      default:
        return;
    }
  }

  const getFeedbackLabel = () => {
    switch (feedbackType) {
      case FEEDBACK_TYPE.NPS:
        return '30-Day NPS Score';
      case FEEDBACK_TYPE.CSAT:
        return '30-Day CSAT Score';
      case FEEDBACK_TYPE.SIMPLE:
        return '30-Day Avg Rating';
      case FEEDBACK_TYPE.NONE:
      default:
        return '';
    }

  }

  const getFeedbackChart = () => {
    switch (feedbackType) {
      case FEEDBACK_TYPE.NPS:
        return <NPSScoreChart value={callCalculatedScore}/>;
      case FEEDBACK_TYPE.CSAT:
        return <CSATScoreChart value={callCalculatedScore}/>;
      case FEEDBACK_TYPE.SIMPLE:
      case FEEDBACK_TYPE.NONE:
      default:
        return null;
    }

  }

  let callCalculatedScore = getFeedbackScore();

  return {
    header: {
      label: getFeedbackLabel(),
      value: callCalculatedScore,
    },
    items: [],
    chart: callCalculatedScore !== 'N/A' ? getFeedbackChart() : null,
  }
}


const kpiSalesSectionItems = (dailySummary, shopData) => {
  return [
    {
      label: 'Number of orders',
      value: dailySummary.ordersCount
    },
    {
      label: 'Conversion Rate',
      value: dailySummary.callsCompleted
        ? percentFormatter().format(
          dailySummary.ordersCount / dailySummary.callsCompleted
        )
        : 'N/A'
    },
    {
      label: 'AOV',
      value: dailySummary.ordersCount && shopData
        ? currencyFormatter(
          dailySummary.ordersTotal / dailySummary.ordersCount,
          shopData.currencyCode
        )
        : 'N/A'
    },
  ]
}

const kpiCallsSectionItems = (dailySummary, averageCallTimeStr, scheduledCallsCount) => {

  return [
    {
      label: 'Average call time',
      value: averageCallTimeStr
    },
    {
      label: 'Scheduled calls left',
      value: scheduledCallsCount
    },
    {
      label: 'Missed calls',
      value: dailySummary.callsMissed
    },

  ]
}
