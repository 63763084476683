import {Cart} from "../../../../../assets/icons/Cart";
import {ExclamationPoint} from "../../../../../assets/icons/ExclamationPoint";
import React from "react";
import './call-badges.scss'

const CartAbandoned = () => {
  return (
    <span className='call-outcome abandoned'>
      <Cart />
      <p>Abandoned</p>
      <span className='alert-point-container'>
        <ExclamationPoint />
      </span>
    </span>
  )
}

export default CartAbandoned