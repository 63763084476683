import React, { useEffect, useState } from 'react';
import LocalMallIcon from '../../../../assets/icons/LocalMall';
import { useActiveCallProvider } from '../../../../components/ActiveCallProvider';
import { useSessionProducts } from '../../../../hooks';
import ImageWithOverlay from '../../../../components/image-with-overlay/image-with-overlay';

const ShowRoom = ({ viewItem, clientViewData }) => {
  const { activeCallId: contextId} = useActiveCallProvider();
  const { sessionSnapshots, removeSessionProduct } = useSessionProducts(contextId);
  const [ clientViewingState, setClientViewingSate ] = useState({});

  const removeItem = (productKey) => {
    removeSessionProduct(productKey);
  };

  useEffect(() => {
    const isItemViewedByClients = (showroomKey) => {
      var views = [];
      Object.keys(clientViewData).forEach(clientUuid => {
        const clientProductView = clientViewData[clientUuid] && clientViewData[clientUuid].productView || {};
        if (clientProductView.showroomKey === showroomKey) {
          views.push(clientUuid);
        }
      })
      return views;
    }

    if (sessionSnapshots) {
      let update = {};
      sessionSnapshots.forEach(snap=>{
        update[snap.key] = isItemViewedByClients(snap.key);
      });
      setClientViewingSate(update);
    }

  }, [sessionSnapshots, clientViewData])

  return (
    <>
      {sessionSnapshots && sessionSnapshots.length ? (
        <div className='product-row-wrapper'>
          <div className='product-row'>
            {sessionSnapshots.map((snapshot) => {
              const product = snapshot.val();
              return (
                <div className='product-container' key={snapshot.key}>
                  <ImageWithOverlay
                    isAdaptive={true}
                    imageUrl={product.image}
                    alt={product.productTitle}
                    viewFunction={() => viewItem(product.productId)}
                    removeFunction={() => removeItem(snapshot.key)}
                    highlighted={clientViewingState[snapshot.key] && clientViewingState[snapshot.key].length > 0}
                  />
                </div>
              );
            })}
          </div>
        </div>

      ) : (
        <div className='empty-showroom'>
          <LocalMallIcon style={{ fontSize: '50px', marginBottom: '25px' }} />
          <p>
            Search for products and add them to client's Showroom. The client
            can also add products she/he is interested in
          </p>
        </div>
      )}
    </>
  );
};

export default ShowRoom;
