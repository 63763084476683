import CameraAltIcon from "@material-ui/icons/CameraAlt";
import React from "react";

import './styles.scss';

const SIZES_KEY = {
  XS: 'xs',
  S: 's',
  M: 'm',
  L: 'l',
  XL: 'xl',
  ADAPTIVE: 'adaptive'
};

const getImageSize = (size) => {
  switch (size) {
    case SIZES_KEY.ADAPTIVE: return 'adaptive'
    case SIZES_KEY.M: return 'm-size'
    case SIZES_KEY.L: return 'l-size'
    case SIZES_KEY.XL: return 'xl-size'
    case SIZES_KEY.XS: return 'xs-size'
    case SIZES_KEY.S:
      default:
      return 's-size';
  }
}

const EmptyImage = ({viewItem = () => {}, product, size = 's', snapshot = null, label = null, highlighted = false, clickAction = null}) => {

  const action = () => {
    if (clickAction !== null) {
      clickAction();
    }

    if (snapshot !== null) {
      viewItem(product, snapshot.key, product.variantId)
    } else {
      viewItem(product ? product.productId : null);
    }
  }

  return (
    <div className={`empty-image ${getImageSize(size)} ${highlighted ? 'highlighted' : ''}`} onClick={action}>
      {
        label !== null
          ? <p>{label}</p>
          : <CameraAltIcon className={`camera-alt-icon ${getImageSize(size)}`} />
      }
    </div>
  );
}

export default EmptyImage;
