import React from "react";

export const ExclamationPoint = () =>
<svg 
    xmlns="http://www.w3.org/2000/svg"
    width="16px" 
    height="16px" 
    viewBox="0 0 16 16" 
    version="1.1" >
    <g id="badge/exclamation-point" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group">
            <rect id="Rectangle" fill="#FFFFFF" opacity="0" x="0" y="0" width="16" height="16"></rect>
            <path d="M8,15 C11.8659833,15 15,11.8659833 15,8 C15,4.134005 11.8659833,1 8,1 C4.134005,1 1,4.134005 1,8 C1,11.8659833 4.134005,15 8,15 Z" id="Path" stroke="#CF7B35" fill="#FFE5D0"></path>
            <g id="!" transform="translate(7.056000, 3.660000)" fill="#CF7B35" fillRule="nonzero">
                <path d="M1.56,0 L1.404,5.82 L0.264,5.82 L0.108,0 L1.56,0 Z M0.876,8.424 C0.628,8.424 0.42,8.34 0.252,8.172 C0.084,8.004 0,7.796 0,7.548 C0,7.3 0.084,7.092 0.252,6.924 C0.42,6.756 0.628,6.672 0.876,6.672 C1.116,6.672 1.32,6.756 1.488,6.924 C1.656,7.092 1.74,7.3 1.74,7.548 C1.74,7.796 1.656,8.004 1.488,8.172 C1.32,8.34 1.116,8.424 0.876,8.424 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>
