import { useState, useEffect, useCallback  } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import Moment from 'moment-timezone';
import useCaazamREST from './useCaazamREST';
import { TWILIO_AUTO_ROOM_COMPLETE_TO_HOURS} from '../utils/consts';

export default function useConnectedCall(userId, deviceId) {
  const [connectedCallData, setConnectedCallData] = useState(null);
  const  { rejoinCall: rejoinREST, teardownCall: teardownREST } = useCaazamREST();

  useEffect(() => {
    const startTime = Moment().subtract(TWILIO_AUTO_ROOM_COMPLETE_TO_HOURS, 'hours').toDate();
    if (userId) {
      const unsub = firebase
        .firestore()
        .collection('calls')
        .where('host.id', '==', userId)
        .where('status', '==', 'connected')
        .where('host.deviceId','==',deviceId)
        .where('timestamp', '>=', startTime)
        .limit(1)
        .onSnapshot(
          (snapshot) => {
            if (snapshot.docs?.[0]?.data()) {
              setConnectedCallData({
                id: snapshot.docs?.[0].id,
                ...snapshot.docs?.[0].data(),
              })
            } else {
              setConnectedCallData(null);
            }            
          },
          (error) => {
            console.error('useConnectedCall ERROR', error);
            setConnectedCallData(null);
          }
        );

      return () => unsub();
    }
  }, [userId]);


  const rejoinCall = useCallback(()=>{
    if (connectedCallData?.id) {
      return rejoinREST(connectedCallData.shopId, connectedCallData.id);
    }

  },[connectedCallData])

  const teardownCall = useCallback(()=>{
    if (connectedCallData?.id) {
      return teardownREST(connectedCallData.shopId, connectedCallData.id);
    }

  },[connectedCallData])

  return {
    connectedCallData,
    rejoinCall,
    teardownCall,
  };
}
