import React, { useState } from 'react';
import './showroom-presets-list.scss';
import Collapsible from '../../../collapsable/collapsible';
import ShowroomPresetItemHeader from '../showroom-preset-item-header/showroom-preset-item-header';
import ImageWithOverlay from '../../../image-with-overlay/image-with-overlay';
import { useShowroomPresetProvider } from '../../../../providers/preset-provider/preset-provider';

const ShowroomPresetsList = ({presets, onSelectPreset, selectedPresetId}) => {
  const {isEditPresetMode, onShowEditPresetModal} = useShowroomPresetProvider()
  const [hiddenPresets, setHiddenPresets] = useState([])

  const onToggleHeader = (preset) => {
    if (isEditPresetMode) {
      onShowEditPresetModal(preset)
    } else {
      if (hiddenPresets.some(id => id === preset.id)) {
        setHiddenPresets(prev => prev.filter(id => id !== preset.id))
      } else {
        setHiddenPresets(prev => [...prev, preset.id])
      }
    }
  }
  return (
    <div className='showroom-presets-list'>
      {presets.length ? (
        <ul>
          {presets?.map((preset) => (
            <li key={preset.id}>
              <Collapsible
                visible={!hiddenPresets.some(id => preset.id === id)}
                header={(
                  <ShowroomPresetItemHeader
                    isSelectedPreset={preset.id === selectedPresetId}
                    onToggle={() => onToggleHeader(preset)}
                    onAddPreset={() => onSelectPreset(preset)}
                    preset={preset}
                  />
                )}>
                <div className='product-view'>
                  {preset.products.map((product, key) => (
                    <div className='showroom-preset-product-item' key={key}>
                      <ImageWithOverlay
                        isAdaptive={true}
                        imageUrl={product.image}
                        alt={product.productTitle}
                      />
                      {product.image && <p className='product-title'>{product.productTitle}</p>}
                    </div>
                  ))}
                </div>
              </Collapsible>
            </li>
          ))}
        </ul>
      ) : (
        <div className='empty-results'>
          <p>No saved showrooms - create a showroom for a call and save it for later</p>
        </div>
      )}
    </div>
  )
}

export default ShowroomPresetsList;