import React, {useEffect, useMemo, useState} from 'react';
import './call-showroom-tab.scss';
import {FilledButton} from "../../../../components";
import CallDetailsShowroomComponent from "./call-details-showroom-component/call-details-showroom-component";
import CallDetailsCartComponent from "./call-details-cart-component/call-details-cart-component";
import {currencyFormatter} from "../../../../utils";
import {DiscountInput} from "../../../../components/discount-input/DiscountInput";
import {useCallDetailsProvider} from "../call-details-provider/call-details-provider";

const ACTIVE_CONTENT = {
  SHOWROOM: 'showroom',
  CART: 'cart'
}

const CallShowroomTab = ({
  visible,
  showroomProducts = [],
  hideCreateCartButton,
  copyLabel,
  cart,
  isShowRoom
}) => {
  const {
    callDetails,
    products,
    onUpdateProducts,
    navigateToCartTab,
    onCreateCart,
    onUpdateCart,
    getFullProductInfo,
    setProducts,
    showroomLoading,
    showroomError
  } = useCallDetailsProvider()

  const [loader, setLoader] = useState(false)
  const [activeContent, setActiveContent] = useState(ACTIVE_CONTENT.SHOWROOM)
  const [errorMessage, setErrorMessage] = useState('')

  const {id, shopId} = callDetails;

  useEffect(() => {
    if (Object.keys(cart ?? {}).length > 0 && cart.callId === id) {
      if (products?.length === 0 && cart?.products?.length > 0) {
        onUpdateProducts(cart.products)
      } else {
        handleRefreshCartEvent()
      }
      setActiveContent(ACTIVE_CONTENT.CART)
    }
  }, [cart, callDetails?.id])

  useEffect(() => {
    return () => {
      setActiveContent(ACTIVE_CONTENT.SHOWROOM)
      setErrorMessage('')
    }
  }, [id])

  const handleRefreshCartEvent = () => {
    const deletedItem = products
      .filter(({variantId}) => variantId && !cart.products.some((cartProduct) => cartProduct.variantId === variantId))
      .pop()
    const newItem = cart?.products
      ?.filter(({variantId}) => !products.some((cartProduct) => cartProduct.variantId === variantId))
      ?.pop()
    if (!!deletedItem) {
      setProducts(products => products.filter(({variantId}) => variantId !== deletedItem.variantId))
    } else if (!!newItem) {
      setProducts(products => {
        if (products.some(({productId}) => newItem.productId === productId)) {
          return [
            ...products.filter(({productId}) => newItem.productId !== productId),
            newItem
          ]
        } else {
          return [...products, newItem]
        }
      })
    } else {
      setProducts(products => {
        return products.map(product => {
          const cartProduct = cart.products?.find(({variantId}) => variantId === product.variantId)
          const deletedItem = products
            .filter(({variantId}) => variantId && !cart.products.some((cartProduct) => cartProduct.variantId === variantId))
          if (deletedItem.length) {
            return
          }
          if (!!cartProduct) {
            return cartProduct
          }
          return product
        })
      })
    }
  }

  const disabledStateButton = () => {
    if (activeContent === ACTIVE_CONTENT.CART && !hideCreateCartButton) {
      if (products?.some(({variantId}) => !variantId)) {
        return true
      }
      if (products?.length !== (cart?.products?.length ?? 0)) {
        return false
      }
      if (!products?.map((product) => {
        const cartProducts = cart?.products?.find(({variantId}) => variantId === product.variantId)
        return cartProducts?.quantity === product?.quantity
      })?.some((isChangedQuantity) => !isChangedQuantity)
      ) {
        return true;
      }
    }
    return false;
  }

  const onPressButton = async () => {
    if (activeContent === ACTIVE_CONTENT.SHOWROOM) {
      setLoader(true)
      try {
        const {cartId} = await onCreateCart()
        const cartProducts = await getFullProductInfo()
        await onUpdateCart(cartId, cartProducts)
        navigateToCartTab(cartProducts)
      } catch (e) {
        setErrorMessage(e.message)
      } finally {
        setLoader(false)
      }
    }
  }

  const totalSum = useMemo(() => (
    products.reduce((sum, product) => sum + product.quantity * product.price, 0)
  ), [JSON.stringify(products)])

  const addProductToCart = async (product) => {
    const cartProduct = await getFullProductInfo([product])
    navigateToCartTab({...cartProduct.pop(), quantity: 1})
  }

  return (
    <div className={`call-showroom-tab${visible ? ' visible' : ''}`}>
      {isShowRoom ? (
        <CallDetailsShowroomComponent
          showroomProducts={showroomProducts}
          hideCreateCartButton={hideCreateCartButton}
          addProductToCart={addProductToCart}
        />
      ) : (
        <CallDetailsCartComponent
          cartUrl={`${cart?.cartRecoveryUrl}`}
          copyLabel={copyLabel}
        />
      )}
      {!hideCreateCartButton && !showroomLoading && !showroomError && (
        <div className='call-showroom-tab-footer'>
          {activeContent === ACTIVE_CONTENT.CART && (
            <>
              <DiscountInput cartId={cart.cartId} shopId={shopId} cartDiscount={cart.cartDiscount}/>
              <div className='call-showroom-tab-footer-total'>
                <p>Total</p>
                <p>{currencyFormatter(totalSum, cart?.cartCurrencyCode)}</p>
              </div>
            </>
          )}
          {activeContent === ACTIVE_CONTENT.SHOWROOM && (
            <FilledButton
              isLoading={loader}
              disabled={disabledStateButton()}
              className='button'
              style={{width: '45%', height: '46px', alignSelf: 'center'}}
              onClick={onPressButton}
            >
              {'Create cart'}
            </FilledButton>
          )}
          {errorMessage && (
            <p className={'error-message'}>{errorMessage}</p>
          )}
        </div>
      )}
    </div>
  )
}

export default CallShowroomTab;
