import React from 'react';
import { PropTypes } from 'prop-types';
import './styles.scss';

const Checkbox = ({ label, value, onChange }) => {
  return (
    <label className='checkbox-wrapper'>
      <input type="checkbox" checked={value} onChange={onChange}  />
      <svg
        className={`checkbox ${value ? "checkbox--active" : ""}`}
        aria-hidden="true"
        viewBox="0 0 15 11"
        fill="none"
      >
        <path
          d="M1 4.5L5 9L14 1"
          strokeWidth="2"
          stroke={value ? "var(--main-color)" : "none"}
        />
      </svg>
      {label}
    </label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
