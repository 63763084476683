import React, { useState, useEffect } from 'react';
import SizedConfetti from 'react-confetti';

const CONFETTI_COLORS = [
    '#CCC3BD',
    '#3B92D1',
    'var(--sherbert-color)',
    'var(--main-color)',
];

const AnimatedConfetti = ({ isConfettiVisible, ...props }) => {
    const [runState, setRunState] = useState(!!isConfettiVisible);
    const [recycleState, setRecycleState] = useState(!!isConfettiVisible);

    useEffect(() => {
        if (isConfettiVisible) {
            setRunState(true);
            setRecycleState(true);
        } else {
            setRecycleState(false);
        }

    }, [isConfettiVisible])

    const getRandomArbitrary = (min, max) => {
        return Math.random() * (max - min) + min;
    }

    const drawStar = (ctx) => {
        const numPoints = 5;
        const outerRadius = getRandomArbitrary(4, 6)
        const innerRadius = outerRadius / 2
        ctx.beginPath()
        ctx.moveTo(0, 0 - outerRadius)

        for (let n = 1; n < numPoints * 2; n++) {
            const radius = n % 2 === 0 ? outerRadius : innerRadius
            const x = radius * Math.sin((n * Math.PI) / numPoints)
            const y = -1 * radius * Math.cos((n * Math.PI) / numPoints)
            ctx.lineTo(x, y)
        }
        ctx.fill()
        ctx.closePath()
    }

    return (
        <div style={{
            position: 'absolute',
            overflow: 'hidden',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        }}>
            <SizedConfetti style={{ zIndex: 200 }} {...props} onConfettiComplete={() => setRunState(false)} numberOfPieces={500} colors={CONFETTI_COLORS} run={runState} recycle={recycleState} initialVelocityY={20} drawShape={drawStar} />
        </div>
    );
};

export default AnimatedConfetti;
