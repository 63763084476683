
import { useDocumentData } from 'react-firebase-hooks/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useShopBusinessHours(shopId) {

    const ref = shopId ? firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('businessHours') : null;
    const [businessHoursConfig, businessHoursLoading, businessHoursError] = useDocumentData(ref);
    return { businessHoursConfig, businessHoursLoading, businessHoursError }
}