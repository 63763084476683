import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import './ProductSection.scss'
import {DEFAULT_PRODUCT_RESULTS_LIMIT} from "../../../../../pages/video-room/components/sidebar/consts";
import {useActiveCallProvider} from "../../../../ActiveCallProvider";
import {useProducts, useProductSearch, useSessionCart} from "../../../../../hooks";
import {DRAFT_ORDER_STATUS} from "../../../../../constants/const";
import {normalizedShopifyProduct} from "../../../../../utils/draftOrder";
import CustomProduct from "./custom-product/CustomProduct";
import SearchProductItem from "../../../../productComponents/search-product-item/search-product-item";
import SearchInputDropdownField
  from "../../../../productComponents/search-input-dropdown-field/search-input-dropdown-field";
import SelectedProductItem from "../../../../productComponents/selected-product-item/selected-product-item";

const ProductSection = ({
  products,
  onUpdateProducts,
  cartProducts,
  onUpdateCartProducts,
  draftOrderStatus,
  cartSynced,
  activeCallId,
  onOpenProductModal,
  shopId
}, ref) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setSearching] = useState(null);
  const [isLoadMore, setLoadMore] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false)
  const [removedProductId, setRemovedProductId] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [updatedProductId, setUpdatedProductId] = useState(false)
  const [showCustomProduct, setShowCustomProduct] = useState(false)
  const [cursor, setCursor] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const [productSearchResults, setProductSearchResults] = useState(null);
  const { activeCallData } = useActiveCallProvider();

  const { getCachedProduct } = useProducts(
    shopId,
    activeCallId,
    activeCallData?.countryCode
  );
  const { searchProducts } = useProductSearch(
    shopId,
   activeCallData?.countryCode
  );

  const { activeCallId: contextId } = useActiveCallProvider();

  const {
    cartSnapshots,
  } = useSessionCart(contextId);

  const isDraftOrderCompleted = useMemo(() => (
    draftOrderStatus === DRAFT_ORDER_STATUS.COMPLETED
  ), [draftOrderStatus])

  useImperativeHandle(ref, () => ({
    onHideProductModals: () => {
      setUpdatedProductId(null)
      setShowCustomProduct(false)
    }
  }));

  useEffect(() => {
    if (cartSnapshots && cartSnapshots.length > 0) {
      const snapshotProducts = cartSnapshots.map(snapshot => ({product: snapshot.val(), snapshotKey: snapshot.key}))
      if (cartProducts.length > 0 && cartProducts.length > snapshotProducts.length) {
        setRemovedProductId(
          cartProducts
            .filter(cartProduct => (
              !snapshotProducts.some(snapshotProduct => snapshotProduct.product.variantId === cartProduct.variantId)
            ))
            .map(product => product.variantId)
            .pop()
        )
      }
      onUpdateCartProducts(snapshotProducts.map(({product, snapshotKey} )=> {
        return {
          ...product,
          price: product.currentPrice,
          quantity: product.quantity,
          title: product.variantTitle,
          productTitle: product.productTitle,
          snapshotKey
        }
      }))
    } else {
      if (cartProducts.length > 0) {
        setRemovedProductId(cartProducts[0].variantId)
        onUpdateCartProducts([])
      }
    }
  }, [cartSnapshots])

  //sync with cart if draft order not created
  useEffect(() => {
    if (!draftOrderStatus) {
      onUpdateProducts([
        //to apply discount from draft order
        ...cartProducts.map(cartProduct => {
          if (products.some(product => cartProduct.variantId === product.variantId)) {
            const {price, discount, compareAtPrice} = products.find(product => cartProduct.variantId === product.variantId)
            return {...cartProduct, price, discount, compareAtPrice}
          }
          return cartProduct
        }),
        //to add items not from the cart
        ...products
          .filter(product => !cartProducts.some(cartProduct => cartProduct.variantId === product.variantId))
          //remove items if we remove it on cart
          .filter(product => product.variantId !== removedProductId)
      ])
      setRemovedProductId(null)
    }
  }, [JSON.stringify(cartProducts)])

  const onAddCustomItem = () => {
    onOpenProductModal()
    setUpdatedProductId(null)
    setShowCustomProduct(true)
  }

  const onAddProduct = (product) => {
    setShowDropdown(false)
    setSearchTerm('')
    if (products.some((addedProduct) => addedProduct.variantId === product.variantId)) {
      onUpdateProducts(products
        .map(addedProduct => {
          if (addedProduct.variantId === product.variantId) {
            addedProduct.quantity += 1
          }
          return addedProduct
        }))
    } else {
      onUpdateProducts([...products, {...product, compareAtPrice: null}])
    }
  }

  const decrement = (variantId) => {
    onUpdateProducts(products
      .map((product) => {
        if (variantId === product.variantId) {
          product.quantity = Math.max(1, product.quantity - 1)
        }
        return product
      })
    )
  }

  const increment = (variantId) => {
    onUpdateProducts(products
      .map((product) => {
        if (variantId === product.variantId) {
          product.quantity += 1
        }
        return product
      })
    )
  }

  const updateProduct = (updatedProduct) => {
    onUpdateProducts(products
      .map((product) => {
        if (product.variantId === updatedProduct.variantId) {
          return {
            ...product,
            ...updatedProduct
          }
        }
        return product
      })
    )
  }

  const onRemoveProduct = (variantId) => {
    onUpdateProducts(products
      .filter((product) => variantId !== product.variantId)
    )
  }

  const handleSearchInput = (e) => {
    const value = e.target.value
    if (!value && showDropdown) {
      setShowDropdown(false)
    }
    setSearchTerm(e.target.value);
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      searchForItems();
    }
  };

  const searchForItems = () => {
    findProducts(searchTerm);
  };

  useEffect(() => {
    if (cursor && isLoadMore) {
      searchProducts(searchTerm, DEFAULT_PRODUCT_RESULTS_LIMIT, cursor)
        .then(result => {
          if (result.products.length > 0) {
            setCurrentPage(currentPage + 1)
            setProductSearchResults([...productSearchResults, ...result.products])
          }
          setCursor(result.cursor)
        })
        .catch(console.error)
        .finally(() => {
          setLoadMore(false)
        })
    }
  }, [cursor, isLoadMore])
  const loadMore =  () => {
    setLoadMore(true)
  }

  const findProducts = async (searchVal) => {
    if (!searchVal) return;
    if (!isSearching) {
      setShowDropdown(true)
      setSearching(true);
      try {
        const { products, cursor } = await searchProducts(searchTerm, DEFAULT_PRODUCT_RESULTS_LIMIT);
        setProductSearchResults(products)
        setCurrentPage(0)
        setCursor(cursor)
      } catch (err) {
        console.error(err);
      } finally {
        setSearching(false);
      }
    }
  };

  const hideAll = () => {
    setShowDropdown(false)
  }

  const openProductDiscount = (productId) => {
    setUpdatedProductId(productId)
    onOpenProductModal()
    setShowCustomProduct(false)
  }

  const onAddCustomProduct = (customProduct) => {
    onUpdateProducts([
      ...products,
      normalizedShopifyProduct(customProduct)
    ])
  }

  return (
    <div className='draft-order-products'>
      <div
        className={`click-outside${showDropdown ? ' visible' : ''}`}
        onClick={hideAll}
      />
      <div className='draft-order-products-header'>
        <h3>Products</h3>
        {!isDraftOrderCompleted && (
          <p className='custom-product-button' onClick={onAddCustomItem}>Add custom Item</p>
        )}
        {showCustomProduct && (
          <CustomProduct
            onHideModal={() => setShowCustomProduct(false)}
            onAddCustomProduct={onAddCustomProduct}
          />
        )}
      </div>
      <SearchInputDropdownField
        disabled={isDraftOrderCompleted}
        placeholder='Search for products'
        onChange={handleSearchInput}
        onKeyUp={handleKeyUp}
        onSearchForItems={searchForItems}
        loadMore={cursor ? loadMore : null}
        isLoading={isLoadMore}
        currentPage={currentPage}
        isSearching={isSearching}
        showDropdown={showDropdown}
        data={productSearchResults}
        value={searchTerm}
        renderItem={(product) => {
          return (
            <div key={product?.productId + 'searchProduct'}>
              <SearchProductItem
                product={product ?? {}}
                onAddProduct={products.length === 10 ? null : onAddProduct}
                getCachedProduct={getCachedProduct}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
              />
            </div>
          )
        }}
      />
      {products.map((product) => {
        return (
          <div
            key={product.variantId + 'selectedProduct'}
            style={{marginTop: '10px'}}
          >
            <SelectedProductItem
              showProductDiscount={updatedProductId === product.variantId}
              setChangedProductId={openProductDiscount}
              product={product}
              decrement={decrement}
              increment={increment}
              onRemoveProduct={onRemoveProduct}
              updateProduct={updateProduct}
              isDraftOrderCompleted={isDraftOrderCompleted}
            />
          </div>
        )
      })}
    </div>
  )
}

export default forwardRef(ProductSection);
