import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import useCaazamREST from './useCaazamREST';
import moment from 'moment';

const useScheduledSlots = (scheduleId, eventTypeId) => {
  const shopId = useSelector((state) => state.shops.activeShopId);
  const { getSlots } = useCaazamREST();
  const [availableSlots, setAvailableSlots] = useState(null);
  const [formWarningMessage, setFormWarningMessage] = useState(null);
  const [formErrorMessage, setFormErrorMessage] = useState(null);

  const slotsOrder = (slots) => {
    let slotsByDate = [];
    let currentDate = null;
    let currentDateSlots = [];
    slots.forEach((slot) => {
      let slotDate = moment(slot.start).format('dddd, MMM Do');
      if (slotDate !== currentDate) {
        if (!!currentDate) {
          slotsByDate.push({
            date: currentDate,
            slots: [...currentDateSlots],
          });
        }
        currentDateSlots = [];
        currentDate = slotDate;
      }
      currentDateSlots.push({
        isAvailable: slot.isAvailable,
        timeSlot: moment(slot.start),
        timeSlotEnd: moment(slot.end),
      });
    });
    // for last date (we've left the loop already)
    slotsByDate.push({ date: currentDate, slots: [...currentDateSlots] });
    return slotsByDate
  }


  useEffect(() => {
    const fetchSlots = async () => {
      try {
        const slotData = await getSlots(shopId, null, scheduleId, eventTypeId);
        setAvailableSlots(slotsOrder(slotData));
        if (slotData?.length === 0)
          setFormWarningMessage('No available scheduling slots. Please update your host availability settings');
        else
          setFormWarningMessage(null);
      } catch (e) {
        setFormErrorMessage(
          'To enable scheduling, please ask your Boutiq admin to configure business hours for your shop'
        );
      }
    }
    if (shopId) {
      fetchSlots()
    }
  }, [shopId, eventTypeId])

  return ({
    availableSlots,
    formWarningMessage,
    formErrorMessage
  })
}

export default useScheduledSlots;