import React from 'react';
import './product-details-skeleton.scss';
import LoadingCard from '../loading-card/loading-card';

const ProductDetailsSkeleton = () => {
  return (
    <div className='product-details-skeleton-container'>
      <div className='product-details-skeleton-left-side'>
        <LoadingCard
          marginLeft={0}
          width={172}
          height={260}
          marginBottom={10}
          borderRadius={10}
        />
        <div className='product-details-skeleton-variants'>
          {Array(4).fill(0).map((_, key) => (
            <div key={key}>
              <LoadingCard
                margin={0}
                marginRight={4}
                width={40}
                height={60}
              />
            </div>
          ))}
        </div>
      </div>
      <div className='product-details-skeleton-right-side'>
        <div>
          <LoadingCard
            marginLeft={0}
            width={'60%'}
            height={30}
          />
          {Array(5).fill(0).map((_, key) => (
            <div key={key}>
              <LoadingCard
                marginLeft={0}
                marginBottom={0}
                marginTop={4}
                width={'100%'}
                height={14}
              />
            </div>
          ))}
          <LoadingCard
            marginLeft={0}
            width={'60%'}
            height={30}
          />
          <LoadingCard
            marginLeft={0}
            marginTop={0}
            width={60}
            height={20}
          />
        </div>
        <LoadingCard
          margin={0}
          borderRadius={30}
          width={'100%'}
          height={60}
        />
      </div>
    </div>
  )
}

export default ProductDetailsSkeleton;
