import React, {useEffect} from 'react';
import storageFactory from "../utils/storageFactory";
import {useDispatch, useSelector} from "react-redux";
import {setAVPermissionsStatus, setShowPermissionsModal, setShowPermissionsWarning} from "../reducers/system-reducer";
import {PERMISSIONS_STATUS} from "../constants/permissions";

export default function usePermissions() {

  const AV_PERMISSIONS_KEY = 'boutiq-av-permissions-modal';

  const localStore = storageFactory(() => localStorage);
  const { avPermissions: { status, showPermissionsModal, showPermissionsHelperModal, showPermissionsWarning } } = useSelector(state => state.system);

  const dispatch = useDispatch();

  useEffect(() => {
    checkBrowserPermissions();
  }, []);

  useEffect(() => {
    const avPermissions = localStore.getItem(AV_PERMISSIONS_KEY);
    switch (status) {
      case PERMISSIONS_STATUS.PROMPT: {
        if (!avPermissions) { 
          if (showPermissionsWarning) {
            dispatch(setShowPermissionsWarning(false));
          }
          if (!showPermissionsModal) {
            dispatch(setShowPermissionsModal(true));
            localStore.setItem(AV_PERMISSIONS_KEY, true);
          }
        } else {
          if (!showPermissionsWarning) {
            dispatch(setShowPermissionsWarning(true));
          }
          if (showPermissionsModal) {
            dispatch(setShowPermissionsModal(false));
          }
        }
        break;
      }
      case PERMISSIONS_STATUS.GRANTED: {
        if (showPermissionsModal) {
          dispatch(setShowPermissionsModal(false));
        }
        if (showPermissionsWarning) {
          dispatch(setShowPermissionsWarning(false));
        }
        break;
      }
      case PERMISSIONS_STATUS.DENIED: {
        if (!showPermissionsWarning) {
          dispatch(setShowPermissionsWarning(true));
        }
        if (showPermissionsModal) {
          dispatch(setShowPermissionsModal(false));
        }
        break;
      }
      default: return;
    }
  }, [status])

  const checkPermissions = (permissions = []) => {
    const permissionsStatuses = permissions?.map(p => p?.state);
    if (permissionsStatuses.includes(PERMISSIONS_STATUS.DENIED)) {
      dispatch(setAVPermissionsStatus(PERMISSIONS_STATUS.DENIED));
    } else if (permissionsStatuses.includes(PERMISSIONS_STATUS.GRANTED)) {
      dispatch(setAVPermissionsStatus(PERMISSIONS_STATUS.GRANTED));
    } else if (permissionsStatuses.includes(PERMISSIONS_STATUS.PROMPT)) {
      dispatch(setAVPermissionsStatus(PERMISSIONS_STATUS.PROMPT));
    } else {
      dispatch(setAVPermissionsStatus(PERMISSIONS_STATUS.DENIED));
    }
  }

  const checkBrowserPermissions = async () => {
    if (navigator.permissions) {
      await Promise.all([
        navigator.permissions.query({ name: "camera" }),
        navigator.permissions.query({ name: "microphone" })
      ]).then((applicationPermissions) => {
        checkPermissions(applicationPermissions);
      }).catch(() => {
        dispatch(setAVPermissionsStatus(PERMISSIONS_STATUS.DENIED));
      });
    }
  }

  const callBrowserPermissions = async () => {
    try {
      if (navigator?.mediaDevices !== undefined && navigator?.mediaDevices?.getUserMedia !== undefined) {
        const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: { facingMode: "user" } });
        if (mediaStream) {
          mediaStream.getTracks().forEach(track => track?.stop());
        }
      }
    } catch (error) {
      // usually will get this error when browser permissions modal is dismised = permisssions denied
      console.error('callBrowserPermissions', error);
    }
    finally {
      await checkBrowserPermissions();
    }
  }

  return {
    showPermissionsModal,
    showPermissionsWarning,
    callBrowserPermissions,
    showPermissionsHelperModal,
  };
}
