import React from 'react';

const IconNotificationCart = (props) => (
  <svg width={44} height={44} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M27.975 5.645c-7.654-1.25-9.287-2.863-16.708-2.62-7.854.256-7.525 2.215-7.071 10.04.248 4.287.153 6.85 0 9.386-.127 2.093.734 7.127 1.079 9.161.762 4.501 1.731 7.545 4.074 8.347 6.796 2.324 21.922.749 27.78-4.637 3.642-3.349 3.019-8.524 2.392-12.87-.241-1.67-3.892-15.556-11.546-16.807Z"
        fill="#FEBE88"
      />
      <g fill="#FFF" fillRule="nonzero">
        <path d="M6.888 4a2.28 2.28 0 0 1 2.2 1.678l.043.194L13.445 29.6l17.435.001a1 1 0 0 1 .993.883l.007.117a1 1 0 0 1-.883.993l-.117.007H12.61a1 1 0 0 1-.955-.705l-.028-.116-4.463-24.55a.28.28 0 0 0-.211-.221L6.888 6H4a1 1 0 0 1-.117-1.993L4 4h2.888Z" />
        <path d="M14.24 29.6a4.2 4.2 0 1 0 0 8.4 4.2 4.2 0 0 0 0-8.4Zm0 2a2.2 2.2 0 1 1 0 4.4 2.2 2.2 0 0 1 0-4.4ZM30.88 29.6a4.2 4.2 0 1 0 0 8.4 4.2 4.2 0 0 0 0-8.4Zm0 2a2.2 2.2 0 1 1 0 4.4 2.2 2.2 0 0 1 0-4.4ZM36 10.4a1 1 0 0 1 .998 1.064l-.014.115-1.945 10.698a3.56 3.56 0 0 1-3.276 2.916l-.227.007H11.447a1 1 0 0 1-.116-1.993l.116-.007h20.09a1.56 1.56 0 0 0 1.499-1.13l.035-.15 1.731-9.521L9.12 12.4a1 1 0 0 1-.993-.883L8.12 11.4a1 1 0 0 1 .883-.993l.117-.007H36Z" />
      </g>
    </g>
  </svg>
)

export default IconNotificationCart