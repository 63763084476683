import React, {useState, useCallback, useMemo} from 'react';
import moment from 'moment-timezone';
import AddIcon from '@material-ui/icons/Add';
import { FilledButton } from '../../buttons/buttons';

import './PendingRequestListItem.scss';
import {getContrastYIQ} from "../../../utils";
import {useSelector} from "react-redux";
import {useHostSettingsProvider} from "../../hostSettingsProvider/host-settings-provider";

export default function PendingRequestListItem({ id, callData, primaryLocale, handleAssign, currentlyAssigning }) {
    const { startTimestamp, customer, message, locale, eventType, shopId } = callData;
    const { availableShops } = useSelector((state) => state.shops);
    const { shopNameIsVisible } = useHostSettingsProvider();

    const MessageContainer = ({ message }) => {
        const [isToggleButtonVisible, setToggleButtonVisible] = useState(false);
        const [isMessageToggled, setMessageToggel] = useState(false);

        const messageRef = useCallback(element => {
            if (element) {
                if (element.scrollWidth > element.clientWidth) {
                    setToggleButtonVisible(true);
                }
            }
        }, []);

        const toggleMessageContent = () => {
            setMessageToggel(!isMessageToggled);
        }

        return (
            <div className={`customer-message-container${isMessageToggled ? ' visible' : ''}`}>
                <p ref={messageRef} className='message'>{message}</p>
                {isToggleButtonVisible &&
                    <button
                        onClick={toggleMessageContent}
                        className='toggle-message-button'> {isMessageToggled ? 'less' : 'more'}
                    </button>}
            </div>
        )
    }

    const eventTypeBudge = () => {
      if (eventType.eventTypeId === 'default')
        return null;
      return (
        <span className='pending-event-type' style={{
          '--badge-color': eventType.color
        }}>
          <span className='event-type-text' style={{
            '--contrastColor': getContrastYIQ(eventType.color),
          }}>{eventType.name}</span>
        </span>
      )
    }

    return (
        <li key={id}>
            {shopNameIsVisible && (
                <p className={'pending-call-shop-name '}>
                    {availableShops[shopId].name}
                </p>
            )}
            <h4>
                <span>
                    {moment(startTimestamp.toDate()).format(
                        'MMM Do, LT'
                    )}
                </span>
            </h4>
            <div className='pending-call-name-wrapper'>
              <p className='customer-name customer'>{customer?.name}</p>
              {eventType && eventTypeBudge()}
            </div>
            <p>{customer?.email}</p>
            {message && <MessageContainer message={message} />}
            <FilledButton
                isLoading={currentlyAssigning === id}
                onClick={() => handleAssign(id, callData, shopId)}
                style={{
                    height: '30px',
                    width: '30px',
                    backgroundColor: 'var(--main-color)',
                    color: 'white',
                    WebkitTextFillColor: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <AddIcon style={{ fontSize: '1rem' }} />
            </FilledButton>
        </li>
    );
}
