import React from 'react';

import './styles.scss';

import useGoogleAuth from "../../hooks/useGoogleAuth";
import GoogleIcon from "../../assets/icons/googleIcon/GoogleIcon";
import { ButtonLoader } from '../buttons/buttons';

export const GoogleAuth = () => {

  const { isLoggedIn, googleSignIn, googleSignOut, googleAuthError, isGoogleAuthLoading, isGoogleAuthDeactivated } = useGoogleAuth();

  return (
    <div className="google-auth-settings">
      <div className="google-auth-settings-text">
        <p className='title'>Synchronize Boutiq appointments to your Google Calendar</p>
        {googleAuthError && <p className='google-auth-settings-text_status_error'>Error: {googleAuthError.message}</p>}
        {!googleAuthError && isLoggedIn && <p className='google-auth-settings-text_status_success'>Your appointments are syncing to Google</p>}
        {!googleAuthError && !isLoggedIn && <p className='google-auth-settings-text_status'>You are not syncing with Google</p>}
      </div>
      <div className='google-auth-button'>
        {
          isLoggedIn
            ? <div className={`google-auth-button_button ${isGoogleAuthDeactivated ? 'google-auth-button_button_disabled' : ''}`} onClick={googleSignOut}>
              <GoogleIcon />
              {
                isGoogleAuthLoading
                  ? <ButtonLoader loaderSize='20px' />
                  : <p>Unsync from Google</p>
              }
            </div>
            :
            <div className={`google-auth-button_button ${isGoogleAuthDeactivated ? 'google-auth-button_button_disabled' : ''}`} onClick={googleSignIn}>
              <GoogleIcon />
              {
                isGoogleAuthLoading
                  ? <ButtonLoader loaderSize='20px' />
                  : <p>Sync with Google</p>
              }
            </div>
        }
      </div>
    </div>
  );
};
