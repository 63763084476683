const APP_DIAGNOSTICS_ACTION_TYPES = {
  PREFLIGHT_COMPLETED: 'preflight-completed',
  PREFLIGHT_FAILED: 'preflight-failed',
  PREFLIGHT_STARTED: 'preflight-started',
  PREFLIGHT_STOPPED: 'preflight-stopped',
  DIAGNOSTIC_RESULT: 'set-diagnostics-result',
  CLEAR_DIAGNOSTIC: 'clear-diagnostic-result',
  REPORT_SENT: 'report-sent',
}

const STORAGE_DIAGNOSTIC_KEY = 'preflight_diagnostic';

const QUALITY_SCORE = {
  POOR: 0,
  SUBOPTIMAL: 1,
  GOOD: 2,
  EXCELLENT: 3,
}

const QUALITY_LABEL = {
  0: 'poor',
  1: 'suboptimal',
  2: 'good',
  3: 'excellent',
}

export {
  STORAGE_DIAGNOSTIC_KEY,
  APP_DIAGNOSTICS_ACTION_TYPES,
  QUALITY_SCORE,
  QUALITY_LABEL,
}
