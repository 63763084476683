import { useEffect } from 'react';
import {useDispatch} from 'react-redux';
import {firestore} from '../../firebaseInitialization';
import {ROLES} from "../../constants/participantRoles";
import {ROOM_STATUS} from "../../constants/roomStatuses";
import {saveActiveRooms, savePendingRooms} from "../../actions/rooms";
import {parseRoomObject} from "../../utils/parsers";
import {FIRESTORE_COLLECTIONS} from "../../constants/firestore";
import { setActiveRoom, setActiveRoomId } from '../../actions/activeRoom';

export default function useRoomsListener(user = {}, shopId) {
  const dispatch = useDispatch();

  const listenPendingRooms = () => {
    return firestore()
      .collection(FIRESTORE_COLLECTIONS.CHAT_ROOMS)
      .where('status', '==', ROOM_STATUS.PENDING)
      .where('shopId', '==', shopId)
      .orderBy('updatedAt','desc')
      .onSnapshot(
        (snapshot) => {
          if (snapshot && snapshot.docs) {
            const data = snapshot.docs.map(item => parseRoomObject({...item.data(), id: item.id}));
            dispatch(savePendingRooms(data))
          }
        }, listenError => console.error('listenPendingRooms',listenError));
  }

  const listenHostActiveRooms = () => {
    return firestore()
      .collection(FIRESTORE_COLLECTIONS.CHAT_ROOMS)
      .where('permissions', 'array-contains', user.uid)
      .where('status', '==', ROOM_STATUS.ACTIVE)
      .where('activeHost.uuid', '==', user.uid)
      .where('shopId', '==', shopId)
      .orderBy('updatedAt','desc')
      .onSnapshot(
        (snapshot) => {
          if (snapshot && snapshot.docs) {
            const data = snapshot.docs.map(item => parseRoomObject({...item.data(), id: item.id}));
            dispatch(saveActiveRooms(data))
          }
        }, listenError => console.error('listenHostActiveRooms',listenError));
  }

  const listenCustomerActiveRoom = () => {
    return firestore()
      .collection(FIRESTORE_COLLECTIONS.CHAT_ROOMS)
      .where('permissions', 'array-contains', user.uid)
      .where('status', '==', ROOM_STATUS.ACTIVE)
      .where('shopId', '==', shopId)
      .onSnapshot(
        (snapshot) => {
          if (snapshot && snapshot.docs && snapshot.docs.length > 0) {
            const data = snapshot.docs.map(item => parseRoomObject({...item.data(), id: item.id}));
            dispatch(setActiveRoom(data[0]));
          }
        }, listenError => console.error('listenCustomerActiveRoom',listenError));
  }

  useEffect(() => {
    if (user.uid && user.role && shopId) {
      if (user.role === ROLES.HOST) {
        const pendingRoomsListener = listenPendingRooms();
        const activeRoomsListener = listenHostActiveRooms();
        return () => {
          pendingRoomsListener();
          activeRoomsListener();
        };
      }
      if (user.role === ROLES.PARTICIPANT) {
        const activeRoomListener = listenCustomerActiveRoom();
        return () => {
          activeRoomListener();
        };
      }
    }
  },[user.uid, user.role, shopId]);
}
