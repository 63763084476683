import React from 'react';
import './PaymentInfoItem.scss';

const PaymentInfoItem = ({title, value, description, onPress, onDescriptionPress, isTotal, disabled}) => {
  return (
    <div className='draft-order-payment-info-item'>
      <p
        onClick={onPress}
        className={`payment-info-title${onPress ? ' clickable' : ''}${isTotal ? ' bold' : ''}${disabled ? ' disabled' : ''}`}
      >{title}</p>
      {description ? (
          <p
            onClick={onDescriptionPress}
            className={`payment-info-description${onDescriptionPress ? ' clickable' : ''}${disabled ? ' disabled' : ''}`}
          >{description}</p>
      ) : (
        <p className='payment-info-description'>&mdash;</p>
      )}
      <p className={`payment-info-value${isTotal ? ' bold' : ''}${disabled ? ' disabled' : ''}`}>{value}</p>
    </div>
  )
}

export default PaymentInfoItem;