import React from 'react';
import { FilledButton, OutlinedButton } from '../../../../../../components/buttons/buttons';
import './index.scss';

export const ProductDetailsSubmitActions = ({
    updateVariant,
    addFullProductToCart,
    isSoldOutBtnVisible,
    isUpdateBtnVisible,
    isUpdateBtnDisabled,
    isProductInCart
}) => {

    return (
        <>
            {isSoldOutBtnVisible ?
                <OutlinedButton
                    className={'sold-out-btn'} >
                    Sold out
                </OutlinedButton> :
                isProductInCart ?
                    isUpdateBtnVisible &&
                    <FilledButton
                        onClick={updateVariant}
                        style={{ height: '48px', width: '100%' }}
                        disabled={isUpdateBtnDisabled}
                    >
                        Update
                    </FilledButton> :
                    <FilledButton
                        onClick={addFullProductToCart}
                        style={{ height: '48px', width: '100%' }}>
                        Add to cart
                    </FilledButton>
            }
        </>
    )
}
