import React from 'react';

export const Checkout = () =>
<svg 
    xmlns="http://www.w3.org/2000/svg"
    width="16px" 
    height="16px" 
    viewBox="0 0 16 16" 
    version="1.1" >
    <g id="icon/checkout" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="CreditCard">
            <rect id="Rectangle" fill="#FFFFFF" opacity="0" x="0" y="0" width="16" height="16"></rect>
            <path d="M14.4157199,2.68821008 C14.9871436,2.68821008 15.4503632,3.15143606 15.4503632,3.7228534 L15.4503632,3.7228534 L15.4503632,12.2771466 C15.4503632,12.8485725 14.9871458,13.3117899 14.4157199,13.3117899 L14.4157199,13.3117899 L1.5842801,13.3117899 C1.01286277,13.3117899 0.549636777,12.8485703 0.549636777,12.2771466 L0.549636777,12.2771466 L0.549636777,3.7228534 C0.549636777,3.15143821 1.01286491,2.68821008 1.5842801,2.68821008 L1.5842801,2.68821008 Z M1.54982985,6.90810367 L1.54963678,12.2771466 C1.54963678,12.2962825 1.56514442,12.3117899 1.5842801,12.3117899 L1.5842801,12.3117899 L14.4157199,12.3117899 C14.434861,12.3117899 14.4503632,12.2962877 14.4503632,12.2771466 L14.4503632,12.2771466 L14.4496368,6.91721008 L14.4276938,6.91841968 L1.69400228,6.91841968 C1.64504337,6.91841968 1.59690621,6.91490133 1.54982985,6.90810367 Z M12.8114157,10.1732166 C13.087558,10.1732166 13.3114157,10.3970742 13.3114157,10.6732166 C13.3114157,10.9186765 13.1345405,11.122825 12.9012913,11.165161 L12.8114157,11.1732166 L10.6728424,11.1732166 C10.3967,11.1732166 10.1728424,10.949359 10.1728424,10.6732166 C10.1728424,10.4277567 10.3497175,10.2236083 10.5829667,10.1812723 L10.6728424,10.1732166 L12.8114157,10.1732166 Z M14.4157199,3.68821008 L1.5842801,3.68821008 C1.56514966,3.68821008 1.54963678,3.70372296 1.54963678,3.7228534 L1.54963678,3.7228534 L1.54982985,4.92873569 C1.55897418,4.92741528 1.56815853,4.9262186 1.57738116,4.92514741 L1.69400228,4.91841968 L14.4276938,4.91841968 L14.4496368,4.91921008 L14.4503632,3.7228534 C14.4503632,3.71009628 14.443471,3.69895162 14.4332063,3.69293931 L14.4157199,3.68821008 Z" id="Combined-Shape" fill="#CF7B35" fillRule="nonzero"></path>
        </g>
    </g>
</svg>
