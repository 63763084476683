import React, {useCallback, useEffect, useState} from 'react';
import './block-off-time-modal.scss';
import Modal from "../modal";
import {StyledInput} from "../../inputs/inputs";
import {OptionElement} from "../../../pages/home-page/host-setting/components/OptionElement";
import {FORM_OBJECT_TYPES} from "../../../pages/home-page/host-setting/components/formBuilder/templateFormBuilder";
import moment from "moment";
import {FilledButton} from "../../buttons/buttons";
import useCaazamREST from "../../../hooks/useCaazamREST";
import {useSelector} from "react-redux";
import useBlockOffEvents from "../../../hooks/useBlockOffEvents";
import { useHostSettingsProvider } from '../../hostSettingsProvider/host-settings-provider';

const BlockOffTimeModal = ({onClose, scheduleId}) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('')
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const {createEvent, rescheduleEvent} = useCaazamREST();
  const shopId = useSelector((state) => state.shops.activeShopId);
  const [isLoading, setLoading] = useState(false)
  const {updateEvent, blockOffEvent} = useBlockOffEvents(shopId, scheduleId)
  const [error, setError] = useState(null)
  const [navigateToAvailabilityScreen, setNavigateToAvailabilityScreen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const { toggleSettingsDialog } = useHostSettingsProvider();

  const style = {
    container: {
      justifyItems: 'flex-end',
      gridTemplateColumns: '100px auto',
      marginTop: '6px'

    },
    wrapper: {
      width: '100%'
    },
    title: {
      justifySelf: 'flex-start'
    }
  }

  const timeRounding = () => {
    const now = moment()
    const minute = now.minute();
    if (minute <= 30) now.set({minute: 30})
    else now.set({hour: now.hour() + 1, minute: 0})
    return now
  }

  const element = useCallback((title = 'Start time', name = 'startTime') => {
    return ({
      visibility: true,
      name: name,
      type: FORM_OBJECT_TYPES.dateTime,
      label: title,
      value: name === 'startTime' ? startDate : endDate,
    })
  }, [startDate, endDate])

  useEffect(() => {
    const now = timeRounding()
    setTitle(blockOffEvent?.title ?? '')
    setDescription(blockOffEvent?.description ?? '')
    setStartDate(blockOffEvent?.startTimestamp ? blockOffEvent.startTimestamp.toDate() : now.toDate())
    setEndDate(blockOffEvent?.endTimestamp ? blockOffEvent.endTimestamp.toDate() : now.add(1, 'h').toDate())
  }, [blockOffEvent])

  const onNavigateToHostAvailability = () => {
    onClose()
    toggleSettingsDialog(true)
  }

  const handleTitleInput = (e) => {
    setTitle(e.currentTarget.value);
  };

  const onChangeDescription = (e) => {
    setDescription(e.currentTarget.value)
  }

  const onValueChange = (name, value) => {
    if (value === 'true' || value === 'false') {
      value = JSON.parse(value);
    }
    if (name === 'startTime') {
      setStartDate(value)
      setEndDate(moment(value).add(1,'h').toDate())
    } else if (name === 'endTime') {
      setEndDate(value)
    }
  }

  const onCreateEvent = async () => {
    setLoading(true)
    try {
      await createEvent(shopId, {
        title,
        description,
        slot: {
          start: moment(startDate).set({second: 0, millisecond: 0}).toISOString(),
          end: moment(endDate).set({second: 0, millisecond: 0}).toISOString()
        }
      })
      onClose()
    } catch (e) {console.log(e)
      setError(e.reason)
    } finally {
      setLoading(false)
    }
  }

  const onUpdateEvent = async () => {
    setLoading(true)
    try {
      if (
        !moment(blockOffEvent.startTimestamp.toDate()).isSame(moment(startDate))
        || !moment(blockOffEvent.endTimestamp.toDate()).isSame(moment(endDate))
      ) {
        await rescheduleEvent(shopId, scheduleId, {
          slot: {
            start: moment(startDate).set({second: 0, millisecond: 0}).toISOString(),
            end: moment(endDate).set({second: 0, millisecond: 0}).toISOString()
          }
        })
      }
      if (blockOffEvent.title !== title || blockOffEvent.description !== description) {
        await updateEvent(title, description)
      }
      onClose()
    } catch (e) {
      setError(e.reason)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (blockOffEvent) {
      const isValueSame = blockOffEvent.title === title
        && blockOffEvent.description === description
        && moment(blockOffEvent.startTimestamp.toDate()).isSame(moment(startDate))
        && moment(blockOffEvent.endTimestamp.toDate()).isSame(moment(endDate))
      setButtonDisabled(
        isValueSame
        || !title.trim()
        || error
      )
    } else {
      setButtonDisabled(!title.trim() || error)
    }
  }, [blockOffEvent, title, description, error, startDate, endDate])

  useEffect(() => {
    const start = moment(startDate).set({second: 0})
    const end = moment(endDate).set({second: 0})
    if (start.isAfter(end) || start.isSame(end)) {
      setError('End time must be after start time')
      return;
    }
    if (moment(endDate).diff(moment(startDate), 'h', true) > 12) {
      setError('Block off time max duration is 12 hours. If you need to change your availability for longer please ')
      setNavigateToAvailabilityScreen(true)
      return;
    }
    setError(null)
    setNavigateToAvailabilityScreen(false)
  }, [startDate, endDate])

  return (
    <Modal
      closeFunc={onClose}
      modalClass='block-off-time'
    >
      <div className='block-off-time-modal-wrapper'>
        <header>
          <h3>{'Block off time'}</h3>
        </header>
        <OptionElement
          styles={style}
          disableDateTimePicker={false}
          disableDatePicker={false}
          disableSelect={false}
          element={element('Start time', `startTime`)}
          onValueChange={onValueChange}
        />
        <OptionElement
          styles={style}
          disableDateTimePicker={false}
          disableDatePicker={false}
          disableSelect={false}
          element={element('End time', `endTime`)}
          onValueChange={onValueChange}
        />
        <StyledInput
          placeholder='Title'
          type='text'
          value={title}
          onChange={handleTitleInput}
        />
        <div className='block-off-time-description-wrapper'>
          <textarea
            name="description"
            rows='6'
            value={description}
            placeholder="Add a short description"
            onChange={onChangeDescription}
            className='caazam-input'
          />
        </div>
      </div>
      <span className='block-off-error-message'>
        {error}
        {navigateToAvailabilityScreen && (
          <span onClick={onNavigateToHostAvailability} className='block-off-error-action'>{'edit host availability'}</span>
        )}
      </span>
      <FilledButton
        onClick={blockOffEvent ? onUpdateEvent : onCreateEvent}
        isLoading={isLoading}
        disabled={buttonDisabled}
        className={`${buttonDisabled ? 'disabled' : 'button'}`}
        style={{width: '100%'}}
      >
        {blockOffEvent ? 'Update' : 'Block off'}
      </FilledButton>
    </Modal>
  )
}

export default BlockOffTimeModal;
