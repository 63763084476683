import * as React from "react"

const IconCalendar = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} {...props}>
    <path
      d="M20.023 4.727H5.477a.727.727 0 0 0-.727.728V20c0 .402.326.727.727.727h14.546A.727.727 0 0 0 20.75 20V5.455a.727.727 0 0 0-.727-.728zm-2.909-1.454v2.909M8.386 3.273v2.909M4.75 9.09h16"
      stroke="#6E76F2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default IconCalendar
