import React from 'react';
import { PropTypes } from 'prop-types';
import './toggle.scss';

const Toggle = ({ isChecked, toggleFunc, tooltipText }) => {
  return (
    <label className='switch'>
      <input type='checkbox' checked={isChecked} onChange={toggleFunc} />
      <span className='slider'></span>
      {tooltipText && <span className='tooltip'>{tooltipText}</span>}
    </label>
  );
};

Toggle.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  toggleFunc: PropTypes.func.isRequired,
  tooltipText: PropTypes.string,
};

export default Toggle;
