import Moment from "moment-timezone";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import RRule, {rrulestr} from "rrule";
import {convertDayToRRule, convertToRRuleDate} from "../utils/rruleConvert";
import {useSelector} from "react-redux";
import useHostShopUser from "./useHostShopUser";
import {useState} from "react";

const useHostAvailabilityActions = (startFrom, collectionPath, events) => {
  const caazamUser = useSelector((state) => state.user.user);
  const hostId = caazamUser && caazamUser.id;
  const shopId = useSelector((state) => state.shops.activeShopId);
  const { hostTeamId } = useHostShopUser(shopId, hostId);

  const [actionLoading, setActionLoading] = useState({
    addHostLoading: false,
    deleteHostLoading: false,
    deleteHostRecurrenceLoading: false,
    updateHostLoading: false,
    updateHostLoadingRecurrence: false,
  })

  const availabilityRef = firebase
    .firestore()
    .collection('shops')
    .doc(shopId)
    .collection(collectionPath);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const updateLoadingState = (value) => {
    setActionLoading(currentState => ({
      ...currentState,
      ...value
    }))
  }

  /**
   * addHostAvailability
   * @param  {Date}           windowStart             when the window starts
   * @param  {number}         duration                integer in minutes
   * @param  {null}           [recurrence]            recurrence info
   * @param  {boolean}        recurrence.isRecurrence if is recurring weekly event
   * @param  {Date | null}    recurrence.endOn        end date for recurrence or null if for ever
   */
  const addHostAvailability = async (windowStart, duration, recurrence) => {
    updateLoadingState({addHostLoading: true})
    const eventEndOn = Moment(windowStart).add(duration, 'minutes').toDate();
    const endOn = recurrence
      ? recurrence.endOn || Moment(windowStart).year(2099).toDate()
      : eventEndOn


    const event = {
      hostId,
      teamId: hostTeamId,
      startFrom: firebase.firestore.Timestamp.fromDate(windowStart),
      duration,
      endOn: firebase.firestore.Timestamp.fromDate(endOn),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }

    if (recurrence) {
      event.recurrencePattern = new RRule({
        freq: RRule.WEEKLY,
        byweekday: convertDayToRRule(Moment(windowStart).day()),
        dtstart: convertToRRuleDate(Moment(windowStart)),
        until: recurrence.endOn ? convertToRRuleDate(Moment(endOn)) : null,
        tzid: timezone,
      }).toString();
    }

    await availabilityRef.add(event);
    updateLoadingState({addHostLoading: false})
  }
  /**
   * deleteHostAvailability
   * @param  {string}   eventId               the event id being deleted
   * @param  {Date}     windowStart           the instance being deleted indicated by the start time
   * @param  {boolean}  deleteAllFuture=true  delete just this instance or all future events
   */
  const deleteHostAvailability = async (eventId, windowStart, deleteAllFuture = true) => {
    updateLoadingState(deleteAllFuture ? {deleteHostRecurrenceLoading: true} : {deleteHostLoading: true})
    const expireDate = windowStart;

    await firebase.firestore().runTransaction(async t => {
      const eventDoc = await t.get(availabilityRef.doc(eventId));
      if (!eventDoc.exists) throw new Error(`availability window ${eventId} not found`);

      const event = await eventDoc.data();

      if (event.recurrencePattern) {
        if (deleteAllFuture) {
          // if reoccurence starts in the future
          if (event.startFrom.toDate() >= expireDate) {
            await t.delete(availabilityRef.doc(eventId))
          } else {
            // reccurence is in effect - starting before expire date and ending at or after expire date
            const rule = rrulestr(event.recurrencePattern);
            let eventExpireDate = Moment(new Date(expireDate - 1)).tz(rule.origOptions.tzid);   // expire the rule so that it doesn't create new instances
            await t.update(availabilityRef.doc(eventId), {
              endOn: firebase.firestore.Timestamp.fromDate(eventExpireDate.toDate()),
              recurrencePattern: new RRule({ ...rule.origOptions, until: convertToRRuleDate(eventExpireDate)}).toString(),
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
          }
        } else {
          // create an expcetion
          await t.update(availabilityRef.doc(eventId), {
            exceptionDates: firebase.firestore.FieldValue.arrayUnion(windowStart.toISOString()),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        }
      } else {
        await t.delete(availabilityRef.doc(eventId));
      }
    });
    updateLoadingState(deleteAllFuture ? {deleteHostRecurrenceLoading: false} : {deleteHostLoading: false})
  }

  /**
   * updateHostAvailability
   * @param  {string} eventId               the event id being updated
   * @param  {Date} windowStart             the instance being updated indicated by the start time
   * @param  {Date} newWindowStart          new (or current) start time
   * @param  {number} newDuration           new (or current) duraiton
   * @param  {boolean} updateAllFuture=true udpate just this instance or all future events
   */
  const updateHostAvailability = async (eventId, windowStart, newWindowStart, newDuration, updateAllFuture = true) => {
    updateLoadingState(updateAllFuture ? {updateHostLoadingRecurrence: true} : {updateHostLoading: true})
    const event = events.find(({id}) => id === eventId);

    if (event.recurrencePattern) {
      if (updateAllFuture) {
        await deleteHostAvailability(eventId, windowStart, updateAllFuture);
        await addHostAvailability(newWindowStart, newDuration, { isRecurrence: true, endOn: event.endOn.toDate() });
      } else {
        // create an expcetion
        await availabilityRef.doc(eventId).update({
          exceptionDates: firebase.firestore.FieldValue.arrayUnion(windowStart.toISOString()),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
        // create new individual event
        await addHostAvailability(newWindowStart, newDuration);
      }
    } else {
      let endOn = Moment(newWindowStart).add(newDuration, 'minutes');
      const update = {
        startFrom: firebase.firestore.Timestamp.fromDate(newWindowStart),
        duration: newDuration,
        endOn: firebase.firestore.Timestamp.fromDate(endOn.toDate()),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      }
      await availabilityRef.doc(eventId).update(update);
    }
    updateLoadingState(updateAllFuture ? {updateHostLoadingRecurrence: false} : {updateHostLoading: false})
  }

  /**
   * updateRecurrenceRule
   * @param  {string} eventId               the event id being updated
   * @param  {Object} newRecurrence         new (or current) recurrence info
   */
  const updateRecurrenceRule = async (eventId, newRecurrence) => {
    updateLoadingState({updateHostLoading: true})
    const event = events.find(({id}) => id === eventId);
    const start = event.startFrom.toDate();
    const instanceEnd = Moment(start).add(event.duration, 'minutes').toDate();
    let eventUpdate = {
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }

    if (event.recurrencePattern) {
      if (newRecurrence) {  // changing recurrence pattern
        let newEndOn = newRecurrence.endOn || Moment(start).year(2099).toDate();
        eventUpdate.endOn = firebase.firestore.Timestamp.fromDate(newEndOn);
        eventUpdate.recurrencePattern = new RRule({
          freq: RRule.WEEKLY,
          byweekday: convertDayToRRule(Moment(start).day()),
          dtstart: convertToRRuleDate(Moment(start)),
          until: newRecurrence.endOn ? convertToRRuleDate(Moment(newEndOn)) : null,
          tzid: timezone,
        }).toString();
      } else {  // removing recurrence
        eventUpdate.endOn = firebase.firestore.Timestamp.fromDate(instanceEnd);
        eventUpdate.recurrencePattern = firebase.firestore.FieldValue.delete();
      }
    } else {
      if (newRecurrence) { // adding new recurrence
        let newEndOn = newRecurrence.endOn || Moment(start).year(2099).toDate();
        eventUpdate.endOn = firebase.firestore.Timestamp.fromDate(newEndOn);

        eventUpdate.recurrencePattern = new RRule({
          freq: RRule.WEEKLY,
          byweekday: convertDayToRRule(Moment(start).day()),
          dtstart: convertToRRuleDate(Moment(start)),
          until: newRecurrence.endOn ? convertToRRuleDate(Moment(newEndOn)) : null,
          tzid: timezone,
        }).toString();
      } else {
        // can't remove recurrence from non-recurring meeting
      }
    }

    await availabilityRef.doc(eventId).update(eventUpdate);
    updateLoadingState({updateHostLoading: false})
  }

  return ({
    addHostAvailability,
    deleteHostAvailability,
    updateHostAvailability,
    updateRecurrenceRule,
    actionLoading
  })
}

export default useHostAvailabilityActions;