import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

//import { Loader } from '../../../../components/loader/loader';
import { StyledInput } from '../../../../components/inputs/inputs'
import { OutlinedButton } from '../../../../components/buttons/buttons'
import useCaazamREST from '../../../../hooks/useCaazamREST'
import useSessionDiscount from '../../../../hooks/useSessionDiscount'


export default function DiscountBar({ callId }) {
  const [discountCode, setDiscountCode] = useState('');
  const [discountCodeSummary, setDiscountCodeSummary] = useState(null);
  const [discountCodeError, setDiscountCodeError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { activeCartId } = useSelector((state) => state.activeCall);
  const { cartDiscount, cartDiscountLoading } = useSessionDiscount(callId, activeCartId);

  const { availableShops: hostShops, callShopId: shopId } = useSelector((state) => state.shops);

  const { productPolicy } = hostShops[shopId];
  const supportsDiscounts = productPolicy && productPolicy.supportsDiscounts;
  
  const { getDiscountCodeStatus } = useCaazamREST();
  const cartRef = firebase.database().ref(`session/${callId}/cart/${activeCartId}/discount`);

  useEffect(() => {
    if (!cartDiscountLoading && cartDiscount) {
      setDiscountCode(cartDiscount.code);
      setDiscountCodeSummary(cartDiscount.summary);
    }
  }, [cartDiscount, cartDiscountLoading])


  const handleCodeChange = (e) => {
    if (!discountCodeSummary) {
      setDiscountCode(e.target.value);
      setDiscountCodeError(null);
    }
  }

  const apply = async () => {
    if (loading) return;
    setDiscountCodeSummary(null);
    setDiscountCodeError(null);
    setLoading(true);
    try {
      const discountCodeData = await getDiscountCodeStatus(shopId, discountCode);
      const { summary, status, startsAt, endsAt } = discountCodeData;
      const now = new Date();
      
      if (endsAt && (new Date(endsAt) < now)) {
        setDiscountCodeError('discount code has expired');
        return;
      }

      if (!status || new Date(startsAt) > now) {
        setDiscountCodeError('discount code inactive');
        return;
      }

      await cartRef.set({ ...discountCodeData, code: discountCode });
      setDiscountCodeSummary(summary);
    } catch (error) {
      if (error.reason) setDiscountCodeError(error.reason)
      else setDiscountCodeError(error.message)
    } finally {
      setLoading(false);
    }
  }

  const remove = async () => {
    try {
    await cartRef.remove();
    setDiscountCodeSummary(null);
    } catch (error) {
      console.error('failed to remove disocunt code', error);
    }
  }

  const applyButtonStyles = {
    height: '30px',
    width: '80px',
    fontWeight: '400',
    fontSize: '16px',
    color: 'var(--base-font-color)',
    WebkitTextFillColor: 'var(--base-font-color)',
    borderColor: 'var(--base-font-color)',
  };

  const removeButtonStyles = {
    height: '30px',
    width: '80px',
    fontWeight: '400',
    fontSize: '16px',
    color: 'var(--base-font-color)',
    WebkitTextFillColor: 'var(--base-font-color)',
    borderColor: 'var(--base-font-color)',
  };

  const inputStyle = {
    width: '200px',
    height: '24px',
    marginRight:'8px',
    color: !discountCodeSummary ? 'var(--base-font-color)' : 'var(--light-font-color)',
    WebkitTextFillColor: !discountCodeSummary ? 'var(--base-font-color)': 'var(--light-font-color)',
  }

  const renderMessage = () => {
    if (discountCodeError) return <p className='discount-text discount-error'>{discountCodeError}</p>
    else if (discountCodeSummary) return <p className='discount-text'>{discountCodeSummary} - Applied at checkout</p>
    else return <p style={{ height: '30px' }}></p>
  }

  if (!supportsDiscounts) return null;
  //TODO: Needs a loader and disabled mode for Outlined button.

  return (
    <div className='discount'>
      {renderMessage()}
      <div className='discount-actions'>
        <StyledInput
          style={inputStyle}
          type='text'
          value={discountCode}
          onChange={handleCodeChange}
          placeholder='Discount code'
          disabled={discountCodeSummary}
        />
        {discountCodeSummary
          ? <OutlinedButton
            onClick={remove}
            style={{ ...removeButtonStyles }}>
            remove
          </OutlinedButton>
          : <OutlinedButton
            onClick={apply}
            style={{ ...applyButtonStyles }}>
            Apply
          </OutlinedButton>}
      </div>
    </div>
  )
};
