import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useScheduleClientFormData(shopId, scheduleId) {
    const [scheduleClientFormData, setScheduleClientFormData] = useState(null);

    useEffect(() => {
      if (shopId && scheduleId) {
        const unsub = firebase.firestore().collection('shops').doc(shopId).collection('schedule').doc(scheduleId).onSnapshot(snap=>{
            const scheduleData = snap && snap.data() || null;
            if (scheduleData && scheduleData.clientForm) {
                setScheduleClientFormData(scheduleData.clientForm)
            }
        })
        return () => {
          unsub()
          setScheduleClientFormData(null)
        };
      }
    }, [shopId, scheduleId])

    return { scheduleClientFormData };
}
