import { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import { useSelector } from 'react-redux';

export default function useHostAppAnalytics() {
  const { user } = useSelector((state) => state.user);
  const { activeShopId } = useSelector((state) => state.shops);

  useEffect(() => {
    firebase.analytics().setUserProperties({ role: user ? 'host' : null });
  }, [!!user]);

  useEffect(() => {
    firebase.analytics().setUserProperties({ shop_id: activeShopId || null });
  }, [activeShopId]);
}
