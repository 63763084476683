import * as React from "react"

function StartVideoCall(props) {
  return (
    <svg
      width={props.width || 29}
      height={props.height || 29}
      viewBox="0 0 29 29"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-386 -26)">
          <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
          <use fill="#FFF" xlinkHref="#prefix__b" />
          <use fill="#000" filter="url(#prefix__c)" xlinkHref="#prefix__b" />
        </g>
        <path
          d="M63-25.5c8.698 0 16.573 3.526 22.274 9.226C90.974-10.574 94.5-2.698 94.5 6h0v47.5h-479V6c0-8.698 3.526-16.573 9.226-22.274 5.7-5.7 13.576-9.226 22.274-9.226h0z"
          stroke="#FFF"
          fill="#FFFFFF00"
        />
        <g transform="translate(-6 -6)" opacity={1}>
          <path
            d="M25.596 23.548c1.122 0 2.058.813 2.274 1.892l1.159-.642a1.225 1.225 0 011.678.508c.099.185.15.392.15.603v2.782a1.25 1.25 0 01-1.238 1.263c-.206 0-.409-.052-.59-.153l-1.21-.67c-.289.974-1.174 1.683-2.223 1.683h-3.404c-1.281 0-2.32-1.06-2.32-2.369v-2.527c0-1.309 1.039-2.37 2.32-2.37h3.404zm-1.81-12.715c1.572 0 2.847 1.302 2.847 2.907v7.265c0 .42-.087.817-.243 1.177a3.654 3.654 0 00-.794-.087h-3.404a3.726 3.726 0 00-3.188 1.817h-.058v.099a3.866 3.866 0 00-.498 1.907v1.627H15.53a.863.863 0 01-.854-.872c0-.482.382-.872.854-.872h1.708v-1.89h-4.84c-1.572 0-2.847-1.3-2.847-2.906V13.74c0-1.605 1.275-2.907 2.847-2.907h11.388zm-5.68 2.616c-.79-.008-1.434.702-1.434 1.575v.686h-1.026c-.221 0-.401.199-.401.445l.382 5.177c0 .14.102.255.229.255h4.472c.115 0 .21-.094.227-.217l.384-5.215c0-.246-.18-.445-.401-.445h-1.026v-.666c0-.867-.624-1.586-1.406-1.595zm-.014.89c.34 0 .618.308.618.685v.686h-1.236v-.686c0-.377.277-.685.618-.685z"
            fill="#000000"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  )
}

export default StartVideoCall
