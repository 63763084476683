import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import { useSelector} from 'react-redux';
import { logger } from '../logging';


export default function useScreenViews() {
  let location = useLocation();
  const { isUserLoading } = useSelector((state) => state.user);
  const { activeShopId } = useSelector((state) => state.shops);

  useEffect(() => {
    if (!isUserLoading) {
      logger.info('currentLocation', { pathname: location.pathname });
      firebase
        .analytics().logEvent('screen_view', { firebase_screen: '/host' + location.pathname, firebase_screen_class: 'Host' });
    }
  }, [location, isUserLoading, activeShopId]);
}
