import React, { useState, useEffect } from 'react';
import { StyledInput, FilledButton } from '../../components';

const CreateAccount = ({
  shopName,
  accept,
  userCred,
  setErrorMessage,
  inviteData,
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState(null);
  const [isPasswordConfirmed, setPasswordConfirmed] = useState(false);
  const [isButtonLoading, setButtonLoading] = useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(null);
  const [hasPasswordError, setPasswordError] = useState(false);

  const nonEmtpyString = (toTest) => !!toTest && /([^\s])/.test(toTest);

  const handleInput = (setterFunc) => (e) => {
    if (nonEmtpyString(e.target.value)) {
      setErrorMessage(null);
    } else {
      setErrorMessage('Please fill in your name');
    }
    setterFunc(e.target.value);
  };

  const handleFirstNameInput = handleInput(setFirstName);
  const handleLastNameInput = handleInput(setLastName);
  const handlePasswordInput = handleInput(setPassword);

  useEffect(() => {
    setFirstName(inviteData.firstName);
    setLastName(inviteData.lastName);
  }, [inviteData]);

  const testAndSetPassword = (e) => {
    setPasswordConfirmed(false);
    setErrorMessage(null);
    setPasswordError(false);
    if (!e.target.value) {
      return;
    }
    if (/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/.test(e.target.value)) {
      handlePasswordInput(e);
    } else {
      setPasswordError(true);
      setErrorMessage(
        'Minimum eight characters, at least one letter and one number'
      );
    }
  };

  const confirmPassword = (e) => {
    setErrorMessage(null);
    setPasswordError(false);
    if (!e.target.value) {
      return;
    }
    const testedPassword = e.target.value;
    if (testedPassword !== password) {
      setPasswordError(true);
      setErrorMessage('The two passwords that you entered do not match!');
    } else {
      setPasswordConfirmed(true);
    }
  };

  useEffect(() => {
    setButtonDisabled(!nonEmtpyString(firstName) || !nonEmtpyString(lastName) || !password);
  }, [firstName, lastName, password]);

  const onInviteSubmit = () => {
    if (!isPasswordConfirmed) {
      setErrorMessage('Please confirm your password');
      return;
    }
    if (!hasPasswordError) {
      setButtonLoading(true);
      userCred.user
        .updatePassword(password)
        .then(() => {
          return accept(userCred.user.uid, firstName, lastName);
        })
        .catch((err) => {
          console.error('onInviteSubmit',err);
          setButtonLoading(false);
          setErrorMessage('There was an error signing up. Please try again.');
        });
    }
  };

  return (
    <form className='create-account'>
      <h2>You've been invited to {shopName} on Boutiq</h2>
      <div className='name-inputs'>
        <StyledInput
          placeholder='First Name'
          onChange={handleFirstNameInput}
          value={firstName}
        />
        <StyledInput
          placeholder='Last Name'
          onChange={handleLastNameInput}
          value={lastName}
        />
      </div>

      <StyledInput
        placeholder='Password'
        type='password'
        onChange={testAndSetPassword}
      />
      <StyledInput
        placeholder='Confirm Password'
        type='password'
        onChange={confirmPassword}
      />
      <div className='form-footer'>
        <FilledButton
          onClick={onInviteSubmit}
          disabled={isButtonDisabled}
          isLoading={isButtonLoading}>
          Sign Up
        </FilledButton>
      </div>
    </form>
  );
};

export default CreateAccount;
