import React from 'react';
import './customer-search-skeleton.scss';
import LoadingCard from '../loading-card/loading-card';

const CustomerSearchSkeleton = () => {
  return (
    Array(2).fill(0).map((_, key) => (
      <div className='customer-search-skeleton-container' key={key}>
        <LoadingCard
          height={40}
          width={'100%'}
          borderRadius={10}
          marginTop={6}
          marginBottom={10}
        />
        <LoadingCard
          height={20}
          width={'100%'}
          borderRadius={10}
        />
      </div>
    ))
  )
}

export default CustomerSearchSkeleton;
