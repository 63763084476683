import React, {useState, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Trash from '../../../../assets/icons/Trash';
import ShoppingBasketIcon from '../../../../assets/icons/ShoppingBasket';
import { useActiveCallProvider } from '../../../../components/ActiveCallProvider';
import { useSessionCart } from '../../../../hooks';
import { currencyFormatter } from '../../../../utils';
import ImageWithOverlay from '../../../../components/image-with-overlay/image-with-overlay';
import DiscountBar from './DiscountBar'
import {showDraftOrderModal} from "../../../../reducers/active-call-reducer";
import useShopDraftOrderConfig from "../../../../hooks/useShopDraftOrderConfig";
import ReactTooltip from "react-tooltip";

export const Cart = ({ viewItem, clientViewData }) => {
  const dispatch = useDispatch()
  const { availableShops, callShopId } = useSelector((state) => state.shops);
  const shopData = availableShops && callShopId && availableShops[callShopId];

  const [cartData, setCartData] = useState({
    total: null,
    totalDiscount: null,
  });
  const { activeCallId: contextId, activeCallData } = useActiveCallProvider();

  const {
    cartSnapshots,
    updateCartProduct,
    removeCartProduct,
  } = useSessionCart(contextId);

  const { disableDiscounts, draftOrderId, showDraftOrder } = useSelector(state => state.activeCall);
  const [ clientViewingState, setClientViewingSate ] = useState({});
  const { draftOrderConfig } = useShopDraftOrderConfig(callShopId);

  const isCurrencySame = useMemo(() => (
    activeCallData.presentationCurrency === shopData.currencyCode
  ), [shopData, activeCallData])

  useEffect(() => {
    const isItemViewedByClients = (cartKey) => {
      var views = [];
      Object.keys(clientViewData).forEach(clientUuid => {
        const clientProductView = clientViewData[clientUuid] && clientViewData[clientUuid].productView || {};
        if (clientProductView.cartKey === cartKey) {
          views.push(clientUuid);
        }
      })
      return views;
    }

    if (cartSnapshots) {
      let update = {};
      cartSnapshots.forEach(snap=>{
        update[snap.key] = isItemViewedByClients(snap.key);
      });
      setClientViewingSate(update);
    }

  }, [cartSnapshots, clientViewData])

  const calculateCart = () => {
    if (!cartSnapshots) return;
    if (!cartSnapshots.length) {
      setCartData({
        total: 0,
        totalDiscount: 0,
        currencyCode: null,
      });
    }
    const cartTotal = cartSnapshots.reduce(
      (cart, snapshot) => {
        const product = snapshot.val();
        const quantity = product.quantity || 1;
        return {
          total: cart.total + Number.parseFloat(product.currentPrice) * quantity,
          totalBeforeDiscount: cart.totalBeforeDiscount + Number.parseFloat(product.currentCompareToPrice || product.currentPrice) * quantity,
          currencyCode: product.currencyCode,
        };
      },
      { total: 0, totalBeforeDiscount: 0 }
    );
    const totalDiscount = Math.round(((cartTotal.totalBeforeDiscount - cartTotal.total) * 100) / cartTotal.totalBeforeDiscount);
    setCartData({
      total: cartTotal.total,
      totalDiscount,
      currencyCode: cartTotal.currencyCode,
    });
  };

  useEffect(calculateCart, [cartSnapshots]);

  const onShowDraftOrder = () => {
    dispatch(showDraftOrderModal())
  }

  const addOne = (snapshotKey, quantity) => {
    updateCartProduct(snapshotKey, { quantity: quantity + 1 });
  };

  const removeOne = (snapshotKey, quantity) => {
    updateCartProduct(snapshotKey, { quantity: Math.max(quantity - 1, 1) });
  };

  const draftOrderButton = () => (
    <>
      <ReactTooltip
        id="deleteProcessorTooltip"
        className='processor-tooltip'
        effect="solid"
        type='info'
        place='top'
        backgroundColor='#6e6e6e'
        disable={isCurrencySame}
      >
        <span>{`Draft orders in ${activeCallData.presentationCurrency} not supported`}</span>
      </ReactTooltip>
      <p
        data-tip
        data-for="deleteProcessorTooltip"
        style={{
          textDecorationLine: 'underline',
          cursor: 'pointer',
          color: isCurrencySame ? 'black' : 'gray',
          marginLeft: '20px'
        }}
        onClick={isCurrencySame ? onShowDraftOrder : null}
      >
        {`${draftOrderId ? 'Show' : 'Create'} draft order`}
      </p>
    </>
  )

  return (
    <>
      {cartSnapshots.length ? (
        <div className='product-grid'>
          {cartSnapshots.map((snapshot) => {
            const snapshotKey = snapshot.key;
            const product = snapshot.val();
            const {
              image,
              currencyCode,
              currentPrice,
              currentCompareToPrice,
              productTitle,
              productDescription,
              variantTitle,
              inventoryPolicy,
              inventoryQuantity,
              quantity,
            } = product;

            return (
              <div className='product-card' key={snapshotKey}>
                <div className='product-card-image'>
                  <ImageWithOverlay
                    isAdaptive={true}
                    isCart={true}
                    imageUrl={image}
                    alt={productTitle}
                    viewFunction={() =>
                      viewItem(product, snapshot.key, product.variantId)
                    }
                    highlighted={clientViewingState[snapshotKey] && clientViewingState[snapshotKey].length > 0}
                  />
                </div>
                <div className='product-card-details'>
                  <h5>{productTitle}</h5>
                  <div className='price-container'>
                    <h5>{currencyFormatter(currentPrice, currencyCode)}</h5>
                    {currentPrice !== currentCompareToPrice && currentCompareToPrice ? (
                      <h5 className='old-price'>
                        {currencyFormatter(currentCompareToPrice, currencyCode)}
                      </h5>
                    ) : null}
                  </div>
                  <div className='product-params'>
                    <span className='quantity-input'>
                      <button onClick={() => removeOne(snapshotKey, quantity)}>
                        -
                      </button>
                      <input
                        type='number'
                        value={quantity ? quantity : 1}
                        readOnly
                      />
                      <button disabled={inventoryPolicy !== 'CONTINUE' && quantity >= inventoryQuantity} onClick={() => addOne(snapshotKey, quantity)}>
                        +
                      </button>
                    </span>
                    <span className='selected-option'>{variantTitle}</span>
                    <div className='product-card-trash'
                      onClick={() => removeCartProduct(snapshot.key, product)}>
                        <Trash />

                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
          <div className='cart-empty'>
            <ShoppingBasketIcon
              style={{ fontSize: '50px', marginBottom: '25px' }}
            />
            <p>
              Client's cart is empty - Clients can add products from the Showroom.
              You can also add products you recommened
          </p>
          </div>
        )}
      <div className='cart-footer'>
        <div className='divider'></div>
        <div className='cart-footer-content'>
          <div className='start'>
            <h1>{currencyFormatter(cartData.total, cartData.currencyCode)}</h1>
            <div style={{
              flexDirection: 'row'
            }}>
              <p>{cartSnapshots.length || 'No'} items in cart</p>
              {draftOrderConfig && draftOrderConfig.enabled && !showDraftOrder && draftOrderButton()}
            </div>
          </div>
          <div className='end'>
            {!(showDraftOrder || draftOrderId) && (cartSnapshots.length > 0 && !disableDiscounts) && <DiscountBar callId={contextId} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
