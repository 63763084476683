import * as React from "react"

const MoreIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g fill="#6E76F2"
       fillRule="evenodd">
      <circle cx={15.5} cy={8.5} r={1.5} />
      <circle cx={15.5} cy={16.5} r={1.5} />
      <circle cx={15.5} cy={24.5} r={1.5} />
    </g>
  </svg>
)

export default MoreIcon
