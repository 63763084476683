import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/installations';
import 'firebase/compat/firestore';

import {setInstallId, setDeviceData,} from '../reducers/system-reducer';

import { deviceDetect } from 'react-device-detect';

export default function useInstallID(user = null) {
    const dispatch = useDispatch();
    const { installId } = useSelector(state => state.system);

    useEffect(() => {
        firebase.installations().getId().then(id => {
            dispatch(setInstallId(id));
        }).catch(error => {
            console.error('**** useInstallID error', error)
            dispatch(setInstallId(null));
        })
    }, [])

    function getDeviceData() {
        const device = deviceDetect();

        const systemName = device.osName || null;
        const systemVersion = device.osVersion || null;
        const browserName = device.browserName || null;
        const browserVersion = device.browserFullVersion || null;
        const appVersion = process.env.REACT_APP_VERSION;
        return { systemName, systemVersion, browserName, browserVersion, appVersion }
    }

    useEffect(() => {
        if (installId && user) {
            const deviceData = getDeviceData();
            dispatch(setDeviceData(deviceData));
            firebase.firestore()
                .collection('users')
                .doc(user.uid)
                .collection('apps')
                .doc(installId)
                .set({
                    ...deviceData,
                    lastLoginAt: firebase.firestore.Timestamp.now(),
                }, { merge: true })
                .catch(error=>{
                    console.error('useInstallID onlogin', error);
                })
        }
    }, [installId, user])

    const stopInstallId = useCallback(async () => {
        if (installId && user) {
            return firebase.firestore()
                .collection('users')
                .doc(user.uid)
                .collection('apps')
                .doc(installId)
                .update({
                    lastLogoutAt: firebase.firestore.Timestamp.now(),
                }).catch(error=>{
                    console.error('useInstallID onlogout', error);
                })
        }
    }, [installId, user]);

    return { stopInstallId };
}
