import React, { useMemo } from 'react';
import './showroom-preset-item-header.scss';
import { FilledButton } from '../../../buttons/buttons';
import { useShowroomPresetProvider } from '../../../../providers/preset-provider/preset-provider';
import Trash from '../../../../assets/icons/Trash';
import { useSelector } from 'react-redux';

const ShowroomPresetItemHeader = ({ preset, isSelectedPreset, onToggle, onAddPreset }) => {
  const {showroomPresetLoading, isEditPresetMode, onShowRemovePresetModal} = useShowroomPresetProvider()
  const {user} = useSelector((state) => state.user);

  const isPublic = useMemo(() => (
    preset.owner === 'public'
  ), [preset.owner])

  const isOwner = useMemo(() => (
    preset.owner === user?.id
  ), [preset.owner, user?.id])

  const onToggleHeader = (event) => {
    const classname = event.nativeEvent.target.getAttribute('class')
    if (classname !== 'prefix__active-path' || classname !== 'remove-preset') {
      onToggle()
    }
  }

  const subtitle = () => {
    if (isEditPresetMode && isPublic) {
      return <p className='subtitle'>Showroom is available for all hosts</p>
    }
    if (isSelectedPreset && !showroomPresetLoading) {
      return <p className='subtitle'>Loaded</p>
    }
  }

  return (
    <div
      className={`showroom-preset-item-header`}
      onClick={(event) => onToggleHeader(event)}
    >
      <div className='showroom-preset-item-title'>
        <p className='preset-title'>{preset.name} {preset.products?.length && `(${preset.products.length})`}</p>
        {subtitle()}
      </div>
      {isEditPresetMode ? (
          <div
            className={`remove-preset ${!isPublic && isOwner ? '' : 'disabled'}`}
            onClick={!isPublic && isOwner ? () => onShowRemovePresetModal(preset) : null}
          >
            <Trash fill={!isPublic && isOwner ? '#be1919' : 'gray'}/>
          </div>
      ) : (
        <FilledButton
          isLoading={isSelectedPreset && showroomPresetLoading}
          loaderSize={'26px'}
          style={{
            backgroundColor: isSelectedPreset && showroomPresetLoading ? 'transparent' : 'var(--main-color)',
            height: '28px',
            fontSize: '14px',
            padding: '0 10px',
            width: 'auto',
            border: isSelectedPreset && showroomPresetLoading ? '1px solid var(--main-color)' : 'none',
            color: isSelectedPreset && showroomPresetLoading ? 'var(--main-color)' : 'white',
            WebkitTextFillColor: isSelectedPreset && showroomPresetLoading ? 'var(--main-color)' : 'white',
            cursor: 'pointer',
          }}
          onClick={onAddPreset}>
          {'Load'}
        </FilledButton>
      )}
    </div>
  )
}

export default ShowroomPresetItemHeader;