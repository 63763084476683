import React, {useEffect, useMemo, useRef, useState} from 'react';
import './sidebar-client-tabs.scss';
import {CLIENT_SEARCH_RESULT} from "../../../../../../constants/const";
import SearchIcon from "../../../../../../assets/icons/Search";
import CustomerProfileSidebar from "../../customer-profile/customer-profile";
import {useActiveCallProvider} from "../../../../../../components/ActiveCallProvider";
import {useSelector} from "react-redux";
import useClientViewHistory from "../../../../../../hooks/useClientViewHistory";
import useScheduleClientFormData from "../../../../../../hooks/useScheduleClientFormData";
import SidebarTabBar from "../../../../../../components/sidebar-tab-bar/sidebar-tab-bar";
import {CALL_DETAILS_TYPE} from "../../../../../../utils/consts";
import CallFormResponses from "../../../../../home-page/call-details-side-bar/call-form-responses/call-form-responses";
import {CLIENT_VIEW_TYPE} from "../../../../../../hooks/useClientSummary";
import {ArrowBackIos} from '@material-ui/icons';

const SidebarClientTabs = ({
    customer,
    setCustomer,
    clientParticipant,
    allowProductDetails,
    selectProduct,
    viewPreviouslyPurchased,
    shopId,
    isUserLoading,
    setUserLoading,
    visible,
}) => {
  const {activeCallData, activeCallId} = useActiveCallProvider();
  const activeRoom = useSelector((state) => state.activeRoom);
  const {activeContextId: chatActiveContextId} = activeRoom;
  const {clientSearchStatus} = useSelector(state => state.clients);
  const {userRecentlyViewed} = useClientViewHistory(activeCallId);
  const { scheduleClientFormData } = useScheduleClientFormData(shopId, activeCallData ? activeCallData.scheduleId : null, activeCallId);
  const [selectedTab, setSelectedTab] = useState(CALL_DETAILS_TYPE.INFO);
  const isCustomerLoading = clientSearchStatus === CLIENT_SEARCH_RESULT.LOADING;
  const [viewType, setViewType] = useState(CLIENT_VIEW_TYPE.PROFILE);
  const [clientName, setClientName] = useState(null);
  const initCustomerEmail = useMemo(() => (
    activeCallId ? activeCallData.customerEmail : clientParticipant.email
  ), [activeCallId, activeCallData, clientParticipant])
  const initCustomerName = useMemo(() => (
    activeCallId ? activeCallData.customerName : clientParticipant.name
  ), [activeCallId, activeCallData, clientParticipant])
  const customerProfileSidebarRef = useRef()

  useEffect(() => {
    if (!isCustomerLoading) {
      if (customer) {
        setClientName({name: customer.displayName, email: customer.email})
      } else if (activeCallData) {
        setClientName({name: activeCallData.customerName, email: activeCallData.customerEmail, anonymous: true})
      } else if (clientParticipant) {
        setClientName({name: clientParticipant.displayName, email: clientParticipant.email, anonymous: true})
      }
    }
  }, [customer, isCustomerLoading, activeCallData, clientParticipant])

  const profileHeader = () => (
    <div className='customer-profile-side-bar-header'>
      <p>Client Profile</p>
      {!(customer && customer?.email !== initCustomerEmail) && (
        <button className='customer-search-button'>
          <SearchIcon
            onClick={customerProfileSidebarRef.current?.onSearchPress}
            style={{color: '#66676c'}}
          />
        </button>
      )}
    </div>
  )

  const profileComponent = (className) => (
    <div className={className}>
      <CustomerProfileSidebar
        ref={customerProfileSidebarRef}
        clientName={clientName}
        user={customer}
        initClientEmail={initCustomerEmail}
        initClientName={initCustomerName}
        currentContextId={activeCallId ? activeCallData.currentContextId : chatActiveContextId}
        isCustomerLoading={isUserLoading}
        setCustomerLoading={setUserLoading}
        setCustomer={setCustomer}
        userRecentlyViewed={userRecentlyViewed}
        viewItem={allowProductDetails ? selectProduct : null}
        viewPreviouslyPurchased={allowProductDetails ? viewPreviouslyPurchased : null}
        header={profileHeader}
        setViewType={setViewType}
        shopId={activeCallData?.shopId}
      />
    </div>
  )

  const tabsComponent = () => (
    <>
      {!isUserLoading && (
        <SidebarTabBar
          barStyle={{
            margin: 16
          }}
          tabs={[
            {
              key: CALL_DETAILS_TYPE.INFO,
              label: 'Info'
            },
            {
              key: CALL_DETAILS_TYPE.FORM_RESPONSES,
              label: 'Form responses',
            }
          ]}
          selectedTab={selectedTab}
          onPressTab={setSelectedTab}
        />
      )}
      <div>
        {
          profileComponent(
          `client-details-tab${visible && selectedTab === CALL_DETAILS_TYPE.INFO ? ' visible' : ''}`
          )
        }
        <CallFormResponses
          visible={visible && selectedTab === CALL_DETAILS_TYPE.FORM_RESPONSES}
          clientForm={scheduleClientFormData}
        />
      </div>
    </>
  )

  return (
    <div className={`sidebar-client-tabs${visible ? ' visible' : ''}`}>
      {!isUserLoading && (
        viewType === CLIENT_VIEW_TYPE.PROFILE && (
          <div className='customer-profile-found-client-header'>
            {customer && customer?.email !== initCustomerEmail ? (
              <div className='customer-profile-found-name'>
                <button className='customer-search-panel-back-button'>
                  <ArrowBackIos
                    onClick={customerProfileSidebarRef.current?.onSearchPress}
                    style={{color: '#66676c'}}
                  />
                </button>
                <h3>{clientName?.name}</h3>
              </div>
            ) : (
              <h3>{clientName?.name}</h3>
            )}
            <p>{clientName?.email}</p>
            {customer?.phone && <p>{customer.phone}</p>}
          </div>
        )
      )}
      {scheduleClientFormData
        ? tabsComponent()
        : profileComponent(`client-details-tab${visible ? ' visible' : ''}`)
      }
    </div>
  )
}

export default SidebarClientTabs;