import * as React from "react"

const IconDraftOrder = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g stroke={props.color ?? "#6E76F2"} fill="none" fillRule="evenodd" strokeLinejoin="round">
      <path strokeLinecap="round" d="M4 5.123h10m-10 3h10m-10 3h5.001" />
      <path d="m18.052 8.296-7.558 9.007-.188 2.73 2.662-.653 7.558-9.008-2.474-2.076z" />
    </g>
  </svg>
)

export default IconDraftOrder
