import React, { useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { StyledInput } from '../inputs/inputs';
import { FilledButton } from '../buttons/buttons';
import useCaazamREST from '../../hooks/useCaazamREST';
import { isEmailValid } from '../../utils';

const Option = ({ label, value, innerProps }) => {
  return (
    <div className='option' {...innerProps}>
      <p>{label}</p>
      <p>{value.email}</p>
    </div>
  );
};

function SelectClient({ search, onChange }) {
  const [isLoading, setIsLoading] = useState(false);
  const onSearch = (input) => {
    setIsLoading(true);
    return search(input)
      .then((searchResults) => {
        return searchResults.map((res) => ({
          value: res,
          label: res.displayName,
          email: res.email,
        }));
      })
      .catch((error) => {
        console.error('Search failed', error);
        return [];
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <AsyncCreatableSelect
      isClearable
      isLoading={isLoading}
      loadOptions={onSearch}
      createOptionPosition='first'
      formatCreateLabel={(value) => <strong>{value}</strong>}
      onChange={(value) => onChange(value)}
      noOptionsMessage={() => <>Search or enter client name</>}
      placeholder='Full Name*'
      classNamePrefix='customer-select'
      components={{ Option }}
    />
  );
}

const CustomerSearch = ({
  customer,
  email,
  setEmail,
  onCustomerNameChanged,
  shopId,
  availableSlots,
  routeToSchedule,
  onInviteFinish,
  loading,
  formErrorMessage,
  formWarningMessage,
}) => {
  const { searchCustomers } = useCaazamREST();
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);

  const handleEmailInput = (e) => {
    setEmail(e.currentTarget.value);
    setEmailErrorMessage(null);
  };

  const validateEmail = () => {
    if (email && !isEmailValid(email)) {
      setEmailErrorMessage('Enter a valid email');
    }
  }

  return (
    <form>
      <h2>Invite a Client</h2>
      <p>
        Enter a client name and email and create an invite link you can share
        with your client
      </p>
      <p>
        Select either to create an instant call link or to schedule an
        appointment for a later time/day
      </p>
      <section className='inputs'>
        <SelectClient
          search={(term) => searchCustomers(shopId, term)}
          value={customer}
          onChange={onCustomerNameChanged}
        />
        <StyledInput
          placeholder='Email'
          type='email'
          value={email}
          onChange={handleEmailInput} 
          onBlur={validateEmail}
          />
      </section>
      <div className='actions'>
        <FilledButton
          className='button'
          style={{ width: '184px' }}
          disabled={!customer || !email || !availableSlots || formErrorMessage || emailErrorMessage || formWarningMessage}
          onClick={routeToSchedule}>
          Schedule
        </FilledButton>
        <FilledButton
          className='button'
          style={{ width: '160px' }}
          disabled={!customer || emailErrorMessage}
          onClick={onInviteFinish}
          isLoading={loading}>
          Create link
        </FilledButton>
      </div>
      <p className={formErrorMessage || emailErrorMessage ? 'error-text' : 'warning-text'}>{formErrorMessage || emailErrorMessage || formWarningMessage}</p>
    </form>
  );
};

export default CustomerSearch;
