import React, { useState } from 'react';
import { FilledButton } from '../buttons/buttons';
import { ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';
import './select-slot.scss';
import {displayTime} from '../../utils';
import AvailableSlotsSkeleton from '../skeletons/available-slots-skeleton/available-slots-skeleton';

const SelectSlot = ({
  selectSlot,
  scheduleCall,
  isScheduling,
  selectedSlot,
  availableSlots,
  rescheduledSlot,
  isInviteFlow = true,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [currentDay, setCurrentDay] = useState(0);

  if (!availableSlots) return <AvailableSlotsSkeleton />;

  const onPageChange = (page) => {
    setCurrentPage(page);
    setCurrentDay(0);
  };

  const selectDayOne = () => setCurrentDay(0);
  const selectDayTwo = () => setCurrentDay(1);

  const slotsForPage = availableSlots.slice(
    currentPage * 2,
    (currentPage + 1) * 2
  );
  const hasNext = availableSlots.length > (currentPage + 1) * 2;
  const hasPrev = currentPage > 0;

  const isDateSelected = (dateObject) =>
    selectedSlot &&
    dateObject.isSame(selectedSlot.timeSlot);

  const isPrevSlot = (timeSlot) => {
    if (!rescheduledSlot) return '';
    return timeSlot.isSame(rescheduledSlot) ? ' scheduled' : '';
  }

  const SchedulerHeader = () => {
    return (
      <div className='tabs'>
        <span className='navigation'>
          {hasPrev && (
            <ArrowBackIos onClick={() => onPageChange(currentPage - 1)} />
          )}
        </span>
        <span className='date-title'>
          <span
            className={`${currentDay === 0 ? 'selected' : ''}`}
            onClick={selectDayOne}>
            {slotsForPage[0].date}
          </span>
        </span>
        <span className='date-title'>
          {slotsForPage[1] && (
            <span
              className={`${currentDay === 1 ? 'selected' : ''}`}
              onClick={selectDayTwo}>
              {slotsForPage[1].date}
            </span>
          )}
        </span>
        <span className='navigation'>
          {hasNext && (
            <ArrowForwardIos onClick={() => onPageChange(currentPage + 1)} />
          )}
        </span>
      </div>
    );
  };

  return (
    <div className='scheduler-container'>
      <header>
        <h3>{isInviteFlow ? 'Schedule' : 'Reschedule'}</h3>
        <p>{isInviteFlow ? 'Select a date and time' : 'Select a new date or time'}</p>
        <SchedulerHeader />
      </header>
      <main>
        <div className='slots-container'>
          {slotsForPage[currentDay].slots &&
            slotsForPage[currentDay].slots.map((session) => {
              return (
                <div
                  key={session.timeSlot}
                  className={`time-slot 
          ${session.isAvailable ? '' : 'unavailable '}
          ${isDateSelected(session.timeSlot) ? 'selected' : ''}
          ${isPrevSlot(session.timeSlot)}`}
                  onClick={() => selectSlot(session)}>
                  <span>{displayTime(session.timeSlot.toDate())}</span>
                  <span>{session.timeSlotEnd.diff(session.timeSlot, 'minutes')} minutes</span>
                </div>
              );
            })}
        </div>
      </main>
      <footer>
        <div className='actions-container'>
          <FilledButton
            onClick={scheduleCall}
            disabled={!selectedSlot}
            className={`${!selectedSlot ? 'disabled' : 'button'}`}
            isLoading={isScheduling}>
            {'Next'}
          </FilledButton>
        </div>
      </footer>
    </div>
  );
};

export default SelectSlot;
