import React from 'react';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import './customer-search.scss';
import CustomerSearchSkeleton
  from '../../../../../components/skeletons/customer-search-skeleton/customer-search-skeleton';

const CustomerSearch = ({ loading, searchResults, selectNewCustomer }) => {
  return (
    <div className='customer-search-container'>
      {loading ? (
        <CustomerSearchSkeleton />
      ) : searchResults ? (
        <>
          {searchResults.map((customer) => (
            <div
              className='customer-card'
              onClick={() => selectNewCustomer(customer)}
              key={customer.id}>
              <h3>{customer.displayName}</h3>
              <p>{customer.email}</p>
            </div>
          ))}
            {!searchResults.length && <div className='empty'>
              <p>No clients found</p>
              <p>Try changing the search term</p>
            </div>}
          </>
        ) : (
        <div className='empty'>
          <PersonRoundedIcon
            style={{
              color: '#CFC8BB',
              fontSize: '80px',
              WebkitTextFillColor: '#CFC8BB',
            }}
          />
          <p>Search for client profiles</p>
        </div>
      )}
    </div>
  );
};

export default CustomerSearch;
