import React, { useState, useEffect } from 'react';
import ImageWithOverlay from '../../../../../../components/image-with-overlay/image-with-overlay';
import './index.scss';

const TABS = {
    RECENTLY_VIEWED: 'recentlyViewed',
    RECOMMENDATIONS: 'recommendations'
}

export const ProductsSuggectionSection = ({
    recentlyViewed = [],
    recommendations = [],
    viewItem
}) => {
    const [activeTab, setActiveTab] = useState(recentlyViewed.length > 0 ? TABS.RECENTLY_VIEWED : TABS.RECOMMENDATIONS);
    const [productsArray, setProductsArray] = useState([]);

    useEffect(() => {
        if (activeTab === TABS.RECENTLY_VIEWED) {
            setProductsArray(recentlyViewed)
        } else if (activeTab === TABS.RECOMMENDATIONS) {
            setProductsArray(recommendations)
        } else {
            setProductsArray([]);
        }
    }, [activeTab, recentlyViewed, recommendations]);

    useEffect(() => {
        if (recentlyViewed.length === 0) {
            setActiveTab(TABS.RECOMMENDATIONS)
        } else {
            setActiveTab(TABS.RECENTLY_VIEWED)
        }
    }, [recentlyViewed, recommendations])

    return (
        <div className='products-suggection-section'>
            <div className='tabs-container'>
                {recentlyViewed.length > 0 && (
                    <div onClick={() => setActiveTab(TABS.RECENTLY_VIEWED)}
                        className={`tab-item${activeTab === TABS.RECENTLY_VIEWED ? ' active' : ''}`}>
                        <h4>Recently Viewed</h4>
                    </div>
                )}
                {recommendations.length > 0 && (
                    <div onClick={() => setActiveTab(TABS.RECOMMENDATIONS)}
                        className={`tab-item${activeTab === TABS.RECOMMENDATIONS ? ' active' : ''}`}>
                        <h4>Recommendations</h4>
                    </div>
                )}
            </div>
            <div className='products-suggection-container'>
                <div className='product-view'>
                    {productsArray.map((productStub, i) => (
                        <ImageWithOverlay
                            isAdaptive={true}
                            imageUrl={productStub.image}
                            alt={productStub.productTitle}
                            viewFunction={viewItem ? () => viewItem(productStub.productId) : null}
                            key={i}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}
