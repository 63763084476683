import CircleProgress from "../circleProgress";
import { currencyFormatter } from "../../utils";
import React from "react";
import './ClientProfileChart.scss'
import { useSelector } from "react-redux";
import { CLIENT_SEARCH_RESULT } from "../../constants/const";

const ClientProfileChart = ({ user, config, containerClass, circleBackground = 'white' }) => {
  const { clientSearchStatus } = useSelector(state => state.clients);
  const averageOrderAmountVal = (value) => {
    if (isNaN(value)) {
      return -1
    }
    if (value === 0) {
      return 0;
    } else {
      return Math.round(value / 5);
    }
  };

  const maxOrderAmountVal = (value) => {
    if (isNaN(value)) {
      return -1
    }
    if (value === 0 || !value) {
      return 0;
    } else {
      return Math.round(value / 10);
    }
  };

  const totalSpendVal = (tSpend) => {
    tSpend = Number(tSpend);
    if (tSpend === 0) {
      return 0;
    } else if (tSpend < 500) {
      return 10;
    } else if (tSpend < 1000) {
      return 20;
    } else if (tSpend < 1500) {
      return 30;
    } else if (tSpend < 2500) {
      return 40;
    } else if (tSpend < 5000) {
      return 50;
    } else if (tSpend < 10000) {
      return 60;
    } else if (tSpend < 15000) {
      return 70;
    } else if (tSpend < 25000) {
      return 80;
    } else if (tSpend < 50000) {
      return 90;
    } else if (tSpend > 50000) {
      return 100;
    } else if (isNaN(tSpend)) {
      return -1
    }
  };

  const valueRepresentation = (value, currencyCode) => {
    let props = {};
    if (Number(value) >= 10000) {
      props = { notation: 'compact', minimumFractionDigits: 2 }
    }
    return currencyFormatter(value, currencyCode, props)
  }

  const circleContainer = (percentage, description, amount) => {
    const userIsFetched = () => {
      if (clientSearchStatus === CLIENT_SEARCH_RESULT.LOADING) {
        return !!user;
      } else {
        return true
      }
    }

    return (
      <div className={`circle-purchase-animation${userIsFetched() ? '' : ' running'}`}>
        <CircleProgress
          percentage={percentage}
          description={description}
          amount={amount}
          userIsFetched={userIsFetched()}
          circleBackground={circleBackground}
        />
      </div>
    )
  }

  return (
    <div className={`client-profile-chart-container ${containerClass}`}>
      {config.showAvgBasket && circleContainer(
        averageOrderAmountVal(user?.averageOrderAmount),
        'Avg basket',
        user?.averageOrderAmount ? valueRepresentation(user?.averageOrderAmount, user?.currencyCode) : null
      )}
      {config.showMaxBasket && circleContainer(
        maxOrderAmountVal(user?.maxOrderAmount),
        'Max basket',
        user?.maxOrderAmount ? valueRepresentation(user?.maxOrderAmount, user?.currencyCode) : null
      )}
      {config.showTotalSpend && circleContainer(
        totalSpendVal(user?.totalSpent),
        'Total spend',
        user?.totalSpent ? valueRepresentation(user?.totalSpent, user?.currencyCode) : null
      )}
    </div>
  )
}

export default ClientProfileChart
