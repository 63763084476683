import React, { useEffect } from 'react';
import HostSettingMenu from './menu';
import './host-settings.scss';
import {useHostSettingsProvider} from "../../../components/hostSettingsProvider/host-settings-provider";

const HostSettings = ({ toggleSettings }) => {
	const { isSettingsVisible, selectedTab, setSelectedTab } = useHostSettingsProvider();

	useEffect(() => {
		toggleSettings(isSettingsVisible);
	}, [isSettingsVisible])

	return (
		<>
			{isSettingsVisible && (
				<>
					<HostSettingMenu onChangeTab={setSelectedTab} selectedTab={selectedTab.label} />
					<div className='settings-container-wrapper'>
						{selectedTab.tabContent}
					</div>
				</>
			)}
		</>
	);
};

export default HostSettings;
