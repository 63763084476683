import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const MEDIA_BUCKET = process.env.REACT_APP_FIREBASE_MEDIA_BUCKET;
const IMAGE_MAX_SIZE = 10 * 1024 * 1024;
const IMAGE_MAX_LENGTH = 2000;

const firestore = firebase.firestore;
const storage = firebase.storage;

const putFileToStorage = async (ref, file, metadata) => {
  if (file.isBase64) {
    return ref.putString(file.data, 'data_url', {...metadata, contentType: file.type});
  } else {
    delete file.data;
    delete file.isBase64;
    return ref.put(file, {...metadata, contentType: file.type});
  }

}

export {
  firebase,
  firestore,
  storage,
  putFileToStorage,
  MEDIA_BUCKET,
  IMAGE_MAX_SIZE,
  IMAGE_MAX_LENGTH
};
