import { useCallback } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';

export default function useHostSchedulingConfig(shopId, userId) {
  
  const hostDoc = firebase.firestore().doc(`shops/${shopId}/hostAvailabilityConfig/${userId}`);

  const [hostSchedulingConfig, hostSchedulingConfigLoading, error] = useDocumentData(hostDoc);
  error && console.error('useHostSchedulingConfig', error);

  const toggleDefaultBusinessHours = useCallback(async ()=>{
    const current = hostSchedulingConfig && hostSchedulingConfig.defaultBusinessHours;
    await hostDoc.set({ defaultBusinessHours: !current }, { merge: true});
  },[hostSchedulingConfig])

  return {
    hostSchedulingConfig,
    hostSchedulingConfigLoading,
    toggleDefaultBusinessHours,
  };
}
