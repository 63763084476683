import { configureStore, createAction, createReducer } from '@reduxjs/toolkit';
import rootReducer from './reducers/root-reducer';

const store = configureStore({
  reducer: rootReducer,
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducers/root-reducer', () => {
    const newRootReducer = require('./reducers/root-reducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export default store;
