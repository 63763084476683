import * as React from "react"

const ProductActive = (props) => (
  <svg width={24} height={24} {...props} viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <path
        d="M28.141 28.006c1.948-2.226.86-5.092.746-5.672-.225-1.139-.001-1.467-.746-3.608-.745-2.142-2.33-2.618-1.948-4.136.7-2.773-3.573-2.39-5.406-4.235-2.432-2.45-8.969-2.327-10.456-2.327-3.965 0-4.904 8.954-5.018 10.698-.111 1.704-.46 6.17 2.103 8.79 2.562 2.618 5.565 1.715 8.641 2.19 3.076.474 10.136.527 12.084-1.7Z"
        fill="#D0D5FF"
      />
      <path
        d="M6.973 4.485a1.381 1.381 0 0 1 1.328 1l.031.134.595 3.272h18.088a.5.5 0 0 1 .5.506l-.008.083-1.339 7.365a2.262 2.262 0 0 1-2.028 1.85l-.198.008H10.71l.619 3.406H23.49a2.703 2.703 0 1 1-2.099 1h-7.258a2.703 2.703 0 1 1-3.722-.459L7.349 5.798a.381.381 0 0 0-.309-.307l-.066-.006H4.985a.5.5 0 0 1-.09-.992l.09-.008h1.988Zm5.062 18.624a1.703 1.703 0 1 0 0 3.406 1.703 1.703 0 0 0 0-3.406Zm11.455 0a1.703 1.703 0 1 0 0 3.406 1.703 1.703 0 0 0 0-3.406ZM26.416 9.89H9.108l1.42 7.813h13.414a1.263 1.263 0 0 0 1.207-.894l.035-.143 1.232-6.776Z"
        fill="#66676C"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default ProductActive
