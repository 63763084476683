import React, {Fragment} from 'react';
import './customer-profile-skeleton.scss';
import LoadingCard from '../loading-card/loading-card';

const CustomerProfileSkeleton = () => {
  return (
    <div className='customer-profile-skeleton-container'>
      <div className='customer-profile-skeleton-row-wrapper'>
        <LoadingCard
          margin={2}
          height={30}
          width={120}
          borderRadius={10}
        />
        <LoadingCard
          margin={2}
          height={40}
          width={40}
          borderRadius={40}
        />
      </div>
      <div className='customer-profile-skeleton-row-wrapper'>
        <LoadingCard
          margin={0}
          marginTop={20}
          height={120}
          width={120}
          borderRadius={60}
        />
        <LoadingCard
          margin={0}
          marginTop={20}
          height={120}
          width={120}
          borderRadius={60}
        />
        <LoadingCard
          margin={0}
          marginTop={20}
          height={120}
          width={120}
          borderRadius={60}
        />
      </div>
      <div className='customer-profile-description-skeleton'>
        {Array(4).fill(0).map((_, index) => (
          <div key={index} className='customer-profile-skeleton-row-wrapper underline'>
            <LoadingCard
              marginLeft={0}
              height={20}
              width={140}
              borderRadius={10}
            />
            <LoadingCard
              marginRight={0}
              height={20}
              width={100}
              borderRadius={10}
            />
          </div>
        ))}
      </div>
      <div className='customer-profile-description-skeleton'>
        <LoadingCard
          marginLeft={0}
          height={20}
          width={200}
          borderRadius={10}
        />
        <div className='customer-profile-recommendation-skeleton'>
          {Array(2).fill(0).map((_, index) => (
            <Fragment key={index}>
              <LoadingCard
                marginLeft={0}
                marginRighht={10}
                height={120}
                width={100}
                borderRadius={10}
              />
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CustomerProfileSkeleton;
