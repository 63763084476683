import React, { useEffect, useMemo } from 'react';

import SpeedIcon from "@material-ui/icons/Speed";
import AnimatedDots from "../../../../../components/animatedDots";
import { getPersistedDiagnostic } from "../../ulits/diagnostics";
import { useSelector } from "react-redux";
import { useAppDiagnosticsProvider } from "../../AppDiagnosticsProvider";
import { getBitrateDisplay } from '../../ulits/diagnostics';
import { useDevices } from '@caazam/boutiq-video-room';
import './styles.scss';

const AppDiagnostic = ({ auto = false }) => {
  const { activeShopId } = useSelector((state) => state.shops);
  const { startDiagnostics, stopDiagnostics } = useAppDiagnosticsProvider();
  const { finalResult: diagnosticsFinalResult, preflightTestInProgress } = useSelector((state) => state.appDiagnostics);

  const { camState } = useDevices();

  const isCamError = useMemo(() => camState !== 'idle' && camState !== 'granted', [camState])

  useEffect(() => {
    if (auto && activeShopId) {
      startDiagnostic();
    }
  }, [auto, activeShopId])

  useEffect(() => {
    return () => {
      stopDiagnostics();
    };
  }, []);

  useEffect(()=>{
    if ( isCamError && preflightTestInProgress) {
      stopDiagnostics();
    }
  },[camState, preflightTestInProgress])

  const startDiagnostic = () => {
    startDiagnostics();
  }

  const renderDiagnosticResult = ({ isShow = false, test = {}, key = '', error = '' }) => {
    return (
      <>
        <div className={`diagnostic-result ${isShow && test.value ? 'fade-in' : ''} ${isShow && (test.error || test.value === null) ? 'diagnostic-result_hidden' : ''}`}>
          <p className='diagnostic-result_key'>{key}</p>
          <p className='diagnostic-result_value'>{test.value}</p>
        </div>
        <div className={`diagnostic-result_error ${isShow && (test.error || (!test.error && test.value === null)) ? 'fade-in' : ''} ${isShow && (!test.error && test.value !== null) ? 'diagnostic-result_hidden' : ''}`}>
          <p className='diagnostic-result_key'>{key}</p>
          <p className='diagnostic-result_value_error'>{error}</p>
        </div>
      </>
    );
  }

  const persistedDiagnostic = getPersistedDiagnostic();

  const resultsForDisplay = useMemo(() => diagnosticsFinalResult ?? persistedDiagnostic ?? null,
    [diagnosticsFinalResult, persistedDiagnostic])

  const isShowDiagnosticResult = useMemo(() => {
    return resultsForDisplay && !preflightTestInProgress;
  }, [resultsForDisplay]);

  const connectivityResult = useMemo(() => {
    if (resultsForDisplay?.connectivityResults?.signalingGateway &&
      resultsForDisplay?.connectivityResults?.turnServer) {
      return {
        value: 'operational',
        error: false
      }
    } else {
      return {
        value: 'failed',
        error: true
      }
    }
  }, [resultsForDisplay]);

  const networkConditionResult = useMemo(() => {
    return {
      value: resultsForDisplay?.preflightTestReport?.result?.totalQualityLabel,
      error: resultsForDisplay?.preflightTestReport?.error,
    }
  }, [resultsForDisplay]);

  const bitrateQualityResult = useMemo(() => {
    return {
      value: getBitrateDisplay(resultsForDisplay?.preflightTestReport?.result),
      error: resultsForDisplay?.preflightTestReport?.error,
    }
  }, [resultsForDisplay]);

  const diagnosticDate = useMemo(() => {
    if (persistedDiagnostic?.date) {
      return `Test last run: ${persistedDiagnostic.date}`;
    }
    return null;
  }, [persistedDiagnostic]);

  return (
    <div className='settings-section'>
      <SpeedIcon className='section-icon' />
      <h2 className='section-title' style={{ marginLeft: '0.4em' }}>Test Connection</h2>
      <div className='settings-container'>
        <div className='test-description'>
          <div>
            <div><p className='section-settings-title'>Test your connection to Boutiq services.</p></div>
            <div><p className='test-camera-notice' >Your camera will be activated for the duration of the test</p></div>
          </div>
          <button disabled={preflightTestInProgress} className={`start-diagnostic-button`} onClick={startDiagnostic}>
            {
              (preflightTestInProgress)
                ? <AnimatedDots />
                : 'Start Test'
            }
          </button>
        </div>
        {
          (preflightTestInProgress) &&
          <div className='test-running-notice'>
            <p>Test is running and will take about 30 seconds</p>
            <p>Please do not navigate away while test is running</p>
          </div>
        }
        <div>
          {isCamError && <p className='test-running-error'>There was an error trying to access your camera for the test: {camState}</p>}
          {renderDiagnosticResult({
            isShow: isShowDiagnosticResult,
            test: connectivityResult,
            key: 'Connectivity:',
            error: 'we’ve detected network connectivity issues'
          })}
          {renderDiagnosticResult({
            isShow: isShowDiagnosticResult,
            test: networkConditionResult,
            key: 'Network conditions:',
            error: 'error'
          })}
          {renderDiagnosticResult({
            isShow: isShowDiagnosticResult,
            test: bitrateQualityResult,
            key: 'Network speed:',
            error: 'test not run - network error'
          })}
          <p className={`diagnostic-result_date  ${isShowDiagnosticResult ? 'fade-in' : ''}`}>{diagnosticDate}</p>
        </div>
      </div>
    </div>
  )
};

export default AppDiagnostic;
