import * as React from "react"

const RegularUser = (props) => (
  <svg width={24} height={24} {...props} viewBox="0 0 32 32">
    <g fill="#66676C" fillRule="nonzero">
      <path
        d="M16 5.075a7.752 7.752 0 1 0 0 15.505 7.752 7.752 0 0 0 0-15.505Zm0 1a6.752 6.752 0 1 1 0 13.505 6.752 6.752 0 0 1 0-13.505Z"/>
      <path
        d="M16 19.58a13.195 13.195 0 0 1 11.426 6.594.5.5 0 1 1-.866.5 12.195 12.195 0 0 0-21.12 0 .5.5 0 1 1-.866-.5A13.196 13.196 0 0 1 16 19.58Z"/>
    </g>
  </svg>
);

export default RegularUser
