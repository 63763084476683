import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import firebase from 'firebase/compat/app';
import { useAuthState } from 'react-firebase-hooks/auth';
import LoadingLogo from '../loading-logo/loading-logo';

const PrivateRoute = ({ children, ...rest }) => {
  const [user, authLoading] = useAuthState(firebase.auth());
  const { isUserLoading } = useSelector((state) => state.user);

  if (authLoading || (user && isUserLoading)) return <LoadingLogo />;
  return (
    <Route
      {...rest}
      render={() => (user ? children : <Redirect to='/login' />)}
    />
  );
};

export default PrivateRoute;
