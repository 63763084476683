import React from 'react';
import ThumbUpIcon from '../assets/icons/ThumbUpIcon';
import ThumbDownIcon from '../assets/icons/ThumbDownIcon';
import { RatingStars } from './ratingStars';
import { FEEDBACK_TYPE } from '../utils/consts';

export default function CallFeedbackRating({ feedbackType, ratingData }) {

    const getStyling = (value) => {
        let type = 'distructive';
        if (feedbackType === FEEDBACK_TYPE.CSAT) {
            if (value >= 4) {
                type = 'positive';
            } else {
                type = 'neutral';
            }
        } else if (feedbackType === FEEDBACK_TYPE.NPS) {
            if (value > 8) {
                type = 'positive';
            } else if (value > 6 && value <= 8) {
                type = 'neutral';
            }
        }
        return { type };
    }

    if (feedbackType === FEEDBACK_TYPE.NPS) {
        if (ratingData.npsRating != null) {
            return <RatingStars value={ratingData.npsRating} styling={getStyling(ratingData.npsRating)}/>;
        }
    } else if (feedbackType === FEEDBACK_TYPE.CSAT) {
        if (ratingData.csatRating != null) {
            return <RatingStars value={ratingData.csatRating} styling={getStyling(ratingData.csatRating)}/>;
        }
    } else if ( feedbackType === FEEDBACK_TYPE.SIMPLE) {
        if (ratingData.rating != null) {
            return ratingData.rating === 5 ?
                <ThumbUpIcon style={{ fill: '#08b273' }} /> :
                <ThumbDownIcon style={{ fill: '#d83a68' }} />
        }
    }

    return null;
}
