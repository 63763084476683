import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import useUserAppSettings from "../../hooks/useUserAppSettings";
import {SETTING_MENU_ITEMS} from "../../pages/home-page/host-setting/tabs";
import storageFactory from "../../utils/storageFactory";

export const SettingsContext = createContext(null);

export default function HostSettingsProvider({ children }) {
  const {availableShops} = useSelector((state) => state.shops);
  const {
    hostAppEventsShowOnlyActiveShop,
    updateHostAppEventsShowOnlyActiveShop,
  } = useUserAppSettings()
  const localStore = storageFactory(() => localStorage);
  const {activeShopId} = useSelector((state) => state.shops);

  const hostShops = useMemo(() => (
    availableShops ? Object.keys(availableShops) : []
  ), [availableShops])

  //this field remove dashboard refresh when change shop
  //if enabled multiple shop events or host has only one shop
  const onListenChangeShop = useMemo(() => (
    hostAppEventsShowOnlyActiveShop || hostShops.length === 1 ? activeShopId : null
  ), [hostAppEventsShowOnlyActiveShop, activeShopId, hostShops])

  const shopNameIsVisible = useMemo(() => (
    hostAppEventsShowOnlyActiveShop ? false : availableShops && Object.keys(availableShops).length > 1
  ), [availableShops, hostAppEventsShowOnlyActiveShop])

  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState(SETTING_MENU_ITEMS[0]);
  const [isIncomingCallSoundMuted, setIncomingCallSoundMuted] = useState(false)

  useEffect(() => {
    const state = localStore.getItem('isIncomingCallSoundMuted')
    setIncomingCallSoundMuted(state === 'true')
  }, [])

  const toggleActiveShopEvents = () => {
    updateHostAppEventsShowOnlyActiveShop(!hostAppEventsShowOnlyActiveShop)
  }

  const toggleMuteIncomingCallSound = () => {
    const state = localStore.getItem('isIncomingCallSoundMuted') === 'true';
    localStore.setItem('isIncomingCallSoundMuted', state ? 'false' : 'true')
    setIncomingCallSoundMuted(!state)
  }

  return <SettingsContext.Provider value={{
    isSettingsVisible,
    selectedTab,
    setSelectedTab: (tab) => setSelectedTab(tab),
    toggleSettingsDialog: (state) => setIsSettingsVisible(state),
    onlyActiveShopEvents: hostAppEventsShowOnlyActiveShop,
    toggleActiveShopEvents,
    hostShops,
    shopNameIsVisible,
    onListenChangeShop,
    toggleMuteIncomingCallSound,
    isIncomingCallSoundMuted
  }}>
    {children}
  </SettingsContext.Provider>;
}

export function useHostSettingsProvider() {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('useHostSettingsProvider must be used within the HostSettingsProvider');
  }
  return context;
}
