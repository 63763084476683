import React, { useEffect } from 'react';
import Moment from 'moment-timezone';
import { currencyFormatter } from '../utils';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/messaging';
import IconChatActive from "../assets/icons/chatActive";
import IconNotificationBag from "../assets/icons/notificationBag";
import IconNotificationCart from "../assets/icons/notificationCart";
import IconNotificationClock from "../assets/icons/notificationClock";

function renderSchedulingTitle(data, suffix) {
  if (data.title) {
    return `${data.title} ${suffix}`
  } else {
    return `Call with ${data.customerName} ${suffix}`    
  }
}
export default function useHostPushNotifications(showToast, setConfettiVisible ) {
  useEffect(() => {
    if (firebase.messaging.isSupported()) {
      const unsubscribe = firebase.messaging().onMessage((payload) => {
        if (payload.data) {
          const shopId = payload.data.shopId
          switch (payload.data.type) {
            case 'new_pending_chat':
              showToast({
                title: `New chat request`,
                description: `By ${payload.data.customerName}`,
                icon: <IconChatActive
                  height={44}
                  width={44}
                  viewBox='0 0 32 32'
                  fillColor={'#BDC4FF'}
                />,
                preventAutoDismiss: true,
                excludes: ['/video-room'],
                shopId
              });
              break;
            case 'new_chat_message':
              showToast({
                title: `${payload.data.senderName}`,
                description: `${payload.data.textPreview}`,
                icon: <IconChatActive
                  height={44}
                  width={44}
                  viewBox='0 0 32 32'
                  fillColor={'#BDC4FF'}
                />,
                preventAutoDismiss: true,
                excludes: ['/video-room', '/chat'],
                shopId
              });
              break;
            case 'new_order':
              setConfettiVisible(true);
              showToast({
                title: `New sale for ${currencyFormatter(
                  payload.data.amount,
                  payload.data.currencyCode
                )}`,
                description: `By ${payload.data.customerName}`,
                icon: <IconNotificationBag />,
                type: 'success',
                preventAutoDismiss: true,
                shopId
              });
              break;
            case 'new_checkout':
              showToast({
                title: `New checkout for ${currencyFormatter(
                  payload.data.amount,
                  payload.data.currencyCode
                )}`,
                description: `By ${payload.data.customerName} - ${payload.data.count} items`,
                icon: <IconNotificationCart />,
                type: 'checkout',
                preventAutoDismiss: true,
                shopId
              });
              break;
            case 'pending_schedule_event':
              showToast({
                title: `New scheduling request by ${payload.data.customerName}`,
                description: `${Moment(payload.data.startTimestamp).format(
                  'MMM Do, LT'
                )} - ${payload.data.customerEmail}`,
                icon: <IconNotificationClock fillColor={'#FFBE88'} />,
                shopId
              });
            case 'assigned_schedule_event':
              showToast({
                title: renderSchedulingTitle(payload.data, 'has been assigned to you'),
                description: `${Moment(payload.data.startTimestamp).format('MMM Do, LT')}`,
                icon: <IconNotificationClock fillColor={'#A9D6D0'}/>,
                shopId
              });
              break;
            case 'unassigned_schedule_event':
              showToast({
                title: renderSchedulingTitle(payload.data, 'has been unassigned'),
                description: `${Moment(payload.data.startTimestamp).format('MMM Do, LT')}`,
                icon: <IconNotificationClock fillColor={'#FFBE88'} />,
                type: 'warning',
                shopId
              });
            case 'cancel_schedule_event':
              showToast({
                title: renderSchedulingTitle(payload.data, 'has been cancelled'),
                description: `${Moment(payload.data.startTimestamp).format('MMM Do, LT')}`,
                icon: <IconNotificationClock fillColor={'#E94848'} />,
                type: 'warning',
                shopId
              });
              break;
            case 'alert_schedule_event':
              showToast({
                title: renderSchedulingTitle(payload.data, 'starting soon'),
                description: `${Moment(payload.data.startTimestamp).format('LT')}`,
                icon: <IconNotificationClock fillColor={'#6E76F2'} />,
                shopId
              });
              break;
            case 'alert_pending_schedule_event':
              showToast({
                title: `Unassigned call with ${payload.data.customerName} starting soon`,
                description: `${Moment(payload.data.startTimestamp).format('LT')}`,
                icon: <IconNotificationClock fillColor={'#FFBE88'} />,
                type: 'warning',
                shopId
              });
              break;
          }
        }
      });
      return unsubscribe;
    }
  }, []);
}
