import './sidebar-chat-tab.scss';
import {SIDEBAR_VIEWS} from "../../consts";
import ChatMessenger from "../../../../../home-page/chat/chat-messenger";
import React, {useState} from "react";
import {useSelector} from "react-redux";

const SidebarChatTab = ({visible, setFullSizeChatImage}) => {
  const activeRoom = useSelector((state) => state.activeRoom);
  const [messageValue, setMessageValue] = useState('');

  return (
    <div className={`chat-details-tab${visible ? ' visible' : ''}`}>
      <ChatMessenger
        messageValue={messageValue}
        setMessageValue={setMessageValue}
        selectedRoom={activeRoom}
        setFullSizeChatImage={setFullSizeChatImage}
        videoChatAction={true}
        showCloseButton={false}
        roomMessagesDisplayed={visible}
      />
    </div>
  )
}

export default SidebarChatTab