import React from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { FilledButton, CaazamButton, PlainButton } from '../../components';

const ContentHeader = ({ visibleDate, setAvailability, onShowClientHistory, isChatScreen }) => {
  const { user: caazamUser } = useSelector((state) => state.user);

  return (
    <div className='content-header'>
      <div className='row-content'>
        <h1 className='customer-name'>{
          isChatScreen
            ? 'Client chat'
            : moment(visibleDate).format('ddd, DD MMMM YYYY')
        }</h1>
        <div className='content-end-row'>
          {!isChatScreen && (
            <PlainButton
              style={{
                color: 'black',
                marginRight: 22,
                textDecoration: 'underline'
              }}
              className='clickable'
              onClick={onShowClientHistory}
            >
              <p>Client view</p>
            </PlainButton>
          )}
          {caazamUser.available
            ? <FilledButton
              id="access-status-btn"
              className='button'
              style={{ height: '44px', backgroundColor: `var(--main-color)` }}
              onClick={setAvailability}
            >
              I'm available
            </FilledButton>
            : <FilledButton
              id="access-status-btn"
              style={{ height: '44px', backgroundColor: `var(--error-color)` }}
              onClick={setAvailability}
            >
              I'm unavailable
            </FilledButton>
          }
        </div>
      </div>
      <hr />
    </div>
  )
}

export default ContentHeader;
