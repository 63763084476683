import React, { createContext, useCallback, useRef, useContext, useState } from "react";

export const SoundFXContext = createContext(null);

export default function SoundFXProvider({ children }) {
    const audioElm = useRef(new Audio());
    const [isUserInteraction, setIsUserInteraction] = useState(false);

    const userInteraction = useCallback(() => {
        if (!isUserInteraction) {
            audioElm.current.play().catch((error)=> console.error('SoundFXProvider: init played with error',error));
            setIsUserInteraction(true);
        }
    }, [isUserInteraction])

    const playSoundFX = useCallback((soundFile) => {
        if (!isUserInteraction)
            console.warn('SoundFXProvider: will not play sound before user interaction');
        else {
            audioElm.current.src = soundFile;
            audioElm.current.play().catch(error => console.error(`SoundFXProvider: failed playing ${soundFile}`, error));
        }

    }, [isUserInteraction])

    return <SoundFXContext.Provider value={{
        userInteraction,
        playSoundFX,
      }}>
          {children}
      </SoundFXContext.Provider>;

}

export function useSoundFX() {
    const context = useContext(SoundFXContext);
    if (!context) {
      throw new Error('useSoundFX must be used within the SoundFXProvider');
    }
    return context;
  }
  

