import React, {useEffect, useState} from "react";
import {DEFAULT_PRODUCT_RESULTS_LIMIT} from "../../../video-room/components/sidebar/consts";
import {useProducts, useProductSearch} from "../../../../hooks";
import SearchProductItem from "../../../../components/productComponents/search-product-item/search-product-item";
import SearchInputDropdownField
  from "../../../../components/productComponents/search-input-dropdown-field/search-input-dropdown-field";

const SideBarProductSearch = ({
  disabled,
  placeholder,
  shopId,
  countryCode,
  products,
  callId,
  onUpdateProducts,
  showVariantSelector = true  
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setSearching] = useState(null);
  const [productSearchResults, setProductSearchResults] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [cursor, setCursor] = useState(null);
  const [isLoadMore, setLoadMore] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null)

  const { searchProducts } = useProductSearch(
    shopId,
    countryCode
  );

  const { getCachedProduct } = useProducts(
    shopId,
    callId,
    countryCode
  );

  const handleSearchInput = (e) => {
    const value = e.target.value
    if (!value && showDropdown) {
      setShowDropdown(false)
    }
    setSearchTerm(e.target.value);
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      searchForItems();
    }
  };

  const searchForItems = async () => {
    if (!searchTerm) return;
    if (!isSearching) {
      setShowDropdown(true)
      setSearching(true);
      try {
        const { products, cursor } = await searchProducts(searchTerm, DEFAULT_PRODUCT_RESULTS_LIMIT);
        setProductSearchResults(products)
        setCurrentPage(0)
        setCursor(cursor)
      } catch (err) {
        console.error(err);
      } finally {
        setSearching(false);
      }
    }
  };

  const loadMore =  () => {
    setLoadMore(true)
  }

  useEffect(() => {
    if (cursor && isLoadMore) {
      searchProducts(searchTerm, DEFAULT_PRODUCT_RESULTS_LIMIT, cursor)
        .then(result => {
          if (result.products.length > 0) {
            setCurrentPage(currentPage + 1)
            setProductSearchResults([...productSearchResults, ...result.products])
          }
          setCursor(result.cursor)
        })
        .catch(console.error)
        .finally(() => {
          setLoadMore(false)
        })
    }
  }, [cursor, isLoadMore])

  const onAddProduct = (product) => {
    setShowDropdown(false)
    setSearchTerm('')
    if (showVariantSelector) {
      if (products.some((addedProduct) => addedProduct.variantId === product.variantId)) {
        onUpdateProducts(products
          .map(addedProduct => {
            if (addedProduct.variantId === product.variantId) {
              addedProduct.quantity += 1
            }
            return addedProduct
          }))
      } else {
        onUpdateProducts([...products, {...product, compareAtPrice: null}])
      }
    } else {
      if (!products.some((addedProduct) => addedProduct.productId === product.productId)) {
        onUpdateProducts([...products, product])
      }
    }
  }

  const onHideDropdown = () => setShowDropdown(false)

  return (
    <>
      <SearchInputDropdownField
        disabled={disabled}
        placeholder={placeholder}
        onChange={handleSearchInput}
        onKeyUp={handleKeyUp}
        onSearchForItems={searchForItems}
        loadMore={cursor ? loadMore : null}
        isLoading={isLoadMore}
        currentPage={currentPage}
        isSearching={isSearching}
        showDropdown={showDropdown}
        data={productSearchResults}
        value={searchTerm}
        renderItem={(product) => (
          <div key={product?.productId + 'searchProduct'}>
            <SearchProductItem
              product={product ?? {}}
              onAddProduct={onAddProduct}
              getCachedProduct={getCachedProduct}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              showVariantSelector={showVariantSelector}
            />
          </div>
        )}
      />
    </>
  )
}

export default SideBarProductSearch;