import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import './fullSizeImageModal.scss';

const FullSizeImageModal = ({closeAction, fullSizeChatImage, videoChat = false}) => {
  return (
    <div className={`modal-container ${videoChat ? 'video-top' : 'top'}`}>
      <div className='close-icon'>
        <CloseIcon fontSize='small' onClick={closeAction} />
      </div>
      <div className='image-container'>
        <img src={fullSizeChatImage} alt='Image' className='image-real' />
      </div>
    </div>
  )
}

export default FullSizeImageModal;
