import { useCallback } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { logger } from '../logging';

const useHostStatus = (user) => {
  return useCallback(
    (status) => {
      logger.info(`host status set to ${status}`);
      if (user) {
        return firebase
          .firestore()
          .collection('users')
          .doc(user.id)
          .update({ available: status });
      }
    },
    [user]
  );
};

export default useHostStatus;
