import React from 'react';

const IconNotificationClock = (props) => (
  <svg width={44} height={44} {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <path
        d="M28.007 41.671C38.181 39.132 43 31.133 43 20.673c0-4.61-3.524-9.381-6.72-12.896C32.224 3.317 27.862 1 22 1 11.519 1 1 8.941 1 19.402c0 6.244 1.023 16.863 8 21.27 4.71 2.974 15 2 19.007 1Z"
        fill={props.fillColor}
      />
      <g fill="#FFF" fillRule="nonzero">
        <path d="M21 1.671c-11.046 0-20 8.955-20 20 0 11.046 8.954 20 20 20s20-8.954 20-20c0-11.045-8.954-20-20-20Zm0 2c9.941 0 18 8.06 18 18 0 9.941-8.059 18-18 18s-18-8.059-18-18c0-9.94 8.059-18 18-18Z" />
        <path d="M18.9 12.188a1 1 0 0 1 .993.883l.007.117-.001 10.083h10.084a1 1 0 0 1 .994.884l.006.116a1 1 0 0 1-.883.993l-.117.007H18.9a1 1 0 0 1-.993-.883l-.007-.117V13.188a1 1 0 0 1 1-1Z" />
      </g>
    </g>
  </svg>
)

export default IconNotificationClock