import React, { useState, useEffect } from 'react';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { useAbandonedCartActions } from '../../hooks/useAbandonedCartItems';
import useCaazamREST from '../../hooks/useCaazamREST';
import { TagIcon } from '../../assets/icons/TagIcon';
import { Loader } from '../loader/loader';
import './DiscountInput.scss';

export const DiscountInput = ({ cartDiscount, shopId, cartId }) => {
    const { getDiscountCodeStatus } = useCaazamREST();
    const { deleteDiscount, submitDiscount } = useAbandonedCartActions(shopId, cartId);
    const [discountCode, setDiscountCode] = useState('');
    const [isDiscountApplied, setIsDiscountApplied] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [discountCodeError, setDiscountCodeError] = useState(null);

    useEffect(() => {
        if (cartDiscount) {
            setIsDiscountApplied(true);
            setDiscountCode(cartDiscount.code)
        } else {
            setDiscountCode('')
            setIsDiscountApplied(false)
        }
    }, [cartDiscount])

    const onToggleDiscountCode = async () => {
        if (isDiscountApplied) {
            onDeclineDiscountCode();
        } else {
            onSubmitDiscountCode();
        }
    }

    const onDeclineDiscountCode = () => {
        setDiscountCode('');
        setDiscountCodeError(null);
        setIsDiscountApplied(false);
        if (cartDiscount) {
            deleteDiscount();
        }
    }

    const onSubmitDiscountCode = async () => {
        if (!discountCodeError && !isDiscountApplied) {
            await applyDiscount();
        }
    }

    const onDiscountCodeChange = (e) => {
        setDiscountCode(e.target.value)
    }

    const applyDiscount = async () => {
        setDiscountCodeError(null);
        setIsLoading(true);
        try {
            const discountCodeData = await getDiscountCodeStatus(shopId, discountCode);
            const { status, startsAt, endsAt } = discountCodeData;
            const now = new Date();

            if (endsAt && (new Date(endsAt) < now)) {
                setDiscountCodeError('Discount code has expired');
                return;
            }

            if (!status || new Date(startsAt) > now) {
                setDiscountCodeError('Discount code inactive');
                return;
            }

            submitDiscount({ ...discountCodeData, code: discountCode });
        } catch (error) {
            if (error.reason) setDiscountCodeError(error.reason)
            else setDiscountCodeError(error.message)
        } finally {
            setIsLoading(false);
            setIsDiscountApplied(true);
        }
    }

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter' && !discountCodeError && !isDiscountApplied) {
            await applyDiscount();
        }
    }

    return (
        <div className={`discount-code-input-container${isDiscountApplied ? ' applied' : ''}${discountCodeError ? ' invalid' : ''}`}>
            <div className='discount-code-input-container-header'>
                <div className='alert-icon-container'>
                    <TagIcon />
                </div>
                <div className='discount-input-wrapper'>
                    <input
                        onKeyDown={handleKeyDown}
                        value={discountCode}
                        readOnly={isDiscountApplied}
                        placeholder='Discount code'
                        onChange={onDiscountCodeChange}
                        className='discount-input' />
                </div>
                <div className='discount-code-btns-container'>
                    <button
                        onClick={() => onToggleDiscountCode()}
                        disabled={!discountCode}
                        className='discount-form-submit-button'>
                        <div className={`discount-form-submit-primary-button${isLoading ? ' loading' : ''}`}>
                            <span className='discount-apply-btn'>
                                Apply
                            </span>
                            <Loader width={'20px'} height={'20px'} stop={!isLoading} />
                        </div>
                        {discountCodeError ? <span className='discount-remove-btn'>
                            Remove
                        </span> : <span className='discount-decline-btn'>
                            <CloseRoundedIcon />
                        </span>
                        }
                    </button>
                </div>
            </div>
            <div className='discount-code-input-message-container'>
                {discountCodeError ?
                    <div className='discount-code-error-text-message'>{discountCodeError}</div> :
                    cartDiscount && <div className='discount-code-succeed-text-message'>
                        <p>{cartDiscount.summary}</p><span>&nbsp;| applied and checkout</span>
                    </div>
                }
            </div>
        </div>
    )
}
