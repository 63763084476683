import {useEffect, useMemo, useState} from "react";
import firebase from "firebase/compat/app";
import 'firebase/compat/analytics';
import useCaazamREST from "./useCaazamREST";
import {useDispatch, useSelector} from "react-redux";
import {parseIdString} from "../utils";
import {updateClientInfo, updateSearchClientResult} from "../reducers/client-reducer";
import {CLIENT_SEARCH_RESULT} from "../constants/const";
import {
  useCallDetailsProvider
} from '../pages/home-page/call-details-side-bar/call-details-provider/call-details-provider';

export const CLIENT_VIEW_TYPE = {
  PROFILE: 'profile',
  SEARCH: 'search'
}

const useClientSummary = (clientEmail, callShopId, defaulState) => {
  const dispatch = useDispatch();
  const [isSearching, setSearching] = useState(false)
  const { searchCustomers, getCustomerProfile } = useCaazamREST();
  const { activeShopId } = useSelector((state) => state.shops);
  const [searchClient, setSearchClient] = useState([]);
  const [isUserLoading, setUserLoading] = useState(false)
  const [client, setClient] = useState(null)
  const [viewType, setViewType] = useState(CLIENT_VIEW_TYPE.PROFILE);
  const {clients, clientSearchStatus} = useSelector(state => state.clients);
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const {callDetails} = useCallDetailsProvider()
  const shopId = useMemo(() => (
    callShopId ?? activeShopId
  ), [callShopId, activeShopId])

  const controller = useMemo(() => (
    new AbortController()
  ), [callDetails?.id])

  const isClientWithoutValue = useMemo(() => (
    clientSearchStatus === CLIENT_SEARCH_RESULT.NOT_EXIST || clientSearchStatus === CLIENT_SEARCH_RESULT.ERROR
  ), [clientSearchStatus])

  useEffect(() => {
    return () => {
      setViewType(CLIENT_VIEW_TYPE.PROFILE)
      setSearchClient([])
    }
  }, [callDetails?.id, clientEmail])

  useEffect(() => {
    if (viewType === CLIENT_VIEW_TYPE.PROFILE) {
      if (defaulState) {
        dispatch(updateSearchClientResult(defaulState))
      } else {
        dispatch(updateSearchClientResult(
          !clients[`${clientEmail}_${shopId}`]
            ? CLIENT_SEARCH_RESULT.LOADING
            : CLIENT_SEARCH_RESULT.EXIST
        ))
      }
    }
    fetchCustomerProfile(null, clientEmail)
  }, [clientEmail, shopId, callDetails?.id])

  useEffect(() => {
    if (searchClient?.length > 0 && viewType === CLIENT_VIEW_TYPE.PROFILE) {
      if (clientEmail === searchClient[0].email) {
        selectNewCustomer(searchClient[0])
      }
    }
  }, [searchClient.length])

  useEffect(() => {
    if (clientEmail === selectedCustomer?.email) {
      setClient(selectedCustomer)
    }
  }, [clientEmail, selectedCustomer])

  const fetchCustomerProfile = (clientId, clientEmail, currentContextId = null) => {
    let cachedClient = clients[`${clientEmail}_${shopId}`]
    setClient(cachedClient)
    dispatch(updateSearchClientResult(cachedClient ? CLIENT_SEARCH_RESULT.EXIST : CLIENT_SEARCH_RESULT.LOADING))
    setUserLoading(!cachedClient);
    getCustomerProfile(shopId, clientId, clientEmail, currentContextId, controller?.signal)
      .then((data) => {
        if (data) {
          dispatch(updateClientInfo({
            ...data,
            shopId
          }))
          setClient(data)
          dispatch(updateSearchClientResult(CLIENT_SEARCH_RESULT.EXIST))
        } else {
          dispatch(updateSearchClientResult(CLIENT_SEARCH_RESULT.ERROR))
        }
      })
      .catch((err) => {
        dispatch(updateSearchClientResult(CLIENT_SEARCH_RESULT.ERROR))
      })
      .finally(() => setUserLoading(false));
  }

  const selectNewCustomer = async (client, currentContextId = null) => {
    setViewType(CLIENT_VIEW_TYPE.PROFILE)
    const cleanCustomerId = parseIdString(client.id);
    fetchCustomerProfile(cleanCustomerId, client.email, currentContextId)
  }

  const findCustomer = (searchVal, initSearch) => {
    if (!searchVal) {
      controller.abort()
      setClient(null)
      return
    }
    if (!isSearching) {
      setSearchClient([])
      let cachedClient = clients[`${searchVal}_${shopId}`]
      if (!cachedClient) {
        dispatch(updateSearchClientResult(CLIENT_SEARCH_RESULT.LOADING))
      }
      if (initSearch) {
        setClient(cachedClient)
      }
      setSearching(!cachedClient);
      searchCustomers(shopId, searchVal, controller?.signal)
        .then((data) => {
          if (data?.length > 0) {
            setSearchClient(data)
          } else {
            dispatch(updateSearchClientResult(CLIENT_SEARCH_RESULT.NOT_EXIST))
            setClient(null)
          }
        })
        .catch((err) => dispatch(updateSearchClientResult(CLIENT_SEARCH_RESULT.ERROR)))
        .finally(() => {
          firebase
            .analytics()
            .logEvent('search_customer', { search_term: searchVal });
          setSearching(false);
        });
    }
  };

  const onSearchPress = () => {
    setViewType(CLIENT_VIEW_TYPE.SEARCH)
  }

  const showProfile = (clientEmail) => {
    //batching simulation to remove render profile for previous
    //customer when press back from search profile
    Promise.resolve().then(() => {
      setViewType(CLIENT_VIEW_TYPE.PROFILE)
      fetchCustomerProfile(null, clientEmail)
    })

  }

  const searchForItems = (searchTerm) => {
    findCustomer(searchTerm);
  };

  return {
    searchClient,
    isUserLoading,
    client,
    onSearchPress,
    searchForItems,
    viewType,
    isSearching,
    selectNewCustomer,
    showProfile,
    isClientWithoutValue,
    setSearchClient
  }
}
export default useClientSummary;
