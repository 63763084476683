import {OrderComplete} from "../../../../../assets/icons/OrderComplete";
import {currencyFormatter} from "../../../../../utils";
import React from "react";
import './call-badges.scss'

const CallOrder = ({ totalOrderAmount, currency }) => {
  return (
    <span className='call-outcome success'>
      <OrderComplete />
      <p>{currencyFormatter(totalOrderAmount, currency)} </p>
    </span>
  );
};

export default CallOrder;