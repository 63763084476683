import React from 'react';
import {useDispatch} from "react-redux";

import './styles.scss';

import Modal from '../modal';
import {FilledButton} from "../../buttons/buttons";
import {setShowPermissionsHelperModal} from "../../../reducers/system-reducer";
/* 
  modal for instructing user to provide permissions manually becuase they were denied
 */
const PermissionsHelperModal = () => {

  const dispatch = useDispatch();

  const closePermissionsHelperModal = () => {
    dispatch(setShowPermissionsHelperModal(false));
  };

  return (
    <Modal modalClass='permissions-helper-container' isClosable={true} closeFunc={closePermissionsHelperModal}>
      <p className='permissions-helper-info'>Please set your browser to allow Boutiq use of your video camera and microphone</p>
      <img src='../permissions_helper.png' className='permissions-helper-logo' />
    </Modal>
  );
};

export default PermissionsHelperModal;
