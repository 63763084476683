import {createSlice} from "@reduxjs/toolkit";
import {CLIENT_SEARCH_RESULT} from "../constants/const";

const initialState = {
  clients: {},
  clientSearchStatus: CLIENT_SEARCH_RESULT.LOADING
}

const clientReducer = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    updateClientInfo(state, {payload}) {
      state.clients = {
        ...state.clients,
        [`${payload.email}_${payload.shopId}`]: payload,
      }
    },
    updateSearchClientResult(state, {payload}) {
      state.clientSearchStatus = payload
    }
  }
})

export const { updateClientInfo, updateSearchClientResult } = clientReducer.actions;

export default clientReducer.reducer;
