import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  presentationMode: false,
  product: {},
  selectedProductVariant: null,
  isProductInShowroom: false,
  currentImages: [],
  selectedImage: null,
  contextId: null,
  isPresentationEnabled: false,
  activeCartId: null,
  disableDiscounts: false,
  showDraftOrder: false,
  draftOrderId: null
};

const systemsSlice = createSlice({
  name: 'activeCall',
  initialState,
  reducers: {
    setPresentationMode(state, { payload }) {
      state.isPresentationEnabled = payload.isPresentationEnabled;
      state.product = payload.product || {};
      state.selectedProductVariant = payload.selectedProductVariant || null;
      state.isProductInShowroom = payload.isProductInShowroom || false;
      state.currentImages = payload.currentImages || [];
      state.selectedImage = payload.selectedImage || null;
      state.contextId = payload.contextId || null;
      state.cartKey = payload.cartKey || null;
      state.selectedOptions = payload.selectedOptions || {};
    },
    enablePresentation(state) {
      state.isPresentationEnabled = true;
    },
    disablePresentation(state) {
      state.isPresentationEnabled = false;
    },
    setSelectedImage(state, { payload }) {
      state.selectedImage = payload.selectedImage;
    },
    clearPresentationMode(state) {
      state.isPresentationEnabled = false;
      state.product = {};
      state.selectedProductVariant = null;
      state.isProductInShowroom = false;
      state.currentImages = [];
      state.selectedImage = null;
      state.contextId = null;
      state.cartKey = null;
      state.selectedOptions = {};
    },
    setActiveCartId(state, { payload }) {
      state.activeCartId = payload.activeCartId;
    },
    disableDiscounts(state, { payload }) {
      state.disableDiscounts = payload.disableDiscounts;
    },
    showDraftOrderModal(state) {
      state.showDraftOrder = true
    },
    hideDraftOrderModal(state) {
      state.showDraftOrder = false
    },
    updateDraftOrderId(state, { payload }) {
      state.draftOrderId = payload.draftOrderId
    }
  },
});

export const { setPresentationMode, enablePresentation, disablePresentation, setSelectedImage, clearPresentationMode, setActiveCartId,
  disableDiscounts, showDraftOrderModal, hideDraftOrderModal, updateDraftOrderId} = systemsSlice.actions;

export default systemsSlice.reducer;
