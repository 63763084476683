import React, {useState} from 'react';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';

import './styles.scss';

const ImageMessage = ({item, isHost, incrementLoadedImages, isMessageLoading = true, onClickAction}) => {
  const [isLoaded, setLoadingStatus] = useState(!isMessageLoading);

  const hiddenStyle = !isLoaded ? { display: "none" } : {};

  const load = () => {
    incrementLoadedImages();
    setLoadingStatus(true)
  };

  //TODO: change to skeleton image
  return (
    <div
      className={`${isHost ? 'host-image-box' : 'image-box'} ${isLoaded || 'loading-style'}`}
      onClick={onClickAction}>
      {
        !isLoaded &&
        <ReactPlaceholder
          className='image-placeholder'
          type='rect'
          ready={isLoaded}
          showLoadingAnimation={true}
          color={'gray'}
        />
      }
      {
        item && item.metadata && item.metadata.url &&
          <img
            src={item.metadata.url}
            alt='Image'
            style={hiddenStyle}
            className='image-real'
            onError={incrementLoadedImages}
            onLoad={load} />
      }
    </div>
  );
}

export default ImageMessage;
