import React from "react"

function RateIcon({type, ...props}) {
  return (
  <svg width="10px" height="10px" fill='rgba(0, 0, 0, 0.2)' viewBox="0 0 48 48" className={type} {...props}>
    <g stroke="none" strokeWidth="1" fillRule="evenodd" strokeLinejoin="round">
        <g fillRule="nonzero" stroke="rgba(0, 0, 0, 0.2)">
            <polygon points="30.208 17.302 24 0.046 17.792 17.302 0.414 18.171 13.985 29.612 9.378 47.348 24 37.259 38.622 47.349 34.015 29.613 47.586 18.172"></polygon>
        </g>
    </g>
</svg>


)}

export default RateIcon
