import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {firestore} from '../../firebaseInitialization';
import { FIRESTORE_COLLECTIONS } from "../../constants/firestore";

export function useRoomIsTyping(roomId = null) {
  const { id: activeRoomId } = useSelector(state => state.activeRoom);
  const [roomTyping, setRoomTyping] = useState({});
  const idOfChat = roomId ? roomId : activeRoomId;

  const listenRoomTyping = () => {

    return firestore()
      .collection(FIRESTORE_COLLECTIONS.CHAT_ROOMS)
      .doc(idOfChat)
      .collection(FIRESTORE_COLLECTIONS.ROOM_FEATURES)
      .doc(FIRESTORE_COLLECTIONS.TYPING_DOC)
      .onSnapshot(
        doc => setRoomTyping(doc.data() || {}),
        listenError => console.error('listenRoomTyping', listenError));
  }

  useEffect(() => {
    if (idOfChat) {
      const listener = listenRoomTyping();
      return () => {
        listener();
      }
    }
  }, [idOfChat]);

  return { roomTyping };
}
