import React from 'react';
import Select from 'react-select';
import './device-selector.scss';

export const DeviceSelector = ({ listitems = [], value = null, onChange }) => {
  const optionChange = ({ value }) => {
    onChange(listitems.find(item => item.deviceId === value));
  }
  const preventKeyboardOpen = (e) => e.preventDefault();

  return (
    <Select
      onChange={optionChange}
      menuPlacement='auto'
      classNamePrefix='device-selector'
      styles={{
        option: (styles, { isFocused, isSelected }) => {
          return {
            ...styles,
            fontSize: 14,
            backgroundColor: isSelected
              ? '#DCDCDC'
              : isFocused
                ? 'rgba(249,139,0,0.04)'
                : null,
          };
        },
      }}
      value={value}
      options={listitems.map(item => ({ label: item.label, value: item.deviceId }))}
      blurInputOnSelect={false}
      onFocus={preventKeyboardOpen}
    />
  );
};
