import React, { useRef, useCallback, cloneElement } from "react";

export const InfiniteScroll = ({
   isLoading,
   results,
   hasMore,
   loadMore,
   item,
   loader = "Loading...",
   renderItem,
   className
}) => {
  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore, loadMore ]
  );

  return (
    <>
      {results && results.map((result, i) => {
        if (results.length === i + 1) {
          return (
            <div
              className={className}
              key={i}
              ref={lastElementRef}>
              {renderItem ? renderItem(result) : cloneElement(item, { item: result })}
            </div>
          );
        } else {
          return <div
            className={className}
            key={i}>
            {renderItem ? renderItem(result) : cloneElement(item, { item: result })}
          </div>
        }
      })}
      <div>{isLoading && loader}</div>
    </>
  );
}
