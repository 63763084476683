import React, {useEffect, useMemo, useRef, useState} from "react";
import './call-summary-tab.scss'
import Collapsible from "../../../../components/collapsable/collapsible";
import {CALL_STATUS, CALL_TYPES, FEEDBACK_TYPE} from "../../../../utils/consts";
import CallFormResponses from "../call-form-responses/call-form-responses";
import CallShowroomTab from "../call-showroom-tab/call-showroom-tab";
import CallSummaryHeader from "./call-summary-header/call-summary-header";
import CallError from "../../calendar-row/components/badges/call-error";
import CallFeedbackRating from "../../../../components/CallFeedbackRating";
import {useCallDetailsProvider} from "../call-details-provider/call-details-provider";
import ConversationSummaryTab from '../conversation-summary-tab/conversation-summary-tab';
import useCallTranscripts from '../../../../hooks/useCallTranscripts';

const SUMMARY_SECTION = {
  SHOWROOM: 'showroomIsVisible',
  ERROR: 'errorIsVisible',
  FORM_RESPONSES: 'formResponseIsVisible',
  FEEDBACK: 'feedbackIsVisible',
  CONVERSATION_SUMMARY: 'conversationSummaryVisible'
}

const CallSummaryTab = ({visible, feedbackType}) => {
  const {
    callDetails,
    scheduleClientFormData,
    cart,
    showroomProducts,
    draftOrder
  } = useCallDetailsProvider();

  const {
    id, shopId, error, orders, checkouts, callFeedback, customerMessage, status, type, clientForm, insightsFlag
  } = callDetails;
  const {  transcripts, transcriptsLoading, transcriptsError } = useCallTranscripts(id, shopId, insightsFlag);

  const initVisibilityState = useRef({
    showroomIsVisible: true,
    errorIsVisible: true,
    formResponseIsVisible: true,
    feedbackIsVisible: true,
    conversationSummaryVisible: true
  }).current

  const [visibility, setVisibility] = useState(initVisibilityState)

  const isFeedbackAvailable = useMemo(() => (
    feedbackType !== FEEDBACK_TYPE.NONE && (callFeedback?.[0] || customerMessage)
  ), [callFeedback, customerMessage])

  const hideCreateCartButton = useMemo(() => (
    Object.keys(cart ?? {}).length !== 0
    || Object.keys(orders ?? {}).length > 0
    || Object.keys(checkouts ?? {}).length > 0
    || !!draftOrder
  ), [orders, checkouts, cart, draftOrder, id])

  const callSummary = useMemo(() => {
    let transcript = transcripts?.find(t => !!t.summary);
    if (transcript) {
      return transcript.summary;
    } else {
      return null;
    }

  },[transcripts])

  useEffect(() => {
    return () => {
      setVisibility(initVisibilityState)
    }
  }, [id])

  const onToggle = (section, event) => {
    if (!event || event?.nativeEvent?.target?.getAttribute('class')?.includes('call-summary')) {
      setVisibility(visibility => ({
        ...visibility,
        [section]: !visibility[section]
      }))
    }
  }

  const textError = useMemo(() => {
    if (status === CALL_STATUS.ERROR) {
      switch (error.error.code) {
        case 403:
          return 'Client is banned'
        case 408:
          return 'No host was available to answer'
        case 409:
          return 'A host rejected the call'
        case 499:
          return 'Client cancelled the call'
        default:
          return error.reason
      }
    }
  }, [status, error])

  const feedbackComponent = () => {
    const message = customerMessage || callFeedback?.[0].message
    return (
      <div className='call-details-feedback-section'>

        <div className={`feedback-message-container`}>
            <span className='feedback-message'>
              {callFeedback?.[0] && (
                <CallFeedbackRating ratingData={callFeedback[0]} feedbackType={feedbackType}/>
              )}
              {message && [FEEDBACK_TYPE.NPS, FEEDBACK_TYPE.CSAT].includes(feedbackType) &&
                <span>{message}</span>
              }
            </span>
        </div>
      </div>
    )
  }

  return (
    <div className={`call-summary-tab${visible ? ' visible' : ''}`}>
      {status === CALL_STATUS.ERROR && (
        <Collapsible
          toggle={(event) => onToggle(SUMMARY_SECTION.ERROR, event)}
          visible={visibility.errorIsVisible}
          header={(
            <CallSummaryHeader
              title={'Error'}
              badge={<CallError error={error} isContentVisible={visibility.errorIsVisible}/>}
            />
          )}
        >
          <div className='call-details-error-component'>
            <p className='error-message'>{textError}</p>
          </div>
        </Collapsible>
      )}
      {callSummary && (
        <Collapsible
          toggle={(event) => onToggle(SUMMARY_SECTION.CONVERSATION_SUMMARY, event)}
          visible={visibility.conversationSummaryVisible}
          header={(
            <CallSummaryHeader title={'Call summary'} isContentVisible={visibility.conversationSummaryVisible}/>
          )}
        >
          <ConversationSummaryTab callSummary={callSummary} loading={transcriptsLoading} />
        </Collapsible>
      )}
      {isFeedbackAvailable && (
        <Collapsible
          toggle={(event) => onToggle(SUMMARY_SECTION.FEEDBACK, event)}
          visible={visibility.feedbackIsVisible}
          header={(
            <CallSummaryHeader title={'Feedback'} isContentVisible={visibility.feedbackIsVisible}/>
          )}
        >
          {feedbackComponent()}
        </Collapsible>
      )}
      {status !== CALL_STATUS.ERROR && (
        <Collapsible
          toggle={(event) => onToggle(SUMMARY_SECTION.SHOWROOM, event)}
          visible={visibility.showroomIsVisible}
          header={(
            <CallSummaryHeader title={'Showroom'} isContentVisible={visibility.showroomIsVisible}/>
          )}
        >
          <CallShowroomTab
            visible={true}
            showroomProducts={showroomProducts}
            isShowRoom={true}
            call={callDetails}
            hideCreateCartButton={hideCreateCartButton}
          />
        </Collapsible>
      )}
      {(type === CALL_TYPES.SCHEDULED && clientForm || scheduleClientFormData) && (
        <Collapsible
          toggle={(event) => onToggle(SUMMARY_SECTION.FORM_RESPONSES, event)}
          visible={visibility.formResponseIsVisible}
          header={(
            <CallSummaryHeader title={'Form Responses'} isContentVisible={visibility.formResponseIsVisible}/>
          )}
        >
          <CallFormResponses
            visible={true}
            clientForm={clientForm ?? scheduleClientFormData}
          />
        </Collapsible>
      )}
    </div>
  )
}

export default CallSummaryTab;
