import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import './index.scss';

export const SearchInput = ({placeholder, searchInputValue, onChange, onSubmit = () => {}}) => {

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };

  return (
    <div className='search-panel-form-container'>
      <button className='search-panel-submit-form-btn'>
        <SearchIcon onClick={() => onSubmit()}/>
      </button>
      <input
        className='search-panel-input-field'
        type='search'
        value={searchInputValue || ''}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        onKeyUp={handleKeyUp}
      />

    </div>
  )
}
