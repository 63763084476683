import {useEffect, useState} from "react";
import {useIMask} from "react-imask";

export const useDigitalInput = (options) => {
  const [inputMask, setInputMask] = useState({
    mask: Number,
    signed: false,
    padFractionalZeros: false,
    normalizeZeros: true,
    radix: '.',
    mapToRadix: ['.'],
  })

  useEffect(() => {
    setInputMask({
      ...inputMask,
      ...options
    })
    if (options?.initValue) {
      setValue(options.initValue)
    }
  }, [])

  const {
    ref,
    value,
    setValue,
  } = useIMask(inputMask);

  useEffect(() => {
    if (value === '0') {
      setValue('')
    }
  }, [value])

  return ({
    ref,
    value,
    setValue
  })
}