import React from 'react';
import {useSelector} from 'react-redux';

import {MSG_TYPES} from "../../../modules/chat/constants/messaging";
import ImageMessage from "../../../components/chat/imageMessage";
import './chat-messages-container.scss';
import {formatMsgTime} from "../../../modules/chat/utils/time";

const ChatMessagesContainer = ({item, index, selectedRoom, videoChatAction, incrementLoadedImages, showModal,}) => {

  const getLink = (textLink) => {
    return textLink.startsWith('http://') || textLink.startsWith('https://') ? textLink : `http://${textLink}`;
  }

  const checkLink = (textToCheck) => {
    let expression = /([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm;
    let regex = new RegExp(expression);
    let match = '', splitText = [], startIndex = 0;
    while ((match = regex.exec(textToCheck)) != null) {
       splitText.push({text: textToCheck.substr(startIndex, (match.index - startIndex)), type: 'text'});
       splitText.push({text: textToCheck.substr(match.index, (match[0].length)), type: 'link'});

       startIndex = match.index + (match[0].length);
    }
    if (textToCheck && startIndex < textToCheck.length) splitText.push({text: textToCheck.substr(startIndex), type: 'text'});

    return splitText;
  };

  const renderHours = (item) => {
    return <h6>{formatMsgTime(item)}</h6>
  };

  const { user } = useSelector((state) => state.user);
  const participant = selectedRoom.participants[selectedRoom.initiator.uuid];
  const isRemoteParticipant = !(item.owner.uuid === user.id);
  const isSystemMessage = item.owner.system === true;
  const noImage = participant?.profileInitials || null;
  const textWithLink = checkLink(item.text);

  if (item.type === MSG_TYPES.CALL_INVITATION) {
    return (
      <div
        className={`message-item${videoChatAction ? ' message-item_hidden_message' : ''}`}
        key={item.id}>
        <div className='message-item message-item_my'>
          <p className='message-item_my_invitation'>Sent invite to start video call</p>
        </div>
        <div className='host-time'>{renderHours(item.timestamp)}</div>
      </div>
    );
  }

  if (item.type === MSG_TYPES.IMAGE) {
    return (
      <div className='message-item' key={index}>
        {isRemoteParticipant
          ? <>
              <div className='msg-with-image'>
                <div className={isSystemMessage ? 'image-system-border' : 'image-border'}>
                  {item.owner.profileAvatarUrl
                    ? <img src={item.owner.profileAvatarUrl} alt='C' className='avatar' />
                    : !isSystemMessage &&
                      <h4>{noImage}</h4>
                  }
                </div>
                <ImageMessage
                  item={item}
                  isHost={isRemoteParticipant}
                  incrementLoadedImages={incrementLoadedImages}
                  onClickAction={() => showModal(item.metadata.url)}
                />
              </div>
              <div className='time'>{renderHours(item.timestamp)}</div>
            </>
          : <>
              <div className='host-msg-with-image'>
                <ImageMessage
                  item={item}
                  isHost={isRemoteParticipant}
                  incrementLoadedImages={incrementLoadedImages}
                  onClickAction={() => showModal(item.metadata.url)}
                />
              </div>
              <div className='host-time'>{renderHours(item.timestamp)}</div>
            </>
        }
      </div>
    )
  }

  if (item.owner.system && item.type === MSG_TYPES.META && item.metadata) {
    return (
      <div className='message-item message-item_system' key={index}>
        <div className='message-item_system_delimiter'/>
          {
            item.metadata.key === 'participant_left_chat' &&
              <p>
                {`${item.metadata.uuid === user.id ? 'you' : item.metadata.displayName} left the chat`}
              </p>
          }
          {
            item.metadata.key === 'participant_joined_chat' &&
              <p>
                {`${item.metadata.uuid === user.id ? 'you' : item.metadata.displayName} joined the chat`}
              </p>
          }
        <div className='message-item_system_delimiter'/>
      </div>
    );
  }

  return (
    <div className='message-item' key={index}>
      {isRemoteParticipant
        ? <>
            <div className='msg-with-image'>
              <div className={isSystemMessage ? 'image-system-border' : 'image-border'}>
                {item.owner.profileAvatarUrl
                  ? <img src={item.owner.profileAvatarUrl} alt='C' className='avatar' />
                  : !isSystemMessage &&
                    <h4>{noImage}</h4>
                }
              </div>
              <div className='msg-border'>
                {textWithLink.map((item, index) => {
                  if (item.type === 'link') {
                    return <a href={getLink(item.text)} key={index} target='_blank'>{item.text}</a>
                  } else {
                    return <h5 key={index}>{item.text}</h5>
                  }
                })}
              </div>
            </div>
            <div className='time'>{renderHours(item.timestamp)}</div>
          </>
        : <>
            <div className='host-msg-with-image'>
              <div className='host-msg-border'>
                {textWithLink.map((item, index) => {
                  if (item.type === 'link') {
                    return <a href={getLink(item.text)} key={index} target='_blank'>{item.text}</a>
                  } else {
                    return <h5 key={index}>{item.text}</h5>
                  }
                })}
              </div>
            </div>
            <div className='host-time'>{renderHours(item.timestamp)}</div>
          </>
      }
    </div>
  )
};

export default ChatMessagesContainer;
