import React, { useState } from 'react';
//import { useSessionProducts } from '../../../../hooks';
import Cart from './cart';
import ShowRoom from './showroom';
import { useActiveCallProvider } from '../../../../components/ActiveCallProvider';

import './panel.scss';

const VideoRoomPanel = ({
  openCheckout,
  view,
  viewShowroomItem,
  viewCartItem,
  clientViewData,
}) => {
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const { activeCallId: contextId} = useActiveCallProvider();
  //const { addToSession } = useSessionProducts(contextId);

  /* const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggedOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggedOver(false);
  };

  const handleDrop = (e) => {
    const draggedProduct = JSON.parse(e.dataTransfer.getData('product'));
    addToSession(draggedProduct);
    setIsDraggedOver(false);
  };
 */
  const renderContent = () => {
    if (view === 1) {
      return <ShowRoom viewItem={viewShowroomItem} clientViewData={clientViewData} />;
    }
    if (view === 2) {
      return <Cart viewItem={viewCartItem} openCheckout={openCheckout} clientViewData={clientViewData} />;
    }
    return null;
  };

  return (
    <div
      className={`video-room-panel ${isDraggedOver ? 'dragged-over' : ''}`}
    //onDragOver={handleDragOver}
    //onDragLeave={handleDragLeave}
    //onDrop={handleDrop}
    >
      {renderContent()}
    </div>
  );
};

export default VideoRoomPanel;
