import React from 'react';
import {useDispatch} from "react-redux";

import './styles.scss';
import { isWindows } from 'react-device-detect';

import Modal from '../modal';
import {FilledButton} from "../../buttons/buttons";
import {setShowLocalTracksHelperModal, setShowLocalTracksHelperModalAction} from "../../../reducers/system-reducer";

const AVPermissionsLockHelperModal = () => {

  const dispatch = useDispatch();

  const closePermissionsHelperModal = () => {
    dispatch(setShowLocalTracksHelperModal(false));
  };

  const action = () => {
    dispatch(setShowLocalTracksHelperModalAction(true));
    dispatch(setShowLocalTracksHelperModal(false));
  }

  return (
    <Modal modalClass='permissions-lock-helper-container' isClosable={true} closeFunc={closePermissionsHelperModal}>
      <p className='permissions-lock-helper-info'>Could not start your device's camera and microphone. </p>
      {!isWindows && <p className='permissions-lock-helper-info'>Please check your camera and microphone preview before answering calls under Audio &amp; Video Settings</p>}
      {isWindows && <>
        <p className='permissions-lock-helper-info'>Please close any applications currently using camera and microphone and try again</p>
        <FilledButton
          className='permissions-button'
          onClick={action}>
          Try again
        </FilledButton>
      </>}
    </Modal>
  );
};

export default AVPermissionsLockHelperModal;
