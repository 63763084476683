import React, {useState, useEffect, useRef, useMemo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import AddAPhoto from '../../../assets/icons/AddAPhoto';
import moment from 'moment-timezone';
import CloseIcon from '../../../assets/icons/Close';
import SendIcon from '../../../assets/icons/Send';
import InfiniteScroll from 'react-infinite-scroll-component';

import { logger } from '../../../logging';

import useCaazamREST from '../../../hooks/useCaazamREST';
import {useActiveRoomData} from '../../../modules/chat/hooks/useActiveRoomData';
import useRoomsRequests from '../../../modules/chat/api/rooms/requests';
import useFeatureRequests from '../../../modules/chat/api/features/requests';
import {useRoomIsTyping} from '../../../modules/chat/api/features/listeners';
import useShopHosts from '../../../hooks/useShopHosts';
import {setActiveRoomId} from '../../../modules/chat/actions/activeRoom';
import {TYPING_TIME} from '../../../utils/consts';
import AnimatedDots from '../../../components/animatedDots';
import {buildUserEntity} from '../../../modules/chat/utils/auth';
import {ROLES} from "../../../modules/chat/constants/participantRoles";
import {MSG_STATUS, MSG_TYPES, TEMP_MESSAGE} from "../../../modules/chat/constants/messaging";
import {IMAGE_MAX_LENGTH, IMAGE_MAX_SIZE} from "../../../modules/chat/firebaseInitialization";
import {getImageParams, resizeFile} from "../../../modules/chat/utils/images";
import ImageMessage from "../../../components/chat/imageMessage";
import './chat-messenger.scss';
import StartVideoCall from "../../../assets/icons/StartVideoCall";
import {clearMessages, showStartVideoCallMessage} from "../../../modules/chat/actions/messages";
import useGetMessages from "../../../modules/chat/api/messages/api";
import useShopChatConfig from "../../../hooks/useShopChatConfig";
import { Loader } from '../../../components/loader/loader';
import ChatMessagesContainer from './chat-messages-container';
import {formatMsgTime} from "../../../modules/chat/utils/time";


const ChatMessenger = ({
  roomMessagesDisplayed = true,
  messageValue,
  setMessageValue,
  selectedRoom,
  videoChatAction = false,
  setFullSizeChatImage,
  showCloseButton = true
}) => {

  const MIN_SEPARATOR_TRACKER = -15;

  const fileInputRef = useRef(null);
  let typingRef = useRef(null);
  const [isTyping, setTyping] = useState(false);
  const [loadedImages, updateLoadedImages] = useState(0);
  const lastMessage = useRef(null);
  const { user } = useSelector((state) => state.user);
  const { activeShopId } = useSelector((state) => state.shops);

  const clientParticipant = selectedRoom.participants[selectedRoom.initiator.uuid];
  const pendingStatus = selectedRoom.status === 'pending';
  const { hosts } = useShopHosts(activeShopId);
  const defaultValue = !!hosts && hosts.filter(item => item.id === user.id);

  const { tempImageMessageId, isShowStartVideoCallMessage, messages } = useSelector(state => state.chatMessages);
  const userEntity = useMemo(() => ({ ...buildUserEntity(user), role: ROLES.HOST }), [user])
  const { sendMessage, sendFile, seenMessages } = useActiveRoomData(userEntity, selectedRoom, selectedRoom.id, roomMessagesDisplayed);
  const { loadMessages } = useGetMessages(selectedRoom.id);

  const { archiveRoom, joinAsHost, reassignHost, isActionInProgress } = useRoomsRequests({shopId: activeShopId, userEntity, room: selectedRoom});

  const { roomTyping } = useRoomIsTyping();
  const { activateChat, reassignChat, archiveChat } = useCaazamREST();
  const { updateTypingStatus } = useFeatureRequests({ userUuid: userEntity.id });
  const { chatConfig } = useShopChatConfig(activeShopId);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [visibleNewMessagesSeparator, setVisibleNewMessagesSeparator] = useState(false);
  const [roomSeenStatus, setRoomSeenStatus] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [messagesList, setMessagesList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    clearMessagesList();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRoomSeenStatus(!roomSeenStatus);
    }, 1000);
    return () => clearTimeout(timer);
  }, [selectedRoom?.metadata?.seenStatus]);

  const clearMessagesList = () => {
    setHasMoreData(true);
    setMessagesList([]);
    dispatch(clearMessages());
  }

  useEffect(() => {
    if (selectedRoom.id) {
      logger.info('Started ChatMessenger UI', { id: selectedRoom.id });
      clearMessagesList();
      lastMessage.current = null;
      setScrollPosition(0);
      (async function fetchFirstMessages() {
        const newLastMessage = await loadMessages(null, selectedRoom.metadata && selectedRoom.metadata.badges ? selectedRoom.metadata.badges[user.id] : 0);
        lastMessage.current = newLastMessage;
        await seenMessages();
      })();
    }
  }, [selectedRoom.id]);

  const fetchOldMessages = () => {
    if (lastMessage.current !== null) {
      loadMessages(lastMessage.current)
        .then((newLastMessage) => {
          if (newLastMessage && newLastMessage.id && lastMessage.current !== null) {
            setHasMoreData(newLastMessage ? lastMessage.current.id !== newLastMessage.id : false);
            lastMessage.current = newLastMessage;
          } else {
            setHasMoreData(false);
          }
        });
    }
  };

  const handleScroll = (event) => {
    const position = event.target.scrollTop;
    if (position > MIN_SEPARATOR_TRACKER && scrollPosition !== 0) {
      setScrollPosition(0);
    } else if (position < MIN_SEPARATOR_TRACKER && scrollPosition === 0) {
      setScrollPosition(position);
    }
  };

  useEffect(() => {
    updateTypingStatus({ typing: isTyping })
  }, [isTyping]);

  const inviteToVideoCall = () => {
    if (!isShowStartVideoCallMessage) {
      dispatch(showStartVideoCallMessage(true));
    }
  }

  const hideSystemMessage = () => {
    if (isShowStartVideoCallMessage) {
      dispatch(showStartVideoCallMessage(false));
    }
  }

  const sendCallInvitation = async () => {
    if (isShowStartVideoCallMessage) {
      dispatch(showStartVideoCallMessage(false));
    }
    await sendMessage({type: MSG_TYPES.CALL_INVITATION});
  }

  const imagesCount = useMemo(() => {
    let images = 0;
    messages.forEach(m => {
      if (m.type === MSG_TYPES.IMAGE) {
        images += 1;
      }
    })
    return images;
  }, [messages.length]);

  const isImageLoaded = useMemo(() => {
    if (tempImageMessageId === null) {
      return false;
    }
    const messageIndex = messages.findIndex(m => m.id === tempImageMessageId);
    return messageIndex !== -1;
  }, [messages.length, tempImageMessageId]);

  const incrementLoadedImages = () => {
    updateLoadedImages(loadedImages + 1);
  };

  const showModal = (item) => {
    setFullSizeChatImage(item);
  };

  const pressClose = () => {
    archiveRoom({archiveChat, chatId: selectedRoom.id});
  };

  const pressStart = () => {
    joinAsHost({activateChat, chatId: selectedRoom.id });
  };

  const pressSend = () => {
    if (messageValue !== '') {
      sendMessage({msg: messageValue});
      setMessageValue('');
    }
  };

  const updateTyping = () => {
    if (!isTyping) {
      setTyping(true);
    }

    clearTimeout(typingRef.current);
    typingRef.current = setTimeout(() => setTyping(false), TYPING_TIME);
  };

  const changeMessageActiveHost = (value) => {
    reassignHost({ reassignChat, chatId: selectedRoom.id, assignTo: value.id});
    dispatch(setActiveRoomId(null));
  };

  const updateMessage = (e) => {
    updateTyping();
    setMessageValue(e.currentTarget.value);
  };

  const sendNewImage = async (event) => {
    const file = event.target.files[0];
    const {width, height} = await getImageParams(file);

    if (file.size > IMAGE_MAX_SIZE || width > IMAGE_MAX_LENGTH || height > IMAGE_MAX_LENGTH) {
      file.data = await resizeFile(file);
      file.isBase64 = true;
    }

    await sendFile({
      file,
      chatRoomId: selectedRoom.id,
      type: MSG_TYPES.IMAGE
    })
  };

  const openGalleryDialog = () => {
    if (!pendingStatus) {
      fileInputRef.current.click();
    }
  };

  const closeVideoChat = () => {
    if (!!videoChatAction) {
      if (scrollPosition === 0) {
        seenMessages()
          .finally(() => {
            videoChatAction();
          })
      } else {
        videoChatAction();
      }
    }
  };

  const renderChatHeader = () => {
    if (videoChatAction) {
      return
    }
    return (
      <div className='green-header'>
        <div className='header-item'>
          <div>
          <h5>Chat with</h5>&nbsp;
          <h5 className='customer-name'>{`${!!clientParticipant.displayName ? clientParticipant.displayName : clientParticipant.email}`}</h5>
          </div>
          {/* <MoreVertRoundedIcon onClick={pressDots} /> */}
          {!pendingStatus && chatConfig && chatConfig.enabled &&
              <div className='header-item_video-call' onClick={inviteToVideoCall}>
                <StartVideoCall />
              </div>
          }
        </div>
        <div className='header-item header-item_actions'>
          {!pendingStatus && (
            <button
              onClick={pressClose}
              className='button-close'
              disabled={isActionInProgress}
            >
              {'CLOSE'}
            </button>
          )}
          {!pendingStatus &&
            <Select
              options={
                hosts.map((user) => {
                  return {label: `${user.firstName} ${user.lastName}`, value: user}
                })
              }
              defaultValue={{value: defaultValue[0], label: `${userEntity.firstName} ${userEntity.lastName}`}}
              className='select-items'
              isSearchable={false}
              onChange={(value) => changeMessageActiveHost(value.value)}
              isDisabled={isActionInProgress}
            />
          }
        </div>
      </div>
    )
  };

  const renderChatBottom = () => {
    return (
      <div className={`bottom-container${!!videoChatAction ? '-video' : ''}`}>
        {pendingStatus ? pendingChatBottom() : activeChatBottom()}
      </div>
    )
  };

  const pendingChatBottom = () => (
    <button
      onClick={pressStart}
      className='button-start-chat'
      disabled={isActionInProgress}
    >
      {'START'}
    </button>
  )

  const activeChatBottom = () => (
    <>
      <div className='bottom-container_send-image' onClick={openGalleryDialog}>
        <AddAPhoto style={{width: '24px', height: '24px'}} />
      </div>
      <input
        className='bottom-container_file-input'
        ref={fileInputRef}
        onChange={sendNewImage}
        type="file"
        accept="image/*"
        value=""
        label='camera'
        capture='image'
      />
      <textarea
        name='valueText'
        onChange={updateMessage}
        value={messageValue}
        placeholder={'Type a message'}
        className='text-input'
        readOnly={pendingStatus}
        onFocus={seenMessages}
      />
      <div>
        <div
          className={`button-send ${pendingStatus ? 'button-send-gray' : 'button'}`}
          onClick={() => !pendingStatus && pressSend()}
        >
          <SendIcon style={{width: '24px', height: '24px'}}/>
        </div>
        {/* TO DO add this code for video icon
            <button onClick={() => !pendingStatus && pressCamera()} className='button-camera'>
              <IconCamera />
            </button>
          */}
      </div>
    </>
  )

  const renderTyping = () => {
    const remoteTyping = roomTyping && Object.keys(roomTyping).filter(id => id !== userEntity.id).find(id => !!roomTyping[id]);
    if (!remoteTyping) return null;

    const userForTyping = selectedRoom.participants[remoteTyping]

    if (userForTyping) {
      return (
        <div className='typing-container'>
          <div className='image-border'>
            {userForTyping.profileAvatarUrl
              ? <img src={userForTyping.profileAvatarUrl} alt='C' className='avatar' />
              : <h4>{userForTyping.profileInitials}</h4>
            }
          </div>
          <div className='typing-dots'>
            <h5>Typing</h5>
            <AnimatedDots />
          </div>
        </div>
      )
    }
    return null;
  };

  const renderHours = (item) => {
    return <h6>{formatMsgTime(item)}</h6>;
  };

  useEffect(() => {
    if (visibleNewMessagesSeparator && scrollPosition === 0 && roomMessagesDisplayed && selectedRoom.id) {
      seenMessages();
      const timer = setTimeout(() => {
        setVisibleNewMessagesSeparator(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [visibleNewMessagesSeparator, scrollPosition, roomMessagesDisplayed]);

  useEffect(() => {
    let list = [...messages];
    const lastSeen = selectedRoom.metadata && selectedRoom.metadata.seenStatus && selectedRoom.metadata.seenStatus[userEntity.id]
      ? new Date(selectedRoom.metadata.seenStatus[userEntity.id]).valueOf()
      : 0

    let lastNewMessagesIndex = JSON.parse(JSON.stringify(messages)).reverse().findIndex((m, i) => {
      return new Date(new Date(m.timestamp).toUTCString()).valueOf() > lastSeen
        && ( m.owner.uuid !== userEntity.id && !m.owner.system)
    });

    if (lastNewMessagesIndex > 0) {
      const separatorIndex = messages.length - lastNewMessagesIndex;
      setVisibleNewMessagesSeparator(true);
      list = messages.slice(0, separatorIndex)
        .concat([{id: `${new Date().valueOf()}_new`, timestamp: lastSeen}])
        .concat(messages.slice(separatorIndex, messages.length + 1))
    }

    setMessagesList(list);
  }, [messages.length, roomSeenStatus, selectedRoom.id, visibleNewMessagesSeparator]);

  const renderChatItems = () => {
    return messagesList.map((msg, index) => {
      if (msg.id.indexOf('_new') !== -1) {
        return (
          <div className='new-message-item new-message-item_newmessage' key={`${msg.id}_new`}>
            <div className='new-message-item_newmessage_delimiter'/>
            <p>new messages</p>
            <div className='new-message-item_newmessage_delimiter'/>
          </div>
        );
      }
      return (
        <ChatMessagesContainer
          key={msg.id || msg.timestamp}
          item={msg}
          index={index}
          selectedRoom={selectedRoom}
          videoChatAction={videoChatAction}
          incrementLoadedImages={incrementLoadedImages}
          showModal={showModal}
        />
      )
    });
  };

  const renderMessages = () => {
    return (
      <div className='message-container-chat'>
        <div
          id="scrollableDiv"
          style={{
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
        >
          <InfiniteScroll
            dataLength={messagesList.length}
            next={fetchOldMessages}
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
            inverse={true}
            hasMore={hasMoreData}
            loader={<Loader height={'2rem'} width={'2rem'} />}
            scrollableTarget="scrollableDiv"
            onScroll={(event) => handleScroll(event)}
            initialScrollY={0}
            key={selectedRoom.id}
          >
            {renderTyping()}
            {
              isShowStartVideoCallMessage &&
                <div className='message-item' key={TEMP_MESSAGE.START_VIDEO_CALL}>
                  <div className='message-item message-item_my'>
                    <p className='message-item_my_question'>Invite to start video call?</p>
                    <p onClick={sendCallInvitation}>Send</p>
                    <p onClick={hideSystemMessage}>Cancel</p>
                  </div>
                  <div className='host-time'>{renderHours(new Date())}</div>
                </div>
            }
            {
              (tempImageMessageId !== null && !isImageLoaded) &&
                <div className='message-item' key={TEMP_MESSAGE.IMAGE}>
                  <div className='host-msg-with-image'>
                    <ImageMessage
                      item={{key: TEMP_MESSAGE.IMAGE}}
                      isHost={false}
                      isMessageLoading={true}
                      incrementLoadedImages={incrementLoadedImages} />
                  </div>
                  <div className='host-time'>{renderHours(new Date())}</div>
                </div>
            }
            {
              messagesList.length > 0 &&
                renderChatItems()
            }
          </InfiniteScroll>
        </div>
      </div>
    )
  }

  return (
    <div className='chat-container'>
      {renderChatHeader()}
      {renderMessages()}
      {renderChatBottom()}
    </div>
  )
};

export default ChatMessenger;
