import React from "react";
import ProfileSettings from './profile-settings';
import ActivityLog from './activity-log/host-calls';
import HostAvailability from './HostAvailability';
import AudioVideoSettings from "./audio-video-settings";
import AppSettings from "./app-settings/app-settings";
import {SETTINGS_TAB} from "../../../constants/const";

export const SETTING_MENU_ITEMS = [
  {
    key: SETTINGS_TAB.MANAGE_AVAILABILITY,
    tabContent: <HostAvailability/>,
    label: 'Manage Availability',
  },
  {
    key: SETTINGS_TAB.PROFILE_DETAILS,
    tabContent: <ProfileSettings />,
    label: 'Profile Details',
  },
  {
    key: SETTINGS_TAB.AUDIO_VIDEO,
    tabContent: <AudioVideoSettings />,
    label: 'Audio & Video',
  },
  {
    key: SETTINGS_TAB.APP_SETTINGS,
    tabContent: <AppSettings />,
    label: 'App settings',
  },
  {
    key: SETTINGS_TAB.CALL_HISTORY,
    tabContent: <ActivityLog />,
    label: 'Call History',
  },
];

export const TERM_OF_USING = [
  {
    tabContent: null,
    label: 'Terms of use',
    route: `${process.env.REACT_APP_WEB_DOMAIN}/terms`,
  },
  {
    tabContent: null,
    label: 'Privacy policy',
    route: `${process.env.REACT_APP_WEB_DOMAIN}/privacy`,
  },
];
