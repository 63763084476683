import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppAuthState } from '../authState';
import { handleResponse } from '../utils/http';

const baseUrl = process.env.REACT_APP_CAAZAM_REST_EP;

export default function useCaazamREST() {
  const { authenticatedUser } = useAppAuthState();
  const { installId: deviceId = null } = useSelector(state => state.system);
  const { activeShopId } = useSelector((state) => state.shops);

  const getUserToken = async (tokenUser) => {
    if (tokenUser) {
      return tokenUser
        .getIdToken(/* forceRefresh */ true)
        .then((token) => {
          return token;
        })
        .catch((error) => {
          console.error('Failed to retrieve REST API token', error);
          return null;
        });
    } else {
      return null;
    }
  };

  const authorizedPostRequest = useCallback((postUrl, body) => {
    return getUserToken(authenticatedUser).then((token) =>
      fetch(`${baseUrl}/${postUrl}`, {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
          'x-caazam-device-id': deviceId,
          'Content-Type': 'application/json',
          'X-Boutiq-App': 'host',
        },
        body: body ? JSON.stringify(body) : null
      })
    );
  }, [authenticatedUser, deviceId]);

  const authorizedDeleteRequest = useCallback((deleteUrl) => {
    return getUserToken(authenticatedUser).then((token) =>
      fetch(`${baseUrl}/${deleteUrl}`, {
        method: 'DELETE',
        headers: {
          authorization: 'Bearer ' + token,
          'x-caazam-device-id': deviceId,
          'X-Boutiq-App': 'host',
        },
      })
    );
  }, [authenticatedUser, deviceId]);

  const authorizedGetRequest = useCallback((getUrl, signal = null) => {
    return getUserToken(authenticatedUser).then((token) =>
      fetch(`${baseUrl}/${getUrl}`, {        
        method: 'GET',
        headers: {
          authorization: 'Bearer ' + token,
          'x-caazam-device-id': deviceId,
          'X-Boutiq-App': 'host',
        },
        signal,
      })
    );
  }, [authenticatedUser, deviceId]);

  const acceptCall = useCallback((shopId, callId) =>
      authorizedPostRequest(`vs/shop/${shopId}/call/${callId}/accept`)
        .then(result => handleResponse(result))
    , [authenticatedUser]);

  const rejectCall = useCallback((shopId, callId) =>
    authorizedPostRequest(`vs/shop/${shopId}/call/${callId}/reject`)
      .then(result => handleResponse(result))
    , [authenticatedUser]);

  const rejoinCall = useCallback((shopId, callId) =>
    authorizedPostRequest(`vs/shop/${shopId}/call/${callId}/rejoin`)
      .then(result => handleResponse(result))
    , [authenticatedUser]);

  const teardownCall = useCallback((shopId, callId) =>
    authorizedPostRequest(`vs/shop/${shopId}/call/${callId}/teardown`)
    , [authenticatedUser]);

  const searchCustomers = useCallback((shopId, searchString, signal) =>
      authorizedGetRequest(`vs/shop/${shopId}/customers/search?search=${encodeURIComponent(searchString)}`, signal)
        .then(result => handleResponse(result))
    , [authenticatedUser]);

  const getCustomerProfile = useCallback(
    (shopId, customerId, customerEmail, contextId, signal) => {
      const query = new URLSearchParams();
      !!customerId && query.append('customerId', customerId);
      !!customerEmail && query.append('customerEmail', customerEmail);
      !!contextId && query.append('contextId', contextId);

      return authorizedGetRequest(`vs/shop/${shopId}/customers/profile?${query.toString()}`, signal)
        .then(result => handleResponse(result))
    }, [authenticatedUser]);

  const callEvent = useCallback((shopId, callId, eventId, eventData) => 
      authorizedPostRequest(`vs/shop/${shopId}/call/${callId}/client_events/${eventId}`, eventData)
    , [authenticatedUser]);

  const createCustomerInvite = useCallback(
    (shopId, name, email, id) => {
      const body = {
        customerName: name || null,
        customerEmail: email || null,
        customerId: id || null,
      };

      return authorizedPostRequest(`vs/shop/${shopId}/call/`, body)
        .then(result => handleResponse(result))
    }, [authenticatedUser]);

  
  const createPermalink = useCallback(
    (shopId) => authorizedPostRequest(`vs/shop/${shopId}/callLinks/hostLink`)
      .then((res) => handleResponse(res))
    , [authenticatedUser]);

  const getSlots = (shopId, cursor = null, scheduleId = null, eventTypeId = null) => {
    let urlParams = new URLSearchParams();
    if (cursor) {
      urlParams.append('cursor', cursor);
    }
    if (scheduleId){
      urlParams.append('scheduleId', scheduleId);
    }
    if (eventTypeId) {
      urlParams.append('eventTypeId', eventTypeId);
    }
    return authorizedGetRequest(`vs/shop/${shopId}/schedule?${urlParams.toString()}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('failed fetching scheduling slots', res.status);
        }
      })
      .then((data) => {
        if (!data.slots || data.slots.length === 0) {
          return null;
        }
        return data.slots;
      });
  };

  const hostAnalytics = useCallback(
    (shopId, body) => authorizedPostRequest(`vs/shop/${shopId}/analytics/host_calls`, body)
      .then((res) => handleResponse(res))
    , [authenticatedUser]);

  const getDiscountCodeStatus = useCallback((shopId, code) => {
    return authorizedGetRequest(`vs/shop/${shopId}/discounts?code=${code}`)
      .then(result => handleResponse(result))
  }, [authenticatedUser]);

  const activateChat = useCallback((shopId, chatId) =>
      authorizedPostRequest(`vs/shop/${shopId}/chats/${chatId}/activate`)
        .then(result => handleResponse(result))
    , [authenticatedUser]);

  const archiveChat = useCallback((shopId, chatId) =>
      authorizedPostRequest(`vs/shop/${shopId}/chats/${chatId}/archive`)
        .then(result => handleResponse(result))
    , [authenticatedUser]);

  const reassignChat = useCallback((shopId, chatId, assignTo) =>
      authorizedPostRequest(`vs/shop/${shopId}/chats/${chatId}/reassign`, { assignTo })
        .then(result => handleResponse(result))
    , [authenticatedUser]);

  const getDiagnosticsToken = useCallback(() => {
    return authorizedGetRequest(`vs/shop/${activeShopId}/diagnostic/token`, )
      .then((connectResponse) => handleResponse(connectResponse));
  }, [authenticatedUser, activeShopId]);

  const getDiagnosticIceServers = useCallback(() => {
    return authorizedGetRequest(`vs/shop/${activeShopId}/diagnostic/ice-servers`, )
      .then((connectResponse) => handleResponse(connectResponse));
  }, [authenticatedUser, activeShopId]);

  const sendDiagnosticReport = useCallback((report) => {
    return authorizedPostRequest(`vs/shop/${activeShopId}/diagnostic/report`, {...report})
      .then((response) => {
        if (response.ok) return;
        else return handleResponse(response)
      })
  }, [authenticatedUser, activeShopId]);

  const sendPostCallFeedback = useCallback((feedback, prevCallShopId) => {
    return authorizedPostRequest(`vs/shop/${prevCallShopId}/diagnostic/post-call-feedback`, {...feedback})
      .then((response) => {
        if (response.ok) return;
        else return handleResponse(response)
      });
  }, [authenticatedUser, activeShopId]);

  const sendDraftOrder = useCallback((shopId, draftOrder) => {
    return authorizedPostRequest(`vs/shop/${shopId}/draftOrders/`, {...draftOrder})
      .then(handleResponse)
  }, [authenticatedUser])

  const updateDraftOrder = useCallback((shopId, draftOrder, draftOrderId) => {
    return authorizedPostRequest(`vs/shop/${shopId}/draftOrders/${draftOrderId}`, {...draftOrder})
      .then(handleResponse)
  }, [authenticatedUser])

  const deleteDraftOrder = useCallback((shopId, draftOrderId) => {
    return authorizedDeleteRequest(`vs/shop/${shopId}/draftOrders/${draftOrderId}`)
      .then((response) => {
        if (response.ok) return;
        else return handleResponse(response)
      })
  }, [authenticatedUser])

  const getInvoicePreview = useCallback((shopId, invoice, draftOrderId) => {
    return authorizedPostRequest(`vs/shop/${shopId}/draftOrders/${draftOrderId}/preview`, {...invoice})
      .then(handleResponse)
  }, [authenticatedUser])

  const sendInvoice = useCallback((shopId, invoice, draftOrderId) => {
    return authorizedPostRequest(`vs/shop/${shopId}/draftOrders/${draftOrderId}/send`, {...invoice})
      .then((response) => {
        if (response.ok) return;
        else return handleResponse(response)
      })
  }, [authenticatedUser])

  const createEvent = useCallback((shopId, callData) => {
    return authorizedPostRequest(`vs/shop/${shopId}/schedule`, {...callData})
      .then(handleResponse)
  }, [authenticatedUser])

  const rescheduleEvent = useCallback((shopId, scheduleId, eventData) => {
    return authorizedPostRequest(`vs/shop/${shopId}/schedule/event/${scheduleId}/reschedule`, {...eventData})
      .then((response) => {
        if (response.ok) return;
        else return handleResponse(response)
      })
  }, [authenticatedUser]);

  const assignEvent = useCallback(
    (shopId, callId) => authorizedPostRequest(`vs/shop/${shopId}/schedule/event/${callId}/assign`)
    , [authenticatedUser]);

  const unassignEvent = useCallback((shopId, scheduleId) => {
    return authorizedPostRequest(`vs/shop/${shopId}/schedule/event/${scheduleId}/unassign`)
      .then((response) => {
        if (response.ok) return;
        else return handleResponse(response)
      })
  }, [authenticatedUser])

  const deleteEvent = useCallback((shopId, scheduleId) => {
    return authorizedDeleteRequest(`vs/shop/${shopId}/schedule/event/${scheduleId}`)
      .then((response) => {
        if (response.ok) return;
        else return handleResponse(response)
      })
  }, [authenticatedUser])

  const cancelEvent = useCallback((shopId, scheduleId, deleteConfirmInfo) => {
    return authorizedPostRequest(`vs/shop/${shopId}/schedule/event/${scheduleId}/cancel`, {...deleteConfirmInfo})
      .then((response) => {
        if (response.ok) return;
        else return handleResponse(response)
      })
  }, [authenticatedUser])

  
  const getEventTypes = useCallback((shopId) => {
    return authorizedGetRequest(`vs/shop/${shopId}/schedule/eventTypes`)
      .then(handleResponse);

  }, [authenticatedUser]);

  const createCart = useCallback((shopId, callId) => {
    return authorizedPostRequest(`vs/shop/${shopId}/cart`, {callId})
      .then(result => handleResponse(result))
  }, [authenticatedUser])

  const refreshShowroomPresetProductDetails = useCallback((shopId, productIds) => {
    const query = new URLSearchParams();
    query.append('ids', productIds)
    return authorizedGetRequest(`vs/shop/${shopId}/productsv2/list?${query.toString()}`)
      .then(result => handleResponse(result))
  }, [authenticatedUser])

  return {
    acceptCall,
    rejectCall,
    rejoinCall,
    teardownCall,
    searchCustomers,
    getCustomerProfile,
    callEvent,
    createCustomerInvite,
    createPermalink,
    getSlots,
    hostAnalytics,
    getDiscountCodeStatus,
    activateChat,
    archiveChat,
    reassignChat,
    getDiagnosticsToken,
    sendDiagnosticReport,
    getDiagnosticIceServers,
    sendPostCallFeedback,
    sendDraftOrder,
    updateDraftOrder,
    deleteDraftOrder,
    getInvoicePreview,
    sendInvoice,
    createEvent,
    rescheduleEvent,
    deleteEvent,
    assignEvent,
    unassignEvent,
    cancelEvent,
    getEventTypes,
    createCart,
    refreshShowroomPresetProductDetails
  };
}
