import React, { useEffect, useState, useRef, useCallback } from 'react';
import {useHistory} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { logger } from '../../logging';
import { useActiveCallProvider } from '../../components/ActiveCallProvider';
import { useSoundFX } from '../../components/SoundFXProvider';
import useIncomingCall from '../../hooks/useIncomingCall';
import useConnectedCall from '../../hooks/useConnectedCall';
import useHostStatus from '../../hooks/useHostStatus';
import InviteToCallModal from '../../components/InviteToCallModal/InviteToCallModal';
import InactiveUserModal from '../../components/inactive-user-modal/inactive-user-modal';
import PageHeader from '../../components/page-header/page-header';
import HomepageSidebar from './sidebar';
import useHostCallsSchedule from '../../hooks/useHostCallsSchedule';
import DailyCalendar from './daily-calendar/daily-calendar';
import useHostDailyCalls from '../../hooks/useHostDailyCalls';
import useHostCallsHistory from '../../hooks/useHostCallsHistory';

import {
  HEADER_NOTIFICATION_TYPES,
  CALL_TYPES,
  SOUND_FX_PATHS,
  FEEDBACK_TYPE,
} from '../../utils/consts';
import { VIDEO_DURATION_FOR_POSTCALL_FEEDBACK } from '../../utils/consts'
import CallIcon from '@material-ui/icons/Call';
import HostSettings from './host-setting/host-settings';
import Chat from './chat/chat';
import ContentHeader from './header-content';
import { useNotificationProvider } from '../../components/notifications/NotificationProvider';
import { setCallShopId } from '../../reducers/shops-reducer';
import useShopFeedbackConfig from '../../hooks/useShopFeedbackConfig';
import './home-page.scss';
import PostCallFeedbackModal from "../../components/Modals/postCallFeedback";
import HeaderNotification from "../../components/notifications/header-notification";
import PermissionsModal from "../../components/Modals/permissions";
import usePermissions from "../../hooks/usePermissions";
import PermissionsHelperModal from "../../components/Modals/permissionsHelper";
import {hideDraftOrderModal, updateDraftOrderId} from "../../reducers/active-call-reducer";
import CallDetailsSideBar from "./call-details-side-bar/call-details-side-bar";
import InviteClientButton from "../../components/buttons/invite-client-button/invite-client-button";
import BlockOffTimeModal from "../../components/Modals/block-off-time-modal/block-off-time-modal";
import {useHostSettingsProvider} from "../../components/hostSettingsProvider/host-settings-provider";
import {useCallDetailsProvider} from "./call-details-side-bar/call-details-provider/call-details-provider";
import ShowroomPresetSaveModal from '../../components/Modals/showroom-preset-save-modal/showroom-preset-save-modal';
import { useShowroomPresetProvider } from '../../providers/preset-provider/preset-provider';
import ShowroomPresetsModal from '../../components/Modals/showroom-presets-modal/showroom-presets-modal';
import RemovePresetModal from '../../components/Modals/remove-preset-modal/remove-preset-modal';
import EmailEventsModal from '../../components/Modals/email-events-modal/email-events-modal';
import LoadingLogo from '../../components/loading-logo/loading-logo';
import ListCallsSkeleton from '../../components/skeletons/list-calls-skeleton/list-calls-skeleton';
import OnFirstLoadModal from '../../components/OnFirstLoadModal/OnFirstLoadModal';
import ClientHistory from './client-history/client-history';
import EmptyDayPage from './empty-day-page/empty-day-page';
import { parsePastCompletedCalls, parsePastScheduledCalls, parseScheduleCalls } from '../../utils/parse';

const HomePage = ({ setVideoToken, isFirstLoad }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { activeShopId, availableShops } = useSelector((state) => state.shops);
  const { user: caazamUser, isUserActive } = useSelector((state) => state.user);
  const { installId: deviceId = null } = useSelector(state => state.system);
  const { callId: prevCallId, callDuration: prevCallDuration, dailyDisconnect: prevDailyDisconnect } = useSelector((state) => state.prevCall);
  const activeShopData =
    availableShops && activeShopId && availableShops[activeShopId];

  const {
    incomingCallData,
    incomingCallError,
    acceptInCall,
    rejectInCall,
  } = useIncomingCall(caazamUser?.id);

  const { connectedCallData, rejoinCall: rejoinExistingCall, teardownCall: teradownExistingCall } = useConnectedCall(caazamUser?.id, deviceId);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const setHostStatus = useHostStatus(caazamUser);
  const { showPermissionsModal, callBrowserPermissions, showPermissionsWarning, showPermissionsHelperModal } = usePermissions();

  const [
    isIncomingCallNotificationVisible,
    setIncomingCallNotificationVisible,
  ] = useState(false);

  const [
    isRejoiningCallNotificationVisible,
    setRejoiningCallNotificationVisible,
  ] = useState(false);

  const [visibleDate, setVisibleDate] = useState(new Date());
  const [currentTime, setCurrentTime] = useState(moment().format('LT'));
  const today = useRef(moment());

  const hostId = caazamUser?.id;
  const { setActiveCallId, setActiveCallData, activeCallId } = useActiveCallProvider();
  const {
    onUpdateCallDetails,
    callDetails,
    toggleSidebarModal,
    emailEventsModalVisibility,
    isClientHistory,
    setIsClientHistory
  } = useCallDetailsProvider()
  const {
    saveShowroomModalVisibility,
    onHidePresetModal,
    presetModalVisibility,
    removePresetModalVisibility,
    clearShowroomPresetState
  } = useShowroomPresetProvider();
  const soundFX = useSoundFX();

  const [callAcceptError, setCallAcceptError] = useState(null);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openBlockOffTimeModal, setOpenBlockOffTimeModal] = useState(false);
  const {
    hostScheduleCalls,
    hostScheduleCallsLoading,
  } = useHostCallsSchedule(hostId, visibleDate);
  const { callsDailySummary, pastCallsLoading, pastCalls } = useHostDailyCalls(
    hostId,
    visibleDate
  );

  const { callsHistorySummary } = useHostCallsHistory(activeShopId, hostId, visibleDate);
  const [showFirstLoadModal, setShowFirstLoadModal] = useState(false);
  const [firstLoadModalDismissed, setFirstLoadModalDismissed] = useState(false);
  const [pastCallsList, setPastCallsList] = useState([]);
  const [scheduledCallsList, setScheduledCallsList] = useState([]);
  const [isCallLoading, setCallLoading] = useState(false);
  const [isShopInactive, setShopInactive] = useState(false);
  const [blockOffEventId, setBlockOffEventId] = useState(null)
  const [inviteClientModalVisible, setInviteClientModalVisible] = useState(false)

  const { showToast, showHeaderNotification, removeHeaderNotification } = useNotificationProvider();
  const {isIncomingCallSoundMuted, isSettingsVisible} = useHostSettingsProvider();

  const { feedbackConfig, feedbackConfigLoading } = useShopFeedbackConfig(activeShopId);
  const feedbackType = feedbackConfig?.type ?? FEEDBACK_TYPE.CSAT;

  const renderPostCallFeedback = prevCallId && prevCallDuration && prevCallDuration < VIDEO_DURATION_FOR_POSTCALL_FEEDBACK && prevDailyDisconnect;

  // https://caazam.atlassian.net/browse/CRRC-1405: need to inform server we cannot connect so room can tear down.
  // probably also show something to host user
  const renderCallConnectErrorModal = prevCallId && !prevDailyDisconnect;
  const dividerRef = useRef();

  const containerRef = useCallback(
    (node) => {
      if (node !== null && pastCalls && dividerRef.current) {
        dividerRef.current.scrollIntoViewIfNeeded(true)
      }
    },
    [pastCalls, hostScheduleCalls, dividerRef.current]
  );

  useEffect(()=>{
    const interval = setInterval(() => {
      setCurrentTime(moment().format('LT'));
    }, 60000);
    return () => clearInterval(interval);
  },[])
  

  const getNotificationType = () => {
    if (isIncomingCallNotificationVisible) {
      return HEADER_NOTIFICATION_TYPES.INCOMING_CALL;
    }
    if (isRejoiningCallNotificationVisible) {
      return HEADER_NOTIFICATION_TYPES.REJOINING_CALL;
    }
    if (isShopInactive) {
      return HEADER_NOTIFICATION_TYPES.SHOP_INACTIVE;
    }
    return null;
  }

  useEffect(() => {
    if (openInviteModal || openBlockOffTimeModal) {
      onHideInviteClientModal()
    }
  }, [openInviteModal, openBlockOffTimeModal])

  useEffect(() => {
    if (incomingCallData) {
      let ringInterval;
      logger.info('Incoming call',incomingCallData);
      setCallAcceptError(null);
      setFirstLoadModalDismissed(true);
      setIncomingCallNotificationVisible(true);
      if (!isIncomingCallSoundMuted) {
        const playRingingSound = () =>
          soundFX.playSoundFX(SOUND_FX_PATHS.ringing);
        ringInterval = setInterval(playRingingSound, 20000);
        playRingingSound();
      }
      return () => {
        setIncomingCallNotificationVisible(false);
        clearInterval(ringInterval);
      };
    }
  }, [incomingCallData]);

  useEffect(() => {
    if (isFirstLoad && !firstLoadModalDismissed && isUserActive) {
      setShowFirstLoadModal(true);
    } else {
      setShowFirstLoadModal(false);
    }
  }, [isFirstLoad, firstLoadModalDismissed, isUserActive]);

  useEffect(() => {
    if (showPermissionsModal) {
      setShowFirstLoadModal(false);
    }
  }, [showPermissionsModal]);

  useEffect(() => {
    toggleSidebarModal(null)
    onUpdateCallDetails(null);
  }, [visibleDate])

  useEffect(() => {
    if (callAcceptError) {
      if (callAcceptError.statusCode === 409) {
        showToast({
          title: `Incoming call`,
          description: `Call was answered by another host`,
          icon: <CallIcon htmlColor={'#FFBE88'}/>,
          type: 'warning',
        });
      } else {
        showToast({
          title: `Error answering call`,
          description: callAcceptError.message,
          icon: <CallIcon htmlColor={'#E94848'}/>,
          type: 'error',
        });
      }
    }
  }, [callAcceptError]);

  useEffect(() => {
    var futureCalls = [];
    if (hostScheduleCalls?.length) {
      const now = new Date();
      futureCalls = hostScheduleCalls.map(parseScheduleCalls)
        .filter((call) => call.timestamp > now);
    }
    setScheduledCallsList(
      futureCalls
        .sort((callA, callB) => (callA.timestamp > callB.timestamp ? 1 : -1))
    );
  }, [hostScheduleCalls]);

  useEffect(() => {
    let pastScheduledCalls = [];
    let pastCompletedCalls = [];
    if (hostScheduleCalls?.length) {
      const now = new Date();
      pastScheduledCalls = hostScheduleCalls
        .map(parsePastScheduledCalls)
        .filter((call) => call.timestamp < now);
    }
    if (pastCalls?.length) {
      pastCompletedCalls = pastCalls.map(parsePastCompletedCalls);
    }
    let pastDailyCalls = pastScheduledCalls
      .concat(pastCompletedCalls)
      .sort((callA, callB) => (callA.timestamp > callB.timestamp ? 1 : -1));
    setPastCallsList(pastDailyCalls);
  }, [pastCalls, hostScheduleCalls]);

  useEffect(() => {
    if (activeShopData) {
      if (activeShopData.active) {
        setShopInactive(false);
      } else {
        setShopInactive(true);
      }
    }
  }, [activeShopData]);

  useEffect(() => {
    toggleSidebarModal(null)
    onUpdateCallDetails(null)
  }, [isSettingsOpen])

  useEffect(() => {
    if(!isSettingsVisible &&
      !isClientHistory &&
      callDetails && ![...pastCallsList, ...scheduledCallsList].some(call => call.id === callDetails?.id)
    ) {
      toggleSidebarModal(null)
    }
  }, [pastCallsList, callDetails])

  useEffect(() => {
    onUpdateCallDetails(currentCall => [...pastCallsList, ...scheduledCallsList].find(call => call.id === currentCall?.id))
  }, [JSON.stringify(pastCallsList), JSON.stringify(scheduledCallsList)]);

  useEffect(()=>{
    return () => clearShowroomPresetState();
  },[])

  const toggleSettings = (state) => {
    setIsSettingsOpen(state);
    setIsChatVisible(false);
  };

  const toggleChat = (state) => {
    setIsClientHistory(false)
    setIsChatVisible(state);
    setIsSettingsOpen(false);
  };

  const acceptCall = () => {
    soundFX.userInteraction();
    setCallLoading(true);
    dispatch(updateDraftOrderId({draftOrderId: null}))
    dispatch(hideDraftOrderModal())
    logger.info('ACCEPTING incoming call',{ callId: incomingCallData.id});
    acceptInCall()
      .then((res) => {
        dispatch(setCallShopId(incomingCallData.shopId));
        setActiveCallId(incomingCallData.id);
        setActiveCallData(incomingCallData);
        setVideoToken(res);
        history.push('/video-room');
      })
      .catch((err) => {
        setCallLoading(false);
        setCallAcceptError(err);
        logger.error('ERROR accepting incoming call',err);
      });
    setIncomingCallNotificationVisible(false);
  };

  const rejectCall = () => {
    logger.info('REJECTING incomming call',{ callId: incomingCallData.id});
    soundFX.userInteraction();
    rejectInCall().catch((error) => {
      logger.error('ERROR rejecting incoming call',error);
    });
    setIncomingCallNotificationVisible(false);
  };

  const rejoinCall = () => {
    soundFX.userInteraction();
    setCallLoading(true);
    dispatch(updateDraftOrderId({draftOrderId: null}))
    dispatch(hideDraftOrderModal())
    if (!connectedCallData) {
      return setRejoiningCallNotificationVisible(false);
    }
    logger.info('REJOINING existing call',{ callId: connectedCallData.id});
    rejoinExistingCall()
      .then((res) => {
        setRejoiningCallNotificationVisible(false);
        dispatch(setCallShopId(connectedCallData.shopId));
        setActiveCallId(connectedCallData.id);
        setActiveCallData(connectedCallData);
        setVideoToken(res);
        history.push('/video-room');
      })
      .catch((err) => {
        setCallLoading(false);
        setCallAcceptError(err);
        logger.error('ERROR rejoining  call',err);
      });
  }

  const teardownCall = () => {
    soundFX.userInteraction();
    if (!connectedCallData) {
      return setRejoiningCallNotificationVisible(false);
    }
    logger.info('TEARING DOWN existing call',{ callId: connectedCallData.id});
    teradownExistingCall()
      .catch((err) => {
        logger.error('ERROR tearing down call',err);
      })
      .finally(()=>setRejoiningCallNotificationVisible(false));
  }


  useEffect(() => {
    if (
      isIncomingCallNotificationVisible
      || isRejoiningCallNotificationVisible
      || isShopInactive) {
      showHeaderNotification({
        type: getNotificationType(),
        incomingCallProps: {
          acceptCall,
          rejectCall,
          customerName: incomingCallData?.customerName,
          shopName: incomingCallData?.shopName,
        },
        rejoiningCallProps: {
          rejoinCall,
          teardownCall,
          customerName: connectedCallData?.customerName,
          shopName: connectedCallData?.shopName,
        }
      });
    } else {
      removeHeaderNotification();
    }
  }, [isIncomingCallNotificationVisible, isRejoiningCallNotificationVisible, isShopInactive])

  useEffect(() => {
    if (!!connectedCallData && !isCallLoading && !prevDailyDisconnect) {
      logger.warn(`This device  - ${deviceId} - should be connected to call ${connectedCallData.id}`);
      setRejoiningCallNotificationVisible(true);
    } else {
      setRejoiningCallNotificationVisible(false);
    }
  }, [connectedCallData, isCallLoading, prevDailyDisconnect])

  const createMeeting = () => setOpenInviteModal(true);

  const onOpenBlockOffTime = (id) => {
    setBlockOffEventId(id)
    setOpenBlockOffTimeModal(true)
  }

  const onHideBlockOffTime = () => {
    setOpenBlockOffTimeModal(false)
    setBlockOffEventId(null)
  }

  const onFirstLoadModalDismiss = () => {
    setFirstLoadModalDismissed(true);
    setShowFirstLoadModal(false);
  };

  const setAvailability = () => {
    setHostStatus(!caazamUser.available).catch((err) => {
      console.error('Error changing status', err);
    });
  };

  const onHideInviteClientModal = () => {
    setInviteClientModalVisible(false)
  }

  const onShowInviteClientModal = () => {
    setInviteClientModalVisible(true)
  }

  const onShowClientPage = () => {
    setIsChatVisible(false)
    setIsClientHistory(true)
  }

  const renderContentHeader = () => {
    return (
      <ContentHeader
        visibleDate={visibleDate}
        setAvailability={setAvailability}
        onShowClientHistory={onShowClientPage}
      />
    )
  };

  const renderHomePageModals = () => {
    return (
      <>
        {
          showPermissionsModal
            ? <PermissionsModal callPermissions={callBrowserPermissions}/> // modal to start permissions flow when they were never requested before (so not denied)
            : <>
              {
                showPermissionsHelperModal
                  ? <PermissionsHelperModal /> //modal for instructing user to provide permissions manually becuase they were denied
                  : <>
                    {
                      showFirstLoadModal &&
                      <OnFirstLoadModal onClose={onFirstLoadModalDismiss} /> //modal for incentivizing the user to make a UX interaction so that we can play sounds.
                    }
                    {
                      renderPostCallFeedback &&
                      <PostCallFeedbackModal/>
                    }
                  </>
              }
            </>
        }
      </>
    )
  }

  const renderContent = () => {
    if (isCallLoading) {
      return (
        <div className='call-loader'>
          <LoadingLogo />
        </div>
      );
    }
    return (
      <>
        {isClientHistory && <ClientHistory onBack={() => setIsClientHistory(false)} />}
        {!isChatVisible && <HostSettings toggleSettings={toggleSettings} />}
        <Chat isChatVisible={isChatVisible} setAvailability={setAvailability} setIsChatVisible={toggleChat} />
        {!(isSettingsOpen || isChatVisible || isClientHistory) ? (
          <>
            <HomepageSidebar
              feedbackType={feedbackType}
              createMeeting={createMeeting}
              pastCalls={pastCalls}
              hostScheduleCalls={hostScheduleCalls}
              dailySummary={callsDailySummary}
              visibleDate={visibleDate}
              setVisibleDate={setVisibleDate}
              isShopInactive={isShopInactive}
              callsHistorySummary={callsHistorySummary}
            />
            {pastCallsLoading || hostScheduleCallsLoading ? (
              <ListCallsSkeleton />
            ) : (
              <div className='calendar-container' >
                {renderContentHeader()}
                <div className='calendar-wrapper'>
                  {(pastCalls?.length) || (hostScheduleCalls?.length) ? (
                    <>
                      <div className={`calls-items-list`} ref={containerRef}>
                        <DailyCalendar
                          callList={pastCallsList}
                          calendarType={CALL_TYPES.HISTORY}
                          feedbackType={feedbackType}
                          selectedId={callDetails && callDetails.id}
                          onOpenBlockOffTime={onOpenBlockOffTime}
                        />
                        {moment(visibleDate).isSame(today, 'day') && (
                          <div className={`calendar-divider`} ref={dividerRef}>
                            <span>{currentTime}</span>
                            <div />
                          </div>
                        )}
                        <DailyCalendar
                          callList={scheduledCallsList}
                          selectedId={callDetails && callDetails.id}
                          calendarType={CALL_TYPES.SCHEDULED}
                          onOpenBlockOffTime={onOpenBlockOffTime}
                        />
                      </div>
                      {callDetails && (
                        <CallDetailsSideBar
                          feedbackType={feedbackType}
                          onOpenBlockOffTime={() => onOpenBlockOffTime(callDetails.id)}
                        />
                      )}
                    </>
                  ) : (
                    <EmptyDayPage today={today.current} date={moment(visibleDate)} />
                  )}
                  <ShowroomPresetsModal
                    visibility={presetModalVisibility}
                    onClosePress={onHidePresetModal}
                  />
                </div>
              </div>
            )}
            {saveShowroomModalVisibility && (
              <ShowroomPresetSaveModal />
            )}
            {emailEventsModalVisibility && (
              <EmailEventsModal />
            )}
            {removePresetModalVisibility && (
              <RemovePresetModal />
            )}
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      {
        showPermissionsWarning &&
        <HeaderNotification type={HEADER_NOTIFICATION_TYPES.AV_PERMISSIONS_DENIED}/>
      }
      <div className='navbar-page-container'>
        <PageHeader
          onHideAll={onHideInviteClientModal}
          createMeeting={createMeeting}
          isShopInactive={isShopInactive}
          setIsChatVisible={setIsChatVisible}
          isShowInviteModal={inviteClientModalVisible}
        />
        <div className={`homepage-container${isClientHistory ? ' common' : ' grid'}`}>
          {renderContent()}
        </div>
        {!isChatVisible && !isSettingsVisible && !isClientHistory && (
          <InviteClientButton
            modalVisible={inviteClientModalVisible}
            onShow={onShowInviteClientModal}
            onOpenInviteClient={createMeeting}
            onOpenBlockOffTime={() => onOpenBlockOffTime(null)}
          />
        )}
        {openInviteModal && (
          <InviteToCallModal
            onClose={() => setOpenInviteModal(false)}
          />
        )}
        {openBlockOffTimeModal && (
          <BlockOffTimeModal
            onClose={onHideBlockOffTime}
            scheduleId={blockOffEventId}
          />
        )}
        {!isUserActive && <InactiveUserModal onClose={() => { }}/>}
        {renderHomePageModals()}
      </div>
    </>
  );
};

export default HomePage;
