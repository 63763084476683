import React, {useState} from 'react';
import Modal from '../modal';
import './styles.scss';
import Checkbox from "../../checkbox";
import {FilledButton} from "../../buttons/buttons";
import {useDispatch, useSelector} from "react-redux";
import { resetPrevCall } from '../../../reducers/prev-call-reducer'
import useCaazamREST from "../../../hooks/useCaazamREST";
import { logger} from '../../../logging';

const PostCallFeedbackModal = () => {

  const dispatch = useDispatch();
  const { callId: prevCallId, callDuration: prevCallDuration, shopId: prevCallShopId } = useSelector((state) => state.prevCall);
  const { deviceData } = useSelector((state) => state.system);
  
  const { sendPostCallFeedback } = useCaazamREST();

  const [noIssue, setNoIssueCheckbox] = useState(false);
  const [notConnected, setNotConnectedCheckbox] = useState(false);
  const [isLowVideoQuality, setVideoQuality] = useState(false);
  const [isLowAudioQuality, setAudioQuality] = useState(false);
  const [isOther, setOther] = useState(false);
  const [comments, setComments] = useState('');
  const [isSending, setSending] = useState(false);
  const [validation, setValidation] = useState(true);

  const isAnyInput = noIssue || notConnected || isLowVideoQuality || isLowAudioQuality || isOther || !!comments;

  const handleNoIssueCheckbox = () => {
    setNoIssueCheckbox(!noIssue);
    setNotConnectedCheckbox(false);
    setVideoQuality(false);
    setAudioQuality(false);
    setOther(false);
    setValidation(true);
  }

  const handleNotConnectedCheckbox = () => {
    setNotConnectedCheckbox(!notConnected);
    setNoIssueCheckbox(false);
    setValidation(true);
  }

  const handleLowVideoQualityCheckbox = () => {
    setVideoQuality(!isLowVideoQuality);
    setNoIssueCheckbox(false);
    setValidation(true);
  }

  const handleLowAudioQualityCheckbox = () => {
    setAudioQuality(!isLowAudioQuality);
    setNoIssueCheckbox(false);
    setValidation(true);
  }

  const handleOtherIssuesCheckbox = () => {
    setOther(!isOther);
    setNoIssueCheckbox(false);
    setValidation(true);
  }

  const handleComments = (e) => {
    setComments(e.currentTarget.value);
    if (!!e.currentTarget.value)
      setValidation(true);
  }

  const closeModal = () => {
    dispatch(resetPrevCall());
  }

  const sendFeedback = () => {
    if (!isAnyInput) {
      setValidation(false);
      return;
    }
    setValidation(true);
    setSending(true);
    const body = {
      formData: {
        noIssue,
        callNotConnect: notConnected,
        lowVideoQuality: isLowVideoQuality,
        lowAudioQuality: isLowAudioQuality,
        isOtherIssue: isOther,
        comments,
      },
      deviceData,
      callId :prevCallId,
      callDuration: prevCallDuration,
    }
    sendPostCallFeedback(body, prevCallShopId)
      .catch(error => logger.error('Failed to send postcall feedback', error))
      .finally(() => {
        closeModal();
        setSending(false);
      })
  }

  return (
    <Modal modalClass='post-call-feedback-container' closeFunc={closeModal} isClosable={true}>
      <h3>{'We noticed this call was fairly short - was there any issue? (please check all that apply):'}</h3>
      <Checkbox
        label="no issue, just a short call"
        value={noIssue}
        onChange={handleNoIssueCheckbox}
      />
      <Checkbox
        label="yes, call got disconnected"
        value={notConnected}
        onChange={handleNotConnectedCheckbox}
      />
      <Checkbox
        label="yes, video quality was not good"
        value={isLowVideoQuality}
        onChange={handleLowVideoQualityCheckbox}
      />
      <Checkbox
        label="yes, audio quality was not good"
        value={isLowAudioQuality}
        onChange={handleLowAudioQualityCheckbox}
      />
      <Checkbox
        label="yes, other"
        value={isOther}
        onChange={handleOtherIssuesCheckbox}
      />
      <textarea
        name='otherIssues'
        onChange={handleComments}
        value={comments}
        placeholder={'Please share any other information on what went wrong. this helps us improve the service.'}
        className={`other-issues-input`}
      />
      <FilledButton
        className='button send-button'
        onClick={sendFeedback}
        disabled={isSending}
      >
        send
      </FilledButton>
      <div className='validation-text'>{ !validation ? `To send your feedback, please check one of the options or add a comment` : '' }</div>
    </Modal>
  );
};

export default PostCallFeedbackModal;
