import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { SettingsContext } from '../../../components/hostSettingsProvider/host-settings-provider';
import { SETTING_MENU_ITEMS, TERM_OF_USING } from './tabs';
import './menu.scss';

const HostSettingMenu = ({onChangeTab, selectedTab}) => {
  const onBackPress = (toggleSettingsDialog) => {
    toggleSettingsDialog(false)
    onChangeTab(SETTING_MENU_ITEMS[0])
  }

  return (
    <section className='host-settings-menu'>
      <ul className='menu-list'>
        <li className='menu-item-container'>
          <SettingsContext.Consumer>
            {({ toggleSettingsDialog }) => (
              <button className='back-btn' onClick={() => onBackPress(toggleSettingsDialog)}>
                <div className='back-btn-label-wrapper'>
                  <div className='back-btn-icon'>
                    <ArrowBackIcon />
                  </div>
                  <span className='back-btn-label'>Back</span>
                </div>
              </button>
            )}
          </SettingsContext.Consumer>
        </li>
        {SETTING_MENU_ITEMS.map((item) => (
          <li
            key={item.key}
            className={`menu-item-container${selectedTab === item.label ? ' active' : ''}`}
          >
            <button
              onClick={() => onChangeTab(item)}
              className='menu-item'
            >
              <span className='menu-item-label'>{item.label}</span>
            </button>
          </li>
        ))}
      </ul>
      <ul className='menu-list'>
        {TERM_OF_USING.map((item, key) => (
          <li 
            key={key} 
            className={`menu-item-container terms-menu${selectedTab === item.label ? ' active' : ''}`}
          >
            <a href={item.route} target='_blank' className='menu-item'>
              <span className='menu-item-label'>{item.label}</span>
            </a>
          </li>
        ))}
        </ul>
    </section>
  );
};

export default HostSettingMenu;
