import React from 'react';
import './InventoryQuantity.scss';

export const InventoryQuantity = ({ inventory, noInventoryLabel }) => {
    return (
        <div
            className={`inventory-counter${inventory <= 0 ? ' warning' : ''}`}>
            {inventory <= 0 && noInventoryLabel ? noInventoryLabel :
            `${inventory} in stock`}
        </div>
    )
}
