import { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerProfileConfig } from '../reducers/user-reducer';

export default function useCustomerProfileConfig() {
  const { activeShopId } = useSelector((state) => state.shops);
	const dispatch = useDispatch();

	useEffect(() => {
		if (activeShopId) {
			const unsub = firebase
        .firestore()
        .collection('shops')
        .doc(activeShopId)
        .collection('installData')
        .doc('customerProfile')
        .onSnapshot(
          (snapshot) => {
            if (snapshot && snapshot.data()) {
              const {
                showLastPurchaseDate = true,
                showTotalCredit = true,
                showClientNote = true,
                showTotalRefunds = true,
                showRecentPurchases = true,
                showProductRecommendations = true,
                showRecentlyViewedProducts = true,
                showAvgBasket = true,
                showMaxBasket = true,
                showTotalSpend = true,
                ...customFields
              } = snapshot.data();

              dispatch(setCustomerProfileConfig({
                showLastPurchaseDate,
                showTotalCredit,
                showClientNote,
                showTotalRefunds,
                showRecentPurchases,
                showProductRecommendations,
                showRecentlyViewedProducts,
                showAvgBasket,
                showMaxBasket,
                showTotalSpend,
                customFields: Object.keys(customFields).filter(fieldId => customFields[fieldId]),
              }));
            }
          },
          (error) => {
            console.error('Error customer profile config', error);
          }
        )
			return () => unsub();
		}

	}, [activeShopId]);
}
