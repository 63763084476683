import React, { useEffect, useState } from 'react';
import './CarouselDots.scss';

export const CarouselDots = ({
    length = 3,
    active,
    customBorder = null,
    borderSize = 2,
    dotsGap = 4,
    dotsSize = 12,
    visible = 5,
    emptyDots = true
}) => {

    const [position, setPosition] = useState(0);
    const [scaledDownDotIndex, setScaledDownDotIndex] = useState();

    useEffect(() => {
        if (length > visible && length >= 3 && visible >= 3) {
            const delta = (active + 2) - visible;
            if (delta > 0) {
                let deltaPos = 0;
                if (delta > (length - visible)) {
                    deltaPos = (length - visible) * -(dotsSize + dotsGap)
                    setScaledDownDotIndex(length - visible);
                } else {
                    deltaPos = delta * -(dotsSize + dotsGap);
                    setScaledDownDotIndex(delta);
                }
                setPosition(deltaPos);
            } else {
                setPosition(0);
                setScaledDownDotIndex(null);
            }
        }
    }, [active, visible])

    return (
        <div className='carousel-gallery-dots'
            style={{
                '--dots-main-color': '#fff',
                '--carousel-dots-length': visible,
                '--dots-border': `${borderSize}px`,
                '--dots-size': `${dotsSize}px`,
                '--dots-gap': `${dotsGap}px`,
                width: `${visible * (dotsSize) + ((visible - 1) * dotsGap)}px`
            }}>
            {Array.from({ length }, (_, index) =>
                <div className={`
                    carousel-gallery-dot-container
                    ${active === index ? ' active' : ''}
                    ${customBorder === index ? ' custom-border' : ''}
                    ${scaledDownDotIndex === index ? ' scaled-down start' : ''}
                    ${(scaledDownDotIndex + visible - 1) === index &&
                        active !== index &&
                        (scaledDownDotIndex + visible - 1) !== length - 1 ? ' scaled-down end' : ''}
                    
                    `}
                    style={{ transform: `translateX(${position}px)` }} key={index}>
                    <div className={`carousel-gallery-dot
                    ${emptyDots ? ' empty-dots' : ''}
                    ${scaledDownDotIndex === index ? ' scaled-down start' : ''}
                    ${(scaledDownDotIndex + visible - 1) === index &&
                            active !== index &&
                            (scaledDownDotIndex + visible - 1) !== length - 1 ? ' scaled-down end' : ''}`}>
                    </div>
                </div>
            )}
        </div>
    )
}
