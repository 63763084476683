import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  archiveRoomFailure,
  archiveRoomRequest,
  archiveRoomSuccess,
  initiateChatConversationRequest,
  initiateChatConversationSuccess,
  initiateChatConversationFailure,
  setActiveHostFailure,
  setActiveHostRequest,
  setActiveHostSuccess, saveActiveRooms,
} from "../../actions/rooms";
import { setActiveRoomId } from "../../actions/activeRoom";
import { buildUserEntity } from "../../utils/auth";
import useSystemRequests from "../system/requests";

export default function useRoomsRequests({ shopId, user = {} }) {
  const dispatch = useDispatch();
  const { sendSystemMessage } = useSystemRequests({ user });
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  // Only client can create a room.
  // For the host side we need to add abstraction for baseUrl var, method API, error handling
  const initiateRoom = async ({ createNewRoom, customerName, customerEmail, customerId, welcomeMessage }) => {
    const creator = buildUserEntity(user);
    if (!creator.uid) {
      return;
    }

    try {
      dispatch(initiateChatConversationRequest());
      const room = await createNewRoom(customerName, customerEmail, customerId, welcomeMessage);

      dispatch(initiateChatConversationSuccess());
      dispatch(setActiveRoomId(room.id));
      dispatch(saveActiveRooms(room));
    } catch (error) {
      dispatch(initiateChatConversationFailure(error.message));
    }
  }

  const joinAsHost = async ({activateChat, chatId}) => {

    try {
      setIsActionInProgress(true);
      dispatch(setActiveHostRequest());
      await activateChat(shopId, chatId);

      dispatch(setActiveHostSuccess());
    } catch (error) {
      dispatch(setActiveHostFailure(error.message));
    } finally {
      setIsActionInProgress(false);
    }
  }

  const reassignHost = async ({reassignChat, chatId, assignTo}) => {

    try {
      setIsActionInProgress(true);
      dispatch(setActiveHostRequest());
      await reassignChat(shopId, chatId, assignTo);

      dispatch(setActiveHostSuccess());
    } catch (error) {
      dispatch(setActiveHostFailure(error.message));
    } finally {
      setIsActionInProgress(false);
    }
  }

  const archiveRoom = async ({archiveChat, chatId}) => {

    try {
      setIsActionInProgress(true);
      dispatch(archiveRoomRequest());
      await archiveChat(shopId, chatId);

      dispatch(setActiveRoomId(null));
      dispatch(archiveRoomSuccess());
    } catch (error) {
      dispatch(archiveRoomFailure(error.message));
    } finally {
      setIsActionInProgress(false);
    }
  }

  return {
    initiateRoom,
    archiveRoom,
    joinAsHost,
    reassignHost,
    isActionInProgress,
  };
}
