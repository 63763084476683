import React, { useEffect } from 'react';
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import { useHistory } from 'react-router-dom';

export default function AdminLogin() {
    const queryParams = new URLSearchParams(window.location.search);
    const history = useHistory();
    // Get the token to complete the login action.
    const token = queryParams.get('token');
    const redirectParam = queryParams.get('redirect');
    const redirect = redirectParam || '/';

    useEffect(() => {
        if (token) {
            firebase.auth().signInWithCustomToken(token)
                .catch(error => console.error('admin login failed with valid token', error))
                .finally(() => history.push(redirect));
        } else {
            history.push(redirect);
        }
    }, [token]);


    return <p>Logging admin into host app...</p>;

}
