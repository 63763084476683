import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Redirect, Prompt, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile, isTablet } from 'react-device-detect';
import { UAParser } from 'ua-parser-js';

import BoutiqVideoRoom, { useBoutiqVideoRoomContext, useLocalTracks } from '@caazam/boutiq-video-room';

import { logger } from '../../logging';
import { useActiveCallProvider } from '../../components/ActiveCallProvider';
import { useNotificationProvider } from '../../components/notifications/NotificationProvider';

import Sidebar from './components/sidebar/sidebar';
import VideoRoomPanel from './components/panel/panel';
import useSpeakerEvents from '../../hooks/useSpeakerEvents';
import { useSessionProducts, useSessionCart } from '../../hooks';
import useConnectedCall from '../../hooks/useConnectedCall';
import useSessionDataClientView from '../../hooks/useSessionDataClientView'
import useCallParticipants from "../../hooks/useCallParticipants";
import ProductPresentationView from "../../components/productPresentationView";
import FullSizeImageModal from '../../components/full-size-image-modal/fullSizeImageModal';

import { buildUserEntity } from '../../modules/chat/utils/auth';
import { useHostChatData } from '../../modules/chat/hooks/useHostChatData';
import { setActiveRoom, resetActiveRoom } from '../../modules/chat/actions/activeRoom';

import { parseIdString } from '../../utils';
import { CART_DISABLE_REASON, HEADER_NOTIFICATION_TYPES } from '../../utils/consts';
import { resetCallShopId } from '../../reducers/shops-reducer';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { setPrevCall, resetPrevCall, setPrevCallDailyDisconnect } from '../../reducers/prev-call-reducer';
import { disableDiscounts } from '../../reducers/active-call-reducer';

import { useVideoConfiguration } from '../../components/VideoConfigurationProvider'

import CallIcon from '@material-ui/icons/Call';
import HeaderNotification from "../../components/notifications/header-notification";
import PermissionsHelperModal from "../../components/Modals/permissionsHelper";
import usePermissions from "../../hooks/usePermissions";
import { setShowLocalTracksWarning, setShowLocalTracksHelperModalAction, setShowPermissionsWarning } from "../../reducers/system-reducer";
import AVPermissionsLockHelperModal from "../../components/Modals/AVPermissionsLockHelper";
import { PROCESSOR_TYPES } from '../../utils/consts';

import './video-room.scss';
import '@caazam/boutiq-video-room/dist/style.css';

const VideoRoomVideo = ({
  videoToken,
  onConnect,
  onDisconnect,
  shopId,
  hostId,
}) => {
  //const [activeLocalVideoTrack, setActiveLocalVideoTrack] = useState(null);
  const currentLocalTracks = useRef([]);
  const [localTracksReady, setLocalTracksReady] = useState(false);
  const [localTracksError, setLocalTracksError] = useState(null);

  const {
    localVideo,
    localAudio,
    startCamera,
    stopCamera,
    restartCamera
  } = useLocalTracks();

  const { isReady, join, leave, applyVideoProcessor, onVideoEvent, offVideoEvent } = useBoutiqVideoRoomContext();
  const { activeCallData: callData } = useActiveCallProvider();

  const dispatch = useDispatch();
  const { hostAppMuteVideoOnCallStart, activeVideoProcessor } = useVideoConfiguration();
  const { connectOptions, avPermissions: { localTracksHelperModalAction } } = useSelector(state => state.system);

  const initials = useMemo(() => {
    if (callData?.host?.displayName) {
      return callData.host.displayName.split(' ')
        .map(item => item?.charAt(0)?.toUpperCase())
        .slice(0, 3)
        .reduce((initials, initial) => initials + initial, '')
    } else {
      return '';
    }
  }, [callData?.host])

  const setVideoProcessor = async () => {
    let processor = { type: 'none' }
    logger.info('The call default video processor:', activeVideoProcessor);

    if (activeVideoProcessor?.type === PROCESSOR_TYPES.BLUR) {
      processor = {
        type: 'background-blur',
        config: { strength: activeVideoProcessor.strength ?? 0.5 }
      };
    } else if (activeVideoProcessor?.type === PROCESSOR_TYPES.VIRTUAL) {
      processor = {
        type: 'background-image',
        config: { url: activeVideoProcessor.backgroundImage }
      };

    }

    return applyVideoProcessor(processor)
      .then(logger.info('The call default video processor enabled', processor))
      .catch(error => {
        logger.error('Error in applying default call video processor', error);
      })
  }

  const prepareLocalTracks = () => {    
    setLocalTracksError(null);
    let persistedAudioInputDevice = null, persistedAudioOutputDevice = null, persistedVideoSourceDevice = null;
    try {
      persistedAudioInputDevice = localStorage.getItem('caazam.audioInput') ?? null;
      persistedAudioOutputDevice = localStorage.getItem('caazam.audioOutput') ?? null;
      persistedVideoSourceDevice = localStorage.getItem('caazam.videoSource') ?? null;
    } catch (error) {
      console.error('Cannot read persisted audio and video settings', error)
    }

    const options = {
      audioDeviceId: persistedAudioInputDevice !== 'default' ? persistedAudioInputDevice : true,
      videoDeviceId: persistedVideoSourceDevice !== 'default' ? persistedVideoSourceDevice : true,
      audioOutputDeviceId: persistedAudioOutputDevice !== 'default' ? persistedAudioOutputDevice : null,
      ... connectOptions,
    }

    if (hostAppMuteVideoOnCallStart) {
      options.startVideoOff = true;
    }

    return setVideoProcessor()
      .then(() => {
        logger.info('getting local tracks', options);
        return startCamera(options)
      })
      .then((trackRes) => {
        if (trackRes?.mic?.error) {
          logger.warn(`startCamera audio error`, trackRes.mic.error);
        }
        if (trackRes?.camera?.error) {
          logger.warn(`startCamera video error`, trackRes.camera.error);
        }

        if (trackRes?.mic?.error && trackRes?.camera?.error) {
          throw new Error(trackRes.error);
        } else if (!trackRes?.mic?.error && !trackRes?.camera?.error) {
          dispatch(setShowPermissionsWarning(false));
        }
        logger.info('local tracks result', trackRes);
      })
      .catch((error) => {
        logger.error('failed to get local tracks', error);
        setLocalTracksError(error);
      })
  }

  useEffect(() => {
    const controlEventHandler = (data)=> logger.info(`user video contorl event: ${data?.action}`, data );
    onVideoEvent('disconnected', onDisconnect);
    onVideoEvent('controlEvent', controlEventHandler);
    return () => {
      offVideoEvent('disconnected', onDisconnect);
      offVideoEvent('controlEvent', controlEventHandler);
    }
  }, [onDisconnect]);

  useEffect(() => {
    if (isReady) {
      prepareLocalTracks()
        .finally(() => setLocalTracksReady(true));
      return () => {
        logger.info('stopping all local tracks');
        stopCamera();
      }
    }
  }, [isReady]);

  useEffect(() => {
    // we couldn't start local tracks either becasue permissions, locked (windows only), or other error
    dispatch(setShowLocalTracksWarning(!!localTracksError));
  }, [localTracksError])

  useEffect(() => {
    // action of AVPermissionsLockHelperModal was clicked so try again
    if (localTracksHelperModalAction) {
      logger.info('attempting to republish tracks');
      restartCamera()
        .then(() => {
          logger.info('local tracks restarted');
          setLocalTracksError(null)
        })
        .catch(error => {
          logger.error('local tracks not restarted',error);
          setLocalTracksError(error);
        })
        .finally(() => {
          dispatch(setShowLocalTracksHelperModalAction(false));
        });
    }
  }, [localTracksHelperModalAction]);
 
  useEffect(() => {
    if (videoToken && localTracksReady) {
      logger.info('connecting to room', videoToken);
      join(
        videoToken.url,
        videoToken.token,
        callData?.host?.displayName,
        {
          initials,
          avatar: callData?.host?.avatarUrl,
        }, 
        connectOptions       
      )
        .then(() => {
          logger.info('CONNECTED');
          onConnect();
        })
        .catch(connectError => {
          // DAILY TODO: we should expose join errors to the host in the UI
          logger.error('CONNECT ERROR', connectError);
          if (connectError?.action === 'error') {
            onConnect(new Error(connectError.errorMsg));
          } else {
            onConnect(connectError);
          }
        });
    }
  }, [videoToken, localTracksReady]);

  useEffect(() => {
    const unloadEvent = isMobile || isTablet ? 'pagehide' : 'unload';

    function beforeUnload(e) {
      e.preventDefault();
      return e.returnValue = 'The video call will be disconnected';
    }

    //window.addEventListener(unloadEvent, leave);
    window.addEventListener('beforeunload', beforeUnload);
    return () => {
      //window.removeEventListener(unloadEvent, leave);
      window.removeEventListener('beforeunload', beforeUnload);
    }
  }, [leave]);

  useEffect(() => {
    currentLocalTracks.current = [localAudio, localVideo];
    const audio = localAudio
      ? {
        kind: localAudio.kind,
        id: localAudio.id,
        label: localAudio.label,
        readyState: localAudio.readyState,
        settings: localAudio.getSettings(),
      }
      : null;

    const video = localVideo
      ? {
        kind: localVideo.kind,
        id: localVideo.id,
        label: localVideo.label,
        readyState: localVideo.readyState,
        settings: localVideo.getSettings(),
      }
      : null;


    logger.info('change in local tracks', {
      audio,
      video,
    });
  }, [localAudio, localVideo]);

  return (
    <BoutiqVideoRoom/>
  )
};

const VideoRoom = ({ videoToken, setVideoToken }) => {
  const [styleType, setStyleType] = useState(null);
  const { showPermissionsWarning, showPermissionsHelperModal } = usePermissions();

  const [showroomCounter, setShowRoomCounter] = useState({
    total: 0,
    isIncreased: false,
  });
  const [cartCounter, setCartCounter] = useState({
    total: 0,
    isIncreased: false,
  });

  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedSnapshotKey, setSelectedSnapshotKey] = useState(null);
  const [selectedProductVariantId, setSelectedProductVariantId] = useState(
    null
  );
  const startSessionDate = useRef(null);
  const [customer, setCustomer] = useState(null);
  const [isUserLoading, setUserLoading] = useState(false);
  const [connected, setConnected] = useState(false);
  const [clientViewingState, setClientViewingSate] = useState({ showroom: [], cart: [] });
  const clientViewingShowroom = clientViewingState.showroom[0] || null;
  const clientViewingCart = clientViewingState.cart[0] || null;
  const [fullSizeChatImage, setFullSizeChatImage] = useState(null);

  let history = useHistory();
  const dispatch = useDispatch();
  const shopId = useSelector((state) => state.shops.callShopId);
  const {
    activeCallId,
    activeCallData: callData,
    setActiveCallId,
    openCart,
    openShowRoom,
    closeDrawer,
    drawerTab,
    isVideoHeightMin
  } = useActiveCallProvider();
  const { sessionSnapshots } = useSessionProducts(activeCallId);
  const { cartSnapshots, updateCartProduct } = useSessionCart(activeCallId);
  const { participants } = useCallParticipants(activeCallId, onClientConnect, onClientDisconnect);
  const { clientViewData } = useSessionDataClientView(activeCallId);

  const { user: caazamUser } = useSelector((state) => state.user);
  const userEntity = useMemo(() => buildUserEntity(caazamUser), [caazamUser])
  const { activeRooms } = useHostChatData(userEntity, shopId);
  useSpeakerEvents(shopId, activeCallId, caazamUser?.id);

  const { showToast, setRouteForNotifications } = useNotificationProvider();
  const { isPresentationEnabled } = useSelector((state) => state.activeCall);
  // const { installId: deviceId = null  } = useSelector(state => state.system);
  const { avPermissions: { showLocalTracksWarning, showLocalTracksHelperModal } } = useSelector((state) => state.system);

  useEffect(() => {
    if (callData.orders && Object.keys(callData.orders).length) {
      setStyleType('order');
    } else if (callData.checkouts && Object.keys(callData.checkouts).length > 0) {
      setStyleType('checkout');
    } else {
      setStyleType(null);
    }
  }, [callData.checkouts, callData.totalOrderAmount]);

  useEffect(() => {
    if (!sessionSnapshots) return;
    if (!sessionSnapshots.length) {
      closeDrawer();
      return;
    }
    if (sessionSnapshots.length > showroomCounter.total) {
      setShowRoomCounter({
        total: sessionSnapshots.length,
        isIncreased: true,
      });
      setTimeout(() => {
        setShowRoomCounter((previousCounter) => {
          return {
            ...previousCounter,
            isIncreased: false,
          };
        });
      }, 3000);
    } else {
      setShowRoomCounter({ total: sessionSnapshots.length });
    }
    if (drawerTab === 0) {
      openShowRoom();
    }
  }, [sessionSnapshots]);

  useEffect(() => {
    if (!cartSnapshots) return;
    if (cartSnapshots.length > cartCounter.total) {
      setCartCounter({
        total: cartSnapshots.length,
        isIncreased: true,
      });
      setTimeout(() => {
        setCartCounter((previousCounter) => {
          return {
            ...previousCounter,
            isIncreased: false,
          };
        });
      }, 3000);
    } else {
      setCartCounter({ total: cartSnapshots.length });
    }
  }, [cartSnapshots]);

  useEffect(() => {
    setRouteForNotifications('/video-room');
    return () => {
      setRouteForNotifications('/');
    }
  }, []);

  useEffect(() => {
    let update = {
      showroom: [],
      cart: [],
    };

    Object.keys(clientViewData).forEach(clientUuid => {
      const clientName = ((participants[clientUuid] && participants[clientUuid].displayName) || 'client').split(' ')[0];
      const clientViewTab = clientViewData[clientUuid] && clientViewData[clientUuid].tabView;
      if (clientViewTab === 'Cart') {
        update.cart.push({ id: clientUuid, name: clientName });
      } else if (clientViewTab === 'Showroom') {
        update.showroom.push({ id: clientUuid, name: clientName });
      }
    })
    setClientViewingSate(update);

  }, [clientViewData, participants]);

  useEffect(() => {
    dispatch(resetActiveRoom());
    return () => dispatch(resetActiveRoom());
  }, []);

  useEffect(() => {
    if (!!activeRooms && activeRooms.length > 0) {
      const activeVideoChat = activeRooms.find((item) => item.activeCallId === activeCallId);
      if (activeVideoChat) {
        dispatch(setActiveRoom(activeVideoChat));
      }
    }
  }, [activeRooms, activeCallId]);


  function onClientConnect(id, data) {
    logger.info(`${activeCallId}: onClientConnect ${id}`, data);
    const { userAgent = null, role, featureSupport } = data;
    if (role === 'participant') {
      const checkoutDisabled = featureSupport?.checkoutDisabled;
      const { os } = UAParser(userAgent);         // checking user agaent for backwards compatability with clients that don't publish featureSupport
      const isIOS151 = os.name === 'iOS' && os.version.startsWith('15.1');
      if (checkoutDisabled?.reason || isIOS151) {
        let deviceName = (checkoutDisabled?.reason === CART_DISABLE_REASON.IOS_15_1 || isIOS151) ? ' (iOS 15.1)' : '';
        showToast({
          title: `${data.name} has connected with a device with limited functionality` + deviceName,
          description: 'Checkout is not available on-call but the cart is saved and the checkout can be completed after the call on the store website',
          icon: <CallIcon htmlColor={'#FFBE88'} />,
          type: 'warning',
          preventAutoDismiss: true,
        });
        dispatch(disableDiscounts({ disableDiscounts: true }));
      } else {
        dispatch(disableDiscounts({ disableDiscounts: false }));
      }
    }
  }

  function onClientDisconnect(id, data) {
    logger.info(`${activeCallId}: onClientDisconnect ${id}`, data);
    showToast({
      title: `${data.name} has disconnected`,
      icon: <CallIcon htmlColor={'#E94848'} />,
      iconType: 'error'
    })
  }

  const onDisconnect = ({ error }) => {
    let callDuration = Date.now() - startSessionDate.current;
    logger.info(`${activeCallId}: onDisconnect. duration = ${callDuration} connectError=${!!error} `);
    dispatch(setPrevCall({
      callId: activeCallId,
      callDuration,
      shopId,
    }));
    dispatch(setPrevCallDailyDisconnect(!error));
    setConnected(false);
    setVideoToken(null);
    setActiveCallId(null);
    setStyleType(null);
    dispatch(resetCallShopId());
    setTimeout(() => {
      history.push('/');
    }, 0);
  };

  const onConnect = (connectError) => {
    logger.info(`${activeCallId}: onConnect`);
    startSessionDate.current = Date.now();
    if (!connectError) {
      setConnected(true)
      dispatch(resetPrevCall());
      dispatch(setPrevCallDailyDisconnect(false));
    } else {
      logger.error(`${activeCallId}: failed to connect - calling onDisconnect`, connectError);
      onDisconnect({error: connectError});
    }
  };

  const resizeDrawer = () => {
    if (drawerTab === 2 || drawerTab === 1) {
      closeDrawer();
      return;
    }
    if (drawerTab === 0) {
      openShowRoom();
    }
  };

  const hideModal = () => {
    setFullSizeChatImage(null);
  };

  const clearSelectedProductIds = () => {
    setSelectedProductId(null);
    setSelectedSnapshotKey(null);
    setSelectedProductVariantId(null);
  };

  const selectProduct = (productId) => {
    clearSelectedProductIds();
    setSelectedProductId(productId);
  };

  const viewCartItem = (product, snapshotKey, variantId) => {
    setSelectedProductId(product.productId);
    setSelectedSnapshotKey(snapshotKey);
    setSelectedProductVariantId(variantId);
  };

  const saveVariant = (
    variantDirtyId,
    variantTitle,
    currentPrice,
    image,
    inventoryPolicy,
    inventoryQuantity,
    quantity,
    cartItemKey,
  ) => {
    const variantId = parseIdString(variantDirtyId);

    const newData = {
      variantId,
      variantTitle,
      currentPrice,
      image,
      inventoryPolicy,
      inventoryQuantity,
    };

    if (quantity !== null) {
      newData.quantity = quantity;
    }

    setSelectedProductVariantId(variantId);
    updateCartProduct(cartItemKey || selectedSnapshotKey, newData);
  };

  if (!videoToken) {
    return <Redirect to='/' />;
  } else {
    return (
      
      <>
        {
          // permissions warning (pre-call permissions not granted) always take precendence on local track warning
          showPermissionsWarning
            ? <HeaderNotification type={HEADER_NOTIFICATION_TYPES.AV_PERMISSIONS_DENIED} permissionsProps={{ isHideHelper: true }} />
            : showLocalTracksWarning && <HeaderNotification type={HEADER_NOTIFICATION_TYPES.AV_PERMISSIONS_LOCKED} />
        }
        <div
          className={`video-room-container ${isVideoHeightMin ? 'ftr-open' : ''
            } ${styleType}`}>
          <div className='video-room-screen'>
            <VideoRoomVideo
              shopId={shopId}
              hostId={userEntity.id}
              videoToken={videoToken}
              onConnect={onConnect}
              onDisconnect={onDisconnect}
            />
          </div>
          <Sidebar
            customer={customer}
            setCustomer={setCustomer}
            shopId={shopId}
            isUserLoading={isUserLoading}
            setUserLoading={setUserLoading}
            clearSelectedProductIds={clearSelectedProductIds}
            selectedProductVariantId={selectedProductVariantId}
            updateProduct={saveVariant}
            selectedSnapshotKey={selectedSnapshotKey}
            selectedProductId={selectedProductId}
            selectProduct={selectProduct}
            clientViewData={clientViewData}
            setFullSizeChatImage={setFullSizeChatImage}
          />
          <div
            className={`drawer-container ${drawerTab === 1 ? 'product-view' : ''
              }${drawerTab === 2 ? ' cart-view' : ''}`}>
            <div className='drawer-header'>
              <div className='start'>
                <span
                  onClick={openShowRoom}
                  className={`${drawerTab === 1 ? 'active' : 'non-active'} ${clientViewingShowroom ? 'with-client-tag' : 'without-client-tag'}`}
                >
                  <div>
                    <div>
                      <p>Showroom</p>
                      <span
                        className={`counter ${showroomCounter.isIncreased ? 'emphasize' : ''
                          }`}>
                        {sessionSnapshots ? sessionSnapshots.length : 0}
                      </span>
                    </div>
                    {clientViewingShowroom &&
                      <div className='client-tag-block'>
                        <h4 className='customer-name'>{clientViewingShowroom.name}</h4>
                      </div>
                    }
                  </div>
                </span>
                <span
                  onClick={openCart}
                  className={`${drawerTab === 2 ? 'active' : 'non-active'} ${clientViewingCart ? 'with-client-tag' : 'without-client-tag'}`}
                >
                  <div>
                    <div>
                      <p>Cart</p>
                      <span
                        className={`counter ${cartCounter.isIncreased ? 'emphasize' : ''
                          }`}>
                        {cartSnapshots ? cartSnapshots.length : 0}
                      </span>
                    </div>
                    {clientViewingCart &&
                      <div className='client-tag-block'>
                        <h4 className='customer-name'>{clientViewingCart.name}</h4>
                      </div>
                    }
                  </div>
                </span>
              </div>

              <div className='end'>
                <button className='ftr-open' onClick={resizeDrawer}>
                  <KeyboardArrowUpIcon />
                </button>
              </div>
            </div>
            <div className='drawer'>
              <VideoRoomPanel
                view={drawerTab}
                viewShowroomItem={selectProduct}
                viewCartItem={viewCartItem}
                shopId={shopId}
                clientViewData={clientViewData}
              />
            </div>
          </div>
          {
            isPresentationEnabled &&
            <ProductPresentationView
              clientViewData={clientViewData}
              selectedSnapshotKey={selectedSnapshotKey}
            />
          }
          {!!fullSizeChatImage &&
            <FullSizeImageModal
              fullSizeChatImage={fullSizeChatImage}
              closeAction={hideModal}
              videoChat={true}
            />
          }
          {
            showPermissionsHelperModal &&
            <PermissionsHelperModal /> //modal for instructing user to provide permissions manually becuase they were denied
          }
          {
            showLocalTracksHelperModal &&
            <AVPermissionsLockHelperModal /> //modal to try local tracks again in case they are used by other app (windows only)
          }
        </div>
        <Prompt
          when={connected}
          message='Leaving this page will disconnect your call'
        />
      </>
    );
  }
};

export default function ({ ...props }) {
  const { activeCallId, activeCallData } = useActiveCallProvider();

  if (!activeCallId || !activeCallData) {
    return <Redirect to='/' />;
  } else {
    return <VideoRoom {...props} />;
  }
}
