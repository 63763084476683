import React from 'react';
import {ArrowBackIos} from '@material-ui/icons';
import CustomerSearch from '../../../customer-search/customer-search';
import {SearchInput} from '../search-input/search-input';
import './index.scss';

export const SearchClientsPanel = ({
  isSearching,
  searchClient,
  selectNewCustomer,
  onBack,
  searchTerm,
  setSearchTerm,
  onSubmitSearch,
}) => {

  return (
    <div className='customer-search-panel-container'>
      <div className='customer-search-panel-header'>
        <button className='customer-search-panel-back-button'>
          <ArrowBackIos
            onClick={onBack}
            style={{color: '#66676c'}}
          />
        </button>
        <SearchInput
          placeholder={'Search for clients'}
          searchInputValue={searchTerm}
          onChange={setSearchTerm}
          onSubmit={onSubmitSearch}
        />
      </div>
      <CustomerSearch
        loading={isSearching}
        searchResults={searchClient}
        selectNewCustomer={selectNewCustomer}
      />
    </div>
  )
}
