const initialState = {
  notifications: {},
};

const chatNotifications = (state = initialState, action) => {
  switch (action.type) {

    default:
      return state;
  }
};

export default chatNotifications;
