import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { FilledButton, StyledInput } from "../../../components";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/auth";
import "firebase/compat/functions";
import "firebase/compat/firestore";
import "./profile-settings.scss";
import { ImageCropper } from "./components/ImageCropper/ImageCropper";
import useHostShopUser from "../../../hooks/useHostShopUser";
import useGoogleSync from "../../../hooks/useGoogleSync";
import { GoogleAuth } from "../../../components/googleAuth";

const MAX_LENGTH_BIO = 250;

const ProfileSettings = () => {
  const [upImg, setUpImg] = useState(null);
  const avatarImageFileInput = useRef(null);
  const [state, setstate] = useState(false);
  const [showImageCropModal, setShowImageCropModal] = useState(false);
  const [isHostNameChanged, setIsHostNameChanged] = useState(false);
  const [isShopUserChanged, setShopUserChanged] = useState(false);
  const [hostData, setHostData] = useState({
    firstName: "",
    lastName: "",
  });
  const [bio, setBio] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [loading, setLoading] = useState(false);
  const { activeShopId } = useSelector((state) => state.shops);
  const caazamUser = useSelector((state) => state.user.user);
  const avatar = caazamUser
    ? caazamUser.avatar
      ? caazamUser.avatar.thumbnailUrl
      : null
    : null;
  const firstName = caazamUser ? caazamUser.firstName || "" : "";
  const lastName = caazamUser ? caazamUser.lastName || "" : "";
  const { hostBio, hostShopUserLoading, hostDisplayName } = useHostShopUser(
    activeShopId,
    caazamUser.id
  );

  const {isGoogleCalendarSyncEnabled} = useGoogleSync(activeShopId);

  useEffect(() => {
    if (hostData.firstName !== firstName || hostData.lastName !== lastName) {
      setIsHostNameChanged(true);
    } else {
      setIsHostNameChanged(false);
    }
  }, [hostData]);

  useEffect(() => {
    setHostData({
      firstName: firstName,
      lastName: lastName,
    });
  }, [firstName, lastName]);

  useEffect(() => {
    if (!hostShopUserLoading) {
      setBio(hostBio ?? '');
      setDisplayName(hostDisplayName ?? '');
    }
  }, [hostShopUserLoading, hostBio, hostDisplayName]);

  useEffect(() => {
    function changed(val1, val2) {
      if (!val1 && !val2) return false;
      else return val1 !== val2;
    }
    setShopUserChanged(changed(hostBio,bio) || changed(hostDisplayName,displayName));
  }, [hostBio, bio, hostDisplayName, displayName]);


  const resetPassword = () => {
    firebase.analytics().logEvent("reset_password_req");
    firebase
      .auth()
      .sendPasswordResetEmail(caazamUser.email, {
        url: window.location.origin + "/login",
      })
      .then(() => {
        setstate(!state);
      })
      .catch((err) => {
        console.error(err);
      });
  };


  const onChangeHostName = (e) => {
    const { name, value } = e.target;
    setHostData({ ...hostData, [name]: value });
  }

  const onChangeDisplayName = (e) => {
    const { value } = e.target;
    setDisplayName(value);
  };

  const onChangeBio = (e) => {
    const { value } = e.target;
    setBio(value);
  };

  const updateProfile = async () => {
    setLoading(true);
    if (isHostNameChanged) {
      const updateUserProfile = firebase
        .functions()
        .httpsCallable("users-updateUserProfile");
      await updateUserProfile({
        firstName: hostData.firstName,
        lastName: hostData.lastName,
      }).catch(() => {
        setHostData({
          firstName: caazamUser.firstName,
          lastName: caazamUser.lastName,
        });
      });
      firebase.analytics().logEvent("host_user_profile_updated");
    }
    if (isShopUserChanged) {
      const updateUserBio = firebase
        .functions()
        .httpsCallable("shopUsers-updateBio");
      await updateUserBio({
        shopId: activeShopId,
        bio,
        displayName,
      });
      firebase.analytics().logEvent("host_user_bio_updated");
    }
    setLoading(false);
  };

  const handleUploadButtonClick = () => {
    avatarImageFileInput.current.click();
  };

  const removeAvatar = async () => {
    await firebase
      .firestore()
      .collection("users")
      .doc(caazamUser.id)
      .update({ avatar: firebase.firestore.FieldValue.delete() });
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setUpImg(reader.result);
        setShowImageCropModal(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div className="setting-container">
      <div className="host-settings-item">
      {showImageCropModal && (
        <ImageCropper
          onClose={() => {
            avatarImageFileInput.current.value = null;
            setShowImageCropModal(false);
          }}
          upImg={upImg}
        />
      )}
      <div className="profile-avatar-setting-container">
        <div className="avatar-container">
          {avatar ? (
            <img className="avatar" src={avatar} />
          ) : (
            <span className="avatar-initials">
              {caazamUser.firstName.charAt(0)}
              {caazamUser.lastName.charAt(0)}
            </span>
          )}
        </div>
        <button
          onClick={handleUploadButtonClick}
          className="upload-image button"
        >
          Update profile picture
        </button>
        <input
          ref={avatarImageFileInput}
          type="file"
          accept="image/jpeg"
          onChange={onSelectFile}
          style={{ display: "none" }}
        />
        {avatar && (
          <button onClick={removeAvatar} className="remove-image button">
            Remove
          </button>
        )}
      </div>
      <div className="host-name-setting-container-wrapper">
        <form className="host-name-setting-container">
          <div className="first-name-input-wrapper">
            <StyledInput
              placeholder="First name"
              name="firstName"
              value={hostData.firstName}
              onChange={onChangeHostName}
            />
          </div>
          <div className="last-name-input-wrapper">
            <StyledInput
              placeholder="Last name"
              name="lastName"
              value={hostData.lastName}
              onChange={onChangeHostName}
            />
          </div>
        </form>
        <span className="host-name-helper-text">
              Your full name
            </span>
      </div>
      <div className="display-name-input-wrapper">
          <div className="display-name-container">
            <StyledInput
              placeholder={firstName}
              name="displayName"
              value={displayName}
              onChange={onChangeDisplayName}
            />
            <span className="display-name-helper-text">
              Your display name: this is how your name is displayed to clients
            </span>
          </div>
        </div>
      <div className="host-bio-input-wrapper">
        <div className="host-bio-container">
          <textarea
            name="bio"
            rows='6'
            value={bio}
            placeholder="Add a short description about yourself"
            maxLength={MAX_LENGTH_BIO}
            onChange={onChangeBio}
            className='text-input'
          />
          <span className="char-counter">
            {bio?.length ?? 0} / {MAX_LENGTH_BIO}
          </span>
        </div>
      </div>
      <div className="submit-button-wrapper">
        <FilledButton
          className="update-profile-button"
          disabled={
            (!isHostNameChanged ||
              !hostData.firstName ||
              !hostData.lastName) &&
            !isShopUserChanged
          }
          onClick={updateProfile}
          style={{ padding: ".5ch 1ch", height: "auto", width: "auto" }}
          isLoading={loading}
        >
          Update
        </FilledButton>
      </div>
      </div>
      <div className="host-settings-item">
        <button
          className={`reset-passwod-button${state ? " show-message" : ""}`}
          onClick={resetPassword}
        >
          {!state && (
            <div className="password-reset-label">Request password reset</div>
          )}
          {state && (
            <div className="email-successfully-sent">
              <CheckCircleRoundedIcon />
              <span className="succeed-message">Password Reset Email Sent</span>
            </div>
          )}
        </button>
      </div>
      {
        isGoogleCalendarSyncEnabled &&
          <div className='host-settings-item'>
            <GoogleAuth />
          </div>
      }
      {showImageCropModal && (
        <ImageCropper
          onClose={() => {
            avatarImageFileInput.current.value = null;
            setShowImageCropModal(false);
          }}
          upImg={upImg}
        />
      )}
    </div>
  );
};

export default ProfileSettings;
