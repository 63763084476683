import React from 'react';
import './index.scss';

export const HostAvailabilityDot = ({isAvailable}) => {

    return (
        <span className={`host-vailability-dot${isAvailable ? ' available' : ' unavailable'}`}>

        </span>
    )
}
