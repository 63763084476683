import * as React from "react"

const IconCopy = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g
      stroke="#6E76F2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20 17.091V4H6.91" />
      <path d="M17.091 6.91H4V20h13.09z" />
    </g>
  </svg>
)

export default IconCopy
