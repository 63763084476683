import React, {useEffect, useRef, useState} from "react";
import {calculateProductPrice, generateVariantTitle} from "../../../utils/draftOrder";
import EmptyImage from "../../emptyImage";
import ProductPrice from "../../productPrice";
import Trash from "../../../assets/icons/Trash";
import OrderDiscount from "../../Modals/draft-order-modal/components/order-discount/OrderDiscount";
import './selected-product-item.scss'

const SelectedProductItem = ({
  product,
  increment,
  decrement,
  onRemoveProduct,
  updateProduct = () => {},
  isDraftOrderCompleted,
  showProductDiscount,
  setChangedProductId = () => {},
  errorMessage
}) => {
  const [orderDiscount, setOrderDiscount] = useState({discountReason: ''})
  const orderDiscountRef = useRef();

  useEffect(() => {
    let updatedOrderDiscount = orderDiscount
    if (product.discount) {
      updatedOrderDiscount.discount = product.discount
    }
    if (product.discountReason) {
      updatedOrderDiscount.discountReason = product.discountReason
    }
    updateDiscount(updatedOrderDiscount)
  }, [product])

  useEffect(() => {
    if (orderDiscount.discount) {
      updateProduct({
        ...product,
        price: calculateProductPrice(orderDiscount, product),
        compareAtPrice: product.compareAtPrice ?? product.price,
        discount: {
          discountType: orderDiscount.discount.discountType,
          discountValue: orderDiscount.discount.discountValue,
        },
        discountReason: orderDiscount.discountReason,
      })
    }
  }, [orderDiscount])

  useEffect(() => {
    if (showProductDiscount) {
      const container = document.getElementById('draft-order-container')
      container.scroll({
        top: orderDiscountRef.current.offsetTop,
        behavior: 'smooth'
      })
    }
  }, [showProductDiscount])

  const updateDiscount = (orderDiscount) => {
    setChangedProductId(null)
    setOrderDiscount(orderDiscount)
  }

  const onRemoveDiscount = () => {
    setChangedProductId(null)
    setOrderDiscount({discountReason: ''})
    updateProduct({
      ...product,
      price: product.compareAtPrice,
      compareAtPrice: null,
      discount: null,
      discountReason: ''
    })
  }

  return (
    <div className='selected-product-variant-container'>
      <div className='selected-product-variant-item'>
        <div className='selected-product-variant-item-description'>
          {product.image ? (
            <img
              className='main-image'
              src={product.image}
            />
          ) : (
            <EmptyImage product={product} size={'xs'}/>
          )}

          <div className='product-description'>
            <span className='product-title'>{product.productTitle}</span>
            <span className='variant-title'>{generateVariantTitle(product.title || product.variantTitle)}</span>
            <div
              className={'price-container'}
              onClick={isDraftOrderCompleted ? null :() => setChangedProductId(product.variantId)}
            >
              <ProductPrice
                selectedProductVariant={product}
              />
            </div>
          </div>
        </div>
        <div className='selected-product-variant-actions'>
          <span className='quantity-input'>
            <button disabled={isDraftOrderCompleted ? true : product.quantity === 1 || !!errorMessage} onClick={() => decrement(product.variantId)}>-</button>
            <input
              type='number'
              value={product.quantity}
              readOnly
            />
            <button disabled={isDraftOrderCompleted || !!errorMessage} onClick={() => increment(product.variantId)}>+</button>
          </span>
          {!isDraftOrderCompleted && (
            <div
              className='remove-selected-product-product'
              onClick={() => onRemoveProduct(product.variantId)}
            >
              <Trash/>
            </div>
          )}
        </div>
      </div>
      {errorMessage && (
        <p className='error-message'>{errorMessage}</p>
      )}
      {showProductDiscount && (
        <div className='selected-product-variant-discount'>
          <OrderDiscount
            ref={orderDiscountRef}
            hideDiscount={() => setChangedProductId(null)}
            updateDiscount={updateDiscount}
            orderDiscount={orderDiscount}
            onRemoveDiscount={onRemoveDiscount}
          />
        </div>
      )}
    </div>
  )
}

export default SelectedProductItem;
