import {useCallback} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import {useDispatch} from "react-redux";
import {disablePresentation, enablePresentation} from "../reducers/active-call-reducer";

const usePresentationMode = (callId, productId, variantId) => {

  const dispatch = useDispatch();

  const startProductPresentation = useCallback(
    ({imageId, isEnabled = false, cartKey = null, selectedOptions = null}) => {
      firebase
        .firestore()
        .collection('calls')
        .doc(callId)
        .collection('sessionData')
        .doc('presentationView')
        .set({
          productId,
          isEnabled,
          variantId,
          imageId,
          cartKey,
          selectedOptions,
        }, {merge: true})
        .then(() => {
          dispatch(enablePresentation());
          firebase.analytics().logEvent('start_product_presentation');
        });
    },
    [callId, productId, variantId]
  );

  const stopProductPresentation = useCallback(
    () => {
      firebase
        .firestore()
        .collection('calls')
        .doc(callId)
        .collection('sessionData')
        .doc('presentationView')
        .set({
          isEnabled: false,
          imageId: null,
          productId: null,
          variantId: null,
          cartKey: null,
          selectedOptions: null,
        }, {merge: true})
        .then(() => {
          firebase.analytics().logEvent('stop_product_presentation');
        });
    },
    [callId, productId, variantId]
  );

  return {
    startProductPresentation,
    stopProductPresentation,
  };
};

export default usePresentationMode;
