import React, { useEffect, useMemo, useState } from 'react';
import { Close } from '@material-ui/icons';
import './showroom-presets-modal.scss'
import { useShowroomPresetProvider } from '../../../providers/preset-provider/preset-provider';
import ShowroomPresetsList from './showroom-presets-list/showroom-presets-list';
import {
  useCallDetailsProvider
} from '../../../pages/home-page/call-details-side-bar/call-details-provider/call-details-provider';
import { useActiveCallProvider } from '../../ActiveCallProvider';
import ShowroomPresetEditmodeHeader from './showroom-preset-editmode-header/showroom-preset-editmode-header';
import CallPreparationTab
  from '../../../pages/home-page/call-details-side-bar/call-preparation-tab/call-preparation-tab';
import { MAX_SHOWROOM_PRODUCTS } from '../../../utils/consts';

const ShowroomPresetsModal = ({visibility, onClosePress, onUpdateShowroomProducts}) => {
  const [animationAction, setAnimationAction] = useState(null)
  const [selectedPresetId, setSelectedPresetId] = useState(null)
  const {callDetails} = useCallDetailsProvider()
  const {
    showroomPresetsList,
    onUpdatePreparationProducts,
    preparationProducts,
    refreshProductDetails,
    onHidePresetModal,
    callPreparationShowroom,
    isEditPresetMode,
    editPresetModalVisibility,
    filterText
  } = useShowroomPresetProvider();
  const {activeCallId} = useActiveCallProvider();

  useEffect(() => {
    if (visibility) {
      setAnimationAction(callDetails ? 'open' : `sidebar-open ${activeCallId ? ' video-call' : ' edit-mode'}`)
    } else if (!visibility && animationAction) {
      setAnimationAction(callDetails ? 'close' : `sidebar-close ${activeCallId ? ' video-call' : ' edit-mode'}`)
    }
  }, [visibility])

  useEffect(() => {
    if (callPreparationShowroom?.presetId) {
      setSelectedPresetId(callPreparationShowroom?.presetId)
    } else {
      setSelectedPresetId(null)
    }
  }, [callPreparationShowroom?.presetId, callDetails?.id])

  const filteredPresets = useMemo(() => (
    showroomPresetsList.filter(({name, products}) => (
      name.toLowerCase().includes(filterText.toLowerCase().trim())
      || products.filter(({productTitle}) => productTitle.toLowerCase().includes(filterText.toLowerCase().trim())).length
    ))
  ), [showroomPresetsList, filterText])

  const onSelectPreset = async (preset) => {
    setSelectedPresetId(preset.id)
    if (onUpdateShowroomProducts) {
      await onUpdateShowroomProducts(preset.products)
    } else {
      const presetProducts = await refreshProductDetails(
        preset.products.map(({productId}) => productId),
        preset.id,
      )
      const newProductsList = [
        ...presetProducts,
        ...preparationProducts
          .filter((product) => !presetProducts
            .some(({productId}) => product.productId === productId)
          )
      ].slice(0, MAX_SHOWROOM_PRODUCTS);
      await onUpdatePreparationProducts(
        newProductsList,
        preset
      )
    }
    onHidePresetModal()
  }

  const containerStyle = () => {
    if (activeCallId) {
      return 'video-call-container'
    }
    if (callDetails) {
      return 'common-container'
    }
    return ''
  }

  return (
    <div
      className={`showroom-presets-modal ${animationAction} ${containerStyle()}`}
      style={{
        '--modal-width': activeCallId ? '37.5vw' : '450px'
      }}
    >
      <div className='showroom-presets-header'>
        <h3>{isEditPresetMode ? 'Saved Showrooms' : 'Load a showroom'}</h3>
        <button onClick={onClosePress}>
          <Close/>
        </button>
      </div>
      <ShowroomPresetEditmodeHeader />
      <div className='product-display'>
        <ShowroomPresetsList
          selectedPresetId={selectedPresetId}
          presets={filteredPresets}
          onSelectPreset={onSelectPreset}
        />
      </div>
      <CallPreparationTab
        visible={editPresetModalVisibility}
      />
    </div>
  )

}

export default ShowroomPresetsModal;