import React from "react";
import './call-details-showroom-component.scss'
import CallDetailsShowroomItem from "./call-details-showroom-item/call-details-showroom-item";
import LocalMallIcon from "../../../../../assets/icons/LocalMall";
import {useCallDetailsProvider} from "../../call-details-provider/call-details-provider";
import ListProductSkeleton from '../../../../../components/skeletons/list-product-skeleton/list-product-skeleton';

const CallDetailsShowroomComponent = ({showroomProducts, hideCreateCartButton, addProductToCart}) => {

  const {products, onUpdateProducts, draftOrder, loadingProduct, showroomLoading, showroomError} = useCallDetailsProvider();

  const selectProduct = (product) => {
    const isProductInCart = products.some(({productId}) => productId === product.productId)
    if (isProductInCart) {
      onUpdateProducts(currentProducts => currentProducts.filter(({productId}) => productId !== product.productId))
    } else {
      onUpdateProducts(currentProducts => [...currentProducts, {...product, quantity: 1}])
    }
  }

  const renderContent = () => {
    if (showroomLoading) {
      return <ListProductSkeleton />
    }
    if (showroomProducts.length === 0 || showroomError) {
      return (
        <div className='empty-showroom'>
          <LocalMallIcon style={{fontSize: '50px', marginBottom: '25px'}}/>
          <p>
            {showroomError
              ? 'Could not load call showroom'
              : 'The call showroom is empty'
            }
          </p>
        </div>
      )
    }
    return (
      showroomProducts.map((product) => {
          const productInCart = products.find(({productId}) => product.productId === productId)
          const productInDraftOrder = draftOrder?.lineItems?.find(({productId}) => product.productId === productId)
          return (
            <CallDetailsShowroomItem
              loader={loadingProduct?.productId === product.productId}
              key={product.productId}
              product={productInCart ?? product}
              isSelectedProduct={!!productInCart || !!productInDraftOrder}
              selectProduct={selectProduct}
              addProductToCart={addProductToCart}
              hideCreateCartButton={hideCreateCartButton}
            />
          )
        }
      )
    )
  }

  return (
    <div className='showroom-component-wrapper'>
      {renderContent()}
    </div>
  )
}

export default CallDetailsShowroomComponent;
