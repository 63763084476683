import IconCopy from "../../../../../assets/icons/Copy";
import {AddToCalendar} from "../../../../../components/add-to-calendar/AddToCalendar";
import IconCalendar from "../../../../../assets/icons/Calendar";
import MoreIcon from "../../../../../assets/icons/More";
import React, {useMemo, useRef} from "react";
import {useSelector} from "react-redux";
import './scheduled-actions.scss';
import {CALL_TYPES} from "../../../../../utils/consts";

const ScheduledActions = ({
   isDetailsOpen,
   call,
   showCalendar,
   onCopyCallingLink,
   showMoreActions,
   onRescheduled,
   unassign,
   showConfirm,
   showCalendarModalModal,
   showMoreActionsModal,
   onEditEvent,
   onHideActionModals
}) => {
  const calendarRef = useRef()
  const { user } = useSelector((state) => state.user);
  const createdForSelf = call.createdFor && call.createdFor.id === user.id;
  const isMyScheduledCall = call.hostId ===user.id

  const position = useMemo(() => {
    if (calendarRef.current) {
      return window.innerHeight - calendarRef.current?.getBoundingClientRect()?.bottom < 100 ? 'top' : 'bottom'
    }
    return 'top'
  }, [calendarRef.current?.getBoundingClientRect()])

  const onHandleClick = (event, action = () => {}) => {
    action()
    event.stopPropagation()
  }

  return (
    <div className='scheduled-actions-container' onMouseLeave={onHideActionModals}>
      {call.type !== CALL_TYPES.BLOCKED_EVENT && (
        <div className='row' onClick={(event) => onHandleClick(event, onCopyCallingLink)}>
          <IconCopy/>
          <p className={isDetailsOpen ? 'tooltiptext' : `action-text`}>Copy call link</p>
        </div>
      )}
      <div ref={calendarRef} className='row' onClick={(event) => onHandleClick(event, showCalendar)}>
        <AddToCalendar
          modalPosition={position}
          icsDownloadUrl={call.icsDownloadUrl}
          modalVisibility={showCalendarModalModal}
          button={(
            <div className='row min-width'>
              <IconCalendar/>
              <p className={isDetailsOpen ? 'tooltiptext' : `action-text`}>Add To Calendar</p>
            </div>
          )}
          hostId={user.id}
        />
      </div>
      {isMyScheduledCall && (
        <div className='row' onClick={(event) => onHandleClick(event, showMoreActions)}>
          <MoreIcon/>
          <div className={`more-call-action-modal${showMoreActionsModal ? ' visible' : ''} ${position}`}>
            {call.type === CALL_TYPES.BLOCKED_EVENT ? (
              <ul>
                <li onClick={onEditEvent}>Edit</li>
                <li className='red' onClick={showConfirm}>Delete</li>
              </ul>
            ) : (
              <ul>
                <li onClick={onRescheduled}>Reschedule</li>
                {!createdForSelf && <li onClick={unassign}>Revert to pending</li>}
                <li className='red' onClick={showConfirm}>Cancel</li>
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default ScheduledActions;
