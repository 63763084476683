import React from 'react';
import './loading-logo.scss';

const LoadingLogo = () => {
  return (
    <div className='logo-loader-container-wrapper'>
      <img className='logo' src={'../logo_icon.png'}/>
    </div>
  )
}

export default LoadingLogo
