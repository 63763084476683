import React, {useEffect, useMemo, useRef, useState} from "react";
import {parseIdString} from "../../../utils";
import EmptyImage from "../../emptyImage";
import ProductPrice from "../../productPrice";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import {FilledButton} from "../../buttons/buttons";
import './search-product-item.scss'
import {generateVariantTitle} from "../../../utils/draftOrder";
import Trash from "../../../assets/icons/Trash";
import ProductOptionsSkeleton from '../../skeletons/product-options-skeleton/product-options-skeleton';

const SearchProductItem = ({
  product,
  onAddProduct,
  getCachedProduct,
  selectedProduct,
  setSelectedProduct,
  addButtonStyles,
  errorMessage,
  onRemoveProduct,
  showVariantSelector = true
}) => {
  const [availableOptions, setAvailableOptions] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedProductVariant, setSelectedProductVariant] = useState(null);
  const [fullProductInfoLoading, setFullProductInfoLoading] = useState(false);

  const containerRef = useRef()

  const isSelectedProduct = useMemo(() => {
    if (!!product && !!selectedProduct) {
      return parseIdString(product.productId) === parseIdString(selectedProduct.productId)
    } else {
      return false
    }
  }, [product, selectedProduct])

  const renderProduct = useMemo(() => (
    isSelectedProduct ? selectedProduct : product
  ), [selectedProduct, product, isSelectedProduct])

  useEffect(() => {
    setSelectedOptions([])
    setSelectedProduct(null)
    setAvailableOptions(null)
    setSelectedProductVariant(null)
  }, [])

  useEffect(() => {
    if (selectedProduct) {
      if (selectedProduct.options) {
        let dropDownOptions = [];
        selectedProduct.options.forEach((option, optionIndex) => {
          const availableValues = option.values.map((value) => {
            return {
              label: value,
              value,
              category: option.name,
            };
          });
          if (selectedProduct.selectedOptions?.length > 0) {
            const relevantOption = selectedProduct.selectedOptions.find(
              (selectedOption) => selectedOption.name === option.name
            );
            const formattedRelevantOptions = {
              label: relevantOption.value,
              value: relevantOption.value,
              category: relevantOption.name,
            };
            updateSelectedOptions(formattedRelevantOptions, optionIndex);
          } else if (option.values.length === 1) {
            updateSelectedOptions(availableValues[0], optionIndex);
          }

          dropDownOptions.push({
            placeholder: `Please select a ${option.name.toLowerCase()}`,
            selectValues: availableValues,
          });
        });
        setAvailableOptions(dropDownOptions);
      }
    }
    return () => {
      setSelectedOptions([]);
      setAvailableOptions(null);
      setSelectedProductVariant(null);
    };
  }, [selectedProduct]);

  useEffect(() => {
    if (availableOptions && selectedOptions.length === availableOptions.length && selectedProduct) {
      const selectedVariant = selectedProduct?.variants.find(item => {
        const selectedVariant = item.selectedOptions.every(productOption => {
          const isIncludedOption = selectedOptions.some(selectedOption => productOption.name === selectedOption?.category && productOption.value === selectedOption?.value);
          return isIncludedOption
        })
        return selectedVariant;
      })
      setSelectedProductVariant({...selectedVariant, productTitle: selectedProduct.title});
    }
  }, [selectedOptions, availableOptions])

  const onSelectProduct = async (event) => {
    const attr = event.nativeEvent.target.getAttribute('class')
    if (attr?.includes('css-')) {
      return;
    }
    if (!showVariantSelector) {
      onAddProduct({
        ...product,
        productId: parseIdString(product.productId),
      })
      return
    }
    if (selectedProduct) {
      if (isSelectedProduct) {
        setSelectedProduct(null)
        return
      }
      setSelectedProduct(product)
    }
    if (!fullProductInfoLoading) {
      const container = document.getElementById('search-dropdown')
      if (!isSelectedProduct) {
        setSelectedProduct(null)
      }
      if (container) {
        container.scroll({
          top: containerRef.current.offsetTop,
          behavior: 'smooth'
        })
      }
      setFullProductInfoLoading(true);
      const cleanProductId = parseIdString(product.productId);
      try {
        const fullProductInfo = await getCachedProduct(cleanProductId)
        setSelectedProduct({...fullProductInfo, ...product})
        if (container) {
          container.scroll({
            top: containerRef.current.offsetTop,
            behavior: 'smooth'
          })
        }
      } catch (e) {
        console.error(e);
      } finally {
        setFullProductInfoLoading(false)
      }
    }
  }

  const onAddPress = () => {
    onAddProduct({
      ...selectedProductVariant,
      productId: parseIdString(product.productId),
      variantId: parseIdString(selectedProductVariant.id),
      variantTitle: selectedProductVariant.title,
      productTitle: product.title ?? product.productTitle,
      image: getImagePerSelectedVariant(),
      descriptionHtml: product.descriptionHtml,
      quantity: 1
    })
    setSelectedProduct(null)
  }

  const getImagePerSelectedVariant = () => {
    const getDefaultImage = () => {
      if (!isSelectedProduct) {
        return product.image
      }
      return selectedProduct?.images?.length > 0 ? selectedProduct?.images[0]?.url : null;
    }
    if (selectedProductVariant) {
      if (selectedProductVariant.image) {
        return selectedProductVariant.image.url
      } else {
        return getDefaultImage()
      }
    } else {
      return getDefaultImage()
    }
  }

  const updateSelectedOptions = (newOption, optionIndex) => {
    setSelectedOptions((existingOptions) => {
      const existingOptionsCopy = [...existingOptions];
      existingOptionsCopy[optionIndex] = newOption;
      return existingOptionsCopy;
    });
  };

  const variantTitle = (product) => {
    if (product.variantTitle) {
      return `${generateVariantTitle(product.variantTitle)}`
    }
    return ''
  }

  return (
    <div
      className={`search-product-item-component${showVariantSelector ? ' pointer' : ''}`}
      onClick={onSelectProduct}
      ref={containerRef}
    >
      <div className='search-product-item-description-container'>
        {getImagePerSelectedVariant() ? (
          <img
            className='main-image'
            src={getImagePerSelectedVariant()}
          />
        ) : (
          <EmptyImage product={product} size={'xs'} />
        )}
        <div className='product-description-wrapper'>
          <div className='product-description'>
            <span className='product-title'>{renderProduct.productTitle ?? renderProduct.title}</span>
            <span className='variant-title'>{variantTitle(renderProduct)}</span>
            <ProductPrice
              product={renderProduct}
            />
          </div>
          {onRemoveProduct && (
            <div
              className='remove-selected-product-product'
              onClick={() => onRemoveProduct(product.variantId ?? product.productId)}
            >
              <Trash/>
            </div>
          )}
        </div>
      </div>
      {errorMessage && (
        <p className='error-message'>{errorMessage}</p>
      )}
      {fullProductInfoLoading && (
        <ProductOptionsSkeleton />
      )}
      {isSelectedProduct && availableOptions && !selectedProduct.hasOnlyDefaultVariant && showVariantSelector && (
        availableOptions?.map((option, optionIndex) => {
          return (
            <Select
              key={`${product.productId}${optionIndex}`}
              onChange={(newSelection) =>
                updateSelectedOptions(newSelection, optionIndex)
              }
              placeholder={option.placeholder}
              menuPlacement='auto'
              value={selectedOptions[optionIndex] || null}
              styles={{
                menuList: (styles) => {
                  return {
                    ...styles,
                    maxHeight: 150,
                  }
                },
                menu: (styles) => {
                  return {
                    ...styles,
                    position: 'initial',
                    overflowY: 'auto'
                  }
                },
                control: (styles) => {
                  return {
                    ...styles,
                    boxSizing: 'border-box',
                    border: '1px solid #CCC3BD',
                    borderRadius: '8px',
                    marginTop: '10px',
                  }
                },
                option: (styles, { isFocused, isSelected }) => {
                  return {
                    ...styles,
                    backgroundColor: isSelected
                      ? '#DCDCDC'
                      : isFocused
                        ? 'rgba(249,139,0,0.04)'
                        : null,
                  };
                },
              }}
              options={option.selectValues}
            />
          )}
        ))
      }
      {isSelectedProduct && (
        <>
          <ReactTooltip
            id="addProductTooltip"
            className='processor-tooltip'
            effect="solid"
            type='info'
            place='top'
            backgroundColor='#6e6e6e'
            disable={!!onAddProduct}
          >
            <span>{`Products cannot be more than 10`}</span>
          </ReactTooltip>
          <FilledButton
            data-tip
            data-for="addProductTooltip"
            disabled={availableOptions?.length !== selectedOptions?.length}
            className='button'
            style={{
              height: '34px',
              width: '60px',
              backgroundColor: onAddProduct ? 'var(--main-color)' : 'var(--main-color-hover)',
              justifySelf: 'flex-end',
              alignSelf: 'flex-end',
              marginTop: '10px',
              marginBottom: '10px',
              ...addButtonStyles
            }}
            onClick={onAddPress}
          >
            Add
          </FilledButton>
        </>
      )}
    </div>
  )
}

export default SearchProductItem;
