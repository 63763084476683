import React, { useEffect, useRef, useState } from "react";
import ReactTooltip from 'react-tooltip';
import BlockIcon from "@material-ui/icons/Block";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { logger } from '../../logging';
import { useBoutiqVideoRoomContext } from '@caazam/boutiq-video-room';
import { PROCESSOR_TYPES } from "../../utils/consts";

import './styles.scss';
import { useVideoConfiguration } from '../VideoConfigurationProvider';

export default function VideoFilterSettings({ isLoading }) {

  const { applyVideoProcessor } = useBoutiqVideoRoomContext();
  const { uploadVirtualBackground, activeVideoProcessor, saveVideoSetting, clearVideoProcessorSettings } = useVideoConfiguration();
  const preprocessorImageFileInput = useRef(null);

  useEffect(() => {
    let processor = { type: 'none' }

    logger.info('The active video processor=', activeVideoProcessor);
    
    if (activeVideoProcessor?.type === PROCESSOR_TYPES.BLUR) {
      isLoading(true);
      processor = {
        type: 'background-blur',
        config: { strength: activeVideoProcessor.strength ?? 0.5 }
      };
    } else if (activeVideoProcessor?.type === PROCESSOR_TYPES.VIRTUAL) {
      processor = {
        type: 'background-image',
        config: { url: activeVideoProcessor.backgroundImage }
      };

    }
    
    applyVideoProcessor(processor)
      .catch(error => {
        logger.error('Error in applying video processor', error);
        isLoading(false);
      })
      .finally(()=> isLoading(false));
  }, [activeVideoProcessor]);


  const onApplyVideoBlur = () => {
    if (activeVideoProcessor?.type === PROCESSOR_TYPES.BLUR) return;
    const strength = 0.5;
    isLoading(true);
    saveVideoSetting({ type: PROCESSOR_TYPES.BLUR, strength }).catch(error => {
      logger.error('failed to save video filter blur settings', error);
      isLoading(false);
    })

  }

  async function onApplyVideoBackground(event) {
    const file = event.target.files[0];
    isLoading(true);
    try {
      const { imagePublicUrl, imageStorageRef } = await uploadVirtualBackground(file);
      await saveVideoSetting({ type: PROCESSOR_TYPES.VIRTUAL, backgroundImage: imagePublicUrl, backgroundImageRef: imageStorageRef });
    } catch (error) {
      logger.error('failed to save video filter virtual settings', error);
      isLoading(false);
    }
  }

  const onApplyVideoNone = () => {
    applyVideoProcessor({ type: 'none' });
    clearVideoProcessorSettings();
  }

  const selectBackgroundImage = () => {
    preprocessorImageFileInput.current.click();
  }

  return (
    <div className='section-video-settings-processors'>
      <button
        data-tip
        data-for="deleteProcessorTooltip"
        onClick={onApplyVideoNone}
        className={`section-video-settings-processors_button active`}>
        <BlockIcon />
      </button>
      <button
        data-tip
        data-for="blurTooltip"
        onClick={onApplyVideoBlur}
        className={`section-video-settings-processors_button active`}>
        <BlurOnIcon />
      </button>
      <button
        data-tip
        data-for="virtualTooltip"
        onClick={selectBackgroundImage}
        className={`section-video-settings-processors_button active`}>
        <AddPhotoAlternateIcon />
      </button>
      <input
        className='section-video-settings-processors_upload'
        ref={preprocessorImageFileInput}
        onChange={onApplyVideoBackground}
        type="file"
        accept="image/jpg, image/jpeg, image/png"
        value=""
        capture='image'
      />
      <ReactTooltip id="deleteProcessorTooltip" effect="solid" place='right' backgroundColor='#6E76F2'>
        <span>Turn off visual effects</span>
      </ReactTooltip>
      <ReactTooltip id="blurTooltip" effect="solid" place='right' backgroundColor='#6E76F2'>
        <span>Blur your background</span>
      </ReactTooltip>
      <ReactTooltip id="virtualTooltip" effect="solid" place='right' backgroundColor='#6E76F2'>
        <span>Upload a background image</span>
      </ReactTooltip>
    </div>
  );
};
