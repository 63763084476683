import React, {useState, useRef, useEffect, useMemo} from 'react';
import CloseRoundedIcon from '../../assets/icons/CloseRounded';
import AddRoundedIcon from '../../assets/icons/AddRounded';
import PresentToAllRoundedIcon from '../../assets/icons/PresentToAllRounded';
import ArrowBackIosRoundedIcon from '../../assets/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '../../assets/icons/ArrowForwardIosRounded';
import {useDispatch, useSelector} from 'react-redux';
import Zoom from 'react-img-zoom'

import { useSessionCart } from '../../hooks';
import './styles.scss';
import {disablePresentation, setSelectedImage} from '../../reducers/active-call-reducer';
import {FilledButton, OutlinedButton} from '../buttons/buttons';
import ProductPrice from '../productPrice';
import {useSessionProducts} from '../../hooks';
import {parseIdString} from '../../utils';
import usePresentationMode from '../../hooks/usePresentationMode';
import { CarouselDots } from '../carousel-dots/CarouselDots';
import { innerLinkHandler } from '../../utils/innerLinkHandler';

const ProductPresentationView = ({ clientViewData, selectedSnapshotKey }) => {
  const SCROLL_STEP = 65;

  const galleryRef = useRef(null);
  const mainImageRef = useRef(null);
  const zoomedViewRef = useRef(null);
  const naturalImgParams = useRef(null);
  const dispatch = useDispatch();
  const {product, selectedProductVariant, isProductInShowroom, currentImages,
    selectedImage, contextId, cartKey, selectedOptions} = useSelector((state) => state.activeCall);
  const [variantId, setVariantId] = useState(null);
  const [zoom, setZoom] = useState(false);
  const [mainImageWidth, setMainImageWidth] = useState(0);
  const [mainImageHeight, setMainImageHeight] = useState(0);
  const [inShowroom, toggleIsProductInShowroom] = useState(null);
  const { sessionSnapshots, addToSession } = useSessionProducts(contextId);
  const [startedPresentationMode, setStartedPresentationMode] = useState(false);
  const [isProductInCart, toggleIsProductInCart] = useState(false);
  const { cartSnapshots } = useSessionCart(contextId);
  const [imageClientViewing, setImageClientViewing] = useState(null);

  const productId = useMemo(() => {
    if (product.productId) {
      return parseIdString(product.productId)
    }
    return null;
  }, [product.productId]);

  useEffect(() => {

    window.addEventListener('resize', () => {
      if (zoomedViewRef.current && selectedImage) {
        const {width, height} = getRenderedSize(true, naturalImgParams.current.width,
          naturalImgParams.current.height, naturalImgParams.current.width, naturalImgParams.current.height, 0);
        calcImageSize(width, height);
      }
    });

    const index = currentImages.findIndex(i => i.id === selectedImage.id);
    if (selectedImage && currentImages.length > 1) {
      if (index !== -1) {
        if (index > 2) {
          scroll(SCROLL_STEP * index);
        }
      }
      dispatch(setSelectedImage({selectedImage: {...selectedImage, index}}));
    }

    return () => window.removeEventListener('resize', updateNodeParams);
  }, []);

  useEffect(() => {
    if (product) {
      if (selectedSnapshotKey) {
        const snapshotsKeysInCart = cartSnapshots.map((snapshot) => snapshot.key);
        toggleIsProductInCart(snapshotsKeysInCart.includes(selectedSnapshotKey));
      } else {
        toggleIsProductInCart(false);
      }
    }
  }, [product, cartSnapshots, selectedSnapshotKey]);

  useEffect(() => {
    const clientUuid = Object.keys(clientViewData)[0];
    const clientActivity = clientViewData[clientUuid];
    const viewingImageByClient = clientActivity?.productView?.imageId ?? null;
    if (viewingImageByClient) {
      const viewingIndex = currentImages.findIndex(el => el.id === viewingImageByClient)
      setImageClientViewing(viewingIndex !== -1 && viewingIndex);
    }
  }, [currentImages, clientViewData]);

  const handleMouseOver = () => setZoom(true);

  const handleMouseOut = () => setZoom(false);

  function getRenderedSize(contains, cWidth, cHeight, width, height){
    const oRatio = width / height;
    const cRatio = cWidth / cHeight;
    let imgWidth = 0;
    let imgHeight = 0;
    if (contains ? (oRatio > cRatio) : (oRatio < cRatio)) {
      imgWidth = cWidth;
      imgHeight = cWidth / oRatio;
    } else {
      imgWidth = cHeight * oRatio;
      imgHeight = cHeight;
    }
    return {width: imgWidth, height: imgHeight}
  };

  const getImgSizeInfo = (img) => {
    const position = window.getComputedStyle(img).getPropertyValue('object-position').split(' ');
    naturalImgParams.current = {width: img.naturalWidth, height: img.naturalHeight}
    return getRenderedSize(true, img.width, img.height, img.naturalWidth, img.naturalHeight, parseInt(position[0]));
  };

  const updateNodeParams = () => {
    if (mainImageRef.current) {
      const {width, height} = getImgSizeInfo(mainImageRef.current);
      calcImageSize(width, height);
    }
  };

  const calcImageSize = (width, height) => {
    const maxImageHeight = window.innerHeight - 200;
    const maxImageWidth = window.innerWidth / 3;
    let activeHeight = height;
    let activeWidth = width;
    const coef = naturalImgParams.current.height / naturalImgParams.current.width;

    if (height > maxImageHeight) {
      activeHeight = maxImageHeight;
      activeWidth = activeHeight / coef;
    }

    if (activeWidth > maxImageWidth) {
      activeWidth = maxImageWidth;
      activeHeight = activeWidth * coef;
    }

    setMainImageWidth(activeWidth);
    setMainImageHeight(activeHeight);
  }

  useEffect(() => {
    if (zoom) {
      updateNodeParams();
    }
  }, [mainImageRef.current, zoom]);

  useEffect(() => {
    if (!mainImageWidth && !mainImageHeight) {
      updateNodeParams();
    }
  }, [mainImageHeight, mainImageWidth, selectedImage])

  useEffect(() => {
    if (selectedProductVariant) {
      setVariantId(parseIdString(selectedProductVariant.id));
    }
  }, [selectedProductVariant, isProductInCart]);

  const {startProductPresentation, stopProductPresentation} = usePresentationMode(contextId, productId, variantId);

  useEffect(() => {
    if (product) {
      if (isProductInShowroom) {
        toggleIsProductInShowroom(true);
      }
      const productIDsInShowroom = sessionSnapshots.map(
        (snapshot) => snapshot.val().productId
      );
      toggleIsProductInShowroom(productIDsInShowroom.includes(productId));
    }
  }, [product, productId, sessionSnapshots, isProductInShowroom]);

  const stopPresentation = () => {
    stopProductPresentation();
    setStartedPresentationMode(false);
  };

  const hidePresentationView = () => {
    stopPresentation();
    dispatch(disablePresentation());
  };

  const startPresentation = () => {
    const formatImageId = selectedImage
      ? parseIdString(selectedImage.id)
      : null;
    startProductPresentation({imageId: formatImageId, isEnabled: true, cartKey, selectedOptions});
    setStartedPresentationMode(true);
  };

  useEffect(() => {
    if (startedPresentationMode) {
      startPresentation();
    }
  }, [selectedImage]);

  const addToShowroom = () => {
    addToSession(product);
  };

  const renderProductData = () => (
    <div className='presentation-view_modal_details'>
      <div className='presentation-view_modal_details_actions'>
      {
        startedPresentationMode
          ? <OutlinedButton
              onClick={stopPresentation}
              className='presentation-view_modal_details_actions_mode-btn presentation-view_modal_details_actions_mode-btn_active'>
              <div className='presentation-view_modal_details_actions_mode-btn_content presentation-view_modal_details_actions_mode-btn_content_active'>
                <PresentToAllRoundedIcon  />
                presentation mode
              </div>
            </OutlinedButton>
          : <FilledButton
              onClick={startPresentation}
              className='presentation-view_modal_details_actions_mode-btn'>
              <div className='presentation-view_modal_details_actions_mode-btn_content'>
                <PresentToAllRoundedIcon fill='white' />
                presentation mode
              </div>
            </FilledButton>
      }
      <p className={`presentation-view_modal_details_actions_helper_text${startedPresentationMode ? '_active' : ''}` }>Present this product to the client now</p>
      </div>
      <p className='presentation-view_modal_details_title'>{product && product.title ? product.title : ''}</p>
      <div className='presentation-view_modal_details_prices'>
        <ProductPrice product={product} selectedProductVariant={selectedProductVariant}/>
      </div>
      {
        !inShowroom
          ? <FilledButton
            onClick={addToShowroom}
            className='presentation-view_modal_details_showroom-btn'>
            <div className='presentation-view_modal_details_showroom-btn_content'>
              <AddRoundedIcon fill='white' />
              showroom
            </div>
          </FilledButton>
          : <p className='presentation-view_modal_details_added'>added to showroom</p>
      }
      <div onClick={(e) => innerLinkHandler(e, product.onlineStoreUrl)} className='presentation-view_modal_details_description' dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}/>
    </div>
  );

  const scrollBack = () => {
    scroll(-SCROLL_STEP);
  };

  const scrollForward = () => {
    scroll(SCROLL_STEP);
  };

  const scroll = (scrollOffset) => {
    galleryRef.current.scroll({top: 0, left: galleryRef.current.scrollLeft + scrollOffset * 3, behavior: 'smooth' });
  };

  const updateMainImage = (image, index) => {
    setMainImageHeight(0);
    setMainImageWidth(0);
    dispatch(setSelectedImage({ selectedImage: { ...image, index } }))
  }

  const renderImagesList = () => {
    return (
        currentImages.map((image, index) => (
          <div
            key={image.id}
            onClick={() => updateMainImage(image, index)}
            className={`presentation-view_modal_gallery_block_images-gallery_previews-wrapper${(imageClientViewing === index ? ' highlighted' : '')}`}
            >
            <img
              className={'presentation-view_modal_gallery_block_images-gallery_previews'}
              src={image.url}
              id={image.id}
              alt={image.altText}
            />
          </div>
        ))
    );
  };

  const renderGallery = () => (
    <div className='presentation-view_modal_gallery'>
      {
        currentImages.length > 1 &&
        <div className='presentation-view_modal_gallery_block'>
          <ArrowBackIosRoundedIcon
            fill='#FFFFFF'
            style={{height: 36, width: 25}}
            className='presentation-view_modal_gallery_block_left-arrow'
            onClick={scrollBack}/>
          <div ref={galleryRef} className='presentation-view_modal_gallery_block_images-gallery'>
            {renderImagesList()}
          </div>
          <ArrowForwardIosRoundedIcon
            fill='#FFFFFF'
            style={{height: 36, width: 25}}
            className='presentation-view_modal_gallery_block_right-arrow'
            onClick={scrollForward}
          />
        </div>
      }
      {currentImages.length > 1 &&
        <div className='presentation-view_modal_gallery_dots'>
          <CarouselDots
            length={currentImages.length}
            active={selectedImage && selectedImage.index ? selectedImage.index : 0}
            customBorder={imageClientViewing}
          />
        </div>
      }
      {
        selectedImage &&
          <>
            {
              !mainImageWidth && !mainImageHeight &&
                <img
                  onLoad={updateNodeParams}
                  ref={mainImageRef}
                  className='presentation-view_modal_gallery_main-image_default'
                  src={selectedImage ? selectedImage.fullSizedUrl : (selectedImage.url ? selectedImage.url : '')}
                  alt={selectedImage ? selectedImage.altText : ''}
                />
            }
            {
              (selectedImage.fullSizedUrl || selectedImage.url) && mainImageWidth && mainImageHeight &&
                <div
                  style={{ width: `${mainImageWidth}px`, height: `${mainImageHeight}px` }}
                  className='presentation-view_modal_gallery_zoom'
                  onLoad={updateNodeParams}
                  onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver}
                >
                  <Zoom
                    key={`${mainImageWidth}_${mainImageHeight}`}
                    ref={zoomedViewRef}
                    img={selectedImage ? selectedImage.fullSizedUrl : (selectedImage.url ? selectedImage.url : '')}
                    zoomScale={3}
                    width={mainImageWidth}
                    height={mainImageHeight}
                    alt={selectedImage ? selectedImage.altText : ''}
                  />
                </div>
            }
          </>
      }
    </div>
  );

  const renderRightBlock = () => (
    <div className='presentation-view_modal_right-part'>
      <div className='presentation-view_modal_right-part_close-btn'>
        <CloseRoundedIcon style={{color: '#FFFFFF'}} onClick={hidePresentationView} />
      </div>
    </div>
  );

  return (
    <div className='presentation-view'>
      <div className='presentation-view_modal'>
        {renderProductData()}
        {renderGallery()}
        {renderRightBlock()}
      </div>
    </div>
  );
};

export default ProductPresentationView;
