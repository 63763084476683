import React, { createContext, useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { logger } from '../../../logging';
import usePreflightTest from "./hooks/appStateDiagnostic/usePreflightTest";
import useCaazamREST from '../../../hooks/useCaazamREST';
import { getQualityScore, persistDiagnostic, clearPersistedDiagnostic, getPreflightStopped, getPreflightFailed } from "./ulits/diagnostics";
import { APP_DIAGNOSTICS_ACTION_TYPES } from "./constants/appDiagnostics";
import { VIDEO_DURATION_FOR_POSTCALL_FEEDBACK } from '../../../utils/consts'

export const AppDiagnosticsContext = createContext(null);

const preflightDuration = 15;

export default function AppDiagnosticsProvider({ canRunTest = true, children }) {
  const dispatch = useDispatch();

  const { startPreflightTest, stopPreflightTest } = usePreflightTest(preflightDuration);
  const { sendDiagnosticReport } = useCaazamREST();

  const { preflightTest, preflightTestFinished, duration, reportSent } = useSelector((state) => state.appDiagnostics);

  const { callDuration: prevCallDuration } = useSelector((state) => state.prevCall);

  useEffect(() => {
    return () => stopDiagnostics();
  }, []);

  useEffect(() => {
    if (prevCallDuration && prevCallDuration < VIDEO_DURATION_FOR_POSTCALL_FEEDBACK && !preflightTestFinished && canRunTest) {
      //startDiagnostics(); DAILY: need to handle init of new video tarck just as the call ends so that the test runs
    }
  }, [prevCallDuration, preflightTestFinished, canRunTest])

  const startDiagnostics = () => {
    dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.CLEAR_DIAGNOSTIC });
    clearPersistedDiagnostic();
    startPreflightTest();
  }

  const stopDiagnostics = () => {
    stopPreflightTest();
    dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.CLEAR_DIAGNOSTIC });
  }

  useEffect(() => {
    if (preflightTestFinished && !reportSent) {
      const signalingGateway = preflightTest.signalingGatewayReachable;
      const turnServer = preflightTest.turnServersReachable;

      logger.info('preflightTestFinished',preflightTest);
      const finalTestResults = {
        version: '1', // 1 == daily, none = twilio
        browserInformation: {
          ua: navigator.userAgent
        },
        connectivityResults: {
          signalingGateway,
          turnServer,
        },
        preflightTestReport: {
          report: preflightTest.report,
          error: preflightTest.error,
          failed: getPreflightFailed(preflightTest.report) ?? !!preflightTest.error,
          stopped: getPreflightStopped(preflightTest.report, preflightDuration),
          result: getQualityScore(preflightTest.report, duration),
        },
        appVersion: process.env.REACT_APP_VERSION,
      };

      logger.info('Sending diag report', finalTestResults);
      dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.DIAGNOSTIC_RESULT, result: finalTestResults });
      persistDiagnostic(finalTestResults);
      sendDiagnosticReport(finalTestResults)
        .then(() => {
          dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.REPORT_SENT });
        })
        .catch(error => {
          logger.error('failed to save diagnostics report', error);
        });
    }
  }, [preflightTestFinished, reportSent, sendDiagnosticReport])

  return (
    <AppDiagnosticsContext.Provider value={{
      startDiagnostics,
      stopDiagnostics,
    }}>
      {children}
    </AppDiagnosticsContext.Provider>
  );
}

export function useAppDiagnosticsProvider() {
  const context = useContext(AppDiagnosticsContext);
  if (!context) {
    throw new Error('useAppDiagnosticsProvider must be used within the AppDiagnosticsProvider');
  }
  return context;
}
