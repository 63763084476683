import React, { useRef, useEffect, useState, useCallback } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import { DeviceSelector } from './components/DeviceSelector/device-selector';
import MicNoneIcon from '@material-ui/icons/MicNone';
import VolumeUpOutlinedIcon from '@material-ui/icons/VolumeUpOutlined';
import MicIcon from '@material-ui/icons/Mic';
import VideocamIcon from '@material-ui/icons/Videocam';
import { SOUND_FX_PATHS } from '../../../utils/consts';
//import AudioLevelIndicator from './indicator/AudioLevelIndicator';
//import { useDevices } from './hooks/useDevices';
import { useDevices, useLocalTracks, useAudioLevel, AudioLevelIndicator, browserSupport } from '@caazam/boutiq-video-room';
import { Spinner } from '../../../components/spinner/spinner';
import './audio-video-settings.scss';
import VideoFilterSettings from "../../../components/VideoFilterSettings";
import AppDiagnostic from "./components/AppDiagnostic";
import SettingRow from "./app-settings/setting-row/setting-row";
import { useVideoConfiguration } from "../../../components/VideoConfigurationProvider";
import throttle from 'lodash.throttle';

const AUDIO_ERROR_MESSAGE = 'This browser does not support the audio element.';

export default function AudioVideoSettings() {
    const [isVideoStreamLoading, setIsVideoStreamLoading] = useState(false);
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const audioElementRef = useRef(null);
    const videoElementRef = useRef(null);
    const { hostAppMuteVideoOnCallStart, onToggleMuteVideoOnCallStart } = useVideoConfiguration()

    const persistedAudioInputDevice = localStorage.getItem('caazam.audioInput');
    const persistedAudioOutputDevice = localStorage.getItem('caazam.audioOutput');
    const persistedVideoSourceDevice = localStorage.getItem('caazam.videoSource');

    const [initialMicLoad, setInitialMicLoad] = useState(false);
    const [initialCamLoad, setInitialCamLoad] = useState(false);
    const [initialSpeakerLoad, setInitialSpeakerLoad] = useState(false);

    const [startCameraReady, setStartCameraReady] = useState(false);

    const {
        currentMic, hasMicError, micState, setMicrophone, microphones,
        currentCam, hasCamError, camState, setCamera, cameras,
        currentSpeaker, speakers, setSpeaker,
        refreshDevices
    } = useDevices();

    const { localAudio, localVideo, startCamera, stopCamera } = useLocalTracks();
    const [volume, setVolume] = useState(null);

    const updateVolume = useCallback(throttle((volume) => {
        // this volume number will be between 0 and 1
        // give it a minimum scale of 0.15 to not completely disappear 👻
        setVolume(volume);
    }, 100), []);

    useAudioLevel(localAudio, updateVolume);

    useEffect(() => {
        startCamera().then(() => setStartCameraReady(true));
        return () => stopCamera();
    }, []);

    /* 
        useEffect(() => {
    
            if (micState === 'granted') {
                setAudioInputPermissionDenied(false);
                setMircoRequestPermission(false)
            } else {
                setAudioInputPermissionDenied(true);
                setMircoRequestPermission(true)
            }
    
        }, [micState]);
    
        useEffect(() => {
            if (camState === 'granted') {
                setVideoInputPermissionDenied(false);
                setCameraRequestPermission(false)
            } else {
                setVideoInputPermissionDenied(true);
                setCameraRequestPermission(true)
            }
        }, [camState]); */
    /* 
        useEffect(() => {
            if ((microRequestPermission !== null && cameraRequestPermission !== null) &&
                microRequestPermission || cameraRequestPermission) {
                const constraints = {
                    audio: microRequestPermission,
                    video: cameraRequestPermission
                };
                navigator.mediaDevices.getUserMedia(constraints).then(() => getDevices());
            }
        }, [microRequestPermission, cameraRequestPermission])
     */

    useEffect(() => {
        if (startCameraReady && !initialMicLoad && microphones.length && persistedAudioInputDevice) {
            let persistedDevice = microphones.find((mic) => mic.device.deviceId === persistedAudioInputDevice)
            if (!persistedDevice) {
                localStorage.removeItem('caazam.audioInput')
            } else {
                setMicrophone(persistedAudioInputDevice);
            }
            setInitialMicLoad(true);
        }
    }, [microphones, initialMicLoad]);

    useEffect(() => {
        if (startCameraReady && !initialCamLoad && cameras.length && persistedVideoSourceDevice) {
            let persistedDevice = cameras.find((cam) => cam.device.deviceId === persistedVideoSourceDevice)
            if (!persistedDevice) {
                localStorage.removeItem('caazam.videoSource')
            } else {
                setCamera(persistedVideoSourceDevice);
            }
            setInitialCamLoad(true);
        }
    }, [cameras, initialCamLoad]);

    useEffect(() => {
        if (startCameraReady && !initialSpeakerLoad && speakers.length && persistedAudioOutputDevice) {
            let persistedDevice = speakers.find((speaker) => speaker.device.deviceId === persistedAudioOutputDevice)
            if (!persistedDevice) {
                localStorage.removeItem('caazam.audioOutput')
            } else {
                setSpeaker(persistedAudioOutputDevice);
            }
            setInitialSpeakerLoad(true);
        }
    }, [speakers, initialSpeakerLoad]);

    useEffect(() => {
        if (currentSpeaker?.device?.deviceId) {
            attachSinkId(
                audioElementRef.current,
                currentSpeaker.device.deviceId
            );
        }
    }, [currentSpeaker?.device?.deviceId])

    const play = () => {
        audioElementRef.current.pause();
        audioElementRef.current.currentTime = 0;
        audioElementRef.current.play();
    };

    const playing = () => {
        setIsAudioPlaying(true);
    }

    const ended = () => {
        setIsAudioPlaying(false);
    }

    useEffect(() => {
        if (audioElementRef.current) {
            audioElementRef.current.addEventListener('playing', playing);
            audioElementRef.current.addEventListener('ended', ended);
        }
        return () => {
            if (audioElementRef.current) {
                audioElementRef.current.removeEventListener('playing', playing);
                audioElementRef.current.removeEventListener('ended', ended);
            }
        }
    }, [audioElementRef.current])


    const onAudioOutputChange = (device) => {
        /* const selectedDevice = { label: device.label, value: device.deviceId };
        setCurrentAudioOutput(selectedDevice); */
        setSpeaker(device.deviceId);
        if (device.deviceId === 'default') {
            localStorage.removeItem('caazam.audioOutput');
        } else {
            localStorage.setItem('caazam.audioOutput', device.deviceId);
        }
        firebase.analytics().logEvent("host_custom_audio_output");
    }


    const onAudioInputChange = (device) => {
        setMicrophone(device.deviceId);
        if (device.deviceId === 'default') {
            localStorage.removeItem('caazam.audioInput');
        } else {
            localStorage.setItem('caazam.audioInput', device.deviceId);
        }
        firebase.analytics().logEvent("host_custom_audio_input");
    }

    const onVideoInputChange = (device) => {
        setIsVideoStreamLoading(true);
        setCamera(device.deviceId);
        localStorage.setItem('caazam.videoSource', device.deviceId);
        firebase.analytics().logEvent("host_custom_video_input");
    }

    const attachSinkId = (audioElem, sinkId) => {
        if (typeof audioElem?.sinkId !== 'undefined') {
            audioElem
                .setSinkId(sinkId)
                .catch((error) => {
                    let errorMessage = error;
                    if (error.name === 'SecurityError') {
                        errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                    }
                    console.error(errorMessage);
                });
        } else {
            console.warn('Browser does not support output device selection.');
        }
    };

    function getMicErrorMessage() {
        switch (micState) {
            case 'pending': return 'Microphone permissions pending';
            case 'blocked': return 'Microphone permissions denied';
            case 'not-supported': return 'Microphone not supported';
            case 'not-found': return 'Microphone not found';
            default: return 'Microphone error: ' + micState;
        }

    }

    function getCamErrorMessage() {
        switch (camState) {
            case 'pending': return 'Camera permissions pending';
            case 'blocked': return 'Camera permissions denied';
            case 'not-supported': return 'Camera not supported';
            case 'not-found': return 'Camera not found';
            default: return 'Camera error: ' + camState;
        }
    }

    useEffect(() => {
        if (!localVideo) return;
        videoElementRef.current.srcObject = new MediaStream([localVideo])
        setIsVideoStreamLoading(false);
        return () => {
            if (videoElementRef.current) {
                videoElementRef.current.srcObject = null;
            }
        };
    }, [localVideo]);

    return (
        <div className='audio-video-settings-container'>
            <div className='audio-video-settings-container_audio-video-block'>
                <div className='settings-section audio-video-block_audio'>
                    <MicIcon className='section-icon' />
                    <h2 className='section-title'>Audio</h2>
                    <div className='settings-container'>
                        {['granted', 'idle'].includes(micState)
                            ? <>
                                <h4 className='section-settings-title'>Microphone</h4>
                                <div className='manage-device-controls'>
                                    <DeviceSelector
                                        value={{ value: currentMic?.device?.deviceId, label: currentMic?.device?.label }}
                                        onChange={onAudioInputChange}
                                        listitems={microphones?.map(m => m.device)}
                                    />
                                    <MicNoneIcon />
                                    <AudioLevelIndicator volume={volume} />
                                </div>
                            </>
                            : <h4 className='section-settings-title'>{getMicErrorMessage()}</h4>}
                        {['granted'].includes(micState)
                            ? speakers?.length > 1
                                ? <>
                                    <h4 className='section-settings-title'>Speakers</h4>
                                    <div className='manage-device-controls'>
                                        <DeviceSelector
                                            value={{ value: currentSpeaker?.device?.deviceId, label: currentSpeaker?.device?.label }}
                                            onChange={onAudioOutputChange}
                                            listitems={speakers?.map(s => s.device)}
                                        />
                                        <button onClick={play} className={`test-button${isAudioPlaying ? ' playing' : ''}`}>
                                            <VolumeUpOutlinedIcon />
                                            <div className='test-button-label' active-status='playing'>
                                                <span className='passive-state'>Test</span>
                                                <span className='active-state'>Playing</span>
                                            </div>
                                        </button>
                                        <audio ref={audioElementRef} title='local audio file'>
                                            <source src={SOUND_FX_PATHS.ringing} type='audio/mp3' />
                                            {AUDIO_ERROR_MESSAGE}
                                        </audio>
                                    </div>
                                </>
                                : !speakers?.length
                                    ? <h4 className='section-settings-title'>Speakers not found</h4>
                                    : <h4 className='section-settings-title'>Default speakers</h4>
                            : null}
                    </div>
                </div>
                <div className='settings-section audio-video-block_video'>
                    <VideocamIcon className='section-icon' />
                    <h2 className='section-title'>Video</h2>
                    <div className='settings-container'>
                        {['granted', 'idle'].includes(camState)
                            ? <>
                                <h4 className='section-settings-title'>Camera</h4>
                                <div className='manage-device-controls video-settings'>

                                    <div className='video-element-container'>
                                        <Spinner isLoading={isVideoStreamLoading} />
                                        <video ref={videoElementRef} muted playsInline autoPlay></video>
                                        {browserSupport.supportsVideoProcessing && <VideoFilterSettings isLoading={setIsVideoStreamLoading}/>}
                                    </div>
                                    <DeviceSelector
                                        value={{ value: currentCam?.device?.deviceId, label: currentCam?.device?.label }}
                                        listitems={cameras?.map(c => c.device)}
                                        onChange={onVideoInputChange}
                                    />
                                </div>
                            </>
                            : <h4 className='section-settings-title'>{getCamErrorMessage()}</h4>}
                        <div className='video-init-state'>
                            <SettingRow
                                isChecked={hostAppMuteVideoOnCallStart}
                                toggleFunc={() => onToggleMuteVideoOnCallStart(!hostAppMuteVideoOnCallStart)}
                                title={'Mute video on call start'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <AppDiagnostic />
        </div>
    )
}
