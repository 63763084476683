import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const useUserAppSettings = () => {
  const { user } = useSelector((state) => state.user);
  const [hostAppEventsShowOnlyActiveShop, setHostAppEventsShowOnlyActiveShop] = useState(true);

  useEffect(() => {
    if (user) {
      const ref = firebase.firestore()
        .collection('users')
        .doc(user.id)
        .collection('private')
        .doc('appSettings')
        .onSnapshot((doc) => {
          setHostAppEventsShowOnlyActiveShop(doc.data()?.hostAppEventsShowOnlyActiveShop ?? true);
        })
      return () => {
        ref()
      }
    }
  }, [user])

  const setAppSettingsField = (data) => {
    if (user) {
      firebase.firestore()
        .collection('users')
        .doc(user.id)
        .collection('private')
        .doc('appSettings')
        .set(data, {merge: true})
        .then(() => {
          firebase.analytics().logEvent(`setAppSettingsField: ${data}`);
        });
    }
  }

  const updateHostAppEventsShowOnlyActiveShop = (state) => {
    setAppSettingsField({
      hostAppEventsShowOnlyActiveShop: state
    })
  }

  return {
    hostAppEventsShowOnlyActiveShop,
    updateHostAppEventsShowOnlyActiveShop,
  }

}

export default useUserAppSettings;