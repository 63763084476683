import React from 'react';

import RoomItem from "../roomItem";
import ChatTime from "../chatTime";
import {getNumberOfUnreadMessages} from "../../../modules/chat/utils/rooms";

const ActiveChatsList = ({activeRooms, selectedRoom, user, openDialog}) => {
  if (!activeRooms || activeRooms.length === 0) {
    return null;
  }

  return (
    <>
      {activeRooms.map((item, index) => {
          const customer = item.participants[item.initiator.uuid];
          const numberOfUnreadMessages = getNumberOfUnreadMessages(item, user);
          const colorStyle = (selectedRoom && selectedRoom.id === item.id) ? 'gray' : 'white';

          return (
            <li key={index} className='menu-item-container'>
              <button className={colorStyle} onClick={() => openDialog(item)}>
                <div className='space'>
                  {(!!customer)
                    ? (!!customer.displayName)
                      ? <h4 className='customer-name'>{customer.displayName}</h4>
                      : <h4>{customer.email}</h4>
                    : null
                  }
                  {numberOfUnreadMessages > 0 &&
                  <div className='indicator'>{numberOfUnreadMessages}</div>
                  }
                </div>
                <div className='space'>
                  <RoomItem item={item} />
                  <ChatTime item={item} />
                </div>
                <hr />
              </button>
            </li>
          )
        }
      )}
    </>
  )
};

export default ActiveChatsList;
