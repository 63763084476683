import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { FilledButton } from '../../../../../components/buttons/buttons';
import './product-sidebar.scss';
import { useSessionCart, useSessionProducts } from '../../../../../hooks';
import { useActiveCallProvider } from '../../../../../components/ActiveCallProvider';
import { parseGID, parseIdString } from '../../../../../utils';
import existingVariants from '../../../../../utils/variant-map';
import { currencyFormatter } from '../../../../../utils/index';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import EmptyImage from '../../../../../components/emptyImage';
import { setPresentationMode, setSelectedImage, clearPresentationMode } from '../../../../../reducers/active-call-reducer';
import ProductPrice from "../../../../../components/productPrice";
import { ProductDetailsSubmitActions } from '../components/producd-details-submit-actions/producd-details-submit-actions';
import { InventoryQuantity } from '../../../../../components/inventoryQuantity/InventoryQuantity';
import {ArrowBackIos} from "@material-ui/icons";
import ProductDetailsSkeleton
  from '../../../../../components/skeletons/product-details-skeleton/product-details-skeleton';

const NO_VARIANT_TITLE = 'Default Title';

const ProductSidebar = ({
  product,
  updateProduct,
  isProductLoading,
  productLoadingError,
  selectedProductVariantId,
  selectedSnapshotKey,
  previousPurchaseData,
  clearSelectedProduct,
  clearPreviousPurchaseData,
  clientViewData,
}) => {
  const { selectedImage } = useSelector((state) => state.activeCall);
  const [currentImages, setCurrentImages] = useState([]);
  const [selectedProductVariant, setSelectedProductVariant] = useState(null);
  const [availableOptions, setAvailableOptions] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [emptyOptions, setEmptyOptions] = useState([]);
  const [isProductInShowroom, toggleIsProductInShowroom] = useState(null);
  const [isProductInCart, toggleIsProductInCart] = useState(null);
  const [fullSizeImageView, setFullSizeImageView] = useState({
    isOpen: false,
    images: null,
  });
  const [cleanProductId, setCleanProductId] = useState(null);
  const [variantNotFound, setVariantNotFound] = useState(false);
  const [variantNotInIventory, setVariantNotInInventory] = useState(null);
  const { activeCallId: contextId } = useActiveCallProvider();
  const { cartSnapshots, addToCart, removeCartProduct } = useSessionCart(contextId);
  const { sessionSnapshots, addToSession } = useSessionProducts(contextId);
  const [clientImageViewingState, setClientImageViewingSate] = useState({});
  const [allowedContainerHeight, setAllowedContainerHeight] = useState(null);
  const [isOverflowed, setIsOverflowed] = useState(false);

  const galleryRef = useRef(null);
  const imagesRefs = useRef([]);
  const mainImageRef = useRef(null);
  const productDescriptionRef = useRef(null);
  const productDescriptionContainerRef = useRef(null);

  const hasOnlyDefaultVariant = product?.hasOnlyDefaultVariant;
  const dispatch = useDispatch();
  const openPresentationView = () =>
    dispatch(setPresentationMode({
      isPresentationEnabled: true,
      product,
      selectedProductVariant,
      isProductInShowroom,
      currentImages,
      selectedImage,
      contextId,
      cartKey: selectedSnapshotKey,
      selectedOptions,
    }));

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      setAllowedContainerHeight(null);
    };
  }, [product]);

  useEffect(() => {
    if (product && !isProductLoading) {
      const containerHeight = productDescriptionContainerRef.current?.clientHeight;
      const contentHeight = productDescriptionRef?.current.clientHeight;
      setIsOverflowed(containerHeight < contentHeight)
    }
  }, [allowedContainerHeight, product, isProductLoading])


  const handleResize = () => {
    if (mainImageRef.current) {
      setAllowedContainerHeight(mainImageRef.current?.clientHeight);
    }
  }

  const closeFullSizedImageView = () => {
    setFullSizeImageView((state) => {
      return { ...state, isOpen: false };
    });
  };
  const removeFullSizedImage = () =>
    setFullSizeImageView({
      isOpen: false,
      images: null,
    });

  const updateSelectedOptions = (newOption, optionIndex) => {
    setSelectedOptions((existingOptions) => {
      const existingOptionsCopy = [...existingOptions];
      existingOptionsCopy[optionIndex] = newOption;
      return existingOptionsCopy;
    });
  };

  useEffect(() => {
    if (selectedImage) {
      const activeImageId = imagesRefs.current?.findIndex(i => i.id === selectedImage.id);
      if (activeImageId !== -1 && window.innerWidth >= 1025) {
        imagesRefs.current[activeImageId].ref.scrollIntoView({
          behavior: 'smooth',
          block: "end",
          inline: "nearest"
        });
      }
    }
  }, [selectedImage]);

  const setMainImage = (image, isForce) => {
    if ((image && !selectedImage) || (image && selectedImage && image.id !== selectedImage.id) || isForce) {
      dispatch(setSelectedImage({ selectedImage: image }));
    }
  }

  useEffect(
    () => () => {
      clearSelectedProduct();
      clearPreviousPurchaseData();
      dispatch(clearPresentationMode());
    },
    []
  );

  useEffect(() => {
    if (product) {
      setCleanProductId(parseIdString(product.productId));
      if (product.images && product.images.length > 0) {
        setCurrentImages(product.images);
        setMainImage(product.images[0]);
      }
      if (product.options) {
        let previousVariant;
        let dropDownOptions = [];
        if (selectedProductVariantId) {
          const isSelectedProductVariantExisted = product.variants.find(
            (variant) =>
              parseIdString(variant.id) === selectedProductVariantId
          );
          if (isSelectedProductVariantExisted) {
            previousVariant = existingVariants.getVariant(
              selectedProductVariantId
            );
            if (!previousVariant) {
              const selectedVariant = product.variants.find(
                (variant) =>
                  parseIdString(variant.id) === selectedProductVariantId
              );
              const normalizedValue = selectedVariant.selectedOptions.reduce(
                (reducedVariant, variantOption) => {
                  return {
                    ...reducedVariant,
                    [variantOption.name]: variantOption.value,
                  };
                },
                {}
              );
              const normalizedVariant = {
                ...selectedVariant,
                variantId: parseIdString(selectedVariant.id),
                normalizedValue,
              };
              previousVariant = normalizedVariant;
              existingVariants.setVariant(normalizedVariant);
            }
          }
        }
        product.options.forEach((option, optionIndex) => {
          const availableValues = option.values.map((value) => {
            return {
              label: value,
              value,
              category: option.name,
            };
          });
          if (previousVariant) {
            const relevantOption = previousVariant.selectedOptions.find(
              (selectedOption) => selectedOption.name === option.name
            );
            const formattedRelevantOptions = {
              label: relevantOption.value,
              value: relevantOption.value,
              category: relevantOption.name,
            };
            updateSelectedOptions(formattedRelevantOptions, optionIndex);
          } else if (option.values.length === 1) {
            updateSelectedOptions(availableValues[0], optionIndex);
          }

          dropDownOptions.push({
            placeholder: `Please select a ${option.name.toLowerCase()}`,
            selectValues: availableValues,
          });
        });
        setAvailableOptions(dropDownOptions);
      }
    }
    return () => {
      setSelectedOptions([]);
      setEmptyOptions([]);
      setAvailableOptions(null);
      setCleanProductId(null);
      removeFullSizedImage();
      setVariantNotFound(null);
      setVariantNotInInventory(null);
      setSelectedProductVariant(null);
      setCurrentImages([]);
      setMainImage(null, true);
    };
  }, [product, selectedProductVariantId]);

  useEffect(() => {
    if (product) {
      const productIDsInShowroom = sessionSnapshots.map(
        (snapshot) => snapshot.val().productId
      );
      toggleIsProductInShowroom(productIDsInShowroom.includes(cleanProductId));
    }
  }, [product, sessionSnapshots, cleanProductId]);

  useEffect(() => {
    if (product) {
      const snapshotsKeysInCart = cartSnapshots.map((snapshot) => snapshot.key);
      toggleIsProductInCart(snapshotsKeysInCart.includes(selectedSnapshotKey));
    }
  }, [product, cartSnapshots, selectedSnapshotKey]);

  useEffect(() => {
    if (product) {
      firebase.analytics().logEvent('view_item', {
        items: [
          {
            id: parseIdString(product.productId),
            name: product.title,
          },
        ],
      });
    }
  }, [product]);

  useEffect(() => {
    setVariantNotInInventory(false);
    if (availableOptions && selectedOptions.length === availableOptions.length) {
      const selectedVariant = product.variants.find(item => {
        const selectedVariant = item.selectedOptions.every(productOption => {
          const isIncludedOption = selectedOptions.some(selectedOption => productOption.name === selectedOption?.category && productOption.value === selectedOption?.value);
          return isIncludedOption
        })
        return selectedVariant;
      })
      setSelectedProductVariant(selectedVariant);
      if (selectedVariant && selectedVariant.image) {
        const index = product.images.findIndex(item => item.id === selectedVariant.image.id);
        let item;
        let arrayImages = [...product.images];
        if (index !== -1) {
          item = arrayImages.splice(index, 1);
        } else {
          item = [selectedVariant.image];
        }
        setCurrentImages([...item, ...arrayImages])
        setMainImage(selectedVariant.image)
      } else {
        setMainImage(product.images[0])
        setCurrentImages([...product.images])
      }
    }
  }, [selectedOptions, availableOptions])

  useEffect(() => {
    const isImageViewedByClients = (imageId) => {
      var views = [];
      Object.keys(clientViewData).forEach(clientUuid => {
        const clientProductView = clientViewData[clientUuid] && clientViewData[clientUuid].productView || {};
        if (clientProductView.imageId === imageId) {
          views.push(clientUuid);
        }
      })
      return views;
    }

    let update = {};
    currentImages.forEach(image => {
      update[image.id] = isImageViewedByClients(image.id);
    });
    setClientImageViewingSate(update);

  }, [currentImages, clientViewData])

  const getExistingItemFromCart = () => {
    let existingItem = null;
    Object.keys(cartSnapshots).forEach(key => {
      const selectedId = cartSnapshots[key].val().variantId;
      const builtProductId = selectedProductVariant ? parseGID(selectedProductVariant.id) : null;
      if (selectedId === builtProductId) {
        existingItem = { key: cartSnapshots[key].key, data: cartSnapshots[key].val() };
      }
    })
    return existingItem;
  }

  const findSelectedVariant = (
    selectedPropertyNames,
    normalizedSelectedOptions,
    defaultQuantity,
  ) => {
    const normalizedVariants = product.variants.map((variant) => {
      const normalizedValue = variant.selectedOptions.reduce(
        (reducedVariant, variantOption) => {
          return {
            ...reducedVariant,
            [variantOption.name]: variantOption.value,
          };
        },
        {}
      );
      return {
        ...variant,
        variantId: parseIdString(variant.id),
        normalizedValue,
      };
    });
    const selectedFullVariant = normalizedVariants.find((variant) => {
      let isEqual = true;
      selectedPropertyNames.forEach((propName) => {
        if (
          variant.normalizedValue[propName] !==
          normalizedSelectedOptions[propName]
        ) {
          isEqual = false;
          return;
        }
      });
      return isEqual;
    });
    if (!selectedFullVariant) {
      setVariantNotFound(true);
      return;
    }

    if (cartSnapshots) {
      const cartItems = cartSnapshots.map((snapshot) => ({ ...snapshot.val(), key: snapshot.key }));
      let cartItemQuantity = 0;
      if (cartItems && cartItems.length > 0) {
        const variantIndex = cartItems.findIndex(i => i && i.variantId === selectedProductVariantId);
        if (variantIndex !== -1) {
          cartItemQuantity = cartItems[variantIndex].quantity;
        }
      }

      if (cartItems && Object.keys(cartItems).length !== 0) {
        const existingItem = getExistingItemFromCart(cartItems);
        if (existingItem !== null) {
          if (((defaultQuantity + cartItemQuantity + existingItem.data.quantity) > existingItem.data.inventoryQuantity)
            && selectedProductVariant && selectedProductVariant.inventoryPolicy !== 'CONTINUE') {
            setVariantNotInInventory(true);
            return;
          }
        }
      }

      const targetVariantIndex = cartItems.findIndex(i => i.key === selectedSnapshotKey)
      if (targetVariantIndex !== -1 && selectedFullVariant) {
        if ((selectedFullVariant.inventoryQuantity <= 0
          || selectedFullVariant.inventoryQuantity < cartItems[targetVariantIndex].quantity)
          && selectedFullVariant.inventoryPolicy !== 'CONTINUE') {

          setVariantNotInInventory(true);
          return;
        }
      } else if (selectedFullVariant.inventoryQuantity <= 0 && selectedFullVariant.inventoryPolicy !== 'CONTINUE') {
        setVariantNotInInventory(true);
        return;
      }
    }

    setVariantNotFound(false);
    setVariantNotInInventory(false);
    return selectedFullVariant;
  };

  const getImagePerSelectedVariant = () => {
    const getDefaultImage = () => {
      return product.images.length > 0 ? product.images[0].url : null;
    }
    if (selectedProductVariant) {
      if (selectedProductVariant.image) {
        return selectedProductVariant.image.url
      } else {
        return getDefaultImage()
      }
    } else {
      return getDefaultImage()
    }
  }

  const generatVariantTitle = (variant) =>
    variant.title === NO_VARIANT_TITLE ? '' : variant.title;

  const addFullProductToCart = (e) => {
    e.preventDefault();
    let selectedPropertyNames = [];
    let normalizedSelectedOptions = {};
    const notSelectedOptions = [];
    if (availableOptions) {
      selectedOptions.forEach((option) => {
        selectedPropertyNames.push(option.category);
        normalizedSelectedOptions[option.category] = option.value;
      });
      product.options.forEach((option) => {
        if (!selectedPropertyNames.includes(option.name)) {
          notSelectedOptions.push(option);
        }
      });
    } else {
      const defaultOption = product.options[0];
      if (!defaultOption) {
        console.error('No default variant provided for product');
        return;
      }
      selectedPropertyNames.push(defaultOption.name);
      normalizedSelectedOptions[defaultOption.name] = defaultOption.values[0];
    }

    if (notSelectedOptions.length) {
      setEmptyOptions(
        notSelectedOptions.reduce((accumulatorArr, option) => {
          return [...accumulatorArr, option.name];
        }, [])
      );
      return;
    }
    setEmptyOptions([]);

    const selectedFullVariant = findSelectedVariant(
      selectedPropertyNames,
      normalizedSelectedOptions,
      1,
    );

    if (!selectedFullVariant) return;

    if (cartSnapshots) {
      const cartItems = cartSnapshots.map((snapshot) => ({ ...snapshot.val(), key: snapshot.key }));
      if (cartItems && cartItems.length > 0) {
        const variantIndex = cartItems.findIndex(i => i.variantId === selectedFullVariant.variantId);
        if (variantIndex !== -1) {
          const cartItem = cartItems[variantIndex];
          updateCurrentVariant(cartItem.key, cartItem.quantity + 1);
          return;
        }
      }
    }

    const normalizedProduct = {
      productId: cleanProductId,
      image: getImagePerSelectedVariant(),
      currencyCode: selectedFullVariant.currencyCode,
      currentPrice: selectedFullVariant.price,
      productTitle: product.title,
      productDescription: product.descriptionHtml,
      variantId: parseIdString(selectedFullVariant.id),
      variantTitle: generatVariantTitle(selectedFullVariant),
      inventoryPolicy: selectedFullVariant.inventoryPolicy,
      inventoryQuantity: selectedFullVariant.inventoryQuantity,
      quantity: 1,
    };
    addToCart(normalizedProduct);
    existingVariants.setVariant(selectedFullVariant);
  };

  const updateCurrentVariant = (cartItemKey = null, quantity = null) => {
    let selectedPropertyNames = [];
    let normalizedSelectedOptions = {};
    selectedOptions.forEach((option) => {
      selectedPropertyNames.push(option.category);
      normalizedSelectedOptions[option.category] = option.value;
    });
    const selectedFullVariant = findSelectedVariant(
      selectedPropertyNames,
      normalizedSelectedOptions,
      0,
    );
    if (!selectedFullVariant) return;


    if (cartSnapshots && Object.keys(cartSnapshots).length > 0 && cartItemKey === null) {
      const existingItem = getExistingItemFromCart();
      if (existingItem && existingItem.key !== selectedSnapshotKey) {
        let currentQuantity = 1;
        const cartItems = cartSnapshots.map((snapshot) => ({ ...snapshot.val() }));
        if (cartItems && cartItems.length > 0) {
          const variantIndex = cartItems.findIndex(i => i.variantId === selectedProductVariantId);
          if (variantIndex !== -1) {
            currentQuantity = cartItems[variantIndex].quantity;
          }
        }
        updateProduct(
          selectedFullVariant.id,
          generatVariantTitle(selectedFullVariant),
          selectedFullVariant.price,
          getImagePerSelectedVariant(),
          selectedFullVariant.inventoryPolicy,
          selectedFullVariant.inventoryQuantity,
          existingItem.data.quantity + currentQuantity,
          existingItem.key,
        );
        existingVariants.setVariant(selectedFullVariant);
        removeCartProduct(selectedSnapshotKey, product);
        return;
      }
    }

    updateProduct(
      selectedFullVariant.id,
      generatVariantTitle(selectedFullVariant),
      selectedFullVariant.price,
      getImagePerSelectedVariant(),
      selectedFullVariant.inventoryPolicy,
      selectedFullVariant.inventoryQuantity,
      quantity,
      cartItemKey,
    );
    existingVariants.setVariant(selectedFullVariant);
  }

  const updateVariant = () => {
    updateCurrentVariant();
  };

  const handleAddToShowroom = () => {
    clearPreviousPurchaseData();
    addToSession(product);
  };

  const showFullSizedImageView = () => {
    setFullSizeImageView({ images: currentImages, isOpen: true });
    return;
  };

  const renderImages = () => {
    if (!currentImages || !currentImages.length) {
      return <EmptyImage clickAction={openPresentationView} product={product} size='xl' />;
    }
    return (
      <div className='images-container'>
        <img
          className='main-image'
          src={selectedImage ? selectedImage.url : ''}
          alt={selectedImage ? selectedImage.altText : ''}
          onClick={openPresentationView}
          ref={mainImageRef}
          onLoad={() => handleResize()}
        />
        <div className='image-gallery-wrapper' ref={galleryRef}>
          <div className='image-gallery'>
            {currentImages.length > 1 &&
              currentImages.map((image, i) => (
                <div key={image.id} className={'image-thumbnail-container' + (clientImageViewingState[image.id] && clientImageViewingState[image.id].length > 0 ? ' highlighted' : '')}>
                  <img
                    ref={(ref) => imagesRefs.current?.push({ id: image.id, ref })}
                    src={image.url}
                    onClick={() => setMainImage(image)}
                    alt={image.altText}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  const handleDescriptionBlock = (imageHeight, isMobile) => {
    let descriptionHeight;
    if (imageHeight) {
      if (isMobile) {
        descriptionHeight = '150px'
      } else {
        descriptionHeight = `${Math.max(120, imageHeight)}px`
      }
    } else {
      if (isMobile) {
        descriptionHeight = '150px'
      } else {
        descriptionHeight = '150px'
      }
    }
    return descriptionHeight;
  }

  const renderErrorMessage = () => {
    return (
      <p className='error-text'>
        {emptyOptions.length
          ? `Please select ${emptyOptions.join(', ')}`
          : variantNotFound
            ? 'Can’t add/update product to cart - variant is missing'
            : variantNotInIventory
              ? 'Can’t add/update product to cart - out of stock'
              : ''}
      </p>
    );
  };

  const handleClick = (event) => {
    if (event.nativeEvent.target) {
      event.stopPropagation();
      event.preventDefault();
      openPresentationView();
    }
  }

  const updateBtnDisableStatus = () => {
    return selectedProductVariant && parseIdString(selectedProductVariant.id) === selectedProductVariantId;
  }

  const updateBtnVisibilityState = () => {
    return product && product.variants && product.variants.length > 1;
  }

  const soldOutBtnVisibilityStatus = () => {
    return selectedProductVariant && selectedProductVariant.inventoryQuantity <= 0 && selectedProductVariant.inventoryPolicy !== 'CONTINUE';
  }

  const productDetails = () => (
    <>
      <button className='customer-search-panel-back-button'>
        <ArrowBackIos
          onClick={clearSelectedProduct}
          style={{color: '#66676c'}}
        />
      </button>
      <div className='product-details-container'>
        {fullSizeImageView.isOpen && (
          <div className='full-size-image'>
            <div>
              <span onClick={closeFullSizedImageView}>&#215;</span>
            </div>
            {currentImages.length > 0 && (
              <Carousel showThumbs={false}>
                {currentImages.map((image) => (
                  <img src={image.fullSizedUrl} alt={image.altText} key={image.id} />
                ))}
              </Carousel>
            )}
          </div>
        )}
        {renderImages()}
        <div className='product-details'>
          <div style={{ height: handleDescriptionBlock(allowedContainerHeight) }}
               className='product-details-description-wrapper'>
            <p className='product-title'>
              <strong>{product.title}</strong>
            </p>
            <div ref={productDescriptionContainerRef}
              onClick={openPresentationView}
              className={`product-description-block${isOverflowed ? ' overflow-background' : ''}`}>
              <div ref={productDescriptionRef}
                   onClick={handleClick}
                   className='product-description'
                   dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} />
              {isOverflowed && <span className='more-button'>more</span>}
            </div>
            {previousPurchaseData ? (
              <div className='previous-purchase'>
                <h4>
                  {currencyFormatter(
                    parseFloat(previousPurchaseData.amount),
                    previousPurchaseData.currencyCode
                  )}
                </h4>
                <p>{previousPurchaseData.variantTitle}</p>
                <p>Purchased: {previousPurchaseData.processedAt}</p>
              </div>
            ) : (<ProductPrice product={product} selectedProductVariant={selectedProductVariant} />)
            }
            <InventoryQuantity
              inventory={selectedProductVariant ?
                selectedProductVariant.inventoryQuantity :
                product.totalInventory} />
          </div>
          {isProductInShowroom ? (
            <form className={`${availableOptions ? '' : 'no-select'}`}>
              {availableOptions && !hasOnlyDefaultVariant
                ? availableOptions.map((option, optionIndex) => {
                  return (
                    <Select
                      key={`${product.productId}${optionIndex}`}
                      onChange={(newSelection) =>
                        updateSelectedOptions(newSelection, optionIndex)
                      }
                      placeholder={option.placeholder}
                      menuPlacement='auto'
                      value={selectedOptions[optionIndex] || null}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          boxSizing: 'border-box',
                          border: '1px solid #CCC3BD',
                          borderRadius: '8px',
                        }),
                        option: (styles, { isFocused, isSelected }) => {
                          return {
                            ...styles,
                            backgroundColor: isSelected
                              ? '#DCDCDC'
                              : isFocused
                                ? 'rgba(249,139,0,0.04)'
                                : null,
                          };
                        },
                      }}
                      options={option.selectValues}
                    />
                  );
                })
                : null}
              <ProductDetailsSubmitActions
                isSoldOutBtnVisible={soldOutBtnVisibilityStatus()}
                isUpdateBtnVisible={updateBtnVisibilityState()}
                isUpdateBtnDisabled={updateBtnDisableStatus()}
                updateVariant={updateVariant}
                addFullProductToCart={addFullProductToCart}
                isProductInCart={isProductInCart}
              />
              {renderErrorMessage()}
            </form>
          ) : (
            <FilledButton
              style={{
                height: '48px',
                width: '100%',
              }}
              onClick={handleAddToShowroom}>
              + showroom
            </FilledButton>
          )}
        </div>
      </div>
    </>
  )

  return (
    <div className='product-sidebar'>
      {isProductLoading ? (
        <ProductDetailsSkeleton />
      ) : product ? productDetails() : (
        <div className='product-details-error-container'>
          <p>There was an error loading the product info</p>
          {productLoadingError && <p>{productLoadingError.message} {productLoadingError?.reason}</p>}
        </div>
      )}
    </div>
  );
};

export default ProductSidebar;
