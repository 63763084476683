export const HEADER_NOTIFICATION_TYPES = {
  //UNAVAILABLE: 'unavailable',
  INCOMING_CALL: 'incoming-call',
  REJOINING_CALL: 'rejoining-call',
  SHOP_INACTIVE: 'shop-inactive',
  AV_PERMISSIONS_DENIED: 'av-permissions-denied',
  AV_PERMISSIONS_LOCKED: 'av-permissions-locked',
};

export const CALL_TYPES = {
  HISTORY: 'history',
  SCHEDULED: 'scheduled',
  BLOCKED_EVENT: 'blocked'
};

export const CALL_STATUS = {
  COMPLETED: 'completed',
  ERROR: 'error',
  ASSIGNED: 'assigned'
};
export const CALL_DETAILS_TYPE = {
  INFO: 'info',
  CALL_SUMMARY: 'callSummary',
  FORM_RESPONSES: 'formResponses',
  CHECKOUTS: 'checkouts',
  ABANDONED: 'abandoned',
  ORDER: 'order',
  DRAFT_ORDER: 'draft_order',
  BLOCKED_EVENT: 'blocked_event',
  SHOWROOM: 'showroom',
  CART: 'CART',
  CALL_PREPARATION: 'call_preparation'
}

export const SOUND_FX_PATHS = {
  ringing: '/sounds/doorbell.mp3',
};

export const ScheduleStatus = {
  pending: 'pending',
  assigned: 'assigned',
  completed: 'completed',
  deleted: 'deleted',
}


export const CART_DISABLE_REASON = {
  NO_TABS: 'no_tabs',
  IOS_15_1: 'ios_15_1',
}

export const TYPING_TIME = 1000;
export const CUSOMER_MAIN_LETTER = 'C';
export const SYSTEM_SENDER_ID = 'system';
export const VIDEO_DURATION_FOR_POSTCALL_FEEDBACK = 2 * 60 * 1000;

export const FEEDBACK_TYPE = {
  NPS: 'nps',
  CSAT: 'csat',
  SIMPLE: 'simple',
  NONE: 'none',
}

export const TWILIO_AUTO_ROOM_COMPLETE_TO_HOURS = 2;

export const MAX_SHOWROOM_PRODUCTS = 20;
export const MAX_CART_PRODUCTS = 20;

export const PROCESSOR_TYPES = {
  BLUR: 'blur',
  VIRTUAL: 'virtual'
}

