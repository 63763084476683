import React, {useMemo} from "react";
import {CALL_DETAILS_TYPE, CALL_STATUS, CALL_TYPES} from "../utils/consts";
import ClientDetailsTab from "../pages/home-page/call-details-side-bar/client-details-tab/client-details-tab";
import CallFormResponses from "../pages/home-page/call-details-side-bar/call-form-responses/call-form-responses";
import CallSummaryTab from "../pages/home-page/call-details-side-bar/call-summary-tab/call-summary-tab";
import MultipleOrdersTab from "../pages/home-page/call-details-side-bar/multiple-orders-tab/multiple-orders-tab";
import CartDetailsTab from "../pages/home-page/call-details-side-bar/cart-details-tab/cart-details-tab";
import {currencyFormatter} from "../utils";
import {normalizeDraftOrderProducts} from "../utils/draftOrder";
import CallShowroomTab from "../pages/home-page/call-details-side-bar/call-showroom-tab/call-showroom-tab";
import {
  useCallDetailsProvider
} from "../pages/home-page/call-details-side-bar/call-details-provider/call-details-provider";
import {useNotificationProvider} from "../components/notifications/NotificationProvider";
import CallPreparationTab from '../pages/home-page/call-details-side-bar/call-preparation-tab/call-preparation-tab';

const useCallDetailsTabs = (feedbackType) => {
  const {
    callDetails,
    checkoutData,
    orderData,
    draftOrder,
    abandonedCartItems,
    cart,
    selectedTab,
    client,
    isClientHistory
  } = useCallDetailsProvider()

  const {
    abandonedCart,
    checkouts,
    orders,
    draftOrders,
    shopId,
    carts,
    type,
    clientForm,
    status,
  } = callDetails;

  const {showToast} = useNotificationProvider();


  const openOrderDetails = (orderId) => {
    const formatOrderId = orderId.substring(orderId.indexOf('-') + 1);
    const linkForOrder = `https://${shopId}/admin/orders/${formatOrderId}`;
    window.open(linkForOrder, '_blank')
  }

  const copyToClipboard = () => {
    showToast({
      description: 'Link copied to clipboard',
      type: 'info',
    });
    navigator.clipboard.writeText(tabs.filter(({key}) => key === selectedTab)[0].url)
  }



  const tabs = useMemo(() => {
    const tabs = []
    if (!isClientHistory) {
      tabs.push({
        key: CALL_DETAILS_TYPE.INFO,
        label: 'Info',
        component: () => (
          <ClientDetailsTab
            visible={selectedTab === CALL_DETAILS_TYPE.INFO}
          />
        )
      })
    }
    if (type === CALL_TYPES.SCHEDULED) {
      if (clientForm) {
        tabs.push({
          key: CALL_DETAILS_TYPE.FORM_RESPONSES,
          label: 'Form responses',
          component: () => (
            <CallFormResponses
              visible={selectedTab === CALL_DETAILS_TYPE.FORM_RESPONSES}
              clientForm={clientForm}
            />
          )
        })
      }
      tabs.push({
        key: CALL_DETAILS_TYPE.CALL_PREPARATION,
        label: 'Showroom',
        component: () => (
          <CallPreparationTab
            visible={selectedTab === CALL_DETAILS_TYPE.CALL_PREPARATION}
          />
        )
      })
    } else {
      tabs.push({
        key: CALL_DETAILS_TYPE.CALL_SUMMARY,
        label: 'Call summary',
        component: () => (
          <CallSummaryTab
            visible={selectedTab === CALL_DETAILS_TYPE.CALL_SUMMARY}
            feedbackType={feedbackType}
          />
        )
      })
    }
    if (orders && Object.keys(orders).length) {
      if (Object.keys(orders).length > 1) {
        tabs.push({
          key: CALL_DETAILS_TYPE.ORDER,
          label: 'Order',
          component: () => (
            <MultipleOrdersTab
              orderData={orderData}
              openOrderDetails={openOrderDetails}
              visible={selectedTab === CALL_DETAILS_TYPE.ORDER}
            />
          )
        })
      } else {
        const order = orderData?.[0]
        tabs.push({
          key: CALL_DETAILS_TYPE.ORDER,
          label: 'Order',
          products: order?.products?.length,
          component: () => (
            <CartDetailsTab
              orderData={order}
              type={CALL_DETAILS_TYPE.ORDER}
              visible={selectedTab === CALL_DETAILS_TYPE.ORDER}
              products={order?.products}
              openOrderDetails={openOrderDetails}
              total={currencyFormatter(order?.totalPrice, order?.currencyCode)}
            />
          )
        })
      }
      return tabs
    }
    if (draftOrders && Object.keys(draftOrders).length) {
      tabs.push({
        key: CALL_DETAILS_TYPE.DRAFT_ORDER,
        label: 'Draft order',
        url: draftOrder?.invoiceUrl,
        products: normalizeDraftOrderProducts(draftOrder)?.length,
        component: () => (
          <CartDetailsTab
            copyToClipboard={copyToClipboard}
            type={CALL_DETAILS_TYPE.DRAFT_ORDER}
            visible={selectedTab === CALL_DETAILS_TYPE.DRAFT_ORDER}
            products={normalizeDraftOrderProducts(draftOrder)}
            total={currencyFormatter(draftOrder?.totalPrice, draftOrder?.currencyCode)}
            draftOrder={draftOrder}
          />
        )
      })
    }
    if (cart && cart.isAbandonded && Object.keys(draftOrders ?? {}).length === 0) {
      tabs.push({
        key: CALL_DETAILS_TYPE.CART,
        label: 'Cart',
        products: cart?.products?.length,
        component: () => (
          <CallShowroomTab
            cart={cart}
            copyLabel={'Copy cart recovery link'}
            visible={selectedTab === CALL_DETAILS_TYPE.CART}
          />
        )
      })
    }
    //todo: old abandoned cart remove in future
    if (!!abandonedCart && Object.keys(draftOrders ?? {}).length === 0) {
      tabs.push({
        label: 'Cart',
        key: CALL_DETAILS_TYPE.ABANDONED,
        url: `${abandonedCartItems?.cartRecoveryUrl}${abandonedCartItems?.cartDiscount ? `&discount=${abandonedCartItems?.cartDiscount?.code}` : ''}`,
        products: abandonedCartItems?.products?.length,
        component: () => (
          <CartDetailsTab
            type={CALL_DETAILS_TYPE.ABANDONED}
            copyToClipboard={copyToClipboard}
            products={abandonedCartItems?.products}
            visible={selectedTab === CALL_DETAILS_TYPE.ABANDONED}
            total={currencyFormatter(abandonedCartItems?.cartTotal, abandonedCartItems?.currencyCode)}
            cartDiscount={abandonedCartItems?.cartDiscount}
          />
        )
      })
    }
    if (status !== CALL_STATUS.ERROR
      && type !== CALL_TYPES.SCHEDULED
      && (!!carts && Object.keys(carts ?? {}).length > 0)
      && Object.keys(draftOrders ?? {}).length === 0
      && !tabs.some(({key}) => key === CALL_DETAILS_TYPE.CART)
    ) {
      tabs.push({
        key: CALL_DETAILS_TYPE.CART,
        label: 'Cart',
        products: cart?.products?.length,
        component: () => (
          <CallShowroomTab
            cart={cart}
            copyLabel={'Copy cart link'}
            visible={selectedTab === CALL_DETAILS_TYPE.CART}
          />
        )
      })
    }
    if (checkouts && Object.keys(checkouts).length) {
      tabs.push({
        key: CALL_DETAILS_TYPE.CHECKOUTS,
        label: 'Checkout',
        products: checkoutData?.products?.length,
        url: checkoutData?.shopify?.abandonedCheckoutUrl,
        component: () => (
          <CartDetailsTab
            copyToClipboard={copyToClipboard}
            type={CALL_DETAILS_TYPE.CHECKOUTS}
            visible={selectedTab === CALL_DETAILS_TYPE.CHECKOUTS}
            products={checkoutData?.products}
            total={currencyFormatter(checkoutData?.totalPrice, checkoutData?.currencyCode)}
          />
        )
      })
    }
    return tabs
  }, [
    abandonedCart,
    checkouts,
    orders,
    draftOrders,
    carts,
    type,
    clientForm,
    status,
    cart,
    checkoutData,
    orderData,
    draftOrder,
    abandonedCartItems,
    selectedTab,
    client,
    isClientHistory
  ])

  return ({
    tabs
  })
}

export default useCallDetailsTabs;
