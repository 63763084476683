import * as React from "react"

const Send = (props) => (
  <svg
    className="MuiSvgIcon-root"
    viewBox="0 0 24 24"
    aria-hidden="true"
    style={props?.style}
    {...props}
  >
    <path d="M2.01 21 23 12 2.01 3 2 10l15 2-15 2z" />
  </svg>
)

export default Send
