import React from 'react';
import EventNoteIcon from '@material-ui/icons/EventNote';
import '../home-page.scss'

const EmptyDayPage = ({ today, date }) => {
  let message = 'No data available for this day';
  if (today.isSame(date, 'day')) {
    message = (
      <h1>
        No activity today
        <br /> Recent calls and scheduled appointments will be displayed here
      </h1>
    );
  } else if (today < date) {
    message = (
      <h1>
        No scheduled appointments for this day
        <br /> Schedule a new appointment by inviting a client
      </h1>
    );
  } else {
    message = <h1>No calls completed or missed on this day</h1>;
  }

  return (
    <div className='empty-page'>
      <div className='empty-content'>
        <EventNoteIcon fontSize='large' />
        {message}
      </div>
    </div>
  );
}

export default EmptyDayPage;
