import React from 'react';
import './loading-card.scss';

const LoadingCard = (styles) => {
  return (
    <div
      style={{...styles}}
      className={`loading-card`}
    />
  )
}

export default LoadingCard;