import React, {useEffect, useMemo, useRef, useState} from 'react';
import './DraftOrderModal.scss'
import {FilledButton, OutlinedButton} from "../../buttons/buttons";
import ProductSection from "./components/product-section/ProductSection";
import useCaazamREST from "../../../hooks/useCaazamREST";
import useDraftOrder from "../../../hooks/useDraftOrder";
import PaymentSection from "./components/payment-section/PaymentSection";
import {DRAFT_ORDER_STATUS} from "../../../constants/const";
import {
  draftOrderProducts,
  normalizedShopifyProduct
} from "../../../utils/draftOrder";
import {useActiveCallProvider} from "../../ActiveCallProvider";
import Confirm from "../confirm";
import {SHIPPING_TYPE} from "./components/order-shipping/OrderShipping";
import {useDraftOrderInvoiceProvider} from "../invoice-modal/InvoiceModal";
import SearchInputDropdownField from "../../productComponents/search-input-dropdown-field/search-input-dropdown-field";
import {Close} from "@material-ui/icons";
import { useSelector } from 'react-redux';

const DraftOrderModal = ({visibility, onClosePress, customer, initialProducts, initialDraftOrderId, callData}) => {
  const [isLoadDraftOrder, setLoadDraftOrder] = useState(false)
  const [isDeletingDraftOrder, setDeletingDraftOrder] = useState(false)
  const [notes, setNotes] = useState('');
  const [products, setProducts] = useState([])
  const [cartProducts, setCartProducts] = useState([])
  const [cartSynced, setCartSynced] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [animationAction, setAnimationAction] = useState(null)
  const [styleType, setStyleType] = useState('')
  const [isDeleteOrderEvent, setDeleteOrderEvent] = useState(false)
  const [isChangedFromShopify, setIsChangedFromShopify] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const paymentSectionRef = useRef()
  const productSectionRef = useRef()

  const [localDraftOrderObj, setLocalDraftOrderObj] = useState(null)
  const prevLocalDraftOrder = useRef(localDraftOrderObj)
  const [isLocalDraftOrderChanged, setLocalDraftOrderChanged] = useState(false)

  const { sendDraftOrder, deleteDraftOrder, updateDraftOrder } = useCaazamREST();
  const [draftOrderId, setDraftOrderId] = useState(initialDraftOrderId)
  const {draftOrder} = useDraftOrder(callData.shopId, draftOrderId, isDeleteOrderEvent)
  const [callId, setCallId] = useState(null)

  const {showInvoiceModal} = useDraftOrderInvoiceProvider();
  const { activeShopId } = useSelector((state) => state.shops);

  const {
    activeCallData,
  } = useActiveCallProvider();

  const removeButtonIsVisible = useMemo(() => (
    draftOrder && draftOrder.status !== DRAFT_ORDER_STATUS.COMPLETED
  ), [draftOrder])

  const isDraftOrderCompleted = useMemo(() => (
    draftOrder && draftOrder.status === DRAFT_ORDER_STATUS.COMPLETED
  ), [draftOrder])

  useEffect(() => {
    setDraftOrderId(initialDraftOrderId)
  }, [initialDraftOrderId, visibility])

  useEffect(() => {
    setCallId(callData?.id)
    return () => {
      setDraftOrderId(null)
    }
  }, [callData?.id, visibility])

  useEffect(() => {
    if (initialProducts) {
      onUpdateProducts(initialProducts, true)
    }
  }, [initialProducts, visibility])

  //sync with shopify
  useEffect(() => {
    if (draftOrder) {
      const shopifyProducts = draftOrder.lineItems.map(product => normalizedShopifyProduct(product, draftOrder.currencyCode))
      onUpdateProducts(shopifyProducts, true)
      if (draftOrder.appliedDiscount) {
        const {value, description, valueType} = draftOrder.appliedDiscount;
        paymentSectionRef.current.onUpdateDiscount({
          discountType: valueType,
          discountValue: value
        }, description)
      } else {
        paymentSectionRef.current.onRemoveDiscount()
      }
      if (draftOrder.shippingLine) {
        const {title, discountedPriceSet} = draftOrder.shippingLine;
        const price = Number(discountedPriceSet.shopMoney.amount)
        paymentSectionRef.current.onUpdateShipping({
          shippingType: price === 0 ? SHIPPING_TYPE.FREE : SHIPPING_TYPE.CUSTOM,
          rateName: title,
          price
        })
      } else {
        paymentSectionRef.current.onRemoveShipping()
      }
      if (draftOrder.note2) {
        onUpdateNotes(draftOrder.note2, true)
      } else {
        onUpdateNotes('', true)
      }
      if (draftOrder.totalTax) {
        paymentSectionRef.current.onUpdateTotalTax(draftOrder.totalTax)
      } else {
        paymentSectionRef.current.onUpdateTotalTax('0.00')
      }
      setIsChangedFromShopify(true)
    }
  }, [draftOrder, visibility])

  //to show green or sherbert border when checkout
  useEffect(() => {
    if (!activeCallData) {
      return
    }
    if (activeCallData.totalOrderAmount && activeCallData.totalOrderAmount > 0 ||
      activeCallData.checkouts && Object.keys(activeCallData.checkouts).length > 0
    ) {
      setStyleType('margin');
    } else {
      setStyleType('');
    }
  }, [activeCallData?.checkouts, activeCallData?.totalOrderAmount]);

  //to hide draft order modal if cart was checkouted
  useEffect(() => {
    if (Object.keys(callData.checkouts ?? {}).length > 0 && products.length === 0
      && cartProducts.length === 0 &&  !draftOrder && !draftOrderId) {
      onClosePress()
    }
  }, [callData, products.length, cartProducts.length])

  useEffect(() => {
    if (visibility) {
      setAnimationAction('open')
    } else if (!visibility && animationAction) {
      setAnimationAction('close')
      clearState()
    }
  }, [visibility])

  //check for draft order changes
  useEffect(() => {
    if (isChangedFromShopify) {
      setLocalDraftOrderChanged(false)
      setErrorMessage(null)
      prevLocalDraftOrder.current = localDraftOrderObj
      return
    }
    setLocalDraftOrderChanged(
      JSON.stringify(localDraftOrderObj) !== JSON.stringify(prevLocalDraftOrder.current)
    )
    setErrorMessage(null)
  }, [localDraftOrderObj])

  useEffect(() => {
    setLocalDraftOrderObj(prev => ({
      ...prev,
      callId,
      clientId: customer?.customerId,
    }))
  }, [customer, callId])

  const onUpdateNotes = (note, isShopify = false) => {
    setIsChangedFromShopify(isShopify)
    setNotes(note)
    setLocalDraftOrderObj(prev => ({
      ...prev,
      note,
    }))
  }

  const onUpdateProducts = (updateProducts, isFromShopify = false) => {
    setProducts(updateProducts)
    setIsChangedFromShopify(isFromShopify)
    setLocalDraftOrderObj(prev => ({
      ...prev,
      lineItems: draftOrderProducts(updateProducts),
    }))
  }

  const onDiscountChanged = (discount, discountReason) => {
    if (discount?.discountValue) {
      setLocalDraftOrderObj(prev => ({
        ...prev,
        discount: {
          valueType: discount.discountType,
          value: discount?.discountValue ?? 0,
          description: discountReason,
        }
      }))
    } else {
      setLocalDraftOrderObj(prev => ({
        ...prev,
        discount: null
      }))
    }
  }

  const onShippingChanged = (shipping) => {
    if (shipping) {
      setLocalDraftOrderObj(prev => ({
        ...prev,
        shipping: {
          title: shipping.rateName,
          amount: shipping.price ?? 0
        }
      }))
    } else {
      setLocalDraftOrderObj(prev => ({
        ...prev,
        shipping: null
      }))
    }
  }

  const clearState = () => {
    onUpdateProducts([])
    setNotes('')
    paymentSectionRef.current.clearState()
    setCartSynced(false)
    setStyleType('')
    setDeleteOrderEvent(false)
    setIsChangedFromShopify(false)
    setShowConfirmDialog(false)
    setErrorMessage(null)
    setLocalDraftOrderObj(null)
    setLocalDraftOrderChanged(false)
    setDraftOrderId(null)
    setCallId(null)
  }

  const handleNotes = (e) => {
    onUpdateNotes(e.target.value)
  };

  const onCreateDraftOrder = async () => {
    try {
      setDeleteOrderEvent(false)
      setCartSynced(false)
      setLoadDraftOrder(true)
      if (draftOrder) {
        await updateDraftOrder(callData.shopId, localDraftOrderObj, draftOrderId)
      } else {
        let draftOrderData = localDraftOrderObj
        //protects against errors when creating a DO if we support multiple shops for customer calls
        if (callData.shopId !== activeShopId) {
          delete draftOrderData.clientId
        }
        const {draftOrderId} = await sendDraftOrder(callData.shopId, draftOrderData)
        setDraftOrderId(draftOrderId)
      }
      prevLocalDraftOrder.current = localDraftOrderObj
      setLocalDraftOrderChanged(false)
    } catch (e) {
      handleError(e)
    } finally {
      setLoadDraftOrder(false)
    }
  }

  const onRemoveDraftOrder = async () => {
    try {
      setShowConfirmDialog(false)
      setDeletingDraftOrder(true)
      await deleteDraftOrder(callData.shopId, draftOrderId)
      setDraftOrderId(null)
      setDeleteOrderEvent(true)
      clearState()
    } catch (e) {
      setCartSynced(false)
      handleError(e)
    } finally {
      setDeletingDraftOrder(false)
    }
  }

  const handleError = (error) => {
    if (error.reason) {
      setErrorMessage(error.reason)
      return;
    }
    if (error.message) {
      setErrorMessage(error.message)
      return;
    }
    setErrorMessage('Sorry, something went wrong')
  }

  const onShowInvoiceModal = () => {
    showInvoiceModal({
      customer,
      draftOrderId,
      callData: activeCallData ?? callData,
    })
  }

  const onDiscountModalChangeVisibility = () => {
    productSectionRef.current.onHideProductModals()
  }

  const onOpenProductModal = () => {
    paymentSectionRef.current.onHideDiscountModals()
  }

  const draftOrderHeader = () => (
    <div className='draft-order-header'>
      <h3>{draftOrder ? `Draft order ${draftOrder.name}` : 'Create draft order'}</h3>
      {isDraftOrderCompleted ? (
        <div className='draft-order-completed-tag'>
          <p className='draft-order-completed-title'>Completed</p>
        </div>
      ) : <div />}
      <button onClick={onClosePress}>
        <Close/>
      </button>
    </div>
  )

  const noteSection = () => (
    <div className='draft-order-notes'>
      <h3>Notes</h3>
      <SearchInputDropdownField
        placeholder='Notes'
        onChange={handleNotes}
        showDropdown={false}
        disabled={isDraftOrderCompleted}
        value={notes}
      />
    </div>
  )

  const draftOrderFooter = () => (
    <div className='draft-order-footer'>
      <p className='error-message'>{errorMessage}</p>
      <div className={`draft-order-action-buttons${removeButtonIsVisible ? ' with-remove-button' : ''}${errorMessage ? ' with-message' : ''}`}>
        {removeButtonIsVisible && (
          <OutlinedButton
            className={'button'}
            isLoading={isDeletingDraftOrder}
            style={{
              height: '44px',
              width: '100px',
              justifySelf: 'center',
              alignSelf: 'flex-end',
              textTransform: 'capitalize',
              fontSize: '14px',
            }}
            bordercolor={'var(--error-color)'}
            onClick={() => setShowConfirmDialog(true)}
          >
            Delete
          </OutlinedButton>
        )}
        {draftOrder && draftOrder?.status !== DRAFT_ORDER_STATUS.COMPLETED && !isLocalDraftOrderChanged && (
          <OutlinedButton
            style={{
              height: '44px',
              width: '150px',
              justifySelf: 'flex-end'
            }}
            onClick={onShowInvoiceModal}
          >
            Send Invoice
          </OutlinedButton>
        )}
        {(draftOrder ? isLocalDraftOrderChanged : true) && (
          <FilledButton
            disabled={products.length === 0}
            isLoading={draftOrder ? isLoadDraftOrder : isLoadDraftOrder && !cartSynced}
            className='button'
            style={{
              height: '44px',
              backgroundColor: `var(--main-color)`,
              width: '150px',
              justifySelf: 'flex-end'
            }}
            onClick={onCreateDraftOrder}
          >
            {draftOrder ? 'Save' : 'Create'}
          </FilledButton>
        )}
      </div>
    </div>
  )

  return (
    <div className={`draft-order-modal ${animationAction} ${styleType} ${activeCallData ? 'active-call' : ''}`}>
      {draftOrderHeader()}
      <div className='draft-order-container' id={'draft-order-container'}>
        <ProductSection
          ref={productSectionRef}
          products={products}
          onUpdateProducts={onUpdateProducts}
          cartProducts={cartProducts}
          onUpdateCartProducts={setCartProducts}
          draftOrderStatus={draftOrder?.status}
          cartSynced={cartSynced}
          activeCallId={callId}
          onOpenProductModal={onOpenProductModal}
          shopId={callData.shopId}
        />
        <PaymentSection
          ref={paymentSectionRef}
          products={products}
          onDiscountChanged={onDiscountChanged}
          onShippingChanged={onShippingChanged}
          isDraftOrderCompleted={isDraftOrderCompleted}
          setIsChangedFromShopify={setIsChangedFromShopify}
          onDiscountModalChangeVisibility={onDiscountModalChangeVisibility}
          shopId={callData.shopId}
        />
        {noteSection()}
      </div>
      {draftOrderFooter()}
      {showConfirmDialog && (
        <Confirm
          content='Are you sure you want to delete this draft order?'
          okFunc={onRemoveDraftOrder}
          cancelFunc={() => setShowConfirmDialog(false)}
          closeFunc={() => setShowConfirmDialog(false)}
          modalClass='warning'
        />
      )}
    </div>
  )
}

export default DraftOrderModal;
