import React from 'react';
import './remove-preset-modal.scss'
import Modal from '../modal';
import { useShowroomPresetProvider } from '../../../providers/preset-provider/preset-provider';
import { FilledButton } from '../../buttons/buttons';

const RemovePresetModal = () => {
  const {onHideRemovePresetModal, editedPreset, onRemovePreset, showroomPresetLoading} = useShowroomPresetProvider();

  const onRemove = async () => {
    await onRemovePreset(editedPreset?.id)
    onHideRemovePresetModal()
  }

  return (
    <Modal modalClass='remove-preset-modal' closeFunc={onHideRemovePresetModal}>
      <div className='remove-preset-wrapper'>
        <p>{`Are you sure you want to delete showroom ${editedPreset?.name}?`}</p>
        <FilledButton
          isLoading={showroomPresetLoading}
          className='button'
          style={{width: '45%', height: '46px', alignSelf: 'center'}}
          onClick={onRemove}
        >
          {'Delete'}
        </FilledButton>
      </div>
    </Modal>
    )
}

export default RemovePresetModal;