import React, {useEffect, useRef} from 'react';
import './collapsible.scss';

const Collapsible = ({children, header, visible, toggle = () => {}}) => {
  return (
    <div className={'collapsible-component'} onClick={toggle}>
      {header}
      <div
        className={`${visible ? 'content-show' : 'content-parent'}`}
      >
        <div className='content'>{children}</div>
      </div>
    </div>
  )
}

export default Collapsible;