import React from 'react';
import { HostAvailabilityDot } from '../AvailableDot/AvailableDot';
import './index.scss';

export const HostAvatar = ({src, hostInitials, isAvailable}) => {
    return (
        <div className={'host-avatar-container-wrapper'}>
            <div className={'host-avatar-container'}>
                {src ?
                    <img className='host-avatar' src={src} /> :
                    <span className={`host-avatar${src === false ? ' default' : ''}`}>{hostInitials}</span>}
			</div>
            <div className='host-vailability-dot-wrapper'>
                <HostAvailabilityDot isAvailable={isAvailable} />
            </div>
        </div>
    )
}
