import React, {useState} from 'react';
import './multiple-orders-tab.scss';
import CartDetailsTab from "../cart-details-tab/cart-details-tab";
import {CALL_DETAILS_TYPE} from "../../../../utils/consts";
import {currencyFormatter} from "../../../../utils";
import Collapsible from "../../../../components/collapsable/collapsible";
import CallOrderHeader from "../call-order-header/call-order-header";

const MultipleOrdersTab = ({visible, orderData, openOrderDetails}) => {
  const [selectedOrderId, setSelectedOrderId] = useState();

  const onToggle = (orderId) => {
    setSelectedOrderId(selectedOrderId => selectedOrderId !== orderId ? orderId : null)
  }

  return (
    <div className={`multiple-orders-tab${visible ? ' visible' : ''}`}>
      <ul>
        {orderData?.map((order) => (
          <li>
            <Collapsible
              toggle={() => onToggle(order.id)}
              visible={selectedOrderId === order.id}
              header={(
                <CallOrderHeader order={order}/>
              )}
            >
              <CartDetailsTab
                isMultipleOrder={true}
                orderData={order}
                type={CALL_DETAILS_TYPE.ORDER}
                visible={true}
                products={order.products}
                openOrderDetails={() => openOrderDetails(order.id)}
                total={currencyFormatter(order.totalPrice, order.currencyCode)}
              />
            </Collapsible>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default MultipleOrdersTab;