import {useSelector} from "react-redux";
import {useEffect, useMemo, useState} from "react";
import {useHostSettingsProvider} from "../components/hostSettingsProvider/host-settings-provider";

const useHostCalls = (ref, deps) => {
  const {onlyActiveShopEvents, onListenChangeShop} = useHostSettingsProvider()
  const {activeShopId} = useSelector((state) => state.shops);
  const {availableShops} = useSelector((state) => state.shops);
  const hostShops = useMemo(() => (
    availableShops ? Object.keys(availableShops) : []
  ), [availableShops])

  const [allCalls, setAllCalls] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [shopCalls, setShopCalls] = useState({})

  const firestoreRef = (shopId) => (
    ref?.(shopId)?.onSnapshot(
      (snapshot) => {
        const callData = snapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id
        }))
        setShopCalls(prev => ({
          ...prev,
          [shopId]: callData
        }))
      },
      (error) => {
        console.error('useHostCalls', error)
      }
    )
  )

  useEffect(() => {
    if (onlyActiveShopEvents || hostShops.length === 1) {
      clearState() //to remove prev daily calls
      const ref = firestoreRef(activeShopId)
      return () => {
        ref && ref()
      }
    } else if (hostShops.length > 1) {
      clearState() //to remove prev daily calls
      const refs = hostShops.map(firestoreRef)
      return () => {
        refs && refs.forEach((ref) => {
          ref()
        })
      }
    }
  }, [...deps, hostShops, onlyActiveShopEvents, onListenChangeShop])

  useEffect(() => {
    //this is condition for the data not to flicker
    if (shopCalls && onlyActiveShopEvents
      ? Object.keys(shopCalls).length === 1
      : Object.keys(shopCalls).length === hostShops.length
    ) {
      setAllCalls(Object
        .values(shopCalls)
        .flatMap(call => call)
        .map(call => ({
          ...call,
          startCallTime: call.timestamp ? call.timestamp.toDate() : call.startTimestamp.toDate(),
        }))
        .sort((callA, callB) => (callA.startCallTime > callB.startCallTime ? 1 : -1))
      )
    }
  }, [shopCalls])

  useEffect(() => {
    if (allCalls !== null) {
      setLoading(false)
    }
  }, [allCalls])

  const clearState = () => {
    setLoading(true)
    setShopCalls({})
    setAllCalls(null)
  }

  return ({
    allCalls,
    firestoreRef,
    isLoading
  })

}

export default useHostCalls;
