import React, { useState, useLayoutEffect } from 'react';
import {Unavailable} from "../assets/icons/Unavailable";
import {MissedCall} from "../assets/icons/MissedCall";

export const displayDateFormatter = (date, timezone) => {
  return new Intl.DateTimeFormat([], {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: timezone
  }).format(date);
}

export const displayTime = (date) => {
  return new Intl.DateTimeFormat([], {
    hour: 'numeric',
    minute: 'numeric',
  }).format(date);
}

export const currencyFormatter = (value, currencyCode = 'USD', props = {}) => {
  return new Intl.NumberFormat(window.navigator.language, {
    style: 'currency',
    currency: currencyCode,
    ...props
  }).format(value);
};

export const percentFormatter = () =>
  new Intl.NumberFormat(window.navigator.language, {
    style: 'percent',
    minimumFractionDigits: 1,
  });

export const numberFormatter = (value) => {
    return new Intl.NumberFormat(window.navigator.language, {
    }).format(value);
  };

export const parseIdString = (idString) => {
  if (!idString) {
    return ''
  }
  const idStringArray = idString.split('/');
  return idStringArray[idStringArray.length - 1];
};

export const isEmailValid = (email) => {
  var regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return regex.test(email);
};

export const isEmail = (string) => {
  if (!string)
    return false;

  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(string);
}

export function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();

    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  return screenSize;
};

export const parseGID = (id) => {
  if (id?.toString().startsWith('gid'))
    return id.slice(-(id.length - id.lastIndexOf('/') - 1));
  else
    return id;
}

export const parseStorageUri = (uri) => {
  const components = new URL(uri);
  return { bucket: components.host, file: components.pathname.substring(1)};
}

export const productPrice = (product) => {
  if (product.maxVariantPrice !== product.minVariantPrice) {
    return `${currencyFormatter(product.minVariantPrice, product.currencyCode)} — ${currencyFormatter(product.maxVariantPrice, product.currencyCode)}`
  } else {
    return currencyFormatter(parseFloat(product.price), product.currencyCode)
  }
}

export const errorBadgeType = (error) => {
  let message = '';
  let icon = null;
  if (!error.error) message = 'Error';
  switch (error.error.code) {
    case 403:
      icon = <Unavailable />
      message = 'Banned';
      break;
    case 404:
      icon = <Unavailable />
      message = 'Host unavailable';
      break;
    case 408:
      icon = <MissedCall />
      message = 'Missed';
      break;
    case 409:
      icon = <Unavailable />
      message = 'Rejected';
      break;
    case 429:
      icon = <Unavailable />
      message = 'Over quota';
      break;
    case 499:
      icon = <Unavailable />
      message = 'Cancelled';
      break;
    default:
      icon = <Unavailable />
      message = 'Error';
      break;
  }
  return (<>{icon}<p>{message}</p></>)
}

export const resultRenderer = (value) => {
  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'boolean') {
    return value.toString();
  } else if (Array.isArray(value)) {
    return value.map(item => resultRenderer(item))
  } else if (typeof value === 'object' && value !== null) {
    return value.value
  } else if (value === null) {
    return 'n/a'
  }
}

export const getContrastYIQ = (hexcolor) => {
  if (hexcolor.slice(0, 1) === '#') {
    hexcolor = hexcolor.slice(1);
  }
  const r = parseInt(hexcolor.substr(0,2),16);
  const g = parseInt(hexcolor.substr(2,2),16);
  const b = parseInt(hexcolor.substr(4,2),16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? null : 'white'; //if light set default black text color in css
}

export const localTimezoneGMTOffset = () => {
  return Math.abs(new Date().getTimezoneOffset()/60)
}

export const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

export function formatTimezone(timezone) {
  return timezone && new Intl.DateTimeFormat([], {timeZoneName: "short", timeZone: timezone}).formatToParts().find(({type}) => type === "timeZoneName").value || 'local';
}

export const formatEventDate = (date, timezone) => {
  return new Intl.DateTimeFormat(navigator.language, {
    dateStyle: 'long',
    timeStyle: 'short',
    timeZone: timezone
  }).format(date);
}

export const toPresetProduct = ({
    currencyCode,
    image,
    maxVariantPrice,
    minVariantPrice,
    price,
    productId,
    title,
    descriptionHtml,
    productTitle,
    productDescription,
    compareAtPrice = null
}) => ({
  currencyCode,
  image,
  maxVariantPrice,
  minVariantPrice,
  price,
  productId,
  compareAtPrice,
  productTitle: title ?? productTitle,
  productDescription: descriptionHtml ?? productDescription
})
