import React, {useState} from 'react';
import './call-details-cart-item.scss';
import SelectedProductItem
  from "../../../../../../components/productComponents/selected-product-item/selected-product-item";
import SearchProductItem from "../../../../../../components/productComponents/search-product-item/search-product-item";
import {useProducts} from "../../../../../../hooks";

const CallDetailsCartItem = ({product, onUpdateProducts, call, withVariant = true}) => {
  const {shopId, id, countryCode} = call ?? {};
  const [selectedProduct, setSelectedProduct] = useState()

  const {getCachedProduct} = useProducts(
    shopId,
    id,
    countryCode
  );

  const decrement = (id) => {
    onUpdateProducts(products => JSON.parse(JSON.stringify(products))
      .map((product) => {
        if (id === product.variantId) {
          product.quantity = Math.max(1, product.quantity - 1)
        }
        return product
      })
    )
  }

  const onRemoveProduct = (id) => {
    onUpdateProducts(products => JSON.parse(JSON.stringify(products))
      .filter((product) => id !== (withVariant ? product.variantId : product.productId))
    )
  }

  const onAddProduct = (updatedProduct) => {
    onUpdateProducts(currentProducts => {
      const products = JSON.parse(JSON.stringify(currentProducts))
      if (products.some(product => product.variantId === updatedProduct.variantId)) {
        return products
          .map((product) => {
            if (product.variantId === updatedProduct.variantId) {
              return ({
                ...product,
                quantity: product.quantity + 1
              })
            }
            return product
          })
          //filter products w/selected variant
          .filter(product => {
            if (product.productId === updatedProduct.productId) {
              return product.variantId;
            }
            return true
          })
      } else {
        return products.map((product) => {
          if (product.productId === updatedProduct.productId) {
            return updatedProduct
          }
          return product
        })
      }
    })
  }

  const increment = (id) => {
    onUpdateProducts(products => JSON.parse(JSON.stringify(products))
      .map((product) => {
        if (id === product.variantId) {
          product.quantity += 1
        }
        return product
      })
    )
  }

  return (
    <div
      key={(product.variantId ?? product.productId) + 'selectedProduct'}
    >
      {product.variantId ? (
        <SelectedProductItem
          product={product}
          decrement={decrement}
          increment={increment}
          onRemoveProduct={onRemoveProduct}
        />
      ) : (
        <SearchProductItem
          product={product}
          onAddProduct={withVariant ? onAddProduct : () => {}}
          getCachedProduct={getCachedProduct}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          errorMessage={!product.variantId && withVariant ? 'Select variant for product' : null}
          onRemoveProduct={onRemoveProduct}
          showVariantSelector={withVariant}
        />
      )}
    </div>
  )
}

export default CallDetailsCartItem;