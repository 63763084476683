import {useDispatch, useSelector} from 'react-redux';
import {firebase, firestore, putFileToStorage, MEDIA_BUCKET} from '../../firebaseInitialization';
import {MSG_TYPES} from "../../constants/messaging";
import {FIRESTORE_COLLECTIONS} from "../../constants/firestore";
import {
  sendMessageFailure,
  sendMessageRequest,
  sendMessageSuccess,
  setTempImageMessage,
} from "../../actions/messages";
import metadataRequests from "../metadata/requests";
import {buildUserEntity, getUserId} from "../../utils/auth";
import { getMessageRecepients, buildMessageSendStatus } from "../../utils/participants";

export default function useMessagesRequests({user}) {
  const dispatch = useDispatch();
  const {id, participants } = useSelector(state => state.activeRoom);
  const {updateLastMessage} = metadataRequests({user});

  const sendMessage = async ({msg = null, type = MSG_TYPES.TEXT}) => {
    const privateUserData = participants[getUserId(user)];
    const sender = buildUserEntity(privateUserData);
    const recipients = getMessageRecepients(sender.uid, participants);
    const status = buildMessageSendStatus(sender.uid, recipients);

    if (!id) {
      return;
    }

    dispatch(sendMessageRequest());
    const roomRef = firestore().collection(FIRESTORE_COLLECTIONS.CHAT_ROOMS).doc(id)
    const messageRef = roomRef.collection(FIRESTORE_COLLECTIONS.CHAT_MESSAGES).doc();
    const timestamp = firestore.Timestamp.fromDate(new Date());
    const message = {
      id: messageRef.id,
      owner: {
        uuid: sender.uid,
        role: sender.role,
        displayName: sender.displayName || null,
        profileAvatarUrl: sender?.profileAvatarUrl || null,
        profileInitials: sender?.profileInitials,
      },
      recipients,
      room: {
        id: id,
      },
      type,
      text: msg,
      timestamp,
      status,
    };

    try {
      await messageRef.set(message);
      dispatch(sendMessageSuccess())
      await updateLastMessage({message})
    } catch (e) {
      console.error('Error of message sending');
      dispatch(sendMessageFailure(e.message))
    }
  }

  const sendFile = async ({file, chatRoomId, type = MSG_TYPES.FILE}) => {
    const privateUserData = participants[getUserId(user)];
    const sender = buildUserEntity(privateUserData);
    const recipients = getMessageRecepients(sender.uid, participants);
    const status = buildMessageSendStatus(sender.uid, recipients);
    if (!chatRoomId) {
      return;
    }
    const fileName = `${new Date().valueOf()}_${file.name || file.fileName}`;
    dispatch(sendMessageRequest());
    const roomRef = firestore().collection(FIRESTORE_COLLECTIONS.CHAT_ROOMS).doc(chatRoomId)
    const messageRef = roomRef.collection(FIRESTORE_COLLECTIONS.CHAT_MESSAGES).doc();
    dispatch(setTempImageMessage(messageRef.id));
    try {
      const storageRef = firebase.app()
        .storage(MEDIA_BUCKET)
        .ref()
        .child('userMedia')
        .child(sender.uuid)
        .child(fileName);

      await putFileToStorage(storageRef, file, {customMetadata: {chatId: chatRoomId}});
      const freeUrl = await storageRef.getDownloadURL();
      const timestamp = firestore.Timestamp.fromDate(new Date());
      const message = {
        id: messageRef.id,
        owner: {
          uuid: sender.uid,
          role: sender.role,
          displayName: sender.displayName || null,
          profileAvatarUrl: sender?.profileAvatarUrl || null,
          profileInitials: sender?.profileInitials,
        },
        recipients,
        room: {
          id: chatRoomId,
        },
        type: type,
        text: '',
        metadata: {
          key: 'file',
          type: file.type || null,
          url: freeUrl,
        },
        timestamp,
        status,
      };
      await messageRef.set(message);
      dispatch(sendMessageSuccess());
      await updateLastMessage({message})
      dispatch(setTempImageMessage(null));
    } catch (e) {
      dispatch(setTempImageMessage(null));
      dispatch(sendMessageFailure(e.message))
    }
  }

  return {
    sendMessage,
    sendFile,
  };
}
