import React, {useEffect, useState} from 'react';
import './sidebar-product-tab.scss';
import ProductSidebar from "../../product-view/product-sidebar";
import {useProducts, useProductSearch} from "../../../../../../hooks";
import {useActiveCallProvider} from "../../../../../../components/ActiveCallProvider";
import SearchSidebar from "../../product-search/search-sidebar";
import {DEFAULT_PRODUCT_RESULTS_LIMIT, SIDEBAR_VIEWS} from "../../consts";
import firebase from "firebase/compat/app";
import 'firebase/compat/analytics';
import {parseIdString} from "../../../../../../utils";

const SidebarProductTab = ({
  visible,
  shopId,
  selectedProductVariantId,
  updateProduct,
  selectedSnapshotKey,
  clientViewData,
  selectProduct,
  selectedProductId,
  clearSelectedProductIds,
  previousPurchaseData,
  setPreviousPurchaseData,
  updateCurrentTab
}) => {
  const {
    activeCallData,
    activeCallId,
  } = useActiveCallProvider();

  const {getCachedProduct, isProductLoading} = useProducts(
    shopId,
    activeCallId,
    activeCallData?.countryCode
  );
  const {searchProducts} = useProductSearch(
    shopId,
    activeCallData?.countryCode
  );
  const [isSearching, setSearching] = useState(null);
  const [productSearchResults, setProductSearchResults] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [isNewSearch, setIsNewSearch] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    if (selectedProductId) {
      viewItem(selectedProductId);
    }
  }, [selectedProductId]);

  const viewItem = async (productId) => {
    const cleanProductId = parseIdString(productId);
    const fullProduct = await getCachedProduct(cleanProductId);
    updateCurrentTab(SIDEBAR_VIEWS.PRODUCT)
    setSelectedProduct(fullProduct);
    if (previousPurchaseData && productId !== previousPurchaseData.productId) {
      setPreviousPurchaseData(null);
    }
  };

  const findProducts = () => {
    if (!searchTerm) return;
    if (!isSearching) {
      setSearching(true);
      setIsNewSearch(true);
      searchProducts(searchTerm, DEFAULT_PRODUCT_RESULTS_LIMIT)
        .then((searchResults) => {
          setProductSearchResults(searchResults);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          firebase.analytics().logEvent('search', {search_term: searchTerm});
          setSearching(false);
        });
    }
  };

  const clearSelectedProduct = () => {
    clearSelectedProductIds();
    setSelectedProduct(null);
  };

  const clearPreviousPurchaseData = () => setPreviousPurchaseData(null);

  return (
    <div className={`sidebar-product-details-tab${visible ? ' visible' : ''}`}>
      {selectedProductId ? (
        <ProductSidebar
          product={selectedProduct}
          isProductLoading={isProductLoading}
          selectedProductVariantId={selectedProductVariantId}
          updateProduct={updateProduct}
          selectedSnapshotKey={selectedSnapshotKey}
          shopId={shopId}
          previousPurchaseData={previousPurchaseData}
          clearSelectedProduct={clearSelectedProduct}
          clearPreviousPurchaseData={clearPreviousPurchaseData}
          clientViewData={clientViewData}
        />
      ) : (
        <SearchSidebar
          isSearching={isSearching}
          setSearching={setSearching}
          viewItem={selectProduct}
          searchProducts={searchProducts}
          searchResults={productSearchResults}
          setSearchResults={setProductSearchResults}
          loading={isSearching}
          searchTerm={searchTerm}
          isNewSearch={isNewSearch}
          setIsNewSearch={setIsNewSearch}
          setSearchTerm={setSearchTerm}
          onSubmitSearch={findProducts}
        />
      )}
    </div>
  )
}

export default SidebarProductTab;