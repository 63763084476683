import React, {createContext, useContext, useEffect, useMemo, useState} from "react";
import useHistoryCallDraftOrder from "../../../../hooks/useHistoryCallDraftOrder";
import {CALL_DETAILS_TYPE, CALL_STATUS, CALL_TYPES} from "../../../../utils/consts";
import useShopDraftOrderConfig from "../../../../hooks/useShopDraftOrderConfig";
import useAbandonedCart from "../../../../hooks/useAbandonedCartItems";
import {normalizeDraftOrderProducts} from "../../../../utils/draftOrder";
import useCallCarts from "../../../../hooks/useCallCarts";
import useCallShowroom from "../../../../hooks/useCallShowroom";
import useShopCheckout from "../../../../hooks/useShopCheckout";
import useShopOrder from "../../../../hooks/useShopOrder";
import useScheduleClientFormData from "../../../../hooks/useScheduleClientFormData";
import { ANIMATION, EMAIL_EVENTS } from "../../../../constants/const";
import useCallCartActions from "../../../../hooks/useCallCartActions";
import {useProducts} from "../../../../hooks";
import {parseIdString} from "../../../../utils";
import useScheduleEmailEvents from '../../../../hooks/useScheduleEmailEvents';

const CallDetailsContext = createContext(null);

export const CallDetailsProvider = ({children}) => {
  const [callDetails, setCallDetails] = useState(null);
  const [selectedTab, setSelectedTab] = useState(CALL_DETAILS_TYPE.INFO);
  const [postCallProducts, setPostCallProducts] = useState([])
  const [openDraftOrder, setOpenDraftOrder] = useState(false)
  const [emailEventsModalVisibility, setEmailEventsModalVisibility] = useState(false)
  const [client, setClient] = useState(null);
  const [products, setProducts] = useState([])
  const [sidebarAnimation, setSidebarAnimation] = useState('')
  const [loadingProduct, setLoadingProduct] = useState(null)
  const [isClientHistory, setIsClientHistory] = useState(false);

  const {
    shopId,
    id: callId,
    draftOrders,
    abandonedCart,
    carts,
    status,
    error,
    type,
    orders,
    checkouts,
    scheduleId,
    countryCode,
    callShowroomId,
  } = callDetails ?? {};
  const { scheduleEmailEvents } = useScheduleEmailEvents(shopId, scheduleId ?? callId);

  const {onCreateCart, onUpdateCart} = useCallCartActions(callId, shopId)

  const {getCachedProduct} = useProducts(
    shopId,
    callId,
    countryCode
  );

  const {draftOrder} = useHistoryCallDraftOrder(shopId, callId, draftOrders && Object.keys(draftOrders).length > 0)
  const { draftOrderConfig } = useShopDraftOrderConfig(shopId);

  const { abandonedCartItems } = useAbandonedCart(shopId, abandonedCart);
  const { showroomProducts, showroomError, loading: showroomLoading } = useCallShowroom(
    shopId,
    type !== CALL_TYPES.SCHEDULED && status !== CALL_STATUS.ERROR ? callId : null,
    callShowroomId,
  )
  const {scheduleClientFormData} = useScheduleClientFormData(shopId, scheduleId);

  const emailEvents = useMemo(() => (
    scheduleEmailEvents?.docs?.map(doc => doc.data()).filter(event => Object.keys(EMAIL_EVENTS).includes(event.templateId))
  ), [scheduleEmailEvents])

  const cartId = useMemo(() => (
    Object.keys(carts ?? {}).map((key) => key).pop()
  ), [carts])

  const orderIds = useMemo(() => (
    Object.keys(orders ?? {}).map((key) => orders[key].orderId)
  ), [orders])

  const checkoutId = useMemo(() => (
    Object.keys(checkouts ?? {})
      ?.map(key => ({
        ...checkouts[key],
        checkoutId: key,
        createdAt: checkouts[key].createdAt.toMillis()
      }))
      ?.sort((a, b) => a.createdAt - b.createdAt)
      ?.at(-1)
      ?.checkoutId
  ), [checkouts])

  const {cart} = useCallCarts(cartId, shopId)
  const { checkoutData } = useShopCheckout(shopId, checkoutId)
  const { orderData } = useShopOrder(shopId, orderIds, callId);

  const isSupportDraftOrder = useMemo(() => (
    draftOrderConfig && draftOrderConfig.enabled
    && status === CALL_STATUS.COMPLETED
    && !error
  ), [status, error, draftOrderConfig])

  //to get not completed draft order id from call data
  const callDraftOrderId = useMemo(() => (
    draftOrders
      ? Object.keys(draftOrders)
        .map(draftOrderId => ({
          isCompleted: !!draftOrders[draftOrderId].completedAt,
          draftOrderId
        }))
        .filter(draftOrder => !draftOrder.isCompleted)
        .pop()
        ?.draftOrderId
      : null
  ), [draftOrders])

  useEffect(() => {
    return () => {
      setPostCallProducts([])
      setOpenDraftOrder(false)
      setProducts([])
      setLoadingProduct(null)
      setEmailEventsModalVisibility(false)
    }
  }, [callDetails?.id])

  const onUpdateCallDetails = (call) => {
    setCallDetails(call)
    if (!call) {
      setSidebarAnimation('')
    }
  }

  const onChangeTab = (tab) => {
    setSelectedTab(tab)
  }

  const onShowEmailEvents = () => setEmailEventsModalVisibility(true);

  const onHideEmailEvents = () => setEmailEventsModalVisibility(false);

  const onOpenDraftOrder = (products) => {
    setOpenDraftOrder(true)
    if (Array.isArray(products)) {
      setPostCallProducts(products)
    } else {
      setPostCallProducts(normalizeDraftOrderProducts(draftOrder))
    }
  }

  const onHideDraftOrder = () => {
    setOpenDraftOrder(false)
  }

  const onChangeClient = (client) => {
    setClient(client)
  }

  const getImagePerSelectedVariant = (product, fullInfoProduct) => {
    if (fullInfoProduct) {
      if (fullInfoProduct.image) {
        return fullInfoProduct.image.url
      } else {
        return product.image
      }
    } else {
      return product.image
    }
  }

  const getFullProductInfo = async (showroomProducts) => {
    setLoadingProduct(showroomProducts?.[0])
    return (await Promise.all((showroomProducts ?? products).map(product => getCachedProduct(product.productId))))
      .map((fullProduct) => {
        const product = showroomProducts?.[0] ?? products.find(product => product.productId === parseIdString(fullProduct.productId))
        if (fullProduct.variants.length === 1) {
          return ({
            ...fullProduct.variants[0],
            productId: parseIdString(product.productId),
            variantId: parseIdString(fullProduct.variants[0].id),
            variantTitle: fullProduct.title,
            productTitle: product.title ?? product.productTitle,
            image: getImagePerSelectedVariant(product, fullProduct),
            descriptionHtml: product.descriptionHtml,
            quantity: 1
          })
        } else {
          return product
        }
      })
  }

  const navigateToCartTab = (showroomProducts) => {
    onChangeTab(CALL_DETAILS_TYPE.CART)
    if (Array.isArray(showroomProducts)) {
      setProducts(showroomProducts)
    } else {
      onUpdateProducts(products => [...products, showroomProducts])
    }
    setLoadingProduct(null)
  }

  const toggleSidebarModal = (call) => {
    if (call) {
      setSidebarAnimation(ANIMATION.SHOW)
      onUpdateCallDetails(call);
    } else {
      setSidebarAnimation(ANIMATION.CLOSE)
    }
  }

  const onAnimationEnd = ({animationName}) => {
    if (animationName === 'side-bar-out') {
      onUpdateCallDetails(null);
    }
  }
  const onUpdateProducts = async (updatedProducts) => {
    if (Array.isArray(updatedProducts)) {
      setProducts(updatedProducts)
      if (cartId) {
        await onUpdateCart(cartId, updatedProducts)
      }
    }
    if (updatedProducts instanceof Function) {
      setProducts( (currentProducts) => {
        const products = updatedProducts(currentProducts)
        if (cartId) {
          onUpdateCart(cartId, products)
        }
        return products
      })
    }
  }

  return (
    <CallDetailsContext.Provider
      value={{
        callDetails,
        onUpdateCallDetails,
        draftOrder,
        openDraftOrder,
        isSupportDraftOrder,
        callDraftOrderId,
        onOpenDraftOrder,
        onHideDraftOrder,
        selectedTab,
        onChangeTab,
        abandonedCartItems,
        postCallProducts,
        client,
        onChangeClient,
        cartId,
        cart,
        showroomProducts,
        showroomError,
        showroomLoading,
        checkoutData,
        orderData,
        scheduleClientFormData,
        products,
        onUpdateProducts,
        setProducts,
        navigateToCartTab,
        sidebarAnimation,
        toggleSidebarModal,
        onAnimationEnd,
        onCreateCart,
        onUpdateCart,
        loadingProduct,
        getFullProductInfo,
        emailEvents,
        onShowEmailEvents,
        onHideEmailEvents,
        emailEventsModalVisibility,
        isClientHistory,
        setIsClientHistory
      }}
    >
      {children}
    </CallDetailsContext.Provider>
  )
}

export const useCallDetailsProvider = () => {
  const context = useContext(CallDetailsContext);
  if (!context) {
    throw new Error('useCallDetailsProvider must be used within the CallDetailsProvider');
  }
  return context;
}
