import firebase from "firebase/compat/app";
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import { useEffect, useState } from "react";

const useCallShowroom = (shopId, callId, callShowroomId) => {
  const [showroomProducts, setShowroomProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showroomError, setShowroomError] = useState(false);

  useEffect(() => {
    if (callId) {
      setLoading(true);
      setShowroomError(null);
      if (callShowroomId) {
        const listener = firebase.firestore().collection('shops').doc(shopId).collection('callShowrooms').doc(callShowroomId).onSnapshot(snap => {
          setShowroomProducts(snap.data()?.products ?? []);
          setLoading(false);
        }, 
        (error) => {
          setShowroomError(error);
          setShowroomProducts([]);
          setLoading(false);
        });
        return () => listener();
      } else {
        const ref = firebase.database().ref(`session/${callId}/products`);
        ref.on('value',
          snap => {
            const showroom = snap.val();
            if (showroom) {
              const products = Object.keys(showroom).map(productKey => showroom[productKey]);
              setShowroomProducts(products);
            } else {
              setShowroomProducts([]);
            }   
            setLoading(false);
          }, (error) => {
            setShowroomProducts([]);
            setShowroomError(error);
            setLoading(false);
          });
          return () => ref.off();
      }
    }
  }, [shopId, callId, callShowroomId])

 
  return ({
    showroomProducts,
    loading,
    showroomError,
  })
}

export default useCallShowroom