import React from 'react';
import './call-details-cart-component.scss';
import SideBarProductSearch from "../../side-bar-product-search/side-bar-product-search";
import CallDetailsCartItem from "./call-details-cart-item/call-details-cart-item";
import IconCopy from "../../../../../assets/icons/Copy";
import {useNotificationProvider} from "../../../../../components/notifications/NotificationProvider";
import {useCallDetailsProvider} from "../../call-details-provider/call-details-provider";
import { MAX_CART_PRODUCTS } from '../../../../../utils/consts';

const CallDetailsCartComponent = ({copyLabel, cartUrl}) => {
  const {showToast} = useNotificationProvider();
  const {
    products,
    callDetails,
    onUpdateProducts
  } = useCallDetailsProvider();

  const copyToClipboard = () => {
    showToast({
      description: 'Link copied to clipboard',
      type: 'info',
    });
    navigator.clipboard.writeText(cartUrl)
  }

  return (
    <div className='cart-component-wrapper'>
      <div className='cart-component-details-action-row' onClick={copyToClipboard}>
        <IconCopy/>
        <p>{copyLabel}</p>
      </div>      
      <SideBarProductSearch
        disabled={products.length >= MAX_CART_PRODUCTS}
        placeholder={'Search for products'}
        products={products}
        onUpdateProducts={onUpdateProducts}
        {...callDetails}
      />
      {products?.length > 0 && <div className={'cart-component-products-counter'}>
        <p className={products?.length >= MAX_CART_PRODUCTS && 'limit'}>
          {products?.length} product{products?.length != 1 ? 's' : ''} in cart
          {products?.length >= MAX_CART_PRODUCTS
            && ' (max)'
          }
        </p>
      </div>}
      <div className='cart-component-products-wrapper' id={'search-dropdown'}>
        {products.map((product) => (
          <CallDetailsCartItem
            key={(product.variantId ?? product.productId) + 'selectedProduct'}
            product={product}
            call={callDetails}
            onUpdateProducts={onUpdateProducts}
            products={products}
          />
        ))}
      </div>
    </div>
  )
}

export default CallDetailsCartComponent;
