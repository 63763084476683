import React, {Fragment} from "react";
import './select-event-type.scss'
import EventTypeItem from "./event-type-item/event-type-item";
import {FilledButton} from "../../buttons/buttons";

const SelectEventType = ({events, selectedEvent, setSelectedEvent, onNextPress}) => {
  return (
    <div className='select-event-type-container'>
      <header>
        <h3>{'Select your preferred event'}</h3>
      </header>
      <div className='event-types-wrapper'>
        {events.map((event, index) => (
          <Fragment key={index}>
            <EventTypeItem
              event={event}
              setSelectedEvent={setSelectedEvent}
              isSelected={selectedEvent?.id === event.id}
            />
          </Fragment>
        ))}
      </div>
      <div className='event-actions-container'>
        <FilledButton
          onClick={onNextPress}
          disabled={!selectedEvent}
          className={`${!selectedEvent ? 'disabled' : 'button'}`}
          style={{
            width: '100%'
          }}
          >
          {'Next'}
        </FilledButton>
      </div>
    </div>
  )
}

export default SelectEventType