import React, { useState } from 'react';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/analytics';
import CreateAccount from './create-account';
import { logger } from '../../logging';
import './signup.scss';
import SignupForm from './signup-form';
import { useAppAuthState } from '../../authState'

const VIEWS = {
  EMAIL_VALIDATION: 'emailValidation',
  SIGNUP: 'signup',
};

export default function Signup() {
  const [view, setView] = useState(VIEWS.EMAIL_VALIDATION);
  const [isSigningUp, setSigningUp] = useState(false);
  const [userCred, setUserCred] = useState(null);
  const [inviteData, setInviteData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { URLInviteId } = useParams();
  const { waitForUserInit } = useAppAuthState();
  const history = useHistory();

  // if this isn't a valid signup - redirect

  if (!firebase.auth().isSignInWithEmailLink(window.location.href)) {
    return <Redirect to='/' />;
  }

  const shopName = new URLSearchParams(window.location.search).get('shopName');
  const shopId = new URLSearchParams(window.location.search).get('shopId');
  const inviteHMAC = new URLSearchParams(window.location.search).get(
    'inviteHMAC'
  );

  const accept = (userUuid, firstName, lastName) => {
    const acceptInvite = firebase.functions().httpsCallable('invites-accept');
    return acceptInvite({
      inviteId: URLInviteId,
      firstName: firstName || null,
      lastName: lastName || null,
      shopId,
      inviteHMAC,
    })
      .then(() => {
        logger.info(`acceptInvite ${URLInviteId} ${shopId}`);
        return waitForUserInit(userUuid, shopId)
      })
      .then(() => history.push('/'))
  };

  const firebaseAuth = (email) => {
    return firebase
      .auth()
      .signInWithEmailLink(email, window.location.href)
      .then((res) => {
        logger.info(`invite login by user ${res.user.uid}`);
        return res;
      })
      .catch((error) => {
        let message;
        switch (error.code) {
          case 'auth/expired-action-code':
            message = 'This invite has expired';
            break;
          case 'auth/invalid-action-code':
            message = 'This invite is no longer valid';
            break;
          case 'auth/invalid-email':
            message = "The email entered doesn't match the invited email";
            break;
          case 'auth/user-disabled':
          default:
            console.error(error);
            break;
        }
        throw new Error(message);
      });
  };

  const onEmailSubmit = (email) => {
    setErrorMessage(null);
    setSigningUp(true);
    var isNewUser = false;
    var userUuid;
    firebaseAuth(email)
      .then((_userCred) => {
        setUserCred(_userCred);
        isNewUser = _userCred.additionalUserInfo.isNewUser;
        userUuid = _userCred.user.uid;
        logger.info(`invite login user ${userUuid} ${isNewUser ? 'is' : 'is not'} new user`);
        return firebase
          .firestore()
          .collection('shops')
          .doc(shopId)
          .collection('invites')
          .doc(URLInviteId)
          .get();
      })
      .then((inviteDoc) => {
        if (!inviteDoc.exists) {
          throw new Error('invite is invalid');
        }
        setInviteData(inviteDoc.data());
        if (isNewUser) {
          firebase.analytics().logEvent('sign_up', { method: 'password' });
          setSigningUp(false);
          setView(VIEWS.SIGNUP);
        } else {
          return accept(userUuid);
        }
      })
      .catch((err) => {
        setSigningUp(false);
        console.error(err);
        setErrorMessage('There was an error signing up. ' + err.message);
      });
  };

  return (
    <div className='signup-page'>
      <div className='background-element' />
      <img src='../boutiq.svg' className='brand-logo' />
      <div className={`signup-form-container ${view}`}>
        {view === VIEWS.EMAIL_VALIDATION ? (
          <SignupForm
            onEmailSubmit={onEmailSubmit}
            isLoading={isSigningUp}
            shopName={shopName}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <CreateAccount
            shopName={shopName}
            accept={accept}
            userCred={userCred}
            setErrorMessage={setErrorMessage}
            inviteData={inviteData}
          />
        )}
        {errorMessage && <p className='error-text'>{errorMessage}</p>}
      </div>
    </div>
  );
}
