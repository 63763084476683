import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import {setGoogleAuthLoading, updateGoogleAuth, deleteGoogleAuth} from "../reducers/user-reducer";
import {loadScript, removeScript} from "../utils/html";
import google from '../configs/google';
import {logger} from "../logging";

export default function useGoogleAuth() {

  const GIS_TAG = 'gis-tag';
  const GOOGLE_CALENDAR_SCOPE = 'profile email https://www.googleapis.com/auth/calendar.events';

  const dispatch = useDispatch();
  const { user, googleAuth: { isLoggedIn, isGoogleAuthLoading, access_token: accessToken } } = useSelector(state => state.user);
  const [googleAuthError, setGoogleAuthError] = useState(null);
  const [isGoogleAuthDeactivated, setGoogleAuthDeactivatedStatus] = useState(false);
  const [gisClient, setGisClient] = useState(null);

  const buildAuthRequestParams = () => {
    return{
      client_id: google.googleClientId,
      cookie_policy: 'single_host_origin',
      fetch_basic_profile: true,
      ux_mode: 'popup',
      redirect_uri: window.location.origin.toString(),
      scope: GOOGLE_CALENDAR_SCOPE,
      access_type: 'offline',
    }
  }

  const startGoogleAuth = () => {
    if (gisClient) {
      setGoogleAuthError(null);
      gisClient.requestCode();
    }
  }

  const gisLoad = () => {
    const client = window.google.accounts.oauth2.initCodeClient({
      ...buildAuthRequestParams(),
      callback: async (response) => {
        if (!response?.scope?.includes('calendar.events')) {
           setGoogleAuthError({message: `Make sure Google Calendar permissions are enabled - please try again`});
        } else if (response?.code) {
          dispatch(setGoogleAuthLoading(true))
          await sendAuthCode(response);
        } else {
          logger.warn(`window.gapi.auth2 doesn't return 'code'`)
        }
      },
    });
    setGisClient(client);
  }

  useEffect(() => {
    dispatch(setGoogleAuthLoading(true))
    setGoogleAuthError(null);
    loadScript(GIS_TAG, google.gisUrl, gisLoad)

    return () => {
      dispatch(setGoogleAuthLoading(false))
      removeScript(GIS_TAG);
    }
  }, [])

  const googleSignIn = () => {
    startGoogleAuth();
  }

  const googleSignOut = () => {
    try {
      dispatch(setGoogleAuthLoading(true))
      if (window.google) {
        window.google.accounts.oauth2.revoke(accessToken, () => {
          clearGoogleAuthData();
          if (googleAuthError !== null) {
            setGoogleAuthError(null);
          }
        });
      }
    } catch (e) {
      dispatch(setGoogleAuthLoading(false))
      setGoogleAuthError(e);
    }
  }

  const sendAuthCode = async ({code = null}) => {
    try{
      const onGoogleAuth = firebase.functions().httpsCallable('googleAuth-onGoogleAuth');
      await onGoogleAuth({ code, redirectUri: window.location.origin.toString() })
    } catch (e) {
      setGoogleAuthError(e);
    } finally {
      dispatch(setGoogleAuthLoading(false))
    }
  }

  const clearGoogleAuthData = () => {
    firebase
      .firestore()
      .collection('users')
      .doc(user.id)
      .collection('private')
      .doc('googleAuth')
      .delete()
      .catch((error) => logger.error('Google Auth token cannot be deleted', error));
  }


  const initializeGoogleAuthListener = async () => {
    try {
      const googleAuthRef = firebase
        .firestore()
        .collection('users')
        .doc(user.id)
        .collection('private')
        .doc('googleAuth');

      const doc = await googleAuthRef.get();
      if (!doc.exists) {
        dispatch(setGoogleAuthLoading(false))
      }
      return googleAuthRef.onSnapshot(
        (doc) => {
          if (doc) {
            const data = doc.data();
            if (data) {
              dispatch(updateGoogleAuth(data))
              dispatch(setGoogleAuthLoading(false))
            } else {
              dispatch(deleteGoogleAuth());
            }
          }
          if (isGoogleAuthDeactivated) {
            setGoogleAuthDeactivatedStatus(false);
          }
        }, listenError => {
          setGoogleAuthDeactivatedStatus(true);
          dispatch(setGoogleAuthLoading(false))
          logger.error('listenGoogleAuth', listenError)
        });

    } catch (e) {
      setGoogleAuthDeactivatedStatus(true);
      dispatch(setGoogleAuthLoading(false))
      setGoogleAuthError({message: `Authorization handling error`});
      logger.error('GoogleAuth handling error', e)
    }
  }

  useEffect(() => {
    let googleAuthListener = null;
    if (user && user.id) {
      Promise.resolve(initializeGoogleAuthListener())
        .then((listener) => googleAuthListener = listener);
    }
    return () => {
      if (googleAuthListener) {
        googleAuthListener();
      }
    }
  },[user]);

  return {
    googleAuthError,
    googleSignOut,
    googleSignIn,
    isGoogleAuthLoading,
    isLoggedIn,
    isGoogleAuthDeactivated,
  }

}
