import React, {Fragment, useEffect} from 'react';
import './call-details-tabs.scss';
import {CALL_DETAILS_TYPE} from "../../../../utils/consts";
import SidebarTabBar from "../../../../components/sidebar-tab-bar/sidebar-tab-bar";
import {useCallDetailsProvider} from "../call-details-provider/call-details-provider";
import useCallDetailsTabs from "../../../../hooks/useCallDetailsTabs";

const CallDetailsTabs = ({feedbackType}) => {
  const {
    callDetails,
    onChangeTab,
    selectedTab,
  } = useCallDetailsProvider()

  const {tabs} = useCallDetailsTabs(feedbackType)

  useEffect(() => {
    onChangeTab(tabs?.[0].key)
  }, [callDetails.id])

  useEffect(() => {
    if (!tabs.some(tab => tab.key === selectedTab)) {
      onChangeTab(tabs?.[0].key)
    }
  }, [JSON.stringify(tabs)])

  return (
    <div className={`call-details-tabs-container ${selectedTab}`}>
      <SidebarTabBar
        barStyle={{
          marginLeft: 16,
          marginRight: 16,
        }}
        tabs={tabs}
        onPressTab={onChangeTab}
        selectedTab={selectedTab}
      />
      <div className='tabs-wrapper'>
        {tabs.map(({component, key}) => (
          <Fragment key={key}>
            {component()}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default CallDetailsTabs;
