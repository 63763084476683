import React from "react";
import './availability-legend.scss'

const AvailabilityLegend = ({title, color}) => {
  return (
    <div className='availability-legend-container'>
      <div className='colored-block' style={{'--block-color': color}}/>
      <p>{` - ${title}`}</p>
    </div>
  )
}

export default AvailabilityLegend;