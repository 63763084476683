import moment from "moment";
import CallOrder from "../../calendar-row/components/badges/call-order";
import React from "react";
import './call-order-header.scss'

const CallOrderHeader = ({order}) => {
  return (
    <div className='call-order-header'>
      <div className='call-order-detail'>
        <p className='date'>{`Order ${order.name} · ${moment(order.createdAt.toDate().toISOString()).calendar()}`}</p>
        <p className='customer-name'>{`${order.customer.firstName} ${order.customer.lastName}`}</p>
      </div>
      <div>
        <CallOrder
          totalOrderAmount={order.totalPrice}
          currency={order.currencyCode}
        />
      </div>
    </div>
  )
}

export default CallOrderHeader;