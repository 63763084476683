import React from 'react';
import './timezone-selector-skeleton.scss'
import LoadingCard from '../loading-card/loading-card';

const TimezoneSelectorSkeleton = () => {
  return (
    Array(3).fill(0).map((_, key) => (
      <div key={key} className='timezone-selector-skeleton-wrapper'>
        <LoadingCard
          margin={0}
          height={26}
          width={'100%'}
        />
      </div>
    ))
  )
}

export default TimezoneSelectorSkeleton
