import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import './OrderShipping.scss';
import {FilledButton, OutlinedButton} from "../../../../buttons/buttons";
import {useDigitalInput} from "../../../../../hooks/useDigitalInput";

export const SHIPPING_TYPE = {
  FREE: 'Free shipping',
  CUSTOM: 'Custom'
}

const OrderShipping = ({hideShipping, onRemoveShipping, orderShipping, onUpdateShipping}, ref) => {
  const [shippingType, setShippingType] = useState('');
  const [customShipping, setCustomShipping] = useState({});
  const containerRef = useRef();

  const {ref: shippingRef, value: shippingPrice, setValue: setShippingPrice} = useDigitalInput()

  useEffect(() => {
    setCustomShipping({
      ...customShipping,
      price: shippingPrice
    })
  }, [shippingPrice])

  useImperativeHandle(ref, () => ({
    scrollIntoView: () => {
      const container = document.getElementById('draft-order-container')
      container.scroll({
        top: containerRef.current.offsetTop,
        behavior: 'smooth'
      })
    }
  }), [])

  useEffect(() => {
    if (shippingType === SHIPPING_TYPE.CUSTOM && orderShipping?.shippingType === SHIPPING_TYPE.CUSTOM) {
      setCustomShipping(orderShipping)
      setShippingPrice(orderShipping?.price?.toString())
    } else {
      setCustomShipping({})
    }
  }, [shippingType])

  useEffect(() => {
    if (orderShipping) {
      setShippingType(orderShipping.shippingType)
      setCustomShipping(orderShipping)
      setShippingPrice(orderShipping.price?.toString())
    }
  }, [orderShipping])

  const onChangeRateName = (e) => {
    setCustomShipping({
      ...customShipping,
      rateName: e.target.value
    })
  }

  const onApply = () => {
    onUpdateShipping({
      shippingType,
      rateName: shippingType === SHIPPING_TYPE.CUSTOM ? customShipping.rateName : SHIPPING_TYPE.FREE,
      price: shippingType === SHIPPING_TYPE.CUSTOM ? customShipping.price : 0
    })
  }

  const applyButtonDisabling = () => {
    if (shippingType) {
      if (shippingType === SHIPPING_TYPE.CUSTOM) {
        return !(customShipping.price && customShipping.rateName)
      }
      return false
    }
    return true
  }

  const radioButton = (label) => {
    return (
      <div className='radio-button'  onClick={() => setShippingType(label)}>
        <div className='button-circle'>
          {shippingType === label && (
            <div className='checked-button'/>
          )}
        </div>
        <span>{label}</span>
      </div>
    )
  }

  return (
    <div ref={containerRef} className='draft-order-shipping'>
      {radioButton(SHIPPING_TYPE.FREE)}
      {radioButton(SHIPPING_TYPE.CUSTOM)}
      <div className={`custom-shipping${shippingType === SHIPPING_TYPE.CUSTOM ? ' visible' : ''}`}>
        <div className='section'>
          <p>Rate name</p>
          <input
            className='shipping-input'
            value={customShipping.rateName}
            type='text'
            onChange={onChangeRateName}
          />
        </div>
        <div className='section'>
          <p>Price</p>
          <span
            symbol={customShipping.price ? '$' : ''}
            className={`input-symbol`}
          >
              <input
                ref={shippingRef}
                className='shipping-input'
                placeholder={'$0.00'}
              />
            </span>
        </div>
      </div>
      <div className={`draft-order-shipping${orderShipping ? '-edit' : ''}-buttons`}>
        <OutlinedButton
          className='button'
          style={{
            height: '34px',
            textTransform: 'capitalize',
            width: orderShipping ? '200px' : '100px'
          }}
          onClick={orderShipping ? onRemoveShipping : hideShipping}
          bordercolor={orderShipping ? 'var(--error-color)' : 'var(--main-color)'}
        >
          {orderShipping ? 'Remove shipping' : 'Close'}
        </OutlinedButton>
        <FilledButton
          disabled={applyButtonDisabling()}
          className='button'
          style={{
            height: '34px',
            backgroundColor: `var(--main-color)`,
            textTransform: 'capitalize',
            width: '100px'
          }}
          onClick={onApply}
        >
          {orderShipping ? 'Done' : 'Apply'}
        </FilledButton>
      </div>
    </div>
  )
}

export default forwardRef(OrderShipping);
