import {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {firestore} from '../../firebaseInitialization';
import {parseMsgObject} from "../../utils/parsers";
import {FIRESTORE_COLLECTIONS} from "../../constants/firestore";
import {
  appendMessages,
  getMessagesFailure,
  getMessagesRequest,
  getMessagesSuccess,
} from "../../actions/messages";
import usePrevious from '../../hooks/usePreviousState';
import {LIMIT_CHAT_MESSAGES} from '../../constants/messaging';

export default function useGetMessages(pendingRoomId = null) {
  const dispatch = useDispatch();
  const {id} = useSelector(state => state.activeRoom);

  const idForMessages = pendingRoomId ? pendingRoomId : id;
  const prevRoomId = usePrevious(idForMessages);

  const loadMessages = async (lastMessage = null, numberOfUnreadMessages = 0) => {
    dispatch(getMessagesRequest());
    let newLastMessage;
    try {
      const messagesRef = firestore()
        .collection(FIRESTORE_COLLECTIONS.CHAT_ROOMS)
        .doc(idForMessages)
        .collection(FIRESTORE_COLLECTIONS.CHAT_MESSAGES)
        .orderBy("timestamp", "desc")
        .limit(numberOfUnreadMessages < LIMIT_CHAT_MESSAGES ? LIMIT_CHAT_MESSAGES : numberOfUnreadMessages);

      let messages = [];
      if (lastMessage === null) {
        messages = await messagesRef.get();
      } else {
        messages = await messagesRef
          .startAfter(lastMessage)
          .get();
      }

      if (messages && messages.docs) {
        let data = messages.docs.map(item => parseMsgObject({...item.data(), id: item.id}));
        if (!lastMessage && data.length && prevRoomId !== idForMessages) {
          dispatch(getMessagesSuccess(data));
        } else if (lastMessage && data.length) {
          dispatch(appendMessages(data));
        } else {
          dispatch(getMessagesFailure('Empty list'));
        }
        newLastMessage = messages.docs[messages.docs.length - 1];
      } else {
        dispatch(getMessagesFailure('Empty list'));
      }
    } catch (e) {
      console.error(e)
      dispatch(getMessagesFailure());
    }
    return newLastMessage;
  }

    return {
      loadMessages
    };
  };
