import {Checkout} from "../../../../../assets/icons/Checkout";
import React from "react";
import './call-badges.scss'

const CallCheckout = () => {
  return (
    <span className='call-outcome checkout-started'>
      <Checkout />
      <p>Checkout</p>
    </span>
  );
};

export default CallCheckout;