import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import moment from 'moment';
import Calendar from 'react-calendar';
import { KpiSections } from './sidebar-components/kpiSection';
import {CALL_TYPES} from "../../utils/consts";

const HomepageSidebar = ({
  createMeeting,
  pastCalls,
  hostScheduleCalls,
  dailySummary,
  visibleDate,
  setVisibleDate,
  isShopInactive,
  feedbackType,
  callsHistorySummary
}) => {
  const [averageCallTime, setAverageCallTime] = useState(0);
  const [scheduledCallsCount, setScheduledCallsCount] = useState(0);
  const [checkoutCallsCount, setCheckoutCallsCount] = useState(0);
  const [isTodayShowing, setTodayShowing] = useState(true);
  const visibleDateLength = moment(visibleDate).format('D');
  const styleActiveDay = visibleDateLength.length === 1 ? 'style-one-symbol' : 'style-two-symbols';
  const { availableShops, activeShopId } = useSelector((state) => state.shops);
  const shopData = availableShops && activeShopId && availableShops[activeShopId];

  const averageCallTimeMin = Math.floor(averageCallTime / 60);
  const averageCallTimeSec = Math.floor(
    averageCallTime - averageCallTimeMin * 60
  );
  const averageCallTimeStr =
    averageCallTime > 0
      ? `${`${averageCallTimeMin} min`} ${averageCallTimeSec > 0 ? `${averageCallTimeSec} sec` : ''
      }`
      : 'N/A';

  useEffect(() => {
    if (pastCalls) {
      const completedCalls = pastCalls.filter(
        (call) => call.status === 'completed'
      );
      const totalDuration = completedCalls.reduce(
        (sum, call) => sum + call.videoDuration,
        0
      );
      const callsWithCheckout = completedCalls.filter((call) => call.checkouts);
      const averageDuration = completedCalls.length
        ? (totalDuration / completedCalls.length).toFixed(2)
        : 0;
      setAverageCallTime(averageDuration);
      setCheckoutCallsCount(callsWithCheckout.length);
    }
  }, [pastCalls]);

  useEffect(() => {
    if (hostScheduleCalls) {
      setScheduledCallsCount(hostScheduleCalls.filter(({customer}) => !!customer).length);
    }
  }, [hostScheduleCalls]);

  useEffect(() => {
    const today = new Date();
    const isToday = moment(today).isSame(visibleDate, 'day');
    setTodayShowing(isToday);
  }, [visibleDate]);

  const onChangeDate = date => {
    if (!moment(date).isSame(visibleDate, 'day')) {
      setVisibleDate(date)
    }
  }

  return (
    <aside>
      <section className='sidebar-header'>
        <Calendar
          onChange={onChangeDate}
          value={visibleDate}
          calendarType={'US'}
          next2Label={null}
          nextLabel={
            <ArrowForwardIosRoundedIcon
              style={{
                color: '#CFC8BB',
                WebkitTextFillColor: '#CFC8BB',
                fontSize: '16px',
              }}
            />
          }
          prevLabel={
            <ArrowBackIosRoundedIcon
              style={{
                color: '#CFC8BB',
                WebkitTextFillColor: '#CFC8BB',
                fontSize: '16px',
              }}
            />
          }
          prev2Label={null}
          className={`calendar ${styleActiveDay}`}
          view={'month'}
        />
        <hr />
      </section>
      <KpiSections
        feedbackType={feedbackType}
        dailySummary={dailySummary}
        shopData={shopData}
        averageCallTimeStr={averageCallTimeStr}
        scheduledCallsCount={scheduledCallsCount}
        callsHistorySummary={callsHistorySummary} />
    </aside>
  );
};

export default HomepageSidebar;
