import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { useDocumentData } from 'react-firebase-hooks/firestore';

export default function useClientViewHistory(callId) {

    const ref = callId
        ? firebase.firestore()
            .collection('calls')
            .doc(callId)
            .collection('sessionData')
            .doc('userRecentlyViewed')
        : null

    const [userRecentlyViewed, docLoading, docError] = useDocumentData(ref);
    docError && console.error('useClientViewHistory', docError);

    return { userRecentlyViewed };
}
