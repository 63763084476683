import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/analytics';
import 'firebase/compat/firestore';
import 'firebase/compat/messaging';

export default firebase.initializeApp({
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

// Initialize Cloud Functions through Firebase
var functions = firebase.functions();
//init analytics
firebase.analytics();

if (firebase.messaging.isSupported()) firebase.messaging();
