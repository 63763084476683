import { combineReducers } from '@reduxjs/toolkit';
import shopsReducer from './shops-reducer';
import userReducer from './user-reducer';
import clientReducer from './client-reducer';
import systemReducer from './system-reducer';
import prevCallReducrer from './prev-call-reducer';
import {rooms, activeRoom, chatMessages, chatNotifications} from '../modules/chat/reducers';
import activeCallReducer from './active-call-reducer';
import appDiagnostics from "./appDiagnostics";

const rootReducer = combineReducers({
  shops: shopsReducer,
  user: userReducer,
  system: systemReducer,
  rooms,
  activeRoom,
  chatMessages,
  chatNotifications,
  appDiagnostics,
  activeCall: activeCallReducer,
  prevCall: prevCallReducrer,
  clients: clientReducer
});

export default rootReducer;
