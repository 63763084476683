import Checkbox from "../../../../../components/checkbox";
import {StyledInput} from "../../../../../components";
import React, {useState} from "react";
import './delete-confirm-content.scss'
import {CHANGE_EVENT} from "../../../../../constants/const";

const DeleteConfirmContent = ({deleteConfirmInfo, onChange}) => {
  return (
    <div className='delete-confirm-content'>
      <p>{'Are you sure you want to cancel this appointment?'}</p>
      <Checkbox
        value={deleteConfirmInfo.noShow}
        label={'Mark as no show'}
        onChange={(change) => onChange(change, CHANGE_EVENT.NO_SHOW)}
      />
      <Checkbox
        value={!deleteConfirmInfo.disableClientEmail}
        label={'Send cancellation email to client'}
        onChange={(change) => onChange(change, CHANGE_EVENT.DISABLE_CLIENT_EMAIL)}
      />
      <textarea
        disabled={deleteConfirmInfo.disableClientEmail}
        name="description"
        rows='2'
        value={deleteConfirmInfo.message}
        placeholder="Message to client (optional)"
        onChange={(change) => onChange(change, CHANGE_EVENT.MESSAGE)}
        className='caazam-input'
      />

    </div>
  )
}

export default DeleteConfirmContent;