import { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useVideoAudioSettingsListener({shopId, hostId}) {
  const [videoProcessorConfig, setVideoProcessorConfig] = useState(null);
  const [isVideoConfigLoaded, setLoadingState] = useState(false);
  const [hostAppMuteVideoOnCallStart, setHostAppMuteVideoOnCallStart] = useState(false)

  useEffect(() => {
    if (hostId && shopId) {
      const unsub = firebase
        .firestore()
        .collection('shops')
        .doc(shopId)
        .collection('shopUsers')
        .doc(hostId)
        .collection('settings')
        .doc('audioVideoSettings')
        .onSnapshot(
          (snapshot) => {
            if (snapshot.exists) {
              const audioVideoConfig = snapshot.data();
              setVideoProcessorConfig(audioVideoConfig.processorParams || null);
              setHostAppMuteVideoOnCallStart(audioVideoConfig?.hostAppMuteVideoOnCallStart ?? false)
            } else {
              setVideoProcessorConfig(null);
            }
            setLoadingState(true);
          },
          (error) => {
            console.error('Error of video processor configs', error);
            setVideoProcessorConfig(null);
            setHostAppMuteVideoOnCallStart(true)
            setLoadingState(true);
          }
        );

      return () => unsub();
    }
  }, [hostId, shopId]);

  return {
    isVideoConfigLoaded,
    videoProcessorConfig,
    hostAppMuteVideoOnCallStart
  };
}
