
import React, { createContext, useContext, useEffect, useState } from 'react';
import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

import { useActiveCart } from '../hooks/useSessionCart';
import { appLogger } from '../logging';

export const CallContext = createContext(null);

export default function ActiveCallProvider({ children }) {
  const [drawerTab, setDrawerTab] = useState(0);
  const [isVideoHeightMin, setVideoHeightMin] = useState(false);

  const [activeCallId, setActiveCallId] = useState(null);
  const [activeCallData, setActiveCallData] = useState(null);
  useActiveCart(activeCallId);


  useEffect(() => {
    if (activeCallId) {
      appLogger.setCallContext(activeCallId);
      const dbRef = firebase.firestore().collection('calls').doc(activeCallId);
      const unsub = dbRef.onSnapshot(snapshot => {
        setActiveCallData(snapshot.data());
      })
      return () => {
        unsub();
        setActiveCallData(null);
        appLogger.setCallContext(null);
      }
    }
  }, [activeCallId])

  const openCart = () => setDrawerTab(2);

  const openShowRoom = () => {
    setDrawerTab(1);
    setVideoHeightMin(true);
  };
  const closeDrawer = () => {
    setDrawerTab(0);
    setVideoHeightMin(false);
  };

  return <CallContext.Provider value={{
    activeCallId,
    setActiveCallId,
    activeCallData,
    setActiveCallData,
    drawerTab,
    openCart,
    openShowRoom,
    closeDrawer,
    isVideoHeightMin
  }}>
    {children}
  </CallContext.Provider>;
}

export function useActiveCallProvider() {
  const context = useContext(CallContext);
  if (!context) {
    throw new Error('useActiveCallProvider must be used within the ActiveCallProvider');
  }
  return context;
}
