import * as React from "react"

const PresentToAllRounded = (props) => (
  <svg
    className="MuiSvgIcon-root"
    viewBox="0 0 24 24"
    aria-hidden="true"
    fill={props?.fill}
    style={props?.style}
    {...props}
  >
    <path d="M21 3H3c-1.11 0-2 .89-2 2v14c0 1.11.89 2 2 2h18c1.11 0 2-.89 2-2V5c0-1.11-.89-2-2-2zm-1 16.02H4c-.55 0-1-.45-1-1V5.98c0-.55.45-1 1-1h16c.55 0 1 .45 1 1v12.04c0 .55-.45 1-1 1zM10 12H8l3.65-3.65c.2-.2.51-.2.71 0L16 12h-2v4h-4v-4z" />
  </svg>
)

export default PresentToAllRounded
