import { useEffect, useCallback } from 'react';
import useCaazamREST from './useCaazamREST';
import { useBoutiqVideoRoomContext } from '@caazam/boutiq-video-room';

export default function useSpeakerEvents(shopId, callId, hostId) {
  const { onVideoEvent, offVideoEvent } = useBoutiqVideoRoomContext();
  const { callEvent } = useCaazamREST();

  const onSpeakerEvent = useCallback((event)=>{
    const eventData = {
      isHost: event.participantId === hostId,
      timestamp: event.timestamp,
      participantSid: event.participantSid,
      participantIdentity: event.participantId,
    };
    callEvent(shopId, callId, event.action, eventData);

  },[shopId, callId, hostId])

  useEffect(() => {
    onVideoEvent('participant_speaking', onSpeakerEvent);
    return () => offVideoEvent('participant_speaking', onSpeakerEvent);
  }, [onSpeakerEvent]);

}
