import LoadingCard from '../loading-card/loading-card';
import React, { Fragment } from 'react';
import './calls-history-skeleton.scss'

const CallsHistorySkeleton = ({callsNumber = 4}) => {
  return (
    Array(callsNumber).fill(0).map((_, key) => (
      <div className='list-history-calls-skeleton' key={key}>
        <Fragment key={key}>
          <LoadingCard
            paddingHorizontal={48}
            height={85}
            maxWidth={1024}
            width={'100%'}
            borderRadius={10}
            marginTop={10}
            marginBottom={10}
          />
        </Fragment>
      </div>
    ))
  )
}

export default CallsHistorySkeleton;
