import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {firestore} from '../../firebaseInitialization';
import {parseMsgObject} from "../../utils/parsers";
import {FIRESTORE_COLLECTIONS} from "../../constants/firestore";
import {clearMessages, saveNewMessage} from "../../actions/messages";

export default function useMessageListener() {
  const dispatch = useDispatch();
  const {id} = useSelector(state => state.activeRoom);
  const roomMessagesListener = useRef(null);

  const listenNewMessages = () => {
    const date = new Date();
    return firestore()
      .collection(FIRESTORE_COLLECTIONS.CHAT_ROOMS)
      .doc(id)
      .collection(FIRESTORE_COLLECTIONS.CHAT_MESSAGES)
      .where("timestamp", ">", date)
      .onSnapshot(
        (snapshot) => {
          const changes = snapshot.docChanges();
          if (changes) {
            const messages = []
            changes.forEach(ch => {
              if (ch?.type === 'added') {
                messages.push(parseMsgObject({...ch.doc.data(), id: ch.doc.id}));
              }
            })
            if (messages.length > 0) {
              dispatch(saveNewMessage(messages));
            }
          }
        }, listenError => console.error('listenRoomMessages', listenError));
  };

  useEffect(() => {
    if (id) {
      roomMessagesListener.current = listenNewMessages();
    }
    return () => {
      dispatch(clearMessages());
      if (roomMessagesListener.current) {
        roomMessagesListener.current();
      }
    }
  }, [id]);

};
