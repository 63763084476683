import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import useMetadataRequests from '../../../modules/chat/api/metadata/requests';
import './customer-chat.scss';
import {buildUserEntity} from '../../../modules/chat/utils/auth';
import {parseIdString} from '../../../utils';
import Sidebar, { SIDEBAR_FEATURES } from '../../video-room/components/sidebar/sidebar';
import {useSessionCart} from '../../../hooks';
import {useActiveCallProvider} from '../../../components/ActiveCallProvider';
import ChatMessenger from './chat-messenger';
import FullSizeImageModal from '../../../components/full-size-image-modal/fullSizeImageModal';

const CustomerChat = ({
  messageValue,
  setMessageValue,
  selectedRoom,
}) => {

  const clientParticipant = selectedRoom.participants[selectedRoom.initiator.uuid];
  const { user } = useSelector((state) => state.user);
  const { activeShopId } = useSelector((state) => state.shops);

  const [customer, setCustomer] = useState(null);
  const [isUserLoading, setUserLoading] = useState(true);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedSnapshotKey, setSelectedSnapshotKey] = useState(null);
  const [selectedProductVariantId, setSelectedProductVariantId] = useState(null);
  const [fullSizeChatImage, setFullSizeChatImage] = useState(null);

  const { updateClientsInfo } = useMetadataRequests({user: buildUserEntity(user)});

  const { activeCallId: contextId } = useActiveCallProvider();
  const { updateCartProduct } = useSessionCart(contextId);

  const onCustomerProfileChange = async (customerId, customerData) => {
    if (customerId) {
      await updateClientsInfo({ participantUuid: clientParticipant.uuid, profileId: customerId, chatId: selectedRoom.id });
    }
    setCustomer(customerData);
  };

  const clearSelectedProductIds = () => {
    setSelectedProductId(null);
    setSelectedSnapshotKey(null);
    setSelectedProductVariantId(null);
  };

  const selectProduct = (productId) => {
    clearSelectedProductIds();
    setSelectedProductId(productId);
  };

  const saveVariant = (
    variantDirtyId,
    variantTitle,
    currentPrice,
    image,
    inventoryPolicy,
    inventoryQuantity,
  ) => {
    const variantId = parseIdString(variantDirtyId);
    updateCartProduct(selectedSnapshotKey, {
      variantId,
      variantTitle,
      currentPrice,
      image,
      inventoryPolicy,
      inventoryQuantity,
    });
  };

  const hideModal = () => {
    setFullSizeChatImage(null);
  };

  return (
    <div className='row-container'>
      <div className='customer-block'>
        <ChatMessenger
          messageValue={messageValue}
          setMessageValue={setMessageValue}
          selectedRoom={selectedRoom}
          setFullSizeChatImage={setFullSizeChatImage}
        />
        {!!fullSizeChatImage &&
          <FullSizeImageModal
            fullSizeChatImage={fullSizeChatImage}
            closeAction={hideModal}
          />
        }
      </div>
      <div className='product-block-container'>
        <Sidebar
          customer={customer}
          setCustomer={setCustomer}
          clientParticipant={clientParticipant}
          onCustomerChange={onCustomerProfileChange}
          shopId={activeShopId}
          isUserLoading={isUserLoading}
          setUserLoading={setUserLoading}
          clearSelectedProductIds={clearSelectedProductIds}
          selectedProductVariantId={selectedProductVariantId}
          updateProduct={saveVariant}
          selectedSnapshotKey={selectedSnapshotKey}
          selectedProductId={selectedProductId}
          selectProduct={selectProduct}
          allowProductDetails={false}
          featureSwitch={[SIDEBAR_FEATURES.CUSTOMER]}
        />
      </div>
    </div>
  )
}

export default CustomerChat;
