import React from 'react';
import './call-form-responses.scss';
import {resultRenderer} from "../../../../utils";

const CallFormResponses = ({clientForm, visible}) => {
  return (
    <div className={`form-responses-tab${visible ? ' visible' : ''}`}>
      {clientForm && clientForm.data.map(field => (
        <div className='form-responses-item-container' key={field.id}>
          {field.label && <p className='form-responses-item-label'>{field.label}</p>}
          <div className='form-responses-item-value'>
            {Array.isArray(field.value) ? (
              resultRenderer(field.value).map((item, index) => (
                <p className='form-responses-item' key={index}>{item}</p>
              ))
            ) : (
              <p className='form-responses-item'>{resultRenderer(field.value)}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  )

}

export default CallFormResponses;