import React from "react";
import './call-details-showroom-item.scss'
import EmptyImage from "../../../../../../components/emptyImage";
import Checkbox from "../../../../../../components/checkbox";
import ProductPrice from "../../../../../../components/productPrice";
import {FilledButton} from "../../../../../../components";
import {useCallDetailsProvider} from "../../../call-details-provider/call-details-provider";

const CallDetailsShowroomItem = ({product, isSelectedProduct, selectProduct, hideCreateCartButton, addProductToCart, loader}) => {
  const {draftOrder, callDetails} = useCallDetailsProvider()

  const addButton = () => {
    if ((draftOrder && !isSelectedProduct) || Object.keys(callDetails.orders ?? {}).length > 0) {
      return
    }
    return (
      <FilledButton
        isLoading={loader}
        loaderSize={'26px'}
        style={{
          backgroundColor: isSelectedProduct? 'transparent' : 'var(--main-color)',
          height: '28px',
          fontSize: '14px',
          padding: '0 10px',
          width: 'auto',
          border: isSelectedProduct ? '1px solid var(--main-color)' : 'none',
          color: isSelectedProduct ? 'var(--main-color)' : 'white',
          WebkitTextFillColor: isSelectedProduct ? 'var(--main-color)' : 'white',
          cursor: isSelectedProduct ? 'default' : 'pointer',
        }}
        onClick={() => {
          !isSelectedProduct && addProductToCart(product);
        }}>
        {isSelectedProduct ? 'Added' : 'Add'}
      </FilledButton>
    )
  }

  return (
    <div
      className={`call-details-showroom-item${isSelectedProduct && !hideCreateCartButton ? ' selected' : ''}`}
      key={product.productId}
    >
      {product.image ? (
        <img
          className='main-image'
          src={product.image}
        />
      ) : (
        <EmptyImage product={product} size={'xs'}/>
      )}
      <div className='product-description-wrapper'>
        <div className='product-description'>
          <span className='product-title'>{product.productTitle}</span>
          <ProductPrice
            product={product}
          />
        </div>
        {hideCreateCartButton ? (
          addButton()
        ) : (
          <Checkbox
            value={isSelectedProduct}
            label={'add to cart'}
            onChange={() => selectProduct(product)}
          />
        )}
      </div>
    </div>
  )
}

export default CallDetailsShowroomItem;