import {useDispatch, useSelector} from 'react-redux';
import {firestore} from '../../firebaseInitialization';
import {FIRESTORE_COLLECTIONS} from "../../constants/firestore";
import {
  sendTypingStatusFailure,
  sendTypingStatusRequest,
  sendTypingStatusSuccess,
} from "../../actions/activeRoom";

export default function useFeatureRequests({ userUuid }) {
  const dispatch = useDispatch();
  const { id: activeChatId } = useSelector(state => state.activeRoom);

  const updateTypingStatus = ({ typing, roomId }) => {
    const roomIdToUpdate = roomId || activeChatId;

    if (!roomIdToUpdate) {
      return;
    }

    dispatch(sendTypingStatusRequest());
    const metaRef = firestore()
      .collection(FIRESTORE_COLLECTIONS.CHAT_ROOMS)
      .doc(roomIdToUpdate)
      .collection(FIRESTORE_COLLECTIONS.ROOM_FEATURES)
      .doc(FIRESTORE_COLLECTIONS.TYPING_DOC)

    return metaRef.set({ [userUuid]: typing }, { merge : true })
      .then(() => dispatch(sendTypingStatusSuccess()))
      .catch((error) => dispatch(sendTypingStatusFailure(error.message)));
  }

  return {
    updateTypingStatus,
  };
}
