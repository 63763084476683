import React, { useRef, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useEffect } from 'react';

export default function useCallParticipants(callId, onParticipantConnect = null, onParticipantDisconnect = null ) {
  const participantsRef = firebase.firestore().collection('calls').doc(callId).collection('participants');
  const [ participants, setParticipnats ] = useState({});
  const prevConnectStatus = useRef({});

  useEffect(() => {
    const unsub = participantsRef.onSnapshot(newParticipants => {
      if (newParticipants) {
        setParticipnats(newParticipants.docs.reduce((agg, participant) => {
          agg[participant.id] = participant.data();
          return agg;
        }, {}));
      } else {
        setParticipnats({})
      }
    });
    return () => unsub();
  }, [callId]);

  useEffect(() => {
    for (const id in participants) {
      if (!participants[id].disconnected && !prevConnectStatus.current[id]) {
        onParticipantConnect && onParticipantConnect(id, participants[id]);
      } else if (prevConnectStatus.current[id] && participants[id].disconnected) {
        onParticipantDisconnect && onParticipantDisconnect(id, participants[id]);
      }
    }

    prevConnectStatus.current = Object.keys(participants).reduce((agg, id) => {
      agg[id] = !participants[id].disconnected;
      return agg;
    }, {})
  }, [participants, onParticipantConnect, onParticipantDisconnect])

  return { participants };
};
