import React from 'react';
import './daily-calendar.scss';
import { CalendarRow } from "../calendar-row/calendar-row";
import { CALL_TYPES } from "../../../utils/consts";

const DailyCalendar = ({
  callList,
  calendarType,
  feedbackType,
  selectedId,
  draftOrderConfig,
  onOpenBlockOffTime
}) => {
  if (!callList.length) return null;
  return (
    <ul
      className={`daily-calendar ${calendarType.toLowerCase()}`}>
      {callList.map((call, i) => {
        const firsScheduledCallIndex = callList.findIndex(({type}) => type !== CALL_TYPES.BLOCKED_EVENT)
        return (
          <li key={i}>
            <CalendarRow
              showDivider={callList.length - 1 !== i && callList[i + 1]?.type === CALL_TYPES.SCHEDULED}
              call={call}
              feedbackType={feedbackType}
              isDetailsOpen={!!selectedId}
              isSelected={call.id === selectedId}
              draftOrderConfig={draftOrderConfig}
              isFirstChild={calendarType === CALL_TYPES.SCHEDULED && firsScheduledCallIndex === i}
              onOpenBlockOffTime={onOpenBlockOffTime}
            />
          </li>
        )
      })}
    </ul>
  );
};

export default DailyCalendar;
